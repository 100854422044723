import { DataFilterValueDto } from '@shared/service-proxies';
import { BaseDataFilterValueBuilder } from '../base-data-filter-value-builder';

/**
 * Создатель моделей фильтров поиска по одному значению
 */
export class DefaultDataFilterValueBuilder extends BaseDataFilterValueBuilder {
    /**
     * @override
     */
    protected _build(): DataFilterValueDto {
        return new DataFilterValueDto({
            dataFilterId: this._filterId,
            values: { value: this._value },
        });
    }
}

﻿import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';
import { NotFoundComponent, UserRoles } from '@shared/index';
import { AuthGuard } from './auth/guards/index';
import { AppRouteGuard } from 'shared';

const routes: Routes = [
    { path: '', redirectTo: '/account/login', pathMatch: 'full' },
    {
        path: 'public',
        loadChildren: 'public/public.module#PublicModule',
        data: { preload: true },
    },
    {
        path: 'caseByAgreement',
        loadChildren: 'casebyagreement/casebyagreement.module#CaseByAgreementModule',
        data: { preload: true },
    },
    {
        path: 'account',
        loadChildren: 'auth/auth.module#AuthModule', // Lazy load auth module
        data: { preload: true },
    },
    {
        path: 'app',
        loadChildren: 'app/app.module#AppModule', // Lazy load account module
        canActivate: [AuthGuard],
        data: {
            allowForRoles: [UserRoles.admin, UserRoles.moderator],
            preload: true
        },
    },
    {
        path: 'article-51',
        loadChildren: 'lk-lawyer/lk-lawyer.module#LkLawyerModule', // Lazy load account module
        data: { preload: true },
    },
    {
        path: 'lk-lawyer',
        loadChildren: 'lk-lawyer/lk-lawyer.module#LkLawyerModule', // Lazy load account module
        data: {
            allowForRoles: [UserRoles.lawyer],
            preload: true,
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'accountant-ao',
        loadChildren: 'accountant-lawyer-organization/accountant-lawyer-organization.module#AccountantLawyerOrganizationModule', // Lazy load account module
        canActivate: [AuthGuard],
        data: {
            allowForRoles: [UserRoles.organizationHead, UserRoles.formationAccount],
            preload: true
        },
    },
    {
        path: 'accountant-apm',
        loadChildren: 'accountant-lawyer-chamber-moscow/accountant-lawyer-chamber-moscow.module#AccountantLawyerChamberMoscowModule', // Lazy load account module
        canActivate: [AuthGuard],
        data: {
            allowForRoles: [UserRoles.admin, UserRoles.moderator, UserRoles.chamberAccountant],
            preload: true
        },
    },
    {
        path: 'moderation-conference',
        loadChildren: 'moderation-conference/moderation-conference.module#ModerationConferenceModule', // Lazy load moderation conference module
        canActivate: [AuthGuard],
        data: {
            allowForRoles: [UserRoles.admin, UserRoles.moderator],
            preload: true
        },
    },
    {
        path: 'users',
        loadChildren: 'users/users.module#UsersModule', // Lazy load users module
        canActivate: [AuthGuard],
        data: {
            allowForRoles: [UserRoles.admin, UserRoles.moderator],
            preload: true
        },
    },
    {
        path: 'statements',
        loadChildren: 'statements/statements.module#StatementsModule', // Lazy load statements module
        canActivate: [AuthGuard],
        data: {
            allowForRoles: [UserRoles.admin, UserRoles.moderator],
            preload: true
        },
    },
    {
        path: 'initiator',
        loadChildren: 'initiator/lc-initiator.module#LcInitiatorModule', // Lazy load initiator module
        data: { preload: true },
    },
    {
        path: 'signup',
        redirectTo: 'account/signup',
    },
    {
        path: 'signup-email',
        redirectTo: 'account/signup-email',
    },
    {
        path: 'registry',
        loadChildren: 'registry/registry.module#RegistryModule',
        canActivate: [AuthGuard],
        data: {
            allowForRoles: [UserRoles.admin, UserRoles.moderator, UserRoles.humanResourcesOfficer, UserRoles.chamberAccountant, UserRoles.assistantManager],
            preload: true
        },
    },
    {
        path: 'moderator',
        loadChildren: 'moderator/moderator.module#ModeratorModule',
        data: { preload: true },
    },
    {
        path: 'sitemap',
        loadChildren: 'sitemap/sitemap.module#SitemapModule',
        data: { preload: true },
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {}

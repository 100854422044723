import { Injectable, Injector } from '@angular/core';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { AppSessionService } from '@shared/service-global/app-session.service';

@Injectable({
    providedIn: 'root',
})
export class AuthRedirectFactoryService {
    private _sessionService: AppSessionService;

    constructor(private _injector: Injector) {
        this._sessionService = _injector.get(AppSessionService);
    }

    /**
     * Возвращает url для перехода в нужный модуль в записимости от роли пользователя
     *
     * todo уточнить все роли и url
     */
    createUrl(): string | undefined {
        // fixme на данный момент "admin" выполняет роль модератора. заменить в будущем
        if (this._sessionService.containRole(UserRoles.admin)) {
            return '/moderator/request-list';
        }

        if (this._sessionService.containRole(UserRoles.chamberAccountant)) {
            return '/accountant-apm';
        }

        if (this._sessionService.containRole(UserRoles.humanResourcesOfficer)) {
            return '/registry/lawyers';
        }

        // todo роль "moderator" на данный момент не настроена на сервере
        if (this._sessionService.containRole(UserRoles.moderator)) {
            return '/moderator/request-list';
        }

        if (this._sessionService.containRole(UserRoles.lawyer)) {
            return '/lk-lawyer';
        }

        if (this._sessionService.containRole(UserRoles.formationAccount)) {
            return '/accountant-ao';
        }

        if (this._sessionService.containRole(UserRoles.assistantManager)) {
            return '/moderator/request-list';
        }

        // todo на данный момент у инициатора своя страница логина. переделать в будущем
        if (this._sessionService.containRole(UserRoles.initiator)) {
            return '/initiator/request/new';
        }
    }
}

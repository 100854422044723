import { EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IFileDataDto, MaterialIconHelper as MIcon, UtilHelper, IInstance } from './_imports';

export interface IFileUploaderButtonOption {
    css?: string;
    text?: string;
    icon?: string;
}
export interface IFileUploaderOption extends IInstance {
    instanceId: string;
    selectButton: IFileUploaderButtonOption;
    deleteButton: IFileUploaderButtonOption;
    accept?: string;
    name?: string;
    fileInfo?: IFileInfo[];
    fileData?: IFileDataDto[];
    multiple?: boolean;
    disabled: boolean;
    localDelete?: boolean;
}

export interface IFileInfo {
    id: string;
    /**Unic value for current file, using for notification */
    uiId: string;
}

export enum FileUploaderLoadState {
    /**File in pending  state, lock form for that state */
    loading = 'loading',
    /** loading finished, all data exists, we can unlock our form */
    finish = 'finish',
    /** some error in process */
    error = 'error',
}

export interface IFileUploaderLoadingEventData extends IInstance {
    loadingState: FileUploaderLoadState;
}

export interface IFileUploaderOnLoadedEventData extends IInstance {
    loadedViewFiles: IFileDataView[];
}

export interface IFileUploaderOnDeleteEventData extends IInstance {
    fileInfo: IFileInfo;
}
export interface IFileCommands {
    disable(instanceId: string): void;
    enable(instanceId: string): void;
    reset(instanceId: string): void;
}

export interface IFileNotifier {
    onDelete: EventEmitter<IFileUploaderOnDeleteEventData>;
    onLoaded: EventEmitter<IFileUploaderOnLoadedEventData>;
    onLoadStateChange: EventEmitter<IFileUploaderLoadingEventData>;
}

export interface IFileUploaderNotifier extends IFileCommands, IFileNotifier {
    toUniqFileIds(files: IFileDataView[]): string[];
    removeFromInstance(files: IFileInfo[], fileId: string): void;
    initDefaultSubscriptions(destructor: Subject<any>, cfg: IFileComponentSubscription): void;
}

interface IFileLoader {
    url: string;
    loadFiles(fileInfoes: IFileInfo[]): Observable<IFileDataView[]>;
    upload(file: IFileUploadModel): Observable<IFileDataView[]>;
    uploadBulk(files: IFileUploadModel[]): Observable<IFileDataView[]>;
    delete(fileInfo: IFileInfo): Observable<IFileInfo>;
}
export interface IFileUploaderPrivateService extends IFileLoader {
    onDisable: EventEmitter<string>;
    onEnable: EventEmitter<string>;
    onReset: EventEmitter<string>;
    copyAsFileInfo(fileInfoes: IFileInfo[]): IFileInfo[];
    mapToView(fileInfo: IFileInfo[], fileData: IFileDataDto[]): IFileDataView[];
}

export class FileUploaderOption implements IFileUploaderOption {
    selectButton: IFileUploaderButtonOption;
    deleteButton: IFileUploaderButtonOption;
    accept?: string;
    fileInfo?: IFileInfo[];
    fileData?: IFileDataDto[];
    multiple: boolean;
    disabled: boolean;
    constructor(
        public instanceId: string,
        selectButton?: IFileUploaderButtonOption,
        deleteButton?: IFileUploaderButtonOption,
        accept?: string,
        disabled = true,
        public name = 'file',
    ) {
        this.selectButton = {
            icon: MIcon.createMaterialIcon(MIcon.fileUpload),
            text: 'Прикрепить файл',
            ...selectButton,
        };
        this.deleteButton = {
            icon: MIcon.createMaterialIcon(MIcon.delete),
            text: 'Удалить файл',
            ...deleteButton,
        };
        // this.accept = accept || "image/*";
        this.accept = accept;
        this.multiple = false;
        this.disabled = disabled;
    }
}

export interface IFileDataView extends IFileDataDto, IFileInfo {}

export interface IFileUploadModel extends IFileInfo {
    index: number;
    data: File;
    name?: string;
}
export class FileUploadModel implements IFileUploadModel {
    id: string;
    uiId: string;
    index: number;
    data: File;
    constructor(public name = 'file') {
        this.uiId = UtilHelper.createQuickGuid();
    }
}

export interface IFileComponentSubscription extends IInstance {
    /**
     * если возвращает тру вызывает  enable  у файла, иначе ничего не делает
     */
    onLoaded: (loadedFiles: IFileDataView[]) => boolean;
    // реакция на смену состояний
    /**
     * начался процесс загрузки файла
     */
    onLoading: () => void;
    /**
     * закончился процесс загрузки файла или возникла ошибка
     */
    onFinish: () => void;
    /**
     *  если не указан будет отрабатывать финиш
     */
    onError?: () => void;
    /**
     * если указан делается подписка на удаление иначе ничего не делает
     */
    onDelete?: (fileInfo: IFileInfo) => void;
}

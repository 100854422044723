import { IsTenantAvailableOutputState } from '@shared/service-proxies/service-proxies';
var AppTenantAvailabilityState = /** @class */ (function () {
    function AppTenantAvailabilityState() {
    }
    AppTenantAvailabilityState.Available = IsTenantAvailableOutputState._1;
    AppTenantAvailabilityState.InActive = IsTenantAvailableOutputState._2;
    AppTenantAvailabilityState.NotFound = IsTenantAvailableOutputState._3;
    return AppTenantAvailabilityState;
}());
export { AppTenantAvailabilityState };

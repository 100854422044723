import * as moment from 'moment';
import { indexOf } from 'lodash';
/**
 * Форматер временного периода.
 * ---
 *
 * Временной период может быть представлен строкой вида 0.00:00:00.0000000 (дни.часы:минуты:секунды.доли_секунды).
 * Формат выводимого значения задается шаблоном. Шаблон представляет строку,
 * в котором будут заменены специальные символы или группа символов.
 * Символы, отличные от специальных, заменены не будут.
 *
 * ## Специальные символы для замены в шаблоне:
 *
 * * d - число дней временного периода (0, 1);
 * * H - число часов временного периода (0, 1);
 * * HH - число часов временного периода с ведущим нулем (00, 01);
 * * m - число минут временного периода (0, 1);
 * * mm - число минут временного периода с ведущим нулем (00, 01);
 * * s - число секунд временного периода (0, 1);
 * * ss - число секунд временного периода с ведущим нулем (00, 01).
 *
 * ## Шаблон по умолчанию
 *
 * Если шаблон форматирования не передан, то будет использован шаблон по умолчанию - d.HH:mm:ss
 *
 * ## Пересчет значений
 *
 * Если в шаблоне форматирования не указаны, например, дни, а в передаваемом значении они есть, то значение дней будет
 * переведено в часы и добавлено к значению часов при выводе. Иначе говоря, если в шаблоне не указаны старшие параметры
 * (для часов - дни, для минут - часы, для секунд - минуты), то эти параметры будут преобразованы в значения младших и
 * добавлены к ним.
 */
var DatePeriodFormatter = /** @class */ (function () {
    function DatePeriodFormatter() {
        this.dayPattern = 'd';
        this.hourLargePattern = 'HH';
        this.hourShortPattern = 'H';
        this.minuteLargePattern = 'mm';
        this.minuteShortPattern = 'm';
        this.secondLargePattern = 'ss';
        this.secondShortPattern = 's';
    }
    Object.defineProperty(DatePeriodFormatter, "_formatter", {
        get: function () {
            if (!DatePeriodFormatter._instance) {
                DatePeriodFormatter._instance = new DatePeriodFormatter();
            }
            return DatePeriodFormatter._instance;
        },
        enumerable: true,
        configurable: true
    });
    DatePeriodFormatter.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!value) {
            return '';
        }
        var formatter = DatePeriodFormatter._formatter;
        formatter._duration = moment.duration(value);
        formatter._pattern = args[0] || formatter._getDefaultPattern();
        return formatter._format(formatter._pattern);
    };
    DatePeriodFormatter.prototype._format = function (pattern) {
        var result = pattern
            .replace(this.dayPattern, "" + this._getDays())
            .replace(this.hourLargePattern, this._padZero(this._getHours()))
            .replace(this.hourShortPattern, "" + this._getHours())
            .replace(this.minuteLargePattern, this._padZero(this._getMinutes()))
            .replace(this.minuteShortPattern, "" + this._getMinutes())
            .replace(this.secondLargePattern, this._padZero(this._getSeconds()))
            .replace(this.secondShortPattern, "" + this._getSeconds());
        return this._sanitize(result);
    };
    DatePeriodFormatter.prototype._getDefaultPattern = function () {
        return this.dayPattern + "." + this.hourLargePattern + ":" + this.minuteLargePattern + ":" + this.secondLargePattern;
    };
    DatePeriodFormatter.prototype._getDays = function () {
        return this._duration.days();
    };
    DatePeriodFormatter.prototype._getHours = function () {
        var hours = this._duration.hours();
        if (!this._hasDayPattern()) {
            hours += this._getDays() * 24;
        }
        return hours;
    };
    DatePeriodFormatter.prototype._getMinutes = function () {
        var minutes = this._duration.minutes();
        if (!this._hasHourPattern()) {
            minutes += this._getHours() * 60;
        }
        return minutes;
    };
    DatePeriodFormatter.prototype._getSeconds = function () {
        var seconds = this._duration.seconds();
        if (!this._hasMinutePattern()) {
            seconds += this._getMinutes() * 60;
        }
        return seconds;
    };
    DatePeriodFormatter.prototype._hasDayPattern = function () {
        return this._isPatternInclude(this.dayPattern);
    };
    DatePeriodFormatter.prototype._hasHourPattern = function () {
        return this._isPatternInclude(this.hourShortPattern);
    };
    DatePeriodFormatter.prototype._hasMinutePattern = function () {
        return this._isPatternInclude(this.minuteShortPattern);
    };
    DatePeriodFormatter.prototype._isPatternInclude = function (value) {
        if (!value) {
            return false;
        }
        return indexOf(this._pattern, value) !== -1;
    };
    DatePeriodFormatter.prototype._padZero = function (value) {
        if (value < 10) {
            return "0" + value;
        }
        return "" + value;
    };
    DatePeriodFormatter.prototype._sanitize = function (value) {
        return value.replace(/[dHms]+[.:]?/g, '').replace(/[.:]$/, '');
    };
    return DatePeriodFormatter;
}());
export { DatePeriodFormatter };

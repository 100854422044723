import {
    Component,
    Input,
    OnInit,
    ViewChild,
    TemplateRef,
    ChangeDetectionStrategy,
    DoCheck,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppFormBtnType } from './_imports';
import { IAppFormBtn } from './_buttons';

@Component({
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        class: 'app-form-btn',
    },
    selector: 'app-form-btn',
    templateUrl: './app-form-btn.component.html',
    styleUrls: ['./app-form-btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFormBtnComponent implements OnInit, DoCheck, OnDestroy {
    @Input() btn: IAppFormBtn;
    @Input() formGroupRef?: FormGroup;

    btnTemplate: TemplateRef<IAppFormBtn>;

    @ViewChild(AppFormBtnType.simple)
    private simple: TemplateRef<IAppFormBtn>;

    @ViewChild(AppFormBtnType.icon)
    private icon: TemplateRef<IAppFormBtn>;
    @ViewChild(AppFormBtnType.flat)
    private flat: TemplateRef<IAppFormBtn>;
    @ViewChild(AppFormBtnType.raised)
    private raised: TemplateRef<IAppFormBtn>;
    @ViewChild(AppFormBtnType.stroked)
    private stroked: TemplateRef<IAppFormBtn>;
    @ViewChild(AppFormBtnType.fab)
    private fab: TemplateRef<IAppFormBtn>;
    @ViewChild(AppFormBtnType.miniFab)
    private miniFab: TemplateRef<IAppFormBtn>;
    // @ViewChild(AppFormBtnType.templateBtn)
    // private templateBtn: TemplateRef<IAppFormBtn>;

    @ViewChild('default')
    private default: TemplateRef<IAppFormBtn>;

    private _old: IAppFormBtn;
    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        switch (this.btn.type) {
            case AppFormBtnType.simple:
                this.btnTemplate = this.simple;
                break;
            case AppFormBtnType.icon:
                this.btnTemplate = this.icon;
                break;
            case AppFormBtnType.flat:
                this.btnTemplate = this.flat;
                break;
            case AppFormBtnType.raised:
                this.btnTemplate = this.raised;
                break;
            case AppFormBtnType.stroked:
                this.btnTemplate = this.stroked;
                break;
            case AppFormBtnType.fab:
                this.btnTemplate = this.fab;
                break;
            case AppFormBtnType.miniFab:
                this.btnTemplate = this.miniFab;
                break;
            // case AppFormBtnType.templateBtn:
            //     this.btnTemplate = this.templateBtn;
            //     break;
            default:
                this.btnTemplate = this.default;
                break;
        }

        this._old = {
            css: this.btn.css,
            disabled: this.btn.disabled,
            type: this.btn.type,
            value: this.btn.value,
            onClick: this.btn.onClick,
        };
    }
    ngDoCheck() {
        let detectChanges;
        if (this._old.css !== this.btn.css) {
            detectChanges = true;
            this._old.css = this.btn.css;
        }
        if (this._old.disabled !== this.btn.disabled) {
            detectChanges = true;
            this._old.disabled = this.btn.disabled;
        }
        if (this._old.type !== this.btn.type) {
            detectChanges = true;
            this._old.type = this.btn.type;
        }
        if (this._old.value !== this.btn.value) {
            detectChanges = true;
            this._old.value = this.btn.value;
        }
        if (this._old.onClick !== this.btn.onClick) {
            detectChanges = true;
            this._old.onClick = this.btn.onClick;
        }
        if (detectChanges) {
            this._changeDetectorRef.detectChanges();
        }
    }

    onClick(e: Event): void {
        if (e.defaultPrevented || this.btn.disabled) {
            return;
        }
        this.btn.onClick(e, this);
        this._changeDetectorRef.detectChanges();
    }
    ngOnDestroy(): void {
        this.btn = null;
        this._old = null;
    }
}

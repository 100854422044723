import { DataFilterValueDto } from '@shared/service-proxies';
import { BaseDataFilterValueBuilder } from '../base-data-filter-value-builder';

/**
 * Создатель моделей фильтров поиска по нескольким значениям
 */
export class MultiDataFilterValueBuilder extends BaseDataFilterValueBuilder {
    /**
     * @override
     */
    protected _build(): DataFilterValueDto {
        return new DataFilterValueDto({
            dataFilterId: this._filterId,
            values: { values: this._value },
        });
    }

    /**
     * @override
     */
    setValue(value: any[]): this {
        return super.setValue(value);
    }
}

import { DataFilterValueDto } from '@shared/service-proxies';
import { BaseDataFilterValueBuilder } from '../base-data-filter-value-builder';

/**
 * Создатель моделей фильтров поиска по диапазону значений "От" - "До"
 */
export class RangeDataFilterValueBuilder extends BaseDataFilterValueBuilder {
    /**
     * @override
     */
    protected _build(): DataFilterValueDto {
        return new DataFilterValueDto({
            dataFilterId: this._filterId,
            values: {
                fromValue: this._getParam(this._value, 'fromValue'),
                toValue: this._getParam(this._value, 'toValue'),
            },
        });
    }

    private _getParam(source, paramName: string): any {
        if (!source || !source[paramName]) return null;

        return source[paramName];
    }
}

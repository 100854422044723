import * as moment from 'moment';

/**
 * Интерфейс заявки, выводимой при сканировании ее qr кода
 */
interface IQrRequestInfo {
    /**
     * Номер заявки
     */
    requestNumber: string;

    /**
     * Дата создания заявки
     */
    dateCreate: moment.Moment;

    /**
     * Дата судебного заседания
     */
    hearingDate: moment.Moment;

    /**
     * Адрес заведения, где была создана заявка
     */
    departmentAddress: string;

    /**
     * Номер дела
     */
    caseNumber: string;

    /**
     * Информация о назначенном адвокате
     */
    lawyer?: {
        /**
         * Полное имя адвоката
         */
        fullName: string;

        /**
         * Регистрационный номер
         */
        registryNumber: string;
    };
}

/**
 * Модель заявки, выводимой при сканировании ее qr кода
 */
export class QrRequestInfo implements IQrRequestInfo {
    requestNumber: string;
    dateCreate: moment.Moment;
    hearingDate: moment.Moment;
    departmentAddress: string;
    judicialAuthorityName: string;
    caseNumber: string;
    lawyer?: {
        fullName: string;
        registryNumber: string;
    };

    constructor() {}

    init(data?: IQrRequestInfo): void {
        if (data) {
            this.requestNumber = data['requestNumber'];
            this.dateCreate = data['dateCreate'] && moment(data['dateCreate']);
            this.hearingDate = data['hearingDate'] && moment(data['hearingDate']);
            this.judicialAuthorityName = data['judicialAuthorityName'];
            this.departmentAddress = (<any>data['departmentAddress']).PostIndex
            ? (<any>data['departmentAddress']).PostIndex + ", " + (<any>data['departmentAddress']).Place + ", " + (<any>data['departmentAddress']).Address1
            : data['departmentAddress'];
            this.caseNumber = data['caseNumber'];

            if (data['lawyer']) {
                const lawyer = data['lawyer'];

                this.lawyer = {
                    fullName: lawyer.fullName,
                    registryNumber: lawyer.registryNumber,
                };
            }
        }
    }
}

﻿import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from './app-session.service';

@Injectable({
    providedIn: 'root',
})
export class AppUrlService {
    static tenancyNamePlaceHolder = '{TENANCY_NAME}';
    private _urlStorage: { [key: string]: string } = Object.create(null);

    constructor(private readonly _appSessionService: AppSessionService) {}

    get appRootUrl(): string {
        if (this._appSessionService.tenant) {
            return this.getAppRootUrlOfTenant(this._appSessionService.tenant.tenancyName);
        } else {
            return this.getAppRootUrlOfTenant(null);
        }
    }

    /**
     * Returning url ends with '/'.
     */
    getAppRootUrlOfTenant(tenancyName?: string): string {
        let baseUrl = this.ensureEndsWith(AppConsts.appBaseUrl, '/');

        if (baseUrl.indexOf(AppUrlService.tenancyNamePlaceHolder) < 0) {
            return baseUrl;
        }

        if (baseUrl.indexOf(AppUrlService.tenancyNamePlaceHolder + '.') >= 0) {
            baseUrl = baseUrl.replace(AppUrlService.tenancyNamePlaceHolder + '.', AppUrlService.tenancyNamePlaceHolder);
            if (tenancyName) {
                tenancyName = tenancyName + '.';
            }
        }

        if (!tenancyName) {
            return baseUrl.replace(AppUrlService.tenancyNamePlaceHolder, '');
        }

        return baseUrl.replace(AppUrlService.tenancyNamePlaceHolder, tenancyName);
    }

    getUrl(urlKey: string): string | null {
        if (this._urlStorage[urlKey]) {
            return this._urlStorage[urlKey];
        }
        return null;
    }
    setUrl(urlKey: string, urlValue: string): void {
        if (!urlKey || !urlValue) {
            throw new Error('argument error');
        }
        this._urlStorage[urlKey] = urlValue;
    }
    removeUrl(urlKey: string): void {
        if (this._urlStorage[urlKey]) {
            delete this._urlStorage[urlKey];
        }
    }

    private ensureEndsWith(str: string, c: string) {
        if (str.charAt(str.length - 1) !== c) {
            str = str + c;
        }

        return str;
    }

    private removeFromEnd(str: string, c: string) {
        if (str.charAt(str.length - 1) === c) {
            str = str.substr(0, str.length - 1);
        }

        return str;
    }
}

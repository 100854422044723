import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SitemapRoutingModule } from './sitemap-routing.module';
import { SitemapComponent } from './sitemap.component';

@NgModule({
    imports: [CommonModule, SitemapRoutingModule],
    declarations: [SitemapComponent],
    exports: [],
})
/**
 * Модуль карты сайта
 */
export class SitemapModule {}

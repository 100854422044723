import { TokenService as AbpTokenService } from '@abp/auth/token.service';
import { Injector } from '@angular/core';
import { of } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { ApmAccountServiceProxy, RestorePasswordInput, } from '@shared/service-proxies';
import { AuthCookieService } from './auth-cookie.service';
import * as i0 from "@angular/core";
var AuthTokenService = /** @class */ (function () {
    function AuthTokenService(injector) {
        this._apmAccountServiceProxy = injector.get(ApmAccountServiceProxy);
        this._abpTokenService = injector.get(AbpTokenService);
        this._cookieService = injector.get(AuthCookieService);
    }
    AuthTokenService.prototype.authenticate = function (model) {
        // return this._tokenAuthServiceProxy.authenticate(new AuthenticateModel(model));
        return of([]);
    };
    AuthTokenService.prototype.authenticateByContact = function (model) {
        // return this._tokenAuthServiceProxy.authenticateByContact(new AuthenticateByContactModel(model));
        return of([]);
    };
    AuthTokenService.prototype.authenticateByTicket = function (model) {
        // return this._tokenAuthServiceProxy.authenticateByTicket(new AuthenticateByTicketModel(model));
        return of([]);
    };
    AuthTokenService.prototype.emailValidate = function (email) {
        return this._apmAccountServiceProxy.emailValidate((email + '').trim());
    };
    AuthTokenService.prototype.restorePassword = function (model) {
        return this._apmAccountServiceProxy.restorePassword(new RestorePasswordInput(model));
    };
    AuthTokenService.prototype.refreshToken = function () {
        // return this._tokenAuthServiceProxy.refreshToken();
        return of([]);
    };
    AuthTokenService.prototype.save = function (model) {
        var accessTokenExpireDate;
        var refreshTokenExpireDate;
        if (Number.isInteger(model.expireInSeconds)) {
            var expirePeriodTime = new Date().getTime() + model.expireInSeconds * 1000; // milliseconds
            accessTokenExpireDate = new Date(expirePeriodTime);
            var weekPeriodTime = 60 * 60 * 24 * 7 * 1000; // milliseconds
            var refreshPeriodTime = new Date().getTime() + weekPeriodTime;
            refreshTokenExpireDate = new Date(refreshPeriodTime);
        }
        this._abpTokenService.setToken(model.accessToken, accessTokenExpireDate);
        this._cookieService.setCookie(AppConsts.authorization.encrptedAuthTokenName, model.encryptedAccessToken, accessTokenExpireDate, abp.appPath);
        if (accessTokenExpireDate) {
            this._cookieService.setCookie(AppConsts.authorization.refreshTokenName, model.refreshToken, refreshTokenExpireDate, abp.appPath);
            this._cookieService.setCookie(AppConsts.authorization.expiredTokenName, accessTokenExpireDate.getTime() + '', accessTokenExpireDate, abp.appPath);
        }
    };
    AuthTokenService.prototype.get = function () {
        return {
            accessToken: this._abpTokenService.getToken(),
            encryptedAccessToken: this._cookieService.getCookie(AppConsts.authorization.encrptedAuthTokenName),
            refreshToken: this._cookieService.getCookie(AppConsts.authorization.refreshTokenName),
        };
    };
    AuthTokenService.prototype.remove = function () {
        this._abpTokenService.clearToken();
        this._cookieService.deleteCookie(AppConsts.authorization.encrptedAuthTokenName, abp.appPath);
        this._cookieService.deleteCookie(AppConsts.authorization.refreshTokenName, abp.appPath);
        this._cookieService.deleteCookie(AppConsts.authorization.expiredTokenName, abp.appPath);
    };
    AuthTokenService.ngInjectableDef = i0.defineInjectable({ factory: function AuthTokenService_Factory() { return new AuthTokenService(i0.inject(i0.INJECTOR)); }, token: AuthTokenService, providedIn: "root" });
    return AuthTokenService;
}());
export { AuthTokenService };

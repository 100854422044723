import { TemplateRef } from '@angular/core';

import { MatDrawerMode, IRouteLink } from './../default-layout';

import { LinkBarLinkType, ILinkBarLinkModel } from './../ui-components/link-bar/link-bar.component';


export interface IDefaultLayoutNewModel {
    routeLinks: ILinkBarLinkModel[];
    navMode?: MatDrawerMode;
    navIsOpen?: boolean;
    footer?: TemplateRef<any>;
    showBanner: boolean;
    renderSidebar?: boolean;
}

export class DefaultLayoutNewModel implements IDefaultLayoutNewModel {
    navMode: MatDrawerMode;
    routeLinks: ILinkBarLinkModel[];
    footer?: TemplateRef<any>;
    showBanner: boolean;
    navIsOpen: boolean;
    renderSidebar: boolean;
    constructor(config?: IDefaultLayoutNewModel) {
        if (!config) {
            this.showBanner = true;
            this.routeLinks = [];
            this.footer = null;
            this.navMode = MatDrawerMode.side;
            this.navIsOpen = true;
            this.renderSidebar = true;
        } else {
            this.showBanner = config.showBanner;
            this.routeLinks = config.routeLinks;
            this.footer = config.footer;
            this.navMode = config.navMode;
            this.navIsOpen = config.navIsOpen;
            this.renderSidebar = !!config.renderSidebar;
        }
    }
}

import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { AppSessionService, AuthRedirectFactoryService, LOGIN_ROUTES as LR } from './_imports';
import * as i0 from "@angular/core";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(injector) {
        this._permissionChecker = injector.get(PermissionCheckerService);
        this._router = injector.get(Router);
        this._sessionService = injector.get(AppSessionService);
        this._redirectService = injector.get(AuthRedirectFactoryService);
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        if (!this._sessionService.user) {
            this._router.navigate([LR.login, { returnUrl: state.url }]);
            return false;
        }
        if (!route.data) {
            return true;
        }
        if (route.data['allowForRoles']) {
            var roles = route.data['allowForRoles'];
            var containRole = this._sessionService.containAnyRoleFromList(roles);
            if (!containRole) {
                var redirectUrl_1 = route.data['notAllowRedirectUrl'] || LR.login;
                swal({
                    title: 'Доступ запрещен',
                    text: 'Отсутствуют права доступа к модулю. Обратитесь по email lk-support@advokatymoscow.ru',
                    icon: 'error',
                }).then(function (n) {
                    _this._router.navigate([redirectUrl_1]);
                });
            }
            return containRole;
        }
        this._router.navigate([this._redirectService.createUrl() || LR.login]);
        return false;
    };
    AuthGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i0.INJECTOR)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { AppFormBtnType, AppMaterialButtonName, AppFormBtnState } from './_imports';
var BaseAppFormBtn = /** @class */ (function () {
    function BaseAppFormBtn(typeBtn) {
        this.disabled = false;
        this.type = typeBtn;
    }
    BaseAppFormBtn.prototype.configure = function (configurator) {
        configurator(this);
        return this;
    };
    BaseAppFormBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var msg = 'BaseAppFormBtn ' + this.type + ' :onClick no implemented';
        console.error(msg, {
            this: this,
            $event: $event,
            args: args,
        });
    };
    return BaseAppFormBtn;
}());
export { BaseAppFormBtn };
//#region Icon
var BaseAppFormIconBtn = /** @class */ (function (_super) {
    __extends(BaseAppFormIconBtn, _super);
    function BaseAppFormIconBtn() {
        return _super.call(this, AppFormBtnType.icon) || this;
    }
    return BaseAppFormIconBtn;
}(BaseAppFormBtn));
export { BaseAppFormIconBtn };
var AppFormIconAddBtn = /** @class */ (function (_super) {
    __extends(AppFormIconAddBtn, _super);
    function AppFormIconAddBtn() {
        var _this_1 = _super.call(this) || this;
        _this_1.value = AppMaterialButtonName.add;
        return _this_1;
    }
    return AppFormIconAddBtn;
}(BaseAppFormIconBtn));
export { AppFormIconAddBtn };
// #endregion
//#region Toggle
var AppFormToggleBtn = /** @class */ (function (_super) {
    __extends(AppFormToggleBtn, _super);
    function AppFormToggleBtn(openValue, closeValue, state, type) {
        var _this_1 = _super.call(this, type) || this;
        _this_1.openValue = openValue;
        _this_1.closeValue = closeValue;
        _this_1.onStateChange = new EventEmitter(true);
        _this_1.setToState(state);
        return _this_1;
    }
    AppFormToggleBtn.prototype.open = function () {
        this.value = this.openValue;
        this.state = AppFormBtnState.opened;
        this.onStateChange.emit(this.state);
    };
    AppFormToggleBtn.prototype.close = function () {
        this.value = this.closeValue;
        this.state = AppFormBtnState.closed;
        this.onStateChange.emit(this.state);
    };
    AppFormToggleBtn.prototype.isOpened = function () {
        return this.state === AppFormBtnState.opened;
    };
    AppFormToggleBtn.prototype.setToState = function (state) {
        // console.log('setToState', {
        //     state,
        //     this: this,
        // });
        if (state === AppFormBtnState.opened) {
            this.open();
        }
        else if (state === AppFormBtnState.closed) {
            this.close();
        }
        else {
            console.error('AppFormIconToggleBtn:setToState error', this);
        }
    };
    AppFormToggleBtn.prototype.toggle = function () {
        this.isOpened() ? this.close() : this.open();
    };
    return AppFormToggleBtn;
}(BaseAppFormBtn));
export { AppFormToggleBtn };
var AppFormIconToggleAddMinBtn = /** @class */ (function (_super) {
    __extends(AppFormIconToggleAddMinBtn, _super);
    function AppFormIconToggleAddMinBtn(state) {
        return _super.call(this, AppMaterialButtonName.minimize, AppMaterialButtonName.add, state, AppFormBtnType.icon) || this;
    }
    AppFormIconToggleAddMinBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        // nope
    };
    return AppFormIconToggleAddMinBtn;
}(AppFormToggleBtn));
export { AppFormIconToggleAddMinBtn };
// #endregion
//#region Simple
var BaseAppFormSimpleBtn = /** @class */ (function (_super) {
    __extends(BaseAppFormSimpleBtn, _super);
    function BaseAppFormSimpleBtn(text, css) {
        var _this_1 = _super.call(this, AppFormBtnType.simple) || this;
        _this_1.value = text;
        _this_1.css = css || null;
        return _this_1;
    }
    BaseAppFormSimpleBtn.prototype.getFormRefFromArgs = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (args && args.length && args[0].formGroupRef) {
            return args[0].formGroupRef;
        }
        return null;
    };
    return BaseAppFormSimpleBtn;
}(BaseAppFormBtn));
export { BaseAppFormSimpleBtn };
var AppFormSimpleSaveBtn = /** @class */ (function (_super) {
    __extends(AppFormSimpleSaveBtn, _super);
    function AppFormSimpleSaveBtn(text, css) {
        return _super.call(this, text || 'Сохранить', css) || this;
    }
    return AppFormSimpleSaveBtn;
}(BaseAppFormSimpleBtn));
export { AppFormSimpleSaveBtn };
var AppFormSimpleEditBtn = /** @class */ (function (_super) {
    __extends(AppFormSimpleEditBtn, _super);
    function AppFormSimpleEditBtn(text, css) {
        return _super.call(this, text || 'Изменить', css) || this;
    }
    AppFormSimpleEditBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var form = this.getFormRefFromArgs.apply(this, args);
        if (form && form.disabled) {
            form.enable({
                emitEvent: true,
                onlySelf: false,
            });
        }
    };
    return AppFormSimpleEditBtn;
}(BaseAppFormSimpleBtn));
export { AppFormSimpleEditBtn };
var AppFormCleanSimpleBtn = /** @class */ (function (_super) {
    __extends(AppFormCleanSimpleBtn, _super);
    function AppFormCleanSimpleBtn(text, css) {
        return _super.call(this, text || 'Очистить', css) || this;
    }
    AppFormCleanSimpleBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        console.log('$event', {
            this: this,
            $event: $event,
            args: args,
        });
        var form = this.getFormRefFromArgs.apply(this, args);
        if (form) {
            form.reset();
        }
    };
    return AppFormCleanSimpleBtn;
}(BaseAppFormSimpleBtn));
export { AppFormCleanSimpleBtn };
var AppFormSimpleUndoBtn = /** @class */ (function (_super) {
    __extends(AppFormSimpleUndoBtn, _super);
    function AppFormSimpleUndoBtn(text, css) {
        return _super.call(this, text || 'Отменить', css) || this;
    }
    AppFormSimpleUndoBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var form = this.getFormRefFromArgs.apply(this, args);
        if (form && form.enabled) {
            form.disable({
                emitEvent: false,
                onlySelf: false,
            });
        }
    };
    return AppFormSimpleUndoBtn;
}(BaseAppFormSimpleBtn));
export { AppFormSimpleUndoBtn };
//#endregion
// #reagion  with emmter on form ready
var AppFormValidationSimpleBtn = /** @class */ (function (_super) {
    __extends(AppFormValidationSimpleBtn, _super);
    function AppFormValidationSimpleBtn(text, css) {
        return _super.call(this, text, css) || this;
    }
    Object.defineProperty(AppFormValidationSimpleBtn.prototype, "onFormReady", {
        get: function () {
            if (this._onFormReady) {
                return this._onFormReady;
            }
            this._onFormReady = new EventEmitter(true);
            return this._onFormReady;
        },
        enumerable: true,
        configurable: true
    });
    AppFormValidationSimpleBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (this.disabled) {
            return;
        }
        var form = this.getFormRefFromArgs.apply(this, args);
        if (form && form.valid && form.enabled && !form.pending) {
            this.onFormReady.emit(form);
        }
    };
    return AppFormValidationSimpleBtn;
}(BaseAppFormSimpleBtn));
export { AppFormValidationSimpleBtn };
var AppFormSaveValidationSimpleBtn = /** @class */ (function (_super) {
    __extends(AppFormSaveValidationSimpleBtn, _super);
    function AppFormSaveValidationSimpleBtn(text, css) {
        return _super.call(this, text || 'Сохранить', css) || this;
    }
    return AppFormSaveValidationSimpleBtn;
}(AppFormValidationSimpleBtn));
export { AppFormSaveValidationSimpleBtn };
var AppFormAddValidationSimpleBtn = /** @class */ (function (_super) {
    __extends(AppFormAddValidationSimpleBtn, _super);
    function AppFormAddValidationSimpleBtn(text, css) {
        return _super.call(this, text || 'Добавить', css) || this;
    }
    return AppFormAddValidationSimpleBtn;
}(AppFormValidationSimpleBtn));
export { AppFormAddValidationSimpleBtn };
var AppFormDeleteValidationSimpleBtn = /** @class */ (function (_super) {
    __extends(AppFormDeleteValidationSimpleBtn, _super);
    function AppFormDeleteValidationSimpleBtn(text) {
        return _super.call(this, text || 'Удалить') || this;
    }
    AppFormDeleteValidationSimpleBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var form = this.getFormRefFromArgs.apply(this, args);
        if (form && !form.pending) {
            this.onFormReady.emit(form);
        }
    };
    return AppFormDeleteValidationSimpleBtn;
}(AppFormValidationSimpleBtn));
export { AppFormDeleteValidationSimpleBtn };
var AppFormUndoValidationSimpleBtn = /** @class */ (function (_super) {
    __extends(AppFormUndoValidationSimpleBtn, _super);
    function AppFormUndoValidationSimpleBtn(text, css) {
        return _super.call(this, text || 'Отменить', css) || this;
    }
    AppFormUndoValidationSimpleBtn.prototype.onClick = function ($event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (this.disabled) {
            return;
        }
        var form = this.getFormRefFromArgs.apply(this, args);
        if (!form || form.disabled) {
            return;
        }
        if (!form.pending) {
            this.onFormReady.emit(form);
        }
    };
    return AppFormUndoValidationSimpleBtn;
}(AppFormValidationSimpleBtn));
export { AppFormUndoValidationSimpleBtn };
//#endregion
// #endregion

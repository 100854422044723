import { Routes } from '@angular/router';
import { NotFoundComponent, UserRoles } from '@shared/index';
import { AuthGuard } from './auth/guards/index';
var ɵ0 = { preload: true }, ɵ1 = { preload: true }, ɵ2 = { preload: true }, ɵ3 = {
    allowForRoles: [UserRoles.admin, UserRoles.moderator],
    preload: true
}, ɵ4 = { preload: true }, ɵ5 = {
    allowForRoles: [UserRoles.lawyer],
    preload: true,
}, ɵ6 = {
    allowForRoles: [UserRoles.organizationHead, UserRoles.formationAccount],
    preload: true
}, ɵ7 = {
    allowForRoles: [UserRoles.admin, UserRoles.moderator, UserRoles.chamberAccountant],
    preload: true
}, ɵ8 = {
    allowForRoles: [UserRoles.admin, UserRoles.moderator],
    preload: true
}, ɵ9 = {
    allowForRoles: [UserRoles.admin, UserRoles.moderator],
    preload: true
}, ɵ10 = {
    allowForRoles: [UserRoles.admin, UserRoles.moderator],
    preload: true
}, ɵ11 = { preload: true }, ɵ12 = {
    allowForRoles: [UserRoles.admin, UserRoles.moderator, UserRoles.humanResourcesOfficer, UserRoles.chamberAccountant, UserRoles.assistantManager],
    preload: true
}, ɵ13 = { preload: true }, ɵ14 = { preload: true };
var routes = [
    { path: '', redirectTo: '/account/login', pathMatch: 'full' },
    {
        path: 'public',
        loadChildren: 'public/public.module#PublicModule',
        data: ɵ0,
    },
    {
        path: 'caseByAgreement',
        loadChildren: 'casebyagreement/casebyagreement.module#CaseByAgreementModule',
        data: ɵ1,
    },
    {
        path: 'account',
        loadChildren: 'auth/auth.module#AuthModule',
        data: ɵ2,
    },
    {
        path: 'app',
        loadChildren: 'app/app.module#AppModule',
        canActivate: [AuthGuard],
        data: ɵ3,
    },
    {
        path: 'article-51',
        loadChildren: 'lk-lawyer/lk-lawyer.module#LkLawyerModule',
        data: ɵ4,
    },
    {
        path: 'lk-lawyer',
        loadChildren: 'lk-lawyer/lk-lawyer.module#LkLawyerModule',
        data: ɵ5,
        canActivate: [AuthGuard],
    },
    {
        path: 'accountant-ao',
        loadChildren: 'accountant-lawyer-organization/accountant-lawyer-organization.module#AccountantLawyerOrganizationModule',
        canActivate: [AuthGuard],
        data: ɵ6,
    },
    {
        path: 'accountant-apm',
        loadChildren: 'accountant-lawyer-chamber-moscow/accountant-lawyer-chamber-moscow.module#AccountantLawyerChamberMoscowModule',
        canActivate: [AuthGuard],
        data: ɵ7,
    },
    {
        path: 'moderation-conference',
        loadChildren: 'moderation-conference/moderation-conference.module#ModerationConferenceModule',
        canActivate: [AuthGuard],
        data: ɵ8,
    },
    {
        path: 'users',
        loadChildren: 'users/users.module#UsersModule',
        canActivate: [AuthGuard],
        data: ɵ9,
    },
    {
        path: 'statements',
        loadChildren: 'statements/statements.module#StatementsModule',
        canActivate: [AuthGuard],
        data: ɵ10,
    },
    {
        path: 'initiator',
        loadChildren: 'initiator/lc-initiator.module#LcInitiatorModule',
        data: ɵ11,
    },
    {
        path: 'signup',
        redirectTo: 'account/signup',
    },
    {
        path: 'signup-email',
        redirectTo: 'account/signup-email',
    },
    {
        path: 'registry',
        loadChildren: 'registry/registry.module#RegistryModule',
        canActivate: [AuthGuard],
        data: ɵ12,
    },
    {
        path: 'moderator',
        loadChildren: 'moderator/moderator.module#ModeratorModule',
        data: ɵ13,
    },
    {
        path: 'sitemap',
        loadChildren: 'sitemap/sitemap.module#SitemapModule',
        data: ɵ14,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
];
var RootRoutingModule = /** @class */ (function () {
    function RootRoutingModule() {
    }
    return RootRoutingModule;
}());
export { RootRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };

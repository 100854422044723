import { HttpResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map, switchMap, take } from 'rxjs/operators';
import { AuthTokenService } from '../services';
import { AbpHttpConfiguration, IErrorInfo, LogService, MessageService } from './_imports';
import { AppConsts } from 'shared';

/**
 * Метод переназначения конфигуратора базового HttpInterceptor-а
 */
export type HttpConfigurationMethod = (...args: any[]) => any;

/**
 * Список всех методов конфигуратора базового HttpInterceptor-а, которые могут быть переопределены
 *
 * @see AbpHttpConfiguration
 */
export interface HttpConfigurationInterface {
    showError(error: IErrorInfo): any;
}

@Injectable({
    providedIn: 'root',
})
/**
 * Сервис назначения методов обработки для конфигуратора базового HttpInterceptor-а.
 *
 * Методы могут быть назначены непосредственно в конкретных компонентах
 */
export class AuthHttpConfigurationHandler implements HttpConfigurationInterface {
    private _methods: { [name: string]: HttpConfigurationMethod } = Object.create(null);
    private _methodsEnable: { [name: string]: boolean } = Object.create(null);

    showError(error: IErrorInfo): any {
        return this._methods['showError'] && this._methods['showError'](error);
    }

    /**
     * Назначение замещающего метода обработки
     *
     * @param name Имя метода (должно совпадать с именем из HttpConfigurationInterface)
     * @param method Определение метода
     */
    setMethod(name: string, method: HttpConfigurationMethod): void {
        this._methods[name] = method;
        this._methodsEnable[name] = true;
    }

    /**
     * Удаление замещающего метода обработки
     *
     * @param name Имя метода
     */
    deleteMethod(name: string): void {
        if (this._methods[name]) {
            delete this._methods[name];
            delete this._methodsEnable[name];
        }
    }

    /**
     * Проверка активности замещающего метода обработки
     *
     * @param name Имя метода
     */
    isEnableMethod(name: string): boolean {
        return !!(this._methods[name] && this._methodsEnable[name]);
    }

    /**
     * Устанавливает флаг активности замещающего метода обработки
     *
     * @param name Имя метода
     * @param isEnable Флаг активности
     */
    setEnableMethod(name: string, isEnable: boolean): void {
        if (this._methods[name]) {
            this._methodsEnable[name] = isEnable;

            return;
        }

        delete this._methodsEnable[name];
    }
}

@Injectable()
/**
 * Конфигуратор базового HttpInterceptor-а.
 *
 * Переопределяет поведение некоторых методов базового конфигуратора
 */
export class AuthHttpConfiguration extends AbpHttpConfiguration {
    constructor(
        private _handler: AuthHttpConfigurationHandler,
        messageService: MessageService,
        logService: LogService,
    ) {
        super(messageService, logService);
    }

    /**
     * @override
     */
    showError(error: IErrorInfo): any {
        if (this._handler.isEnableMethod('showError')) {
            return this._handler.showError(error);
        }

        return super.showError(error);
    }
}

@Injectable()
/**
 * Http перехватчик обновления токена авторизации
 */
export class AuthHttpInterceptor implements HttpInterceptor {
    private _isRefreshTokenProcess = false;

    constructor(private _tokenService: AuthTokenService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const request = req.clone();
        let tokens = this._tokenService.get();

        let modifiedHeaders = request.headers.set('__tenant', AppConsts.defaultTenantUid);

        // меняем заголовки запроса если это запрос обновления токена
        if (/RefreshToken/i.test(req.url)) {
            modifiedHeaders = modifiedHeaders.set('Authorization', 'Bearer ' + tokens.refreshToken);

            return next.handle(
                request.clone({
                    headers: modifiedHeaders,
                }),
            );
        }

        // полная очистка если токены авторизации не найдены
        if (!tokens.accessToken && !tokens.refreshToken) {
            tokens = <any>{};
            this._tokenService.remove();
        }

        // обновление токенов
        // if (!this._isRefreshTokenProcess && !tokens.accessToken && tokens.refreshToken) {
        //     this._isRefreshTokenProcess = true;

        //     return this._tokenService.refreshToken().pipe(
        //         take(1),
        //         map((result: IAuthenticateResultModel) => {
        //             this._tokenService.save(result);
        //             this._isRefreshTokenProcess = false;

        //             return result;
        //         }),
        //         switchMap((result: IAuthenticateResultModel) => {
        //             modifiedHeaders = modifiedHeaders.append('Authorization', 'Bearer ' + result.accessToken);

        //             const modifiedRequest = request.clone({
        //                 headers: modifiedHeaders,
        //             });

        //             return next.handle(modifiedRequest);
        //         }),
        //     );
        // }

        const modifiedRequestClone = request.clone({
            headers: modifiedHeaders,
        });

        //return next.handle(modifiedRequestClone);

        return next.handle(modifiedRequestClone).pipe(
            tap(
              (event: any) => {
                // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
                // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
                // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
                // и typesript 3.хх использовать коллекции angular-oauth2-oidc
                if (event.body && event.body.encrypted_access_token) {
                    localStorage.setItem('encrypted_access_token', event.body.encrypted_access_token);
                }
              },
              (err) => {

              }
            )
          );
    }
}

﻿import {AbpModule} from '@abp/abp.module';
import {CommonModule} from '@angular/common';
import {
    ModuleWithProviders,
    NgModule
} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MaterialInput} from './directives';

import {
    PhonePipe,
    HtmlSanitizerPipe,
    DatePeriodPipe
} from './pipe';

import {SharedMaterialModule} from './shared.material.module';

import {
    AppBoxModule,
    AppNoteBoxModule,
    AppFormBtnComponent,
    AppFormComponent,
    FileUploaderModule,
    DatePickerModule,
    DaterangepickerMd,
    AppLoadersModule,
    AppPushInfoComponent,
    PhoneIconComponent,
    MultiFieldComponent,

} from './ui-components';

import {DefaultLayoutModule} from './default-layout';
import {DefaultLayoutNewModule} from './default-layout-new';
import {TextMaskModule} from 'angular2-text-mask';

import {LinkBarModule} from './ui-components/link-bar/link-bar.module';


import {LitigationTypeServiceProxyAdapter} from './service-global/adapters/concrete/LitigationTypeServiceProxyAdapter';
import {MonthYearPeriodTextPipe} from '@shared/pipe/date-period/month-year-period.pipe';
import {DaysAmountPipe} from '@shared/pipe/days-amount.pipe';
import {EmailInfoComponent} from './ui-components/email-info/email-info.component';
import {DaysPipe} from '@shared/pipe/days.pipe';
import {SurchargeOrderStatusPipe} from '@shared/pipe/surcharge-order-status.pipe';
import {SurchargeOrderStatusImportFixPipe} from '@shared/pipe/surcharge-order-status-with-import-fix.pipe';
import {PeriodPipe} from '@shared/pipe/period.pipe';
import {PeriodPipe2} from '@shared/pipe/period2.pipe';
import {PositiveNumberPipe} from '@shared/pipe/positive-number.pipe';
import {PaymentOrderStatusPipe} from '@shared/pipe/payment-order-status.pipe';
import {AcquiringPayTypePipe} from '@shared/pipe/acquiringPayType.pipe';

@NgModule({
    imports: [
        CommonModule,
        SharedMaterialModule,
        AbpModule,
        RouterModule,
        TextMaskModule,
        FileUploaderModule,
        LinkBarModule,
        DatePickerModule,
        DaterangepickerMd,
    ],
    declarations: [
        MaterialInput,
        PhonePipe,
        DaysPipe,
        MonthYearPeriodTextPipe,
        DaysAmountPipe,
        HtmlSanitizerPipe,
        AppFormBtnComponent,
        AppFormComponent,
        AppPushInfoComponent,
        DatePeriodPipe,
        PhoneIconComponent,
        MultiFieldComponent,
        EmailInfoComponent,
        PeriodPipe,
        PeriodPipe2,
        AcquiringPayTypePipe,
        PositiveNumberPipe,
        SurchargeOrderStatusPipe,
        SurchargeOrderStatusImportFixPipe,
        PaymentOrderStatusPipe,
    ],

    providers: [
        LitigationTypeServiceProxyAdapter
    ],

    exports: [
        SharedMaterialModule,
        MaterialInput,
        HtmlSanitizerPipe,
        DatePeriodPipe,
        PhonePipe,
        DaysPipe,
        MonthYearPeriodTextPipe,
        DaysAmountPipe,
        // ui
        AppNoteBoxModule,
        AppBoxModule,
        DefaultLayoutModule,
        DefaultLayoutNewModule,
        AppFormBtnComponent,
        AppFormComponent,
        FileUploaderModule,
        DatePickerModule,
        DaterangepickerMd,
        MultiFieldComponent,
        EmailInfoComponent,
        LinkBarModule,

        AppLoadersModule,
        AppPushInfoComponent,
        PhoneIconComponent,

        SurchargeOrderStatusPipe,
        SurchargeOrderStatusImportFixPipe,
        PaymentOrderStatusPipe,
        PeriodPipe,
        PeriodPipe2,
        PositiveNumberPipe,
        AcquiringPayTypePipe,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
        };
    }
}

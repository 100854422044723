import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from '@shared/shared.material.module';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileSizePipe } from '@shared/pipe';

@NgModule({
    imports: [CommonModule, SharedMaterialModule],
    declarations: [FileUploaderComponent, FileSizePipe],
    exports: [FileUploaderComponent, FileSizePipe],
})
export class FileUploaderModule {}

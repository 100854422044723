import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject, of } from 'rxjs';
import { takeUntil, take, delay } from 'rxjs/operators';
import * as moment from 'moment';
export var AppProgressLoaderMode;
(function (AppProgressLoaderMode) {
    AppProgressLoaderMode["overflow"] = "overflow";
    AppProgressLoaderMode["content"] = "content";
})(AppProgressLoaderMode || (AppProgressLoaderMode = {}));
var AppProgressLoaderComponent = /** @class */ (function () {
    function AppProgressLoaderComponent(_changeDetectorRef) {
        this._changeDetectorRef = _changeDetectorRef;
        this.title = 'Загрузка...';
        this.mode = AppProgressLoaderMode.overflow;
        this.minTime = 1;
        this.ellipsis = Array(6);
        this._destructor = new Subject();
    }
    Object.defineProperty(AppProgressLoaderComponent.prototype, "showWhen", {
        get: function () {
            return this._condition;
        },
        set: function (value) {
            this._setCondition(value);
        },
        enumerable: true,
        configurable: true
    });
    AppProgressLoaderComponent.prototype.ngOnInit = function () {
        this._changeDetectorRef.reattach();
        switch (this.mode) {
            case AppProgressLoaderMode.overflow:
                this.isOverflow = true;
                break;
            case AppProgressLoaderMode.content:
                this.isOverflow = false;
                break;
            default:
                this.isOverflow = true;
                break;
        }
        this._detectChanges();
    };
    AppProgressLoaderComponent.prototype.ngOnDestroy = function () {
        this._destructor.next();
        this._destructor.complete();
        this._changeDetectorRef.detach();
    };
    AppProgressLoaderComponent.prototype._setCondition = function (value) {
        var _this = this;
        if (value === this._condition) {
            return;
        }
        else if (value) {
            this._condition = value;
            this._startTime = moment();
        }
        else {
            var currentTime = moment();
            var diff = currentTime.diff(this._startTime);
            var minDelay = 1000 * this.minTime;
            var delta = minDelay - diff;
            if (diff < minDelay) {
                of(delta)
                    .pipe(takeUntil(this._destructor), take(1), delay(delta))
                    .subscribe(function () {
                    _this._condition = false;
                    _this._detectChanges();
                });
                // do something
            }
            else {
                this._condition = false;
            }
        }
        this._detectChanges();
    };
    AppProgressLoaderComponent.prototype._detectChanges = function () {
        if (!this._destructor.isStopped) {
            this._changeDetectorRef.detectChanges();
        }
    };
    return AppProgressLoaderComponent;
}());
export { AppProgressLoaderComponent };

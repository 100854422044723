// tslint:disable-next-line:class-name
var APP_REGEXP = /** @class */ (function () {
    function APP_REGEXP() {
    }
    Object.defineProperty(APP_REGEXP, "phoneMaskTemplateChar", {
        get: function () {
            return 'x';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "phoneMaskTemplate", {
        get: function () {
            return '+7 (xxx) xxx-xx-xxxxx';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "zeroUnicodeChar", {
        get: function () {
            return '\u24EA'; // empty circled zero
            // return '\u24ff'; // circled zero with  black fill (negative zero)
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "defaultMaskChar", {
        get: function () {
            return '_';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "defaultPhoneMaskChar", {
        get: function () {
            return APP_REGEXP.defaultMaskChar;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isUrl", {
        get: function () {
            return new RegExp(
            // tslint:disable-next-line:max-line-length
            /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isPhone", {
        get: function () {
            return new RegExp(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isMoscowPhoneCode", {
        get: function () {
            // +7 (495) 123 45 45
            return new RegExp(/\((49[59])\)/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isDate", {
        get: function () {
            return new RegExp(/^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isEmail", {
        get: function () {
            return new RegExp(/^[a-zA-Z\d_\.-]+@[a-zA-Z\d_\.-]+\.[a-zA-Z]+$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isOnlyNumber", {
        get: function () {
            return new RegExp(/^\d+$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isRegistryNumber", {
        get: function () {
            return new RegExp(/^[0-9]+(?:\/[0-9]+)?$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isLogin", {
        get: function () {
            return new RegExp(/^[a-zA-Z0-9!@#$%^&*()_+[\]{}|;:'",.<>?/\\=-]+$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "createMobilePhoneMask", {
        get: function () {
            return APP_REGEXP.createDefaultPhoneMask;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "createDefaultPhoneMask", {
        get: function () {
            var d = APP_REGEXP.numberRegExp;
            return ['+', '7', ' ', '(', d, d, d, ')', ' ', d, d, d, '-', d, d, '-', d, d];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "numberRegExp", {
        get: function () {
            return new RegExp(/\d/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "replaceAllDoubleSpace", {
        get: function () {
            return new RegExp(/\s{2,}/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "defaultPhoneMask", {
        get: function () {
            var d = APP_REGEXP.defaultPhoneMaskChar;
            var templateChar = this.defaultPhoneMaskChar;
            var template = this.phoneMaskTemplate;
            return template.replace(templateChar, d);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "zeroPhoneMask", {
        get: function () {
            var zeroChar = APP_REGEXP.zeroUnicodeChar;
            var template = this.phoneMaskTemplate;
            var templateChar = this.phoneMaskTemplateChar;
            return template.replace(templateChar, zeroChar);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "replaceAllNotNumbers", {
        get: function () {
            return new RegExp(/\D/g);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isOnlyLetters", {
        get: function () {
            return new RegExp(/[A-Za-z]/g);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isOnlyRussianLetters", {
        get: function () {
            return new RegExp(/^[ёа-я- ]+$/iu);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "replaceAllNotRussian", {
        get: function () {
            return new RegExp(/[^ёа-я- ]/iu);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isRussianInfinityFullName", {
        get: function () {
            return new RegExp(/^[ёа-я-]{2,36}\s+[ёа-я-]{2,36}(\s+[ёа-я-]{2,36}){0,}$/iu);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isRussianFullName", {
        get: function () {
            return new RegExp(/^[ёа-я-]{1,36}\s+[ёа-я-]{1,36}(\s+[ёа-я-]{1,36}){0,}$/iu);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isPassword", {
        get: function () {
            return new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&-_=+)(]{6,32}$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isTime", {
        get: function () {
            return new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(APP_REGEXP, "isDomain", {
        /**
         * Регулярное выражение проверки доменного имени
         *
         * Совпадает с:
         * * example.com
         * * www.example.com
         * * www.example-org.com
         * * www.city.example-org.com
         *
         * @see https://en.wikipedia.org/wiki/Domain_name
         */
        get: function () {
            return new RegExp(/^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,6}$/i);
        },
        enumerable: true,
        configurable: true
    });
    return APP_REGEXP;
}());
export { APP_REGEXP };

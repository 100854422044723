import {AppValidators} from '@shared/validators';
import {SubscriptionLike} from 'rxjs';
import {filter, map as _map, upperFirst, takeRight, join} from 'lodash';
import {APP_REGEXP} from '../infrastructure/regexp';
import {Guid} from '../infrastructure/guid';
import {IFullName} from '../interfaces/user.interfaces';
import { FormGroup } from '@angular/forms';

export class UtilHelper {

    static Base64ToBytes(base64): Uint8Array {
        const s = window.atob(base64);
        const bytes = new Uint8Array(s.length);
        for (let i = 0; i < s.length; i++) {
            bytes[i] = s.charCodeAt(i);
        }
        return bytes;
    };

    static createQuickGuid(): string {
        return Guid.createQuickGuid().toString();
    }

    static createGuid(): string {
        return Guid.createGuid().toString();
    }

    static createSpanContent(textContent: string, css?: string) {
        return UtilHelper.createTagContent('span', textContent, css);
    }

    static createTagContent(tagName: string, textContent: string, css?: string) {
        if (css) {
            return `<${tagName} class="${css}">${textContent}</${tagName}>`;
        }
        return `<${tagName}>${textContent}</${tagName}>`;
    }

    static unsubscribe(subscription: SubscriptionLike): void {
        if (subscription && !subscription.closed) {
            subscription.unsubscribe();
        }
    }

    static cleanPhoneMask(phone: string): string {
        if (!phone || !phone.length) {
            return '';
        }
        return phone.replace(APP_REGEXP.replaceAllNotNumbers, '');
    }

    static eachFirstToUpper(str: string): string {
        str = str || str.trim().toLowerCase();
        if (!str) {
            return '';
        }
        return _map(filter(str.split(' '), i => i !== ' '), i => upperFirst(i)).join(' ');
    }

    static normalizeString(val: string, regexp: RegExp = null, isInvariant = false): string {
        val = val || '';
        let value = val.trim();
        if (isInvariant) {
            value = value.toLowerCase();
        }
        if (regexp) {
            value = value.replace(regexp, '').trim();
        }
        return value;
    }

    static normalizePhone(phone: string, specialRegExp: RegExp = APP_REGEXP.replaceAllNotNumbers): string {
        if (!phone) {
            return '';
        }
        return UtilHelper.normalizeString(phone, specialRegExp, false);
    }

    static splitFullName(
        fullName: string,
        normalize = true,
        normalizeRegexp: RegExp = null,
        isInvariant = false,
    ): IFullName {
        let checkedName = normalize
            ? UtilHelper.normalizeString(fullName, normalizeRegexp || APP_REGEXP.replaceAllNotRussian, isInvariant)
            : fullName || '';
        checkedName = checkedName.replace(APP_REGEXP.replaceAllDoubleSpace, ' ').trim();

        const nameArray = checkedName.split(' ');
        const fNameName = [];
        const lastName = nameArray[0] || '';
        const firstName = nameArray[1] || '';
        let middleName = '';
        if (nameArray[2]) {
            middleName = join(takeRight(nameArray, nameArray.length - 2), ' ').trim();
        }
        if (lastName) {
            fNameName.push(lastName);
        }
        if (firstName) {
            fNameName.push(firstName);
        }
        if (middleName) {
            fNameName.push(middleName);
        }

        return {
            fullName: fNameName.join(' ').trim(),
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
        };
    }

    static removeSpacesFromClientName(name: string): string  {
        if (name) {
            const regexp = APP_REGEXP.replaceAllDoubleSpace;
            const s = name.trim()
                .replace(regexp, ' ')
                .trim()
                .replace(/\s{1,}-/g, '-')
                .replace(/-\s{1,}/g, '-');
            return s;
        } else {
            return '';
        }
    }

    static getShortFullName(fullName: string): string {
        const tFullName = this.splitFullName(fullName),
            tFirstName = tFullName.firstName.length ? (tFullName.firstName[0] + '.') : '',
            tMiddleName = tFullName.middleName.length ? (tFullName.middleName[0] + '.') : '',
            result = tFullName.lastName + ' ' + tFirstName + ' ' + tMiddleName;

        return result;
    }

    static toPhoneView(phone: string) {
        if (!phone) {
            return '';
        }
        phone = phone || '';
        phone = UtilHelper.normalizePhone(phone);
        return UtilHelper.viewPhoneNumberFormatter(phone);
    }

    static viewPhoneNumberFormatter(phone: string, pattern?) {
        if (!phone) {
            return '';
        }
        if (phone[0] === '8' && phone.length === 11) {
            phone = 7 + phone.substr(1);
        }

        phone = phone.replace('+', '');

        phone += '';
        let val = '';
        const mask = pattern || APP_REGEXP.phoneMaskTemplate;

        let numI = 0;
        for (let idx = 0; idx < mask.length; idx++) {
            if (!phone[numI]) {
                break;
            }
            const maskChar = mask[idx];
            const numChar = phone[numI];
            if (maskChar === numChar || maskChar === 'x') {
                val += numChar;
                numI++;
            } else {
                val += maskChar;
            }
        }

        return val;
    }

    static normalizeLogin(login: string): string {
        let result = '';

        if (APP_REGEXP.isPhone.test(login)) {
            result = UtilHelper.normalizePhone(login);
        } else if (APP_REGEXP.isEmail.test(login)) {
            result = UtilHelper.normalizeString(login);
        } else if (AppValidators.isRegistryNumberByValue(login)) {
            result = UtilHelper.normalizeString(login, APP_REGEXP.replaceAllNotNumbers);
        } else if (APP_REGEXP.isOnlyLetters.test(login.trim())) {
            result = UtilHelper.normalizeString(login);
        } else {
            throw new Error('Логин не тот, что ожидается');
        }

        return result;
    }

    static toPassportView(passport: string): string {
        if (!passport || typeof passport !== 'string' || passport.length < 10) {
            return '';
        }

        passport = passport.replace(/ /g, '');

        const arr = passport.split('');

        return `${arr.slice(0, 2).join('')} ${arr.slice(2, 4).join('')} ${arr.slice(4).join('')}`;
    }

    /**
     * Меняет местами элементы коллекции с указанными индексами
     *
     * @param collection Коллекция
     * @param firstIndex Индекс первого элемента
     * @param secondIndex Индекс второго элемента
     */
    static exchangeItems(collection: any[], firstIndex: number, secondIndex: number): void {
        if (!collection || !collection.length) {
            return;
        }

        if (firstIndex < 0 || secondIndex < 0) {
            return;
        }

        const lastIndex = collection.length - 1;
        if (lastIndex < firstIndex || lastIndex < secondIndex) {
            return;
        }

        const tmp = collection[firstIndex];
        collection[firstIndex] = collection[secondIndex];
        collection[secondIndex] = tmp;
    }

    static stripHtml(string: string) {
        if (typeof(string) !== 'string') {
            return string;
        }
        return string.replace(/(<([^>]+)>)/gi, '');
    }

    static markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    static updateValueAndValidity(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.updateValueAndValidity({onlySelf: true,emitEvent: false});

            if (control.controls) {
                this.updateValueAndValidity(control);
            }
        });
    }
}

import { Injectable, Injector } from '@angular/core';
import { AuthStorageService } from './auth-storage.service';

@Injectable({
    providedIn: 'root',
})
/**
 * Сервис определения нахождения за чужим компьютером
 */
export class AuthForeignService {
    private readonly _key = '60B5E5C4-32A0-44A3-BD95-55694D7E75F6';
    private readonly _defaultExpire = 86400; // sec (1 day)

    private _storageService: AuthStorageService;

    constructor(private _injector: Injector) {
        this._storageService = _injector.get(AuthStorageService);
    }

    isForeign(): boolean {
        return !!this._storageService.getStorage(this._key);
    }

    setForeign(expire?: number): void {
        expire = expire || this._defaultExpire;

        this._storageService.setStorage(this._key, true, expire);
    }

    deleteForeign(): void {
        this._storageService.removeStorage(this._key);
    }
}

// TODO: import to core
/**
 * //todo to global
 */
var SharedMaterialModule = /** @class */ (function () {
    function SharedMaterialModule() {
    }
    return SharedMaterialModule;
}());
export { SharedMaterialModule };

import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';

export interface IInstance {
    /**Id for all instance - using for notification. don't confuse it with  IFileInfo.uiId */
    instanceId: string;
}
export interface IDisableable {
    disabled: boolean;
}
export interface IConfigurable<T> {
    configure(configurator: (_this: T) => void): T;
}
export interface IFormFieldConvetrableModel<T> {
    toFormField(): T;
}

export interface ISelectable<T> {
    list: T[];
    selectedIndex: number;
    selected: T;
}

/* #region Events */
export interface IFormButtonEventData extends IInstance {
    form: FormGroup;
    btn: IDisableable;
    dataDto: any;
}
export interface IEntityOnDeleteButtonEventData<T> extends IFormButtonEventData {
    dataDto: T;
    uiId: string;
}
export interface IEntityOnUpdatedEventData<T> extends IInstance {
    form: FormGroup;
    dto: T;
}
/* #endregion */

/* #region MatTableDataSource */
export interface ITableColumn {
    propName: string;
    headColumnName: string;
}

export interface ITableData<T> {
    dataSource: MatTableDataSource<T>;
    columns: ITableColumn[];
    displayedColumns?: string[];
}

export interface IKeyValue<K, V> {
    key: K;
    value: V;
}

export interface IKeyValueStrings extends IKeyValue<string, string> {}

export class KeyValue<K, V> implements IKeyValue<K, V> {
    key: K;
    value: V;
    constructor(key?: K, value?: V) {
        this.key = key;
        this.value = value;
    }
}

export class KeyValueStrings extends KeyValue<string, string> implements IKeyValueStrings {
    constructor(key?: string, value?: string) {
        super(key, value);
    }
}

/* #endregion */

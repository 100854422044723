import { each } from 'lodash';
export interface ILocalStorageService {
    removeStorage(itemKey: string);
    getExpiresInKey(itemKey: string): string;
    getExpiresInTime(itemKey): number;
    getStorage(itemKey: string): string;
    setStorage(itemKey, value: any, expires?: number): boolean;
}
export interface ILocalStorageProvider<T> {
    get(): T;
    save(value: T, expires?: number): boolean;
    reset(): boolean;
}
export class LocalStorageProvider<T> {
    constructor(private _service: ILocalStorageService, public readonly key: string, private _expires: number) {}
    get(): T {
        const store = this._service.getStorage(this.key);
        if (store) {
            return JSON.parse(store) as T;
        }
        return null;
    }
    save(value: T, expires?: number): boolean {
        if (expires) {
            this._expires = expires;
        }
        return this._service.setStorage(this.key, value, this._expires);
    }
    reset(): boolean {
        return this._service.removeStorage(this.key);
    }
}

export abstract class LocalStorageBaseService {
    protected _postfix_expiresIn = '_expiresIn';
    protected _defaultExpires = 24 * 60 * 60;
    protected _providers: { [key: string]: ILocalStorageProvider<any> } = Object.create(null);
    constructor(protected _className: string) {}

    public removeStorage(itemKey: string) {
        try {
            localStorage.removeItem(itemKey);
            localStorage.removeItem(this.getExpiresInKey(itemKey));
        } catch (e) {
            this._log('removeStorage', `Error removing key ['${itemKey}'] from localStorage`, e);
            return false;
        }
        return true;
    }
    public getExpiresInKey(itemKey: string): string {
        return itemKey + this._postfix_expiresIn;
    }
    public getExpiresInTime(itemKey: string): number {
        const time = localStorage.getItem(this.getExpiresInKey(itemKey));
        if (!time) {
            return 0;
        }
        return +time;
    }
    public getStorage(itemKey: string): string {
        const now = Date.now();
        const expiresIn = this.getExpiresInTime(itemKey);
        if (expiresIn < now) {
            // Expired
            this.removeStorage(itemKey);
            return null;
        } else {
            try {
                return localStorage.getItem(itemKey);
            } catch (e) {
                this._log('getStorage', `Error reading key [${itemKey}] from localStorage`, e);
                return null;
            }
        }
    }
    public setStorage(itemKey: string, value: any, expires?: number): boolean {
        if (!itemKey) {
            return false;
        }
        if (!expires) {
            expires = this._defaultExpires;
        } else {
            expires = Math.abs(expires);
        }
        let valueToSave = '';
        if (typeof value !== 'string') {
            valueToSave = JSON.stringify(value);
        } else {
            valueToSave = value;
        }
        const now = Date.now();
        const schedule = now + expires * 1000;
        try {
            localStorage.setItem(itemKey, valueToSave);
            localStorage.setItem(this.getExpiresInKey(itemKey), schedule + '');
        } catch (e) {
            this._log('setStorage', 'Error setting key [${itemKey}] in localStorage', e);
            return false;
        }
        return true;
    }
    public getOrCreateStorageProvider<T>(key: string, expires: number = null): ILocalStorageProvider<T> {
        if (!this._providers[key]) {
            expires = expires || this._defaultExpires;
            this._providers[key] = new LocalStorageProvider(this, key, expires);
        }
        return this._providers[key] as ILocalStorageProvider<T>;
    }
    protected _resetMany(keys: string[]): void {
        each(keys, k => {
            this.getOrCreateStorageProvider(k).reset();
        });
    }

    protected _log(methodName, message, data = null): void {
        console.log(this._className + `.${methodName}: ${message}`, {
            data: data,
        });
    }
}

import { ViewChild, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { each, find } from 'lodash';

import { DataFilterValueDto, QueryOptionsDto, SortOptionDto } from '../service-proxies/service-proxies';

export interface IPaginatorService<TResponseDtoWithPagination extends IPaginatorResponse> {
    pageSizeOptions: number[];
    pageSize: number;
    onDataChange: EventEmitter<TResponseDtoWithPagination>;
    loadPage: (page?: number, pageSize?: number, opts?: QueryOptionsDto) => void;
    getQueryOptionsDto(opts?: QueryOptionsDto);
}

export interface IPaginatorResponse {
    pageNumber: number;
    totalCount: number;
    pageSize: number;
}

export interface IPaginationProxy<TResponseDtoWithPagination extends IPaginatorResponse> {
    queryPage: (
        pageNumber: number,
        pageSize: number,
        countTotal: boolean,
        queryOptions: QueryOptionsDto,
    ) => Observable<TResponseDtoWithPagination>;
}

export abstract class BasePaginatorService<TResponseDtoWithPagination extends IPaginatorResponse>
    implements IPaginatorService<TResponseDtoWithPagination> {
    public onDataChange: EventEmitter<TResponseDtoWithPagination> = new EventEmitter<TResponseDtoWithPagination>();
    public pageSizeOptions = [15, 25, 50];
    readonly startPage = 1;
    private _pageSize: number;
    public get pageSize(): number {
        if (this._pageSize) {
            return this._pageSize;
        }
        this._pageSize = this.pageSizeOptions[0];
        return this._pageSize;
    }
    public set pageSize(value: number) {
        if (this._pageSize !== value) {
            this._pageSize = value;
        }
    }

    protected constructor(private __proxy: IPaginationProxy<TResponseDtoWithPagination>) {}

    public loadPage(page: number = this.startPage, pageSize: number = this.pageSize, opts?: QueryOptionsDto): void {
        const queryOptions = this.getQueryOptionsDto(opts);
        this.__proxy.queryPage(page, pageSize, true, queryOptions).subscribe(response => {
            this.onDataChange.emit(response);
        });
    }
    public getQueryOptionsDto(opts?: QueryOptionsDto): QueryOptionsDto {
        return (
            opts ||
            new QueryOptionsDto({
                filterValues: [],
                sortOptions: [],
            })
        );
    }

    protected _hasSortOption(opts: SortOptionDto[], sortFieldName: string): boolean {
        if (!opts || !opts.length) {
            return false;
        }
        const sort = find(opts, i => {
            return i.fieldName === sortFieldName;
        });
        return !!sort;
    }

    protected _hasFilterOption(opts: DataFilterValueDto[], filterId: string): boolean {
        return !!this._getFilter(opts, filterId);
    }

    protected _getFilter(opts: DataFilterValueDto[], filterId: string): DataFilterValueDto {
        if (!opts || !opts.length) {
            return null;
        }

        const filter = find(opts, i => {
            return i.dataFilterId === filterId;
        });

        return filter;
    }
}
export abstract class BasePaginatorComponent<TResponseDtoWithPagination extends IPaginatorResponse>
    implements OnDestroy, OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    protected _subs: Subscription[];
    protected constructor(protected _service: IPaginatorService<TResponseDtoWithPagination>) {
        this._subs = [];
    }

    public ngOnInit() {
        this._initPaginator();
    }

    public ngOnDestroy(): void {
        if (this._subs && this._subs.length) {
            each(this._subs, i => {
                if (i && !i.closed) {
                    i.unsubscribe();
                }
            });
        }
    }
    protected _initPaginator() {
        this.paginator.pageSizeOptions = this._service.pageSizeOptions;
        this._subs.push(
            this._service.onDataChange.subscribe((response: TResponseDtoWithPagination) => {
                this._service.pageSize = response.pageSize;
                this.paginator.pageIndex = response.pageNumber - 1;
                this.paginator.length = response.totalCount;
                this.paginator.pageSize = this._service.pageSize;
            }),
        );

        this._subs.push(
            this.paginator.page.subscribe(page => {
                // page:
                // length: 70
                // pageIndex: 1
                // pageSize: 15
                // previousPageIndex: 0
                this._service.pageSize = page.pageSize;
                this._service.loadPage(page.pageIndex + 1);
            }),
        );
    }
}

export class MatPaginatorIntlDefault extends MatPaginatorIntl {
    itemsPerPageLabel = 'Отображены записи';
    nextPageLabel = 'Перейдите на следующую страницу';
    previousPageLabel = 'Перейти на предыдущую страницу';
    firstPageLabel = 'Вернуться на первую страницу';
    lastPageLabel = 'К последней странице';
    constructor() {
        super();
        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
                return '0 из ' + length;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return startIndex + 1 + ' - ' + endIndex + ' из ' + length;
        };
    }
}

import { Injector } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { AppConsts, AppSessionService, } from '@shared/index';
import { AppPreBootstrap } from './app-pre-bootstrap';
export function appInitializerFactory(injector) {
    return function () {
        abp.ui.setBusy();
        return new Promise(function (resolve, reject) {
            var onError = function (err) {
                abp.ui.clearBusy();
                reject(err);
            };
            AppPreBootstrap.run('ui', function () {
                var appSessionService = injector.get(AppSessionService);
                appSessionService.init().then(function (result) {
                    resolve(result);
                    abp.ui.clearBusy();
                }, onError);
            });
        });
    };
}
export function getRemoteServiceBaseUrl() {
    return AppConsts.remoteServiceBaseUrl;
}
export function getCurrentLanguage() {
    var localeName = abp.localization.currentLanguage.name;
    switch (localeName) {
        case 'ru':
            registerLocaleData(localeRu);
            break;
        default:
            registerLocaleData(localeRu);
            break;
    }
    return localeName;
}
var RootModule = /** @class */ (function () {
    function RootModule() {
    }
    return RootModule;
}());
export { RootModule };

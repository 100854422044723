import { has } from 'lodash';
import * as moment from 'moment';
import { NullObject } from '../../infrastructure/null-object';
export interface IDatePickerEvent {
    input: string;
    date: Date;
    moment: moment.Moment;
}
export class AppDatePickerEvent implements IDatePickerEvent {
    input = '';
    date: Date = null;
    moment: moment.Moment = null;
    constructor(event: IDatePickerEvent = null) {
        if (AppDatePickerEvent.isValid(event)) {
            this.input = event.input;
            this.date = event.date;
            this.moment = event.moment;
        }
    }

    static isAppDatePickerEvent(value: IDatePickerEvent | string | object): boolean {
        if (typeof value === 'object' && has(value, 'moment') && has(value, 'input') && has(value, 'date')) {
            return true;
        }
        return false;
    }
    static isValid(value: IDatePickerEvent | string | object): boolean {
        if (AppDatePickerEvent.isAppDatePickerEvent(value)) {
            const val = value as IDatePickerEvent;
            return (
                moment.isMoment(val.moment) &&
                val.date instanceof Date &&
                typeof val.input === 'string' &&
                val.input.length > 0
            );
        }
        return false;
    }
    static createByMoment(value: moment.Moment, format: string): AppDatePickerEvent {
        if (moment.isMoment(value) && value.isValid()) {
            const val = new AppDatePickerEvent();
            val.date = value.toDate();
            val.moment = value;
            val.input = value.format(format);
            return val;
        } else {
            const message = 'createByMoment: incrorrect value type';
            console.error(message, {
                value,
                format,
            });
            throw new Error(message);
        }
    }

    static getDate(value: string | Date | moment.Moment | IDatePickerEvent): Date | null {
        if (!value) {
            return null;
        }
        if (moment.isMoment(value)) {
            return value.toDate();
        }
        if (value instanceof Date) {
            return value;
        }
        if (typeof value === 'string') {
            return moment(value).toDate();
        }
        if (AppDatePickerEvent.isValid(value)) {
            const val = value as IDatePickerEvent;
            return val.date;
        } else {
            return null;
        }
    }
    static getStringVal(value: string | Date | moment.Moment | IDatePickerEvent | NullObject, format?: string): string {
        if (typeof value === 'string') {
            return value;
        }
        if (value instanceof NullObject) {
            return '';
        }

        if (AppDatePickerEvent.isAppDatePickerEvent(value)) {
            return (value as IDatePickerEvent).input;
        } else if (moment.isMoment(value)) {
            // да я в курсе что это котыль, кто придумает лучше способ определить из Date/moment
            // без input <string>  не являются ли значения маской или значением  по умолчанию
            // получит очивку и плюшку от меня лично (Arun)
            const val = value['_i'];
            if (typeof val === 'string') {
                return val;
            } else if (AppDatePickerEvent.isAppDatePickerEvent(val)) {
                return val.input;
            } else {
                return moment(value, format).format(format);
            }
        } else {
            return moment(value as any, format).format(format);
        }
    }
    static getMomentVal(
        value: string | Date | moment.Moment | IDatePickerEvent | NullObject,
        format?: string,
    ): moment.Moment {
        const val = AppDatePickerEvent.getStringVal(value, format);

        return moment(moment(val, format).toJSON());
    }
}

import * as moment from 'moment';
import * as i0 from "@angular/core";
/**
 * Сервис сравнения времени
 */
var DateTimeService = /** @class */ (function () {
    function DateTimeService() {
    }
    /**
     * Возвращает разницу в секундах между проверяемым временем и текущим
     *
     * @param dateTime Проверяемое время
     */
    DateTimeService.prototype.diffCurrent = function (dateTime) {
        var currentTime = this._getCurrentDateTime();
        console.log(currentTime, dateTime);
        return dateTime.unix() - currentTime.unix();
    };
    /**
     * Проверяет, истекло ли проверяемое время по оношению к текущему
     *
     * @param dateTime Проверяемое время
     */
    DateTimeService.prototype.isTimeOver = function (dateTime) {
        var diffCurrent = this.diffCurrent(dateTime);
        return diffCurrent <= 0;
    };
    DateTimeService.prototype._getCurrentDateTime = function () {
        return moment();
    };
    DateTimeService.ngInjectableDef = i0.defineInjectable({ factory: function DateTimeService_Factory() { return new DateTimeService(); }, token: DateTimeService, providedIn: "root" });
    return DateTimeService;
}());
export { DateTimeService };
/**
 * Длительность в секундах временных промежутков
 */
export var DateTimeDuration;
(function (DateTimeDuration) {
    DateTimeDuration[DateTimeDuration["minute"] = 60] = "minute";
    DateTimeDuration[DateTimeDuration["hour"] = 3600] = "hour";
    DateTimeDuration[DateTimeDuration["day"] = 86400] = "day";
})(DateTimeDuration || (DateTimeDuration = {}));

export enum AppExpansionPanelOrientation {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

export enum AppFormFieldType {
    autocomplete = 'autocomplete',
    checkbox = 'checkbox',
    checkboxIsHospitalizationProcedure = 'checkboxIsHospitalizationProcedure',
    datepicker = 'datepicker',
    file = 'file',
    text = 'text',
    textArea = 'textArea',
    textMask = 'textMask',
    number = 'number',
    radio = 'radio',
    simpleSelect = 'simpleSelect',
    select = 'select',
    chips = 'chips',
    multiField = 'multiField',
    tel = 'tel',
    empty = 'empty',
    appDatePicker = 'appDatePicker',
    appDateTtimePpicker = 'appDateTtimePpicker',
    password = 'password',
}

export enum AppMaterialButtonName {
    add = 'add',
    minimize = 'minimize',
}

export enum AppFormBtnState {
    opened = 'opened',
    closed = 'closed',
}

export enum AppFormBtnType {
    simple = 'simple',
    icon = 'icon',
    raised = 'raised',
    stroked = 'stroked',
    flat = 'flat',
    fab = 'fab',
    miniFab = 'miniFab',
    templateBtn = 'templateBtn',
}

export enum AppValidatorsErrorType {
    // parent
    min = 'min',
    max = 'max',
    email = 'email',
    required = 'required',
    requiredTrue = 'requiredTrue',
    minLength = 'minlength',
    maxLength = 'maxlength',
    pattern = 'pattern',
    nullValidator = 'nullValidator',

    // custom
    isEmptyString = 'isEmptyString',
    isFullUserName = 'isFullUserName',
    isEmailValid = 'isEmailValid',
    isDefaultMobilePhoneMask = 'isDefaultMobilePhoneMask',
    isPhoneValid = 'isPhoneValid',
    inPhoneOrEmail = 'inPhoneOrEmail',
    isOnlyRussianLetters = 'isOnlyRussianLetters',
    isCityPhone = 'isCityPhone',
    isNumber = 'isNumber',
    isRegistryNumber = 'isRegistryNumber',
    isMatched = 'isMatched',
    // not implemented in AppValidators
    dateComparer = 'dateComparer',
    dateRange = 'dateRange',
    isDomain = 'isDomain',
    isValidMoment = 'isValidMoment',
}

export enum AppFormCrudType {
    create = 'create',
    update = 'update',
    delete = 'delete',
}

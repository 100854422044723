import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material';
import { ButtonCloseComponent } from './button-close/button-close.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [ButtonCloseComponent],
    exports: [ButtonCloseComponent],
})
export class ButtonCloseModule {}

import { PipeTransform } from '@angular/core';
import { UtilHelper } from '../helpers/util-helper';
var PhonePipe = /** @class */ (function () {
    function PhonePipe() {
    }
    /**
     *
     * @param v
     * @param pattern default "x(xxx) xxx-xx-xx"
     */
    PhonePipe.prototype.transform = function (v, pattern) {
        return UtilHelper.viewPhoneNumberFormatter(v, pattern);
    };
    return PhonePipe;
}());
export { PhonePipe };

import { LawyerRequestDtoState } from '../service-proxies/service-proxies';

export enum LawyerRequestStatusViewType {
    draft = LawyerRequestDtoState._0,
    /// <summary>
    ///     Заявка открыта.
    /// </summary>
    open = LawyerRequestDtoState._1,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам.
    /// </summary>
    proposeLawyers = LawyerRequestDtoState._10,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из первого и второго круга.
    /// </summary>
    proposeLawyersStep1 = LawyerRequestDtoState._11,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из третьего круга.
    /// </summary>
    proposeLawyersStep2 = LawyerRequestDtoState._12,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из четвертого круга.
    /// </summary>
    proposeLawyersStep3 = LawyerRequestDtoState._13,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из пятого круга.
    /// </summary>
    proposeLawyersStep4 = LawyerRequestDtoState._14,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из шестого круга.
    /// </summary>
    proposeLawyersStep5 = LawyerRequestDtoState._15,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из седьмого круга.
    /// </summary>
    proposeLawyersStep6 = LawyerRequestDtoState._16,

    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из восьмого круга.
    /// </summary>
    proposeLawyersStep7 = LawyerRequestDtoState._17,

    /// <summary>
    ///     Назначение исполнителем адвоката из девятого круга.
    /// </summary>
    proposeLawyersStep8 = LawyerRequestDtoState._18,

    /// <summary>
    ///     Приостановить поиск адвоката.
    /// </summary>
    SuspendSearch = 20,

    /// <summary>
    ///     Перезапустить поиск адвоката.
    /// </summary>
    Restart = 30,

    /// <summary>
    ///     Не удалось выбрать адвоката в качестве исполнителя.
    /// </summary>
    proposeLawyersError = LawyerRequestDtoState._40,

    /// <summary>
    ///     По заявке только что откликнулись адвокаты. Состояние говорит о том что можно переходить к процедуре выбора
    ///     адвоката из откликнувшихся.
    /// </summary>
    accepted = LawyerRequestDtoState._50,

    /// <summary>
    ///     Адвокат выбран/назначен.
    /// </summary>
    lawyerSelected = LawyerRequestDtoState._51,

    /// <summary>
    ///     Запущена процедура смены адвоката.
    /// </summary>
    changeLawyer = LawyerRequestDtoState._52,
    /// <summary>
    ///     Запущена процедура смены адвоката по причине его неявки.
    /// </summary>
    startChangeLawyerByAbscence = LawyerRequestDtoState._53,

    /// <summary>
    ///     Запущена процедура смены адвоката по причине процессуального решения.
    /// </summary>
    startChangeLawyerByProceduralDecision = LawyerRequestDtoState._54,

    /// <summary>
    ///     Исполнена.
    /// </summary>
    executed = LawyerRequestDtoState._100,

    /// <summary>
    ///     Оплачена.
    /// </summary>
    payed = LawyerRequestDtoState._150,

    /// <summary>
    ///     Отменена.
    /// </summary>
    cancelled = LawyerRequestDtoState._200,

    /// <summary>
    /// 	Адвокат по соглашению
    /// </summary>
    appointedByAgreement = LawyerRequestDtoState._210,

    /// <summary>
    /// 	Адвокат сменен по соглашению
    /// </summary>
    cancelledByAgreement = LawyerRequestDtoState._250,

    /// <summary>
    ///     Замена адвоката по причине неявки (конечный статус, создается новая заявка)
    /// </summary>
    lawyerChangedByAbsence = LawyerRequestDtoState._251,

    /// <summary>
    ///     Замена адвоката на основании процессуального решения (конечный статус, создается новая заявка)
    /// </summary>
    lawyerChangedByProceduralDecision = LawyerRequestDtoState._252,
}

export interface ILawyerRequestStatusViewModel {
    /**
     * текущий статус заявки
     */
    status: LawyerRequestStatusViewType | LawyerRequestDtoState;
    /**
     * ЧПУ короткое имя
     */
    name: string;
    /**
     * Детальное описание статуса
     */
    description: string;
    /**
     * Флаг говорит об общей возможности сменить адвоката из текущего состояния
     */
    changeLawyerDisabled: boolean;
    /**
     * Флаг говорит о возможности отменить адвоката из текущего состояния
     * в соответствии с судебным решением
     * Доступен после назначения и до начала судабного заседаяния
     */
    changeLawyerByProcessualDisabled: boolean;
    /**
     * Флаг говорит о возможности отменить адвоката из текущего состояния по причине не явки адвоката ,
     * Опция доступна после начала судебного  заседаяния
     * так же говорит  о том что адвокат не явился на назначенное слушанье     *
     */
    changeLawyerByAbsenceDisabled: boolean;
    /**
     * Флаг говорит о возможности отменить заявку со стороны инициатора
     * до  назначения адвоката на дело
     */
    cancelRequestDisabled: boolean;
    /**
     * Флаг говорит о возможности отменить заявку со стороны инициатора из текущего состояния
     * в виду наличия найденного адвоката на указанное дело.
     * Инициатору требуется прикрепить данные о новом адвокате
     */
    cancelRequestByAgreementDisabled: boolean;
    hideLawyerInfo: boolean;
}
export class LawyerRequestStatusViewModel implements ILawyerRequestStatusViewModel {
    status: LawyerRequestStatusViewType | LawyerRequestDtoState;
    name: string;
    description: string;
    changeLawyerDisabled: boolean;
    changeLawyerByProcessualDisabled: boolean;
    changeLawyerByAbsenceDisabled: boolean;
    cancelRequestDisabled: boolean;
    cancelRequestByAgreementDisabled: boolean;
    hideLawyerInfo: boolean;
    constructor(status: LawyerRequestDtoState | LawyerRequestStatusViewType | number) {
        this._init(status);
    }

    protected _setFlags() {
        this.changeLawyerDisabled = true;
        this.changeLawyerByProcessualDisabled = true;
        this.changeLawyerByAbsenceDisabled = true;
        this.cancelRequestDisabled = true;
        this.cancelRequestByAgreementDisabled = true;
        this.hideLawyerInfo = true;

        switch (this.status) {
            case LawyerRequestDtoState._0:
                break;
            case LawyerRequestDtoState._40:
                /**
                 * адвокат не найден, но есть каеи то опции - уточнить
                 **/
                this.cancelRequestDisabled = false;
                this.cancelRequestByAgreementDisabled = false;
                break;
            case LawyerRequestDtoState._1:
            case LawyerRequestDtoState._10:
            case LawyerRequestDtoState._11:
            case LawyerRequestDtoState._12:
            case LawyerRequestDtoState._13:
            case LawyerRequestDtoState._14:
            case LawyerRequestDtoState._15:
            case LawyerRequestDtoState._16:
            case LawyerRequestDtoState._17:
            case LawyerRequestDtoState._18:

            case LawyerRequestDtoState._20:
            case LawyerRequestDtoState._30:

            case LawyerRequestDtoState._50:
                /** работают умолчания, меняем только отличия */
                this.cancelRequestDisabled = false;
                this.cancelRequestByAgreementDisabled = false;
                break;
            case LawyerRequestDtoState._51:
                this.changeLawyerDisabled = false;
                this.changeLawyerByProcessualDisabled = false;
                this.changeLawyerByAbsenceDisabled = false;
                this.cancelRequestDisabled = false;
                this.cancelRequestByAgreementDisabled = false;
                this.hideLawyerInfo = false;

                break;
            case LawyerRequestDtoState._52:
                /** работают умолчания, меняем только отличия */
                this.cancelRequestDisabled = false;
                // this.cancelRequestByAgreementDisabled = false;
                this.hideLawyerInfo = false;
                break;
            case LawyerRequestDtoState._53:
            case LawyerRequestDtoState._54:
                /** работают умолчания, меняем только отличия */
                break;
            case LawyerRequestDtoState._100:
            case LawyerRequestDtoState._150:
            case LawyerRequestDtoState._200:
            case LawyerRequestDtoState._210:
            case LawyerRequestDtoState._250:
            case LawyerRequestDtoState._251:
            case LawyerRequestDtoState._252:
                /** работают умолчания, меняем только отличия */
                break;
            default:
                /** работают умолчания,ничего не меняем */
                break;
        }
    }
    protected _init(status: LawyerRequestDtoState | LawyerRequestStatusViewType) {
        this.status = status;
        const roundPlus = 'Отправка предложений об исполниении заявки адвокатам ';
        switch (status) {
            case LawyerRequestDtoState._0:
                this.name = 'Черновик';
                this.description = 'Черновик';

                break;
            case LawyerRequestDtoState._1:
                this.name = 'Старт поиска адвоката';
                this.description = 'Старт поиска адвоката';

                break;
            case LawyerRequestDtoState._10:
                this.name = 'Инициирована отправка предложений';
                this.description = 'Отправка предложений адвокатам';
                break;
            case LawyerRequestDtoState._11:
                this.name = this._round(1);
                this.description = roundPlus + 'из первого круга';
                break;
            case LawyerRequestDtoState._12:
                this.name = this._round(2);
                this.description = roundPlus + 'из второго круга';
                break;
            case LawyerRequestDtoState._13:
                this.name = this._round(3);
                this.description = roundPlus + 'из третьего круга';
                break;
            case LawyerRequestDtoState._14:
                this.name = this._round(4);
                this.description = roundPlus + 'из четвертого круга';

                break;
            case LawyerRequestDtoState._15:
                this.name = this._round(5);
                this.description = roundPlus + 'из пятого круга';

                break;
            case LawyerRequestDtoState._16:
                this.name = this._round(6);
                this.description = roundPlus + 'из шестого круга';

                break;
            case LawyerRequestDtoState._17:
                this.name = this._round(7);
                this.description = roundPlus + 'из седьмого круга';

                break;
            case LawyerRequestDtoState._18:
                this.name = this._round(8);
                this.description = roundPlus + 'из восьмого круга';

                break;
            case LawyerRequestDtoState._20:
                this.name = 'Приостановка поиска адвоката';
                this.description = 'Приостановить поиск адвоката';

                break;
            case LawyerRequestDtoState._30:
                this.name = 'Перезапуск поиска адвоката';
                this.description = 'Перезапустить поиск адвоката';

                break;
            case LawyerRequestDtoState._40:
                this.name = 'Адвокат не найден';
                this.description = 'Не удалось выбрать адвоката в качестве исполнителя';

                break;
            case LawyerRequestDtoState._50:
                this.name = 'Выбор адвоката (Есть отклики)';
                this.description =
                    'По заявке только что откликнулись адвокаты.' +
                    ' Состояние говорит о том что можно переходить к процедуре выбора адвоката из откликнувшихся';

                break;
            case LawyerRequestDtoState._51:
                this.name = 'Адвокат назначен';
                this.description = 'Адвокат назначен директивно';

                break;
            case LawyerRequestDtoState._52:
                this.name = 'Замена адвоката';
                this.description = 'Запущена процедура замены адвоката';
                break;

            case LawyerRequestDtoState._53:
                this.name = 'Замена адвоката по причине неявки';
                this.description = 'Запущена процедура замены адвоката по причине неявки адвоката';
                break;
            case LawyerRequestDtoState._54:
                this.name = 'Замена адвоката на основании процессуального решения';
                this.description = 'Запущена процедура замены адвоката на основании процессуального решения';
                break;
            case LawyerRequestDtoState._100:
                this.name = 'Завершена';
                this.description = 'Исполнена';
                break;
            case LawyerRequestDtoState._150:
                this.name = 'Оплачена';
                this.description = 'Оплачена';
                break;
            case LawyerRequestDtoState._200:
                this.name = 'Отменена';
                this.name = 'Заявка отменена';
                break;
            case LawyerRequestDtoState._210:
                this.name = 'Назначен по соглашению';
                this.description = 'Адвокат отменен по соглашению';
                break;

            case LawyerRequestDtoState._250:
                this.name = 'Отменена по причине вступления адвоката по соглашению';
                this.description = 'Инициатор отменил заявку по соглашению, и назначил другого адвоката';
                break;
            case LawyerRequestDtoState._251:
                this.name = 'Заменен по причине неявки';
                this.description = 'Замена адвоката по причине неявки';
                break;
            case LawyerRequestDtoState._252:
                this.name = 'Заменен на основании процессуального решения';
                this.description = 'Заменен на основании процессуального решения';
                break;

            default:
                throw new Error('Не поддерживаемй статус: ' + status);
        }
        this._setFlags();
    }
    protected _round(num: number) {
        return `Выбор адвоката ${num} круг`;
    }
}

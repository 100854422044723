/**
 * Класс для работы с объектами ошибок
 */
var ErrorHelper = /** @class */ (function () {
    function ErrorHelper() {
    }
    ErrorHelper.convertError = function (responseError) {
        var json = responseError.headers['$error'];
        if (json) {
            var error = JSON.parse(json);
            return {
                code: error['code'],
                message: error['message'],
                details: error['details'],
                validationErrors: error['validationErrors'],
            };
        }
        return {
            code: undefined,
            message: undefined,
            details: undefined,
            validationErrors: undefined,
        };
    };
    return ErrorHelper;
}());
export { ErrorHelper };

import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, takeUntil } from 'rxjs/operators';
import { each, find, map as _map, uniq, remove } from 'lodash';
import { FileDataServiceProxy, AppConsts } from './_imports';
import { FileUploaderLoadState, } from './_infrastructure';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../service-proxies/service-proxies";
var FileUploaderPrivateService = /** @class */ (function () {
    function FileUploaderPrivateService(_http, _proxy) {
        this._http = _http;
        this._proxy = _proxy;
        this.onDisable = new EventEmitter();
        this.onEnable = new EventEmitter();
        this.onReset = new EventEmitter();
    }
    Object.defineProperty(FileUploaderPrivateService.prototype, "_api", {
        get: function () {
            return AppConsts.remoteServiceBaseUrl + "/upload/";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploaderPrivateService.prototype, "url", {
        get: function () {
            return this._url || (this._url = this._api + 'files');
        },
        enumerable: true,
        configurable: true
    });
    FileUploaderPrivateService.prototype.getDeleteApiUrl = function (id) {
        return this._api + 'delete/' + id;
    };
    FileUploaderPrivateService.prototype.loadFiles = function (fileInfoes) {
        var _this = this;
        if (!fileInfoes || !fileInfoes.length) {
            throw new Error('no data, argument fileIds is empty or null');
        }
        var ids = fileInfoes.map(function (i) {
            return i.id;
        });
        var fis = this.copyAsFileInfo(fileInfoes);
        return this._proxy.getMany(ids).pipe(map(function (i) {
            var coll = _map(i, function (val) {
                return val;
            });
            return _this.mapToView(fis, coll);
        }));
    };
    FileUploaderPrivateService.prototype.upload = function (file) {
        var _this = this;
        var fd = new FormData();
        var fileSource = [file];
        fd.append(file.name || 'file', file.data);
        var fis = this.copyAsFileInfo(fileSource);
        return this._http.post(this.url, fd).pipe(map(function (response) {
            return _this._mapUploadresponse(fileSource, fis, response);
        }));
    };
    FileUploaderPrivateService.prototype.uploadBulk = function (files) {
        var _this = this;
        var formData = new FormData();
        var fis = this.copyAsFileInfo(files);
        each(files, function (file) {
            formData.append(file.index + '', file.data, file.data.name);
        });
        return this._http.post(this.url, formData).pipe(map(function (response) {
            return _this._mapUploadresponse(files, fis, response);
        }));
    };
    FileUploaderPrivateService.prototype.delete = function (fileInfo) {
        return this._http.delete(this.getDeleteApiUrl(fileInfo.id)).pipe(map(function (i) {
            return fileInfo;
        }));
    };
    FileUploaderPrivateService.prototype.mapToView = function (source, response) {
        var result = [];
        each(response, function (value) {
            var info = find(source, function (i) {
                return i.id === value.id;
            });
            if (info && value) {
                var view = Object.assign({}, value);
                view.uiId = info.uiId;
                result.push(view);
            }
        });
        return result;
    };
    FileUploaderPrivateService.prototype.copyAsFileInfo = function (fileInfoes) {
        return fileInfoes.map(function (i) {
            return {
                id: i.id,
                uiId: i.uiId,
            };
        });
    };
    FileUploaderPrivateService.prototype._mapUploadresponse = function (fileSource, fileInfo, response) {
        var result = [];
        if (!response || response.error) {
            return result;
        }
        if (response.result && response.result.length) {
            each(response.result, function (elem, index) {
                fileSource[index].id = fileInfo[index].id = elem.id;
            });
            result = this.mapToView(fileInfo, response.result);
        }
        return result;
    };
    FileUploaderPrivateService.ngInjectableDef = i0.defineInjectable({ factory: function FileUploaderPrivateService_Factory() { return new FileUploaderPrivateService(i0.inject(i1.HttpClient), i0.inject(i2.FileDataServiceProxy)); }, token: FileUploaderPrivateService, providedIn: "root" });
    return FileUploaderPrivateService;
}());
export { FileUploaderPrivateService };
var FileUploaderNotifierService = /** @class */ (function () {
    function FileUploaderNotifierService(_privateService) {
        this._privateService = _privateService;
        this.onDelete = new EventEmitter();
        this.onLoaded = new EventEmitter();
        this.onLoadStateChange = new EventEmitter();
    }
    FileUploaderNotifierService.prototype.disable = function (instanceId) {
        this._privateService.onDisable.emit(instanceId);
    };
    FileUploaderNotifierService.prototype.enable = function (instanceId) {
        this._privateService.onEnable.emit(instanceId);
    };
    FileUploaderNotifierService.prototype.reset = function (instanceId) {
        this._privateService.onReset.emit(instanceId);
    };
    FileUploaderNotifierService.prototype.toUniqFileIds = function (files) {
        if (!files) {
            return [];
        }
        return uniq(_map(files, function (i) { return i.id; }));
    };
    FileUploaderNotifierService.prototype.removeFromInstance = function (files, fileId) {
        remove(files, function (f) {
            return f.id === fileId;
        });
    };
    /**
     * @description Примятяет стандартное поведенени к подпискам на файлы для указанного инстанса
     * @param destructor Деструктор который отпишет от подписок
     * @param cfg конфиг рекций
     */
    FileUploaderNotifierService.prototype.initDefaultSubscriptions = function (destructor, cfg) {
        var _this = this;
        this.onLoaded.pipe(takeUntil(destructor)).subscribe(function (i) {
            if (i.instanceId === cfg.instanceId) {
                console.log('files onLoaded', i);
                if (cfg.onLoaded(i.loadedViewFiles)) {
                    _this.enable(cfg.instanceId);
                }
            }
        });
        this.onLoadStateChange.pipe(takeUntil(destructor)).subscribe(function (i) {
            if (i.instanceId === cfg.instanceId) {
                switch (i.loadingState) {
                    case FileUploaderLoadState.loading:
                        cfg.onLoading();
                        break;
                    case FileUploaderLoadState.finish:
                        cfg.onFinish();
                        break;
                    case FileUploaderLoadState.error:
                        if (cfg.onError) {
                            cfg.onError();
                        }
                        else {
                            cfg.onFinish();
                        }
                        break;
                    default:
                        break;
                }
            }
        });
        if (cfg.onDelete instanceof Function) {
            this.onDelete.pipe(takeUntil(destructor)).subscribe(function (i) {
                if (i.instanceId === cfg.instanceId) {
                    cfg.onDelete(i.fileInfo);
                }
            });
        }
    };
    FileUploaderNotifierService.ngInjectableDef = i0.defineInjectable({ factory: function FileUploaderNotifierService_Factory() { return new FileUploaderNotifierService(i0.inject(FileUploaderPrivateService)); }, token: FileUploaderNotifierService, providedIn: "root" });
    return FileUploaderNotifierService;
}());
export { FileUploaderNotifierService };

import * as moment from 'moment';
/**
 * Модель заявки, выводимой при сканировании ее qr кода
 */
var QrRequestInfo = /** @class */ (function () {
    function QrRequestInfo() {
    }
    QrRequestInfo.prototype.init = function (data) {
        if (data) {
            this.requestNumber = data['requestNumber'];
            this.dateCreate = data['dateCreate'] && moment(data['dateCreate']);
            this.hearingDate = data['hearingDate'] && moment(data['hearingDate']);
            this.judicialAuthorityName = data['judicialAuthorityName'];
            this.departmentAddress = data['departmentAddress'].PostIndex
                ? data['departmentAddress'].PostIndex + ", " + data['departmentAddress'].Place + ", " + data['departmentAddress'].Address1
                : data['departmentAddress'];
            this.caseNumber = data['caseNumber'];
            if (data['lawyer']) {
                var lawyer = data['lawyer'];
                this.lawyer = {
                    fullName: lawyer.fullName,
                    registryNumber: lawyer.registryNumber,
                };
            }
        }
    };
    return QrRequestInfo;
}());
export { QrRequestInfo };

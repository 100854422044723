var Guid = /** @class */ (function () {
    function Guid(_val) {
        this._val = _val;
    }
    Object.defineProperty(Guid, "empty", {
        get: function () {
            return '00000000-0000-0000-0000-000000000000';
        },
        enumerable: true,
        configurable: true
    });
    Guid.isGuid = function (val) {
        return val instanceof Guid;
    };
    Guid.isEmpty = function (val) {
        return !val || val.toString() === Guid.empty;
    };
    Guid.create = function (val) {
        return new Guid(val.toString());
    };
    Guid.createQuickGuid = function () {
        return Guid.create(Guid._createQuickGuid());
    };
    Guid.createGuid = function () {
        return Guid.create(Guid._createGuid());
    };
    Guid.createEmptyGuid = function () {
        return Guid.create(Guid.empty);
    };
    Guid.prototype.toString = function () {
        return this._val || '';
    };
    Guid._createQuickGuid = function () {
        return (Math.random()
            .toString(36)
            .substring(2, 15) +
            Math.random()
                .toString(36)
                .substring(2, 15));
    };
    Guid._createGuid = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            // tslint:disable-next-line:no-bitwise
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
    };
    return Guid;
}());
export { Guid };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DaterangepickerToggleComponent } from './daterangepicker-toggle.component';
import { DaterangepickerComponent } from './daterangepicker.component';
import { DaterangepickerDirective } from './daterangepicker.directive';

@NgModule({
    declarations: [DaterangepickerComponent, DaterangepickerDirective, DaterangepickerToggleComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    providers: [],
    exports: [DaterangepickerComponent, DaterangepickerDirective, DaterangepickerToggleComponent],
    entryComponents: [DaterangepickerComponent],
})
export class DaterangepickerMd {}

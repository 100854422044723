var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppComponentBase } from './app-component-base';
var PagedResultDto = /** @class */ (function () {
    function PagedResultDto() {
    }
    return PagedResultDto;
}());
export { PagedResultDto };
var EntityDto = /** @class */ (function () {
    function EntityDto() {
    }
    return EntityDto;
}());
export { EntityDto };
var PagedRequestDto = /** @class */ (function () {
    function PagedRequestDto() {
    }
    return PagedRequestDto;
}());
export { PagedRequestDto };
var PagedListingComponentBase = /** @class */ (function (_super) {
    __extends(PagedListingComponentBase, _super);
    function PagedListingComponentBase(injector) {
        var _this = _super.call(this, injector) || this;
        _this.pageSize = 10;
        _this.pageNumber = 1;
        _this.totalPages = 1;
        _this.isTableLoading = false;
        return _this;
    }
    PagedListingComponentBase.prototype.ngOnInit = function () {
        this.refresh();
    };
    PagedListingComponentBase.prototype.refresh = function () {
        this.getDataPage(this.pageNumber);
    };
    PagedListingComponentBase.prototype.showPaging = function (result, pageNumber) {
        this.totalPages = (result.totalCount - (result.totalCount % this.pageSize)) / this.pageSize + 1;
        this.totalItems = result.totalCount;
        this.pageNumber = pageNumber;
    };
    PagedListingComponentBase.prototype.getDataPage = function (page) {
        var _this = this;
        var req = new PagedRequestDto();
        req.maxResultCount = this.pageSize;
        req.skipCount = (page - 1) * this.pageSize;
        this.isTableLoading = true;
        this.list(req, page, function () {
            _this.isTableLoading = false;
        });
    };
    return PagedListingComponentBase;
}(AppComponentBase));
export { PagedListingComponentBase };

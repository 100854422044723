export class AppConsts {
    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static defaultTenantId = 1;
    static defaultTenantUid = '841a2213-885a-4c91-bc27-ea38f627414b';

    static oidcBaseUrl: string;
    static oidcClientId: string;
    static oidcClientSecret: string;
    static oidcClientScope: string;

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Apgm',
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
        refreshTokenName: '6420E296-81D4-40D6-9B65-1732FBB57100',
        expiredTokenName: 'B3B6AA46-856E-472A-B83C-9F2CDACDD33E',
    };

    static readonly lawyers = {
        lawyerRole: 'Lawyer',
    };
    static fakeEmail = '1@advokatymoscow.ru';
}

export enum UserRoles {
    admin = 'admin',
    unregisteredUser = 'unregisteredUser',
    cmsAdmin = 'cmsAdmin',
    moderator = 'moderator',
    lawyer = 'lawyer',
    initiator = 'initiator',
    referenceEditor = 'referenceEditor',
    campaignsAdmin = 'campaignsAdmin',
    notificationsAdmin = 'notificationsAdmin',
    integrationsAdmin = 'integrationsAdmin',
    registeredUser = 'registeredUser',
    humanResourcesOfficer = 'HumanResourcesOfficer',
    chamberAccountant = 'ChamberAccountant',
    formationAccount = 'FormationAccount',
    organizationHead = 'OrganizationHead',
    assistantManager = 'AssistantManager',
}

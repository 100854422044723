import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkBarComponent } from './link-bar.component';
import { SharedMaterialModule } from './../../shared.material.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [SharedMaterialModule, CommonModule, RouterModule],
    declarations: [LinkBarComponent],
    exports: [LinkBarComponent],
})
export class LinkBarModule {}

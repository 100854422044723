import { Component, OnInit } from '@angular/core';
import { AppConsts } from '@shared/index';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.scss'],
})
/**
 * Компонент карты сайта
 */
export class SitemapComponent implements OnInit {
    /**
     * Базовый url, относительно которого строится карта сайта
     */
    public clientBaseUrl = '';

    /**
     * Url сервера. Требуется для некоторых сервисных ссылок
     */
    public serverBaseUrl = '';

    ngOnInit(): void {
        this.clientBaseUrl = this._getBaseUrl();
        this.serverBaseUrl = this._getApiBaseUrl();
    }

    /**
     * Определяет, работает ли сайт в production режиме
     */
    isProduction(): boolean {
        if (!environment || !environment.production) {
            return false;
        }

        return environment.production;
    }

    private _getBaseUrl(): string {
        return AppConsts.appBaseUrl;
    }

    private _getApiBaseUrl(): string {
        return AppConsts.remoteServiceBaseUrl;
    }
}

import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppFormBtnType, IConfigurable, AppMaterialButtonName, AppFormBtnState, IDisableable } from './_imports';

export interface IAppFormBtn extends IDisableable {
    value: string;
    type: AppFormBtnType;
    css?: string | string[];
    onClick($event: Event, ...args);
}

export interface IAppToggleBtn {
    onStateChange: EventEmitter<AppFormBtnState>;
    state: AppFormBtnState;
    open(): void;
    close(): void;
    isOpened(): boolean;
    toggle(): void;
    setToState(state: AppFormBtnState): void;
}

export abstract class BaseAppFormBtn implements IAppFormBtn, IConfigurable<IAppFormBtn> {
    value: string;
    type: AppFormBtnType;
    disabled: boolean;
    css?: string | string[];

    constructor(typeBtn: AppFormBtnType | string) {
        this.disabled = false;
        this.type = typeBtn as AppFormBtnType;
    }
    public configure(configurator: (_this: IAppFormBtn) => void): IAppFormBtn {
        configurator(this);
        return this;
    }
    public onClick($event: Event, ...args) {
        const msg = 'BaseAppFormBtn ' + this.type + ' :onClick no implemented';
        console.error(msg, {
            this: this,
            $event: $event,
            args: args,
        });
    }
}

//#region Icon

export class BaseAppFormIconBtn extends BaseAppFormBtn {
    constructor() {
        super(AppFormBtnType.icon);
    }
}
export class AppFormIconAddBtn extends BaseAppFormIconBtn {
    constructor() {
        super();
        this.value = AppMaterialButtonName.add;
    }
}
// #endregion

//#region Toggle

export class AppFormToggleBtn extends BaseAppFormBtn implements IAppToggleBtn {
    onStateChange: EventEmitter<AppFormBtnState>;
    state: AppFormBtnState;

    constructor(
        public readonly openValue: AppMaterialButtonName,
        public readonly closeValue: AppMaterialButtonName,
        state: AppFormBtnState,
        type: string,
    ) {
        super(type);
        this.onStateChange = new EventEmitter<AppFormBtnState>(true);
        this.setToState(state);
    }
    open() {
        this.value = this.openValue;
        this.state = AppFormBtnState.opened;
        this.onStateChange.emit(this.state);
    }

    close() {
        this.value = this.closeValue;
        this.state = AppFormBtnState.closed;
        this.onStateChange.emit(this.state);
    }

    isOpened() {
        return this.state === AppFormBtnState.opened;
    }
    setToState(state: AppFormBtnState) {
        // console.log('setToState', {
        //     state,
        //     this: this,
        // });
        if (state === AppFormBtnState.opened) {
            this.open();
        } else if (state === AppFormBtnState.closed) {
            this.close();
        } else {
            console.error('AppFormIconToggleBtn:setToState error', this);
        }
    }
    toggle() {
        this.isOpened() ? this.close() : this.open();
    }
}

export class AppFormIconToggleAddMinBtn extends AppFormToggleBtn {
    constructor(state: AppFormBtnState) {
        super(AppMaterialButtonName.minimize, AppMaterialButtonName.add, state, AppFormBtnType.icon);
    }
    onClick($event: Event, ...args) {
        // nope
    }
}

// #endregion

//#region Simple
export class BaseAppFormSimpleBtn extends BaseAppFormBtn {
    constructor(text: string, css?: string) {
        super(AppFormBtnType.simple);
        this.value = text;
        this.css = css || null;
    }
    protected getFormRefFromArgs(...args): FormGroup {
        if (args && args.length && args[0].formGroupRef) {
            return args[0].formGroupRef;
        }
        return null;
    }
}
export class AppFormSimpleSaveBtn extends BaseAppFormSimpleBtn {
    constructor(text?: string, css?: string) {
        super(text || 'Сохранить', css);
    }
}
export class AppFormSimpleEditBtn extends BaseAppFormSimpleBtn {
    constructor(text?: string, css?: string) {
        super(text || 'Изменить', css);
    }
    onClick($event: Event, ...args) {
        const form = this.getFormRefFromArgs(...args);
        if (form && form.disabled) {
            form.enable({
                emitEvent: true,
                onlySelf: false,
            });
        }
    }
}
export class AppFormCleanSimpleBtn extends BaseAppFormSimpleBtn {
    constructor(text?: string, css?: string) {
        super(text || 'Очистить', css);
    }
    onClick($event: Event, ...args) {
        console.log('$event', {
            this: this,
            $event: $event,
            args: args,
        });
        const form = this.getFormRefFromArgs(...args);
        if (form) {
            form.reset();
        }
    }
}

export class AppFormSimpleUndoBtn extends BaseAppFormSimpleBtn {
    constructor(text?: string, css?: string) {
        super(text || 'Отменить', css);
    }

    onClick($event: Event, ...args) {
        const form = this.getFormRefFromArgs(...args);
        if (form && form.enabled) {
            form.disable({
                emitEvent: false,
                onlySelf: false,
            });
        }
    }
}
//#endregion

// #reagion  with emmter on form ready

export class AppFormValidationSimpleBtn extends BaseAppFormSimpleBtn {
    private _onFormReady: EventEmitter<FormGroup>;
    get onFormReady(): EventEmitter<FormGroup> {
        if (this._onFormReady) {
            return this._onFormReady;
        }
        this._onFormReady = new EventEmitter(true);
        return this._onFormReady;
    }
    constructor(text: string, css?: string) {
        super(text, css);
    }

    onClick($event: Event, ...args): void {
        if (this.disabled) {
            return;
        }
        const form = this.getFormRefFromArgs(...args);
        if (form && form.valid && form.enabled && !form.pending) {
            this.onFormReady.emit(form);
        }
    }
}

export class AppFormSaveValidationSimpleBtn extends AppFormValidationSimpleBtn {
    constructor(text?: string, css?: string) {
        super(text || 'Сохранить', css);
    }
}

export class AppFormAddValidationSimpleBtn extends AppFormValidationSimpleBtn {
    constructor(text?: string, css?: string) {
        super(text || 'Добавить', css);
    }
}
export class AppFormDeleteValidationSimpleBtn extends AppFormValidationSimpleBtn {
    constructor(text?: string) {
        super(text || 'Удалить');
    }
    onClick($event: Event, ...args): void {
        const form = this.getFormRefFromArgs(...args);
        if (form && !form.pending) {
            this.onFormReady.emit(form);
        }
    }
}

export class AppFormUndoValidationSimpleBtn extends AppFormValidationSimpleBtn {
    constructor(text?: string, css?: string) {
        super(text || 'Отменить', css);
    }
    onClick($event: Event, ...args) {
        if (this.disabled) {
            return;
        }
        const form = this.getFormRefFromArgs(...args);
        if (!form || form.disabled) {
            return;
        }
        if (!form.pending) {
            this.onFormReady.emit(form);
        }
    }
}

//#endregion

// #endregion

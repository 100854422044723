import { OnInit } from '@angular/core';
import { AppConsts } from '@shared/index';
import { environment } from '../environments/environment';
var SitemapComponent = /** @class */ (function () {
    function SitemapComponent() {
        /**
         * Базовый url, относительно которого строится карта сайта
         */
        this.clientBaseUrl = '';
        /**
         * Url сервера. Требуется для некоторых сервисных ссылок
         */
        this.serverBaseUrl = '';
    }
    SitemapComponent.prototype.ngOnInit = function () {
        this.clientBaseUrl = this._getBaseUrl();
        this.serverBaseUrl = this._getApiBaseUrl();
    };
    /**
     * Определяет, работает ли сайт в production режиме
     */
    SitemapComponent.prototype.isProduction = function () {
        if (!environment || !environment.production) {
            return false;
        }
        return environment.production;
    };
    SitemapComponent.prototype._getBaseUrl = function () {
        return AppConsts.appBaseUrl;
    };
    SitemapComponent.prototype._getApiBaseUrl = function () {
        return AppConsts.remoteServiceBaseUrl;
    };
    return SitemapComponent;
}());
export { SitemapComponent };

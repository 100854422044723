import { IError } from '../components/field-error/field-error.component';

export const ERROR_DICTIONARY: IError = {
    required: 'Это поле обязательно для заполнения',
    dateRange: 'Дата находится в неверном диапазоне',
    pattern: 'Неверный формат данных',
    minlength: 'Длина поля не должна быть меньше',
    maxlength: 'Длина поля не должна превышать',
    incorrectDate: 'Даты не должны повторяться',
    isEmailValid: 'Неверный формат email адреса',
    emailUnavailable: 'Email уже зарегистрирован в системе',
    maxAdditionalExpenseSummValue: 'Значение превышает допустимый максимум',
    isFullUserName: 'Поле должно содержать имя и фамилию кириллицей',
};

import { OnInit, ElementRef, OnDestroy, ChangeDetectorRef, ViewRef, } from '@angular/core';
import { indexOf, pullAt, each, find, unionBy, xorBy, cloneDeep, every } from 'lodash';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FileUploadModel, FileUploaderLoadState, } from '../_infrastructure';
import { FileUploaderPrivateService, FileUploaderNotifierService } from '../file-uploader.service';
/**
 * @description load files by file ids for view, deelte them and upload them if nesesary
 *
 * @example
 * <app-file-uploader [fileUploaderOption]="fileUploaderOption" ></app-file-uploader>
 * @see {@link FileUploaderOption}
 *
 * ```typescript
  this.fileUploaderOption  = new FileUploaderOption();
  this.fileUploaderOption.multimpe = false;
  this.fileUploaderOption.fileInfo =[
   { id: "entytyGuidId",
     uiId: "generated unic id for instace"
   }
  ];
```
 *
 */
var FileUploaderComponent = /** @class */ (function () {
    function FileUploaderComponent(_privateService, _notifierService, _changeDetectorRef) {
        this._privateService = _privateService;
        this._notifierService = _notifierService;
        this._changeDetectorRef = _changeDetectorRef;
        this.dataSource = false;
        this.allowAdd = false;
        this.showMaxFileSizeError = false;
        this._locked = false;
        this._files = [];
        this._destructor = new Subject();
        this._comparerKey = 'id';
        this._hasInitFiles = false;
        this._maxFileSize = 10485760;
    }
    Object.defineProperty(FileUploaderComponent.prototype, "_inPending", {
        get: function () {
            return this._pendingFiles && this._pendingFiles.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploaderComponent.prototype, "_input", {
        get: function () {
            return this._fileUploadInput.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploaderComponent.prototype, "disabled", {
        get: function () {
            return this.fileUploaderOption.disabled;
        },
        set: function (value) {
            this.fileUploaderOption.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploaderComponent.prototype, "loading", {
        get: function () {
            return this._locked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileUploaderComponent.prototype, "deleteBtn", {
        get: function () {
            return this.fileUploaderOption.deleteButton;
        },
        enumerable: true,
        configurable: true
    });
    FileUploaderComponent.prototype.updateConfig = function () {
        if (this.fileUploaderOption.fileData &&
            this.fileUploaderOption.fileInfo &&
            this.fileUploaderOption.fileData.length === this.fileUploaderOption.fileInfo.length) {
            this._hasInitFiles = true;
            this._initByFileData(this.fileUploaderOption);
        }
        else if (this.fileUploaderOption.fileInfo && this.fileUploaderOption.fileInfo.length) {
            this._hasInitFiles = true;
            this._initByFileInfo(this.fileUploaderOption);
        }
        else {
            this.allowAdd = true;
        }
    };
    FileUploaderComponent.prototype.ngOnInit = function () {
        this.initFileUploader();
    };
    FileUploaderComponent.prototype.ngOnDestroy = function () {
        this._destructor.next();
        this._destructor.complete();
    };
    FileUploaderComponent.prototype.selectFile = function () {
        if (!this._inPending && !this._locked && !this.disabled) {
            this._fileUploadInput.nativeElement.click();
        }
    };
    FileUploaderComponent.prototype.initFileUploader = function () {
        var cfg = this.fileUploaderOption;
        if (!cfg) {
            return;
        }
        this.disabled = cfg.disabled;
        this.btnUploadContent = cfg.selectButton.icon + cfg.selectButton.text;
        this.deleteBtnTitle = cfg.deleteButton.text;
        this.deleteBtnIcon = cfg.deleteButton.icon;
        this._initInput(cfg);
        this._subscribeOnLoaded(cfg);
        this._subscribeOnDelete(cfg);
        this._subscribeToCommands(cfg);
        if (cfg.fileData && cfg.fileInfo && cfg.fileData.length === cfg.fileInfo.length) {
            this._hasInitFiles = true;
            this._initByFileData(cfg);
        }
        else if (cfg.fileData && cfg.fileInfo) {
        }
        else if (cfg.fileInfo && cfg.fileInfo.length) {
            this._hasInitFiles = true;
            this._initByFileInfo(cfg);
        }
        else {
            this.allowAdd = true;
        }
    };
    FileUploaderComponent.prototype.delete = function (file) {
        var _this = this;
        if (this._locked || this._inPending) {
            return;
        }
        else {
            this._locked = true;
            if (this.fileUploaderOption.localDelete) {
                this._notifierService.onDelete.emit({
                    fileInfo: file,
                    instanceId: this.fileUploaderOption.instanceId,
                });
                this._detectChanges();
            }
            else {
                this._privateService.delete(file).subscribe(function (deletedFileInfo) {
                    _this._notifierService.onDelete.emit({
                        fileInfo: deletedFileInfo,
                        instanceId: _this.fileUploaderOption.instanceId,
                    });
                    _this._detectChanges();
                });
            }
        }
    };
    FileUploaderComponent.prototype.getFileName = function (file) {
        console.log(file);
        return file.name;
    };
    FileUploaderComponent.prototype._subscribeToCommands = function (cfg) {
        var _this = this;
        this._privateService.onDisable
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe(function (i) {
            if (!_this.isCurrentInstance({ instanceId: i })) {
                return;
            }
            _this.showMaxFileSizeError = false;
            _this.disabled = true;
            _this._detectChanges();
        });
        this._privateService.onEnable
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe(function (i) {
            if (!_this.isCurrentInstance({ instanceId: i })) {
                return;
            }
            _this.showMaxFileSizeError = false;
            _this.disabled = false;
            _this._detectChanges();
        });
        this._privateService.onReset
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe(function (i) {
            if (!_this.isCurrentInstance({ instanceId: i })) {
                return;
            }
            if (!_this.disabled) {
                if (_this._hasInitFiles && _this._initFiles && _this._initFiles.length) {
                    _this.viewFiles = cloneDeep(_this._initFiles);
                }
                else {
                    _this.viewFiles = [];
                    _this.allowAdd = true;
                }
            }
            _this.showMaxFileSizeError = false;
            _this._detectChanges();
        });
    };
    FileUploaderComponent.prototype._initByFileInfo = function (cfg) {
        var _this = this;
        this._locked = true;
        this._emitLoadingState(FileUploaderLoadState.loading);
        this._pendingFiles = this._privateService.copyAsFileInfo(cfg.fileInfo);
        this._tryCatch(function () {
            _this._privateService
                .loadFiles(_this._pendingFiles)
                .pipe(takeUntil(_this._destructor))
                .subscribe(function (loadedViewFiles) {
                _this._notifierService.onLoaded.emit({
                    instanceId: cfg.instanceId,
                    loadedViewFiles: loadedViewFiles,
                });
                _this._detectChanges();
            });
        });
    };
    FileUploaderComponent.prototype._initByFileData = function (cfg) {
        var _this = this;
        this._locked = true;
        this._emitLoadingState(FileUploaderLoadState.loading);
        this._tryCatch(function () {
            _this._pendingFiles = _this._privateService.copyAsFileInfo(cfg.fileInfo);
            var loadedViewFiles = _this._privateService.mapToView(cfg.fileInfo, cfg.fileData);
            _this._notifierService.onLoaded.emit({
                instanceId: cfg.instanceId,
                loadedViewFiles: loadedViewFiles,
            });
            // this._changeDetectorRef.detectChanges();
        });
    };
    FileUploaderComponent.prototype._initInput = function (cfg) {
        var _this = this;
        var input = this._input;
        input.type = 'file';
        input.name = 'fileUpload';
        input.multiple = cfg.multiple;
        input.accept = cfg.accept;
        input.onchange = function (e) {
            if (e && e.srcElement === input && input.files && !_this.disabled) {
                if (_this._hasMaxInList(input.files)) {
                    if (!_this.showMaxFileSizeError) {
                        _this.showMaxFileSizeError = true;
                        _this._detectChanges();
                    }
                }
                else {
                    if (_this.showMaxFileSizeError) {
                        _this.showMaxFileSizeError = false;
                        _this._detectChanges();
                    }
                    _this._onInputChange(input.files);
                }
            }
        };
    };
    FileUploaderComponent.prototype._subscribeOnDelete = function (cfg) {
        var _this = this;
        this._notifierService.onDelete
            .asObservable()
            .pipe(takeUntil(this._destructor), finalize(function () {
            _this._emitLoadingState(FileUploaderLoadState.finish);
        }))
            .subscribe(function (data) {
            // check is current instance
            if (!_this.isCurrentInstance(data)) {
                return;
            }
            var deletedFileInfo = data.fileInfo;
            var cfgInfo = cfg.fileInfo;
            if (cfgInfo) {
                var fileInfo = find(cfgInfo, function (f) {
                    return f.uiId === deletedFileInfo.uiId;
                });
                if (fileInfo) {
                    pullAt(cfgInfo, [indexOf(cfgInfo, fileInfo)]);
                }
            }
            if (_this.viewFiles && _this.viewFiles.length) {
                var vfs = _this.viewFiles;
                var hasView = find(vfs, function (i) {
                    return i.id === data.fileInfo.id;
                });
                if (hasView) {
                    var newView = xorBy(vfs, [deletedFileInfo], _this._comparerKey);
                    _this.viewFiles = newView;
                    if (!newView.length) {
                        if (!_this.allowAdd) {
                            _this.allowAdd = true;
                        }
                    }
                    else {
                        if (cfg.multiple && !_this.allowAdd) {
                            _this.allowAdd = true;
                        }
                        else if (!cfg.multiple && _this.allowAdd) {
                            _this.allowAdd = false;
                        }
                    }
                }
                if (_this._locked) {
                    _this._locked = false;
                }
            }
            _this._detectChanges();
        });
    };
    FileUploaderComponent.prototype._subscribeOnLoaded = function (cfg) {
        var _this = this;
        this._notifierService.onLoaded
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe(function (data) {
            if (data.instanceId !== _this.fileUploaderOption.instanceId) {
                return;
            }
            var newFiles = data.loadedViewFiles;
            var pfs = _this._pendingFiles;
            if (pfs && pfs.length && newFiles && pfs.length === newFiles.length) {
                var file_1 = newFiles[0];
                var hasPendingFile = !!find(pfs, function (f) {
                    return f.id === file_1.id;
                });
                if (hasPendingFile) {
                    var viewFiles = newFiles;
                    var currentView = _this.viewFiles || [];
                    var outputView = unionBy(currentView, viewFiles, _this._comparerKey);
                    var newPending = xorBy(pfs, viewFiles, _this._comparerKey);
                    _this._pendingFiles = newPending;
                    if (cfg.multiple) {
                        _this.viewFiles = outputView;
                        if (!_this.allowAdd) {
                            _this.allowAdd = true;
                        }
                    }
                    else {
                        _this.viewFiles = [outputView[0]];
                        if (_this.allowAdd) {
                            _this.allowAdd = false;
                        }
                    }
                    if (!_this._inPending) {
                        _this._locked = false;
                        if (_this._hasInitFiles && !_this._initFiles) {
                            _this._initFiles = cloneDeep(_this.viewFiles);
                        }
                        if (!_this.dataSource) {
                            _this.dataSource = true;
                        }
                        _this._emitLoadingState(FileUploaderLoadState.finish);
                    }
                }
            }
            else if (newFiles.length) {
                _this.dataSource = true;
                _this.allowAdd = cfg.multiple;
                _this._locked = false;
                _this._emitLoadingState(FileUploaderLoadState.finish);
            }
            else {
                _this.dataSource = true;
                _this.allowAdd = cfg.multiple;
                _this._locked = false;
                _this._emitLoadingState(FileUploaderLoadState.finish);
                // this._emitLoadingState(FileUploaderLoadState.error);
            }
            _this._pendingFiles = null;
            _this._detectChanges();
        });
    };
    FileUploaderComponent.prototype._onInputChange = function (files) {
        var _this = this;
        if (this._locked) {
            return;
        }
        this._files = [];
        if (files && files.length) {
            var fs_1 = [];
            each(files, function (file, index) {
                var fModel = new FileUploadModel();
                fModel.index = index;
                fModel.data = file;
                if (_this.fileUploaderOption.name) {
                    fModel.name = _this.fileUploaderOption.name;
                }
                fs_1.push(fModel);
            });
            this._files = fs_1;
            this._uploadFiles();
        }
    };
    FileUploaderComponent.prototype._uploadFiles = function () {
        var _this = this;
        this._input.value = '';
        if (this._files.length > 1) {
            this._locked = true;
            var files_1 = this._files;
            this._files = [];
            this._pendingFiles = files_1;
            this._tryCatch(function () {
                _this._emitLoadingState(FileUploaderLoadState.loading);
                _this._privateService.uploadBulk(files_1).subscribe(function (loadedViewFiles) {
                    _this._notifierService.onLoaded.emit({
                        instanceId: _this.fileUploaderOption.instanceId,
                        loadedViewFiles: loadedViewFiles,
                    });
                    _this._detectChanges();
                });
            });
        }
        else if (this._files.length === 1) {
            this._locked = true;
            var files = this._files;
            var file_2 = files[0];
            this._files = [];
            this._pendingFiles = [file_2];
            this._tryCatch(function () {
                _this._emitLoadingState(FileUploaderLoadState.loading);
                _this._privateService.upload(file_2).subscribe(function (loadedViewFiles) {
                    _this._notifierService.onLoaded.emit({
                        instanceId: _this.fileUploaderOption.instanceId,
                        loadedViewFiles: loadedViewFiles,
                    });
                    _this._detectChanges();
                });
            });
        }
    };
    FileUploaderComponent.prototype._emitLoadingState = function (state) {
        this._notifierService.onLoadStateChange.emit({
            instanceId: this.fileUploaderOption.instanceId,
            loadingState: state,
        });
        this._detectChanges();
    };
    FileUploaderComponent.prototype._tryCatch = function (action) {
        try {
            action();
        }
        catch (error) {
            this._locked = false;
            this._emitLoadingState(FileUploaderLoadState.error);
            // this._changeDetectorRef.detectChanges();
            throw error;
        }
        finally {
            this._detectChanges();
        }
    };
    FileUploaderComponent.prototype.isCurrentInstance = function (info) {
        return info && info.instanceId === this.fileUploaderOption.instanceId;
    };
    FileUploaderComponent.prototype._detectChanges = function () {
        if (!this._destructor.isStopped && !this._changeDetectorRef.destroyed) {
            this._changeDetectorRef.detectChanges();
        }
    };
    FileUploaderComponent.prototype._hasMaxInList = function (fileList) {
        var _this = this;
        return every(fileList, function (i) {
            console.log('_hasMaxInList', {
                fileList: fileList,
                i: i,
                isMore: _this._maxFileSize <= i.size,
                _maxFileSize: _this._maxFileSize,
            });
            return _this._maxFileSize <= i.size;
        });
    };
    return FileUploaderComponent;
}());
export { FileUploaderComponent };

import { HttpErrorResponse } from '@angular/common/http';

export interface IErrorObject {
    code: number;
    message: string;
    details: string;
    validationErrors: any;
}

/**
 * Класс для работы с объектами ошибок
 */
export class ErrorHelper {
    static convertError(responseError: HttpErrorResponse): IErrorObject {
        const json = responseError.headers['$error'];

        if (json) {
            const error = JSON.parse(json);

            return {
                code: error['code'],
                message: error['message'],
                details: error['details'],
                validationErrors: error['validationErrors'],
            };
        }

        return {
            code: undefined,
            message: undefined,
            details: undefined,
            validationErrors: undefined,
        };
    }
}

import { OnInit, EventEmitter, TemplateRef, AfterViewInit, QueryList, OnDestroy, ChangeDetectorRef, } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatInput, } from '@angular/material';
import { sortBy, find, findIndex } from 'lodash';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { AppFormFieldType } from './_imports';
import { AppOrderedFieldAutocomplete } from './app-ordered-field-autocomplete';
var AppFormComponent = /** @class */ (function () {
    function AppFormComponent(_fb, _changeDetectorRef) {
        this._fb = _fb;
        this._changeDetectorRef = _changeDetectorRef;
        this.isHospitalizationTipVisible = false;
        this._destructor = new Subject();
        this._onOrderChange = new Subject();
    }
    AppFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formGroup = this._fb.group({});
        if (this.formData.fields && this.formData.fields.length) {
            if (!this.formData.isOrderedFields) {
                var f = sortBy(this.formData.fields, ['order']);
                this.formData.fields = f;
                this.formData.isOrderedFields = true;
            }
            this.formData.fields.forEach(function (e) {
                if (e.required) {
                    if (!e.validators) {
                        e.validators = [Validators.required];
                    }
                    else {
                        var hasRequired = !!find(e.validators, function (f) {
                            return f === Validators.required;
                        });
                        if (!hasRequired) {
                            e.validators.push(Validators.required);
                        }
                    }
                }
                var control = new FormControl(e.initialValue, {
                    updateOn: e.updateOn,
                    validators: e.validators,
                });
                // control['_setValue'] = control.setValue;
                // control.setValue = (value: any, options?: {
                //     onlySelf?: boolean;
                //     emitEvent?: boolean;
                //     emitModelToViewChange?: boolean;
                //     emitViewToModelChange?: boolean;
                // }) => {
                //     control['_setValue'](value, options);
                //     if (e.key === 'caseNumber') {
                //         // ;
                //     }
                // };
                if (e.disabled) {
                    control.disable();
                }
                if (e.type === AppFormFieldType.autocomplete && e instanceof AppOrderedFieldAutocomplete) {
                    e.configureFiltration(control);
                }
                if (e.valueChanges instanceof EventEmitter) {
                    control.valueChanges.pipe(takeUntil(_this._destructor)).subscribe(function (v) {
                        _this._emmitFieldEvent(v, e, control);
                    });
                }
                _this.formGroup.addControl(e.key, control);
                if (e.appendFormGroup) {
                    _this._appendForm(e, 'formGroupRef');
                }
            });
        }
        this._appendForm(this.formData, '_formGroup');
        if (this.formData.onGroupFormInitialized instanceof Subject) {
            this.formData.onGroupFormInitialized.next(this.formGroup);
        }
        this.formGroup.markAsPristine();
    };
    AppFormComponent.prototype.ngAfterViewInit = function () {
        this._inputCtrls = this._inputs ? this._inputs.toArray() : [];
    };
    AppFormComponent.prototype.ngOnDestroy = function () {
        this._destructor.next();
        this._destructor.complete();
    };
    AppFormComponent.prototype.trackByFnIndex = function (index, item) {
        return index;
    };
    AppFormComponent.prototype.getTemplate = function (field) {
        if (this[field.type]) {
            return this[field.type];
        }
        return this.default;
    };
    AppFormComponent.prototype.showError = function (formName, errorName) {
        if (this.formGroup.disabled) {
            return false;
        }
        var ctrl = this.formGroup.get(formName);
        if (!ctrl.touched) {
            return false;
        }
        return ctrl.hasError(errorName);
    };
    AppFormComponent.prototype.onKeyEnter = function (e) {
        if (e.defaultPrevented) {
            return;
        }
        var input = this._getNextInput(e.target.id);
        if (input && !input.focused) {
            e.stopImmediatePropagation();
            input.focus();
        }
    };
    AppFormComponent.prototype.dateChange = function (e, field) {
        if (field && field.valueChanges) {
            var ctrl = this.formGroup.get(field.key);
            if (ctrl) {
                var val = null;
                var inputValue = e.targetElement.value;
                var date = moment(inputValue, field.dateFormat);
                if (date.isValid()) {
                    val = date.toDate();
                }
                if (ctrl.value !== val) {
                    ctrl.markAsDirty();
                    ctrl.patchValue(val || '', {
                        emitEvent: true,
                        onlySelf: true,
                    });
                    ctrl.updateValueAndValidity();
                }
            }
        }
    };
    AppFormComponent.prototype.onAppDatePickerChange = function (event, field) {
        this.onAppDateTimePickerChange(event, field);
    };
    AppFormComponent.prototype.onAppDateTimePickerChange = function (event, field) {
        if (field && field.valueChanges) {
            var ctrl = this.formGroup.get(field.key);
            if (ctrl) {
                var value = void 0;
                if (event.input === '') {
                    ctrl.setValue(null);
                    return;
                }
                else if (event.moment) {
                    value = event.moment;
                }
                else if (!!event.input) {
                    value = moment(moment(event.input, field.dateFormat).toJSON());
                }
                var val = null;
                if (moment.isMoment(value) && value.isValid()) {
                    val = value;
                }
                else {
                    val = field.invalidValue;
                }
                if (ctrl.value !== val) {
                    ctrl.setValue(val);
                }
                // console.log('onAppDateTimePickerChange', {
                //     event,
                //     val,
                // });
                // this._emmitFieldEvent(event, field, ctrl);
            }
        }
    };
    AppFormComponent.prototype.hasError = function (field) {
        // f.errorMessages && f.errorMessages.length && (lawCity.dirty || lawCity.touched
        if (field.hidden || !field.errorMessages || !field.errorMessages.length) {
            return false;
        }
        var ctrl = this.formGroup.get(field.key);
        if (!ctrl) {
            console.warn('AppFormComponent field control not exists ');
            return false;
        }
        return field.errorMessages && field.errorMessages.length && (ctrl.dirty || ctrl.touched);
    };
    AppFormComponent.prototype.resetAutocomplete = function (e, field, autoTrigger) {
        field.onClose(e, autoTrigger, this.formGroup.get(field.key));
    };
    AppFormComponent.prototype.displayFnAutocomplete = function (field) {
        return function (value) {
            return field.displayFn(value);
        };
    };
    AppFormComponent.prototype.autocompleteSelected = function ($event, field) {
        field.onSelected($event, this.formGroup.get(field.key));
    };
    AppFormComponent.prototype.onClickAutocomplete = function ($event, field, autoTrigger, isArrow) {
        field.onClick($event, autoTrigger, this.formGroup.get(field.key), isArrow);
    };
    AppFormComponent.prototype._appendForm = function (object, key) {
        var _this = this;
        Object.defineProperty(object, key, {
            get: function () {
                return _this.formGroup;
            },
        });
    };
    AppFormComponent.prototype._getNextInput = function (sourceInputId) {
        var currentIndex = findIndex(this._inputCtrls, function (e) {
            return e.id === sourceInputId;
        });
        if (currentIndex || currentIndex === 0) {
            var nextIndex = currentIndex + 1;
            if (this._inputCtrls[nextIndex]) {
                return this._inputCtrls[nextIndex];
            }
            else {
                return this._inputCtrls[0];
            }
        }
        return null;
    };
    AppFormComponent.prototype._emmitFieldEvent = function (newValue, field, ctrl) {
        var control = ctrl || this.formGroup.get(field.key);
        if (typeof (newValue) === 'boolean') {
            this.isHospitalizationTipVisible = newValue;
        }
        field.valueChanges.emit({
            control: control,
            newValue: newValue,
            formGroup: this.formGroup,
        });
    };
    return AppFormComponent;
}());
export { AppFormComponent };

interface LoginRoutes {
    login: string;
    reminder: string;
    smsVerify: string;
    emailVerify: string;
    terms: string;
}

export const LOGIN_ROUTES: LoginRoutes = Object.create(null);
Object.defineProperties(LOGIN_ROUTES, {
    login: { value: '/account/login', writable: false, enumerable: true, configurable: false },
    reminder: { value: '/account/reminder', writable: false, enumerable: true, configurable: false },
    smsVerify: { value: '/account/signup/verify', writable: false, enumerable: true, configurable: false },
    emailVerify: { value: '/account/signup-email/verify', writable: false, enumerable: true, configurable: false },
    terms: { value: '/account/terms-of-use', writable: false, enumerable: true, configurable: false },
});

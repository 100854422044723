import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import swal from 'sweetalert';

import {
    AppConsts,
    AppSessionService,
    UtilHelper,
    ILocalStorageProvider,
} from './_imports';
import { AuthStorageService } from './auth-storage.service';

import { AuthTenantService } from './auth-tenant.service';
import { AuthTokenService } from './auth-token.service';
import { LOGIN_ROUTES as LR } from './login-routes';

import { authPasswordFlowConfig } from './oauth-config';
import { AuthConfig } from 'angular-oauth2-oidc';

import { ApmAccountServiceProxy } from '@shared/index';

@Injectable({
    providedIn: 'root',
})
export class AuthenticateService {
    private _appSessionService: AppSessionService;
    private _tenantService: AuthTenantService;
    private _tokenService: AuthTokenService;
    private _router: Router;
    private _apmAccountServiceProxy: ApmAccountServiceProxy;
    private _lsRememberClient: ILocalStorageProvider<boolean>;

    constructor(
        injector: Injector,
        ss: AuthStorageService,
        private oauthService: OAuthService,
        private httpClient: HttpClient,
        private cookieService: CookieService,
        ) {
        this._tenantService = injector.get(AuthTenantService);
        this._apmAccountServiceProxy = injector.get(ApmAccountServiceProxy);
        this._tokenService = injector.get(AuthTokenService);
        this._router = injector.get(Router);
        this._appSessionService = injector.get(AppSessionService);
        this._lsRememberClient = ss.rememberClientLs;

        const authConfig: AuthConfig = Object.assign({}, authPasswordFlowConfig);

        authConfig.issuer = AppConsts.oidcBaseUrl;
        authConfig.clientId = AppConsts.oidcClientId;
        authConfig.dummyClientSecret = AppConsts.oidcClientSecret;
        authConfig.scope = AppConsts.oidcClientScope;

        this.oauthService.configure(authConfig);
        // this.oauthService.loadDiscoveryDocument().then(
        //     () => {
        //         console.log('OAuth Discovery Document is loaded');
        //     }).catch(err => {
        //         swal({
        //             title: 'Вход невозможен',
        //             text: 'OAuth Discovery Document не загружается.',
        //             icon: 'error',
        //         });
        // });
    }

    loadDiscoveryDocument(): Observable<object> {
        const authConfig: AuthConfig = Object.assign({}, authPasswordFlowConfig);

        authConfig.issuer = AppConsts.oidcBaseUrl;
        authConfig.clientId = AppConsts.oidcClientId;
        authConfig.dummyClientSecret = AppConsts.oidcClientSecret;
        authConfig.scope = AppConsts.oidcClientScope;

        this.oauthService.configure(authConfig);
        return from(this.oauthService.loadDiscoveryDocument());
    }

    // : Observable<AuthenticateResultModel>

    authenticateByLogin(viewModel: any, redirectUrl?: string): Observable<any> {

        return this.loadDiscoveryDocument().pipe(
            switchMap(() => {
                const login = UtilHelper.normalizeLogin(viewModel.userNameOrEmailAddress);

                const model = {
                    userNameOrEmailAddress: login,
                    password: viewModel.password.trim(),
                    rememberClient: !!viewModel.rememberClient
                };

                this._tenantService.setTenantId(AppConsts.defaultTenantId);

                this.oauthService.customQueryParams = {
                    'grant_type': 'ais_login'
                };

                return from(this.oauthService
                    .fetchTokenUsingPasswordFlow(model.userNameOrEmailAddress, model.password)
                ).pipe(
                    switchMap((r: any) => {
                        if (r.isComplete) {
                            return from(this.oauthService.loadUserProfile()).pipe(
                                switchMap(() => {

                                    // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
                                    // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
                                    // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
                                    // и typesript 3.хх использовать коллекции angular-oauth2-oidc
                                    const encrptedAuthTokenName = localStorage.getItem('encrypted_access_token');

                                    const authResultModel = {
                                        accessToken: this.oauthService.getAccessToken(),
                                        encryptedAccessToken: encrptedAuthTokenName,
                                        // TODO поменять отдачу сервера expireAt и секунды
                                        // r.expireAt
                                        expireInSeconds: 60 * 60 * 24,
                                        userId: undefined,
                                        refreshToken: undefined,
                                        tenantUid: undefined,
                                        userUid: undefined,
                                        lawyerUid: undefined
                                    };

                                return this._refreshSessionByOAuth(authResultModel);
                                })
                            );
                        } else {
                            this._router.navigate([`/account/signup/verify/${r.PersonContact.Id}/${model.userNameOrEmailAddress}`]);
                            return of([]);
                        }
                    })
                );
                })
            );

    }

    authenticateByTicket(model: any,
        contactId: string = null): Observable<any> {

        return this.loadDiscoveryDocument().pipe(
            switchMap(() => {
                this.oauthService.customQueryParams = {
                    'grant_type': 'contact_confirmation',
                    'contactId': contactId,
                    'confirmationCode': model.ticket
                };

                return from(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile('', '')).pipe(
                    switchMap(() => {

                        // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
                        // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
                        // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
                        // и typesript 3.хх использовать коллекции angular-oauth2-oidc
                        const encrptedAuthTokenName = localStorage.getItem('encrypted_access_token');

                        const i = {
                            accessToken: this.oauthService.getAccessToken(),
                            encryptedAccessToken: encrptedAuthTokenName,
                            // TODO поменять отдачу сервера expireAt и секунды
                            // r.expireAt
                            expireInSeconds: 60 * 60 * 24,
                            userId: undefined,
                            refreshToken: undefined,
                            tenantUid: undefined,
                            userUid: undefined,
                            lawyerUid: undefined
                        };
                        return this._refreshSessionByAuth(i);
                    })
                );
            })
        );
    }

    twoFaVerify(contactId: string, verificationCode: string): Observable<any> {
        this.oauthService.customQueryParams = {
            'grant_type': 'contact_confirmation',
            'contactId': contactId,
            'confirmationCode': verificationCode
          };

        return from(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile('', '')).pipe(
            switchMap(() => {

                // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
                // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
                // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
                // и typesript 3.хх использовать коллекции angular-oauth2-oidc
                const encrptedAuthTokenName = localStorage.getItem('encrypted_access_token');

                const i = {
                    accessToken: this.oauthService.getAccessToken(),
                    encryptedAccessToken: encrptedAuthTokenName,
                    // TODO поменять отдачу сервера expireAt и секунды
                    // r.expireAt
                    expireInSeconds: 60 * 60 * 24,
                    userId: undefined,
                    refreshToken: undefined,
                    tenantUid: undefined,
                    userUid: undefined,
                    lawyerUid: undefined
                };
                return this._refreshSessionByAuth(i);
            })
        );
    }
    resendEmail(email: string): Observable<boolean> {
        return this._apmAccountServiceProxy.emailValidate(email);
    }

    logout(): void {
        this.reset();
        this._router.navigate([LR.login]);
    }

    reset(): void {
        this._tokenService.remove();
        // fixme
        this._appSessionService.resetSessionUserInfo();
    }

    onAuthenticateError(err: any, smsRoute: any[], emailRoute: any[]): void {
        if (err.needConfirm) {
            this._router.navigate(smsRoute);
            return;
        }

        if (!err.headers) {
            return;
        }

        const json: string = err.headers['$error'];

        if (json) {
            const error: any = JSON.parse(json);

            if (1 === error.code) {
                // this._router.navigate(['/auth/signup/verify', model.userNameOrEmailAddress]);
                this._router.navigate(smsRoute);
            } else if (2 === error.code) {
                // this._router.navigate(['/auth/signup-email/verify', model.userNameOrEmailAddress]);
                this._router.navigate(emailRoute);
            }
        }
    }

    isCurrentUserByContact(contactValue: string, contactType: number): boolean {
        const cr = this._appSessionService.currentLoginInformations;
        if (!cr || !cr.user || !contactValue || typeof contactType === 'number') {
            return false;
        }
        // mobile
        if (contactType === 1) {
            return cr.user.userName === contactValue;
        } else if (contactType === 0) {
            return cr.user.emailAddress === contactValue;
        }
        return false;
    }

    private _refreshSessionByAuth(authResultModel: any): Observable<any> {
        if (!this._lsRememberClient.get()) {
            authResultModel.expireInSeconds = undefined;
        }

        this._tokenService.save(authResultModel);
        return from(this._appSessionService.init()).pipe(
            map(success => {
                if (success) {
                    return authResultModel;
                }
                // удаляем все пользовательские авторизационные данные
                this.reset();
                throwError('Update session error');
            }),
        );
    }

    private _refreshSessionByOAuth(authResultModel: any): Observable<any> {

        if (!this._lsRememberClient.get()) {
            authResultModel.expireInSeconds = undefined;
        }

        this._tokenService.save(authResultModel);
        return from(this._appSessionService.init()).pipe(
            map(success => {
                if (success) {
                    return authResultModel;
                }
                // удаляем все пользовательские авторизационные данные
                this.reset();
                throwError('Update session error');
            }),
        );
    }
}

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { each, find, map as _map, uniq, remove } from 'lodash';

import { FileDataServiceProxy, IFileDataDto, AppConsts } from './_imports';
import {
    IFileUploaderOnLoadedEventData,
    IFileUploaderOnDeleteEventData,
    IFileInfo,
    IFileUploadModel,
    IFileDataView,
    IFileUploaderLoadingEventData,
    IFileUploaderPrivateService,
    IFileUploaderNotifier,
    FileUploaderLoadState,
    IFileComponentSubscription,
} from './_infrastructure';

@Injectable({
    providedIn: 'root',
})
export class FileUploaderPrivateService implements IFileUploaderPrivateService {
    public onDisable: EventEmitter<string>;
    public onEnable: EventEmitter<string>;
    public onReset: EventEmitter<string>;

    private _url: string;
    private get _api(): string {
        return `${AppConsts.remoteServiceBaseUrl}/upload/`;
    }
    public get url() {
        return this._url || (this._url = this._api + 'files');
    }

    public constructor(private _http: HttpClient, private _proxy: FileDataServiceProxy) {
        this.onDisable = new EventEmitter();
        this.onEnable = new EventEmitter();
        this.onReset = new EventEmitter();
    }
    public getDeleteApiUrl(id: string): string {
        return this._api + 'delete/' + id;
    }

    public loadFiles(fileInfoes: IFileInfo[]): Observable<IFileDataView[]> {
        if (!fileInfoes || !fileInfoes.length) {
            throw new Error('no data, argument fileIds is empty or null');
        }
        const ids = fileInfoes.map(i => {
            return i.id;
        });
        const fis = this.copyAsFileInfo(fileInfoes);
        return this._proxy.getMany(ids).pipe(
            map(i => {
                const coll = _map(i, val => {
                    return val;
                });
                return this.mapToView(fis, coll);
            }),
        );
    }

    public upload(file: IFileUploadModel): Observable<IFileDataView[]> {
        const fd = new FormData();
        const fileSource = [file];
        fd.append(file.name || 'file', file.data);
        const fis = this.copyAsFileInfo(fileSource);
        return this._http.post<any>(this.url, fd).pipe(
            map(response => {
                return this._mapUploadresponse(fileSource, fis, response);
            }),
        );
    }

    public uploadBulk(files: IFileUploadModel[]): Observable<IFileDataView[]> {
        const formData = new FormData();
        const fis = this.copyAsFileInfo(files);
        each(files, file => {
            formData.append(file.index + '', file.data, file.data.name);
        });
        return this._http.post(this.url, formData).pipe(
            map(response => {
                return this._mapUploadresponse(files, fis, response);
            }),
        );
    }

    public delete(fileInfo: IFileInfo): Observable<IFileInfo> {
        return this._http.delete(this.getDeleteApiUrl(fileInfo.id)).pipe(
            map(i => {
                return fileInfo;
            }),
        );
    }

    public mapToView(source: IFileInfo[], response: IFileDataDto[]): IFileDataView[] {
        const result = [];
        each(response, value => {
            const info = find(source, i => {
                return i.id === value.id;
            });

            if (info && value) {
                const view = Object.assign({}, value) as IFileDataView;
                view.uiId = info.uiId;
                result.push(view);
            }
        });
        return result;
    }

    public copyAsFileInfo(fileInfoes: IFileInfo[]): IFileInfo[] {
        return fileInfoes.map(i => {
            return {
                id: i.id,
                uiId: i.uiId,
            };
        });
    }

    private _mapUploadresponse(fileSource: IFileInfo[], fileInfo: IFileInfo[], response: any): IFileDataView[] {
        let result = [];
        if (!response || response.error) {
            return result;
        }
        if (response.result && response.result.length) {
            each(response.result, (elem, index) => {
                fileSource[index].id = fileInfo[index].id = elem.id;
            });
            result = this.mapToView(fileInfo, response.result);
        }
        return result;
    }
}

@Injectable({
    providedIn: 'root',
})
export class FileUploaderNotifierService implements IFileUploaderNotifier {
    public onDelete: EventEmitter<IFileUploaderOnDeleteEventData>;
    public onLoaded: EventEmitter<IFileUploaderOnLoadedEventData>;
    public onLoadStateChange: EventEmitter<IFileUploaderLoadingEventData>;

    constructor(private _privateService: FileUploaderPrivateService) {
        this.onDelete = new EventEmitter();
        this.onLoaded = new EventEmitter();
        this.onLoadStateChange = new EventEmitter();
    }

    disable(instanceId: string): void {
        this._privateService.onDisable.emit(instanceId);
    }
    enable(instanceId: string): void {
        this._privateService.onEnable.emit(instanceId);
    }
    reset(instanceId: string): void {
        this._privateService.onReset.emit(instanceId);
    }

    toUniqFileIds(files: IFileDataView[]): string[] {
        if (!files) {
            return [];
        }
        return uniq(_map(files, i => i.id));
    }
    removeFromInstance(files: IFileInfo[], fileId: string): void {
        remove(files, f => {
            return f.id === fileId;
        });
    }

    /**
     * @description Примятяет стандартное поведенени к подпискам на файлы для указанного инстанса
     * @param destructor Деструктор который отпишет от подписок
     * @param cfg конфиг рекций
     */
    initDefaultSubscriptions(destructor: Subject<any>, cfg: IFileComponentSubscription): void {
        this.onLoaded.pipe(takeUntil(destructor)).subscribe(i => {
            if (i.instanceId === cfg.instanceId) {
                console.log('files onLoaded', i);
                if (cfg.onLoaded(i.loadedViewFiles)) {
                    this.enable(cfg.instanceId);
                }
            }
        });
        this.onLoadStateChange.pipe(takeUntil(destructor)).subscribe(i => {
            if (i.instanceId === cfg.instanceId) {
                switch (i.loadingState) {
                    case FileUploaderLoadState.loading:
                        cfg.onLoading();
                        break;
                    case FileUploaderLoadState.finish:
                        cfg.onFinish();
                        break;
                    case FileUploaderLoadState.error:
                        if (cfg.onError) {
                            cfg.onError();
                        } else {
                            cfg.onFinish();
                        }
                        break;
                    default:
                        break;
                }
            }
        });
        if (cfg.onDelete instanceof Function) {
            this.onDelete.pipe(takeUntil(destructor)).subscribe(i => {
                if (i.instanceId === cfg.instanceId) {
                    cfg.onDelete(i.fileInfo);
                }
            });
        }
    }
}

import { AppConsts } from '@shared/AppConsts';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AppAuthService = /** @class */ (function () {
    function AppAuthService(_router) {
        this._router = _router;
    }
    AppAuthService.prototype.logout = function (reload) {
        abp.auth.clearToken();
        if (reload !== false) {
            location.href = AppConsts.appBaseUrl;
        }
    };
    AppAuthService.prototype.reEnter = function (returnUrl, reload) {
        if (returnUrl === void 0) { returnUrl = null; }
        if (reload === void 0) { reload = false; }
        abp.auth.clearToken();
        if (!returnUrl) {
            returnUrl = encodeURIComponent(location.href);
        }
        console.log(returnUrl, location.href.includes('/account/login'), encodeURIComponent(location.href));
        if (reload === false) {
            this._router.navigate(['/account/login', returnUrl ? { returnUrl: returnUrl } : {}]);
        }
        else {
            location.href = AppConsts.appBaseUrl + '/account/login' + (returnUrl ? ('?returnUrl=' + returnUrl) : '');
        }
    };
    AppAuthService.ngInjectableDef = i0.defineInjectable({ factory: function AppAuthService_Factory() { return new AppAuthService(i0.inject(i1.Router)); }, token: AppAuthService, providedIn: "root" });
    return AppAuthService;
}());
export { AppAuthService };

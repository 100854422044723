import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EventsService, PushNotifyType, SignalRAspNetCoreHelper } from '../_imports';

@Component({
    selector: 'app-push-info',
    template: '',
})
/**
 * нужен рефактор
 */
export class AppPushInfoComponent implements OnInit {
    constructor(private _router: Router, private _eventsService: EventsService) {}
    ngOnInit(): void {
        /**
         * FIXME  на нормальный сервис  и собятия подписки
         */

        const isLawyerPage = this._router.url.includes('/lk-lawyer/article-51');
        const isInitiatorPage = this._router.url.includes('initiator/request/show');

        if (isLawyerPage || isInitiatorPage) {
            SignalRAspNetCoreHelper.initSignalR().subscribe(next => {
                if (next) {
                    abp.event.on('abp.notifications.received', userNotification => {
                        if (PushNotifyType.locked === userNotification.notification.data.notifyType) {
                            this._eventsService.broadcast(PushNotifyType.locked);
                            return;
                        }

                        this._eventsService.broadcast('update', userNotification.notification.data.message);

                        if (isLawyerPage && userNotification.notification.data.notifyType !== PushNotifyType.hidden) {
                            abp.notify.info(userNotification.notification.data.message);

                            // see ref https://pushjs.org/docs/permissions
                            if (Push.Permission.has()) {
                                this.playAudio();

                                Push.create('Уведомление', {
                                    body: userNotification.notification.data.message,
                                    icon: abp.appPath + 'assets/app-logo-small.png',
                                    timeout: 6000,
                                    onClick: function() {
                                        window.focus();
                                        this.close();
                                    },
                                });
                            }
                        }
                    });
                }
            });
        }
    }
    playAudio() {
        const audio = new Audio();
        audio.src = 'assets/app/media/audio/to-the-point.mp3';
        audio.load();
        audio.play();
    }
}

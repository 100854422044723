import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import swal from 'sweetalert';
import { AppSessionService, AuthRedirectFactoryService, LOGIN_ROUTES as LR } from './_imports';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    private _permissionChecker: PermissionCheckerService;
    private _router: Router;
    private _sessionService: AppSessionService;
    private _redirectService: AuthRedirectFactoryService;

    constructor(injector: Injector) {
        this._permissionChecker = injector.get(PermissionCheckerService);
        this._router = injector.get(Router);
        this._sessionService = injector.get(AppSessionService);
        this._redirectService = injector.get(AuthRedirectFactoryService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._sessionService.user) {
            this._router.navigate([LR.login, { returnUrl: state.url }]);
            return false;
        }

        if (!route.data) {
            return true;
        }

        if (route.data['allowForRoles']) {
            const roles = route.data['allowForRoles'] as string[];
            const containRole = this._sessionService.containAnyRoleFromList(roles);

            if (!containRole) {
                const redirectUrl = route.data['notAllowRedirectUrl'] || LR.login;
                swal({
                    title: 'Доступ запрещен',
                    text: 'Отсутствуют права доступа к модулю. Обратитесь по email lk-support@advokatymoscow.ru',
                    icon: 'error',
                }).then(n => {
                    this._router.navigate([redirectUrl]);
                });
            }

            return containRole;
        }

        this._router.navigate([this._redirectService.createUrl() || LR.login]);

        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}

import { PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var HtmlSanitizerPipe = /** @class */ (function () {
    function HtmlSanitizerPipe(_sanitizer) {
        this._sanitizer = _sanitizer;
    }
    HtmlSanitizerPipe.prototype.transform = function (value) {
        return this._sanitizer.sanitize(SecurityContext.HTML, value);
    };
    return HtmlSanitizerPipe;
}());
export { HtmlSanitizerPipe };

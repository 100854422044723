import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material';
import { AppProgressLoaderComponent } from './app-progress-loader/app-progress-loader.component';
import { AppPreloaderComponent } from './app-preloader/app-preloader.component';

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    declarations: [AppProgressLoaderComponent, AppPreloaderComponent],
    exports: [AppProgressLoaderComponent, AppPreloaderComponent],
})
export class AppLoadersModule {}

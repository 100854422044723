import { NgModule } from '@angular/core';
import { AppBoxComponent } from './app-box/app-box.component';
import { AppBoxContentComponent } from './app-box-content/app-box-content.component';
import { AppBoxHeaderComponent } from './app-box-header/app-box-header.component';

@NgModule({
    imports: [],
    declarations: [AppBoxComponent, AppBoxContentComponent, AppBoxHeaderComponent],
    exports: [AppBoxComponent, AppBoxContentComponent, AppBoxHeaderComponent],
    providers: [],
})
export class AppBoxModule {}

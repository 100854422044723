var AppConsts = /** @class */ (function () {
    function AppConsts() {
    }
    AppConsts.defaultTenantId = 1;
    AppConsts.defaultTenantUid = '841a2213-885a-4c91-bc27-ea38f627414b';
    AppConsts.userManagement = {
        defaultAdminUserName: 'admin',
    };
    AppConsts.localization = {
        defaultLocalizationSourceName: 'Apgm',
    };
    AppConsts.authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
        refreshTokenName: '6420E296-81D4-40D6-9B65-1732FBB57100',
        expiredTokenName: 'B3B6AA46-856E-472A-B83C-9F2CDACDD33E',
    };
    AppConsts.lawyers = {
        lawyerRole: 'Lawyer',
    };
    AppConsts.fakeEmail = '1@advokatymoscow.ru';
    return AppConsts;
}());
export { AppConsts };

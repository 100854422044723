import { TemplateRef } from '@angular/core';

export enum MatDrawerMode {
    over = 'over',
    push = 'push',
    side = 'side',
}

export interface IRouteLink {
    routePath: string;
    viewName: string;
    iconTag: string;
    isBlank?: boolean;
    customTemplate?: TemplateRef<IRouteLink>;
    onClick?(event: Event): void;
}

export interface IDefaultLayoutModel {
    routeLinks: IRouteLink[];
    navMode?: MatDrawerMode;
    navIsOpen?: boolean;
    footer?: TemplateRef<any>;
    showBanner: boolean;
    renderSidebar?: boolean;
}

export class DefaultLayoutModel implements IDefaultLayoutModel {
    navMode: MatDrawerMode;
    routeLinks: IRouteLink[];
    footer?: TemplateRef<any>;
    showBanner: boolean;
    navIsOpen: boolean;
    renderSidebar: boolean;
    constructor(config?: IDefaultLayoutModel) {
        if (!config) {
            this.showBanner = true;
            this.routeLinks = [];
            this.footer = null;
            this.navMode = MatDrawerMode.side;
            this.navIsOpen = true;
            this.renderSidebar = true;
        } else {
            this.showBanner = config.showBanner;
            this.routeLinks = config.routeLinks;
            this.footer = config.footer;
            this.navMode = config.navMode;
            this.navIsOpen = config.navIsOpen;
            this.renderSidebar = !!config.renderSidebar;
        }
    }
}

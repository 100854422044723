import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { each } from 'lodash';
import { IBaseFilterFieldModel } from '@shared/base-filter/filter-field-model';
import { IQueryOptionsDto, QueryOptionsDto } from '@shared/service-proxies';

/**
 * Базовый компонент для создания формы фильтра.
 *
 * ### Пример нового компонента:
 * ```typescript
 *    @Component({
 *        selector: 'filter',
 *        templateUrl: './filter.component.html',
 *    })
 *    export class FilterComponent extends BaseFilterComponent {
 *        constructor(private _filterService: BaseFilterService) {
 *            super();
 *        }
 *
 *        createFields(): DefaultFilterFieldModel[] {
 *            return [
 *                {
 *                    name: 'docNumber',
 *                    label: 'Номер заявки',
 *                    type: AppFormFieldType.text,
 *                    filterId: 'A493C0B9-F3CD-480D-B765-BCD567D0A616',
 *                    dataBuilder: DefaultDataFilterValueBuilder,
 *                },
 *                {
 *                    name: 'creationTime',
 *                    label: 'Дата создания заявки',
 *                    type: AppFormFieldType.datepicker,
 *                    filterId: '70EC240A-8428-4FAC-A022-A65FA0B71C96',
 *                    dataBuilder: DateDataFilterValueBuilder,
 *                }
 *            ];
 *        }
 *
 *        public search(): void {
 *            this.change();
 *        }
 *
 *        protected _afterChangeAction(queryOptions: IQueryOptionsDto): void {
 *            this._filterService.emitOnSubmit(queryOptions);
 *        }
 *    }
 * ```
 *
 * ### Пример шаблона:
 * ```html
 *    <form [formGroup]="formGroup">
 *        <div *ngFor="let field of fields">
 *            <ng-template [ngIf]="'text' === field.type">
 *                <mat-form-field>
 *                    <mat-label>{{ field.label }}</mat-label>
 *                    <input matInput [formControl]="formGroup.get(field.name)" />
 *                </mat-form-field>
 *            </ng-template>
 *
 *            <ng-template [ngIf]="'datepicker' === field.type">
 *                <mat-form-field>
 *                    <mat-label>{{ field.label }}</mat-label>
 *                    <input matInput [matDatepicker]="datePicker" [formControl]="formGroup.get(field.name)"/>
 *                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
 *                    <mat-datepicker #datePicker></mat-datepicker>
 *                </mat-form-field>
 *            </ng-template>
 *        </div>
 *        <button mat-button (click)="search()">Найти</button>
 *    </form>
 * ```
 */
export abstract class BaseFilterComponent implements OnInit {
    /**
     * Массив определений для полей формы
     */
    public fields: IBaseFilterFieldModel[];

    /**
     * Группа элементов формы фильтра для вывода в шаблоне
     */
    public formGroup: FormGroup;

    protected _fb = new FormBuilder();

    ngOnInit(): void {
        this.fields = this.createFields();
        this._createControls();
    }

    /**
     * Возвращает массив определений для полей формы
     *
     * ### Пример массива:
     * ```typescript
     *    [
     *        {
     *            name: 'docNumber',
     *            label: 'Номер заявки',
     *            type: AppFormFieldType.text,
     *            filterId: 'A493C0B9-F3CD-480D-B765-BCD567D0A616',
     *            dataBuilder: DefaultDataFilterValueBuilder
     *        },
     *        {
     *            name: 'creationTime',
     *            label: 'Дата создания заявки',
     *            type: AppFormFieldType.datepicker,
     *            filterId: '70EC240A-8428-4FAC-A022-A65FA0B71C96',
     *            dataBuilder: DateDataFilterValueBuilder
     *        }
     *    ]
     * ```
     */
    abstract createFields(): IBaseFilterFieldModel[];

    /**
     * Запуск обработки данных
     */
    change(): void {
        const queryOptions = this._buildQueryOptions();

        this._afterChangeAction(queryOptions);
    }

    /**
     * Создает объект из фильтров, который будет использоваться для запросов к api
     * @protected
     */
    protected _buildQueryOptions(): IQueryOptionsDto {
        const formValues: {} = this.formGroup.value;
        const dataFilter = new QueryOptionsDto({
            filterValues: [],
            sortOptions: [],
        });

        each(this.fields, field => {
            const fieldName = field.name;

            if (!formValues.hasOwnProperty(fieldName) || !formValues[fieldName]) {
                return; // continue
            }

            const dataFilterBuilder = new field.dataBuilder();
            const fieldValue = formValues[fieldName];
            const dataFilterValue = dataFilterBuilder
                .setFilterId(field.filterId)
                .setValue(fieldValue)
                .build();

            dataFilter.filterValues.push(dataFilterValue);
        });

        return dataFilter;
    }

    /**
     * На основе данных из fields создает controls для использования в шаблоне компонента
     * @protected
     */
    protected _createControls(): void {
        this.formGroup = this._fb.group({});

        each(this.fields, field => {
            this.formGroup.addControl(field.name, this._fb.control(''));
        });
    }

    /**
     * Запускает дополнительные действия после обработки данных.
     * Переопределяется в классе-наследнике если нужно
     * @param {IQueryOptionsDto} queryOptions Объект из фильтров
     * @protected
     */
    protected _afterChangeAction(queryOptions: IQueryOptionsDto): void {}
}

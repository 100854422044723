import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from './_imports';
import { DefaultLayoutNewComponent } from './default-layout-new/default-layout-new.component';
import { LinkBarComponent } from './../ui-components/link-bar/link-bar.component';
import { LinkBarModule} from './../ui-components/link-bar/link-bar.module';

@NgModule({
    imports: [LinkBarModule, CommonModule, SharedMaterialModule, RouterModule],
    exports: [DefaultLayoutNewComponent],
    declarations: [DefaultLayoutNewComponent],
})
export class DefaultLayoutNewModule {}

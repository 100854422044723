import * as i0 from "@angular/core";
/**
 * сохранение и восстановления позиции скроллинга для окна или элемента
 */
var ScrollPosService = /** @class */ (function () {
    function ScrollPosService() {
        this._inited = false;
        this._saveTime = 30 * 60 * 1000; // время хранения не более 30 минут
    }
    ScrollPosService.prototype.save = function (url, element) {
        var urlshash = this._getUrlsHash();
        this._clearOldUrls(urlshash);
        urlshash.hash[url] = {
            ts: new Date().valueOf(),
            pos: this._getScrollPos(element),
            element: element
        };
        this._saveUrlsToLocalStorage(urlshash);
    };
    ScrollPosService.prototype.restore = function (url) {
        var urlshash = this._getUrlsHash();
        var item = urlshash.hash[url];
        if (item) {
            this._restoreScrollPos(item);
        }
    };
    ScrollPosService.prototype.restoreRequest = function (url) {
        var urlshash = this._getUrlsHash();
        var item = urlshash.hash[url];
        if (item) {
            item.restoreRequest = 1;
            this._saveUrlsToLocalStorage(urlshash);
        }
    };
    ScrollPosService.prototype.checkRestoreRequest = function (url) {
        var urlshash = this._getUrlsHash();
        var item = urlshash.hash[url];
        if (item && item.restoreRequest) {
            var now = new Date().valueOf();
            if (now - item.ts < 10 * 60 * 1000) {
                this._restoreScrollPos(item);
            }
            delete urlshash.hash[url];
            this._saveUrlsToLocalStorage(urlshash);
        }
    };
    ScrollPosService.prototype._restoreScrollPos = function (item) {
        if (item.element) {
            setTimeout(function () {
                $(item.element).scrollTop(item.pos);
            }, 100);
        }
        else {
            this._windowScrollTo(item.pos);
        }
    };
    ScrollPosService.prototype._getUrlsHash = function () {
        var urlshash;
        try {
            urlshash = JSON.parse(localStorage.getItem('ScrollPosHelperHash')) || this._getEmptyHash();
        }
        catch (e) {
            urlshash = this._getEmptyHash();
        }
        return urlshash;
    };
    ScrollPosService.prototype._getEmptyHash = function () {
        return {
            hash: {}
        };
    };
    ScrollPosService.prototype._windowScrollTo = function (pos) {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        // Internet Explorer
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            setTimeout(function () {
                window.scrollTo(0, pos);
            }, 100);
        }
        else {
            setTimeout(function () {
                window.scrollTo({
                    top: pos,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        }
    };
    ScrollPosService.prototype._getScrollPos = function (element) {
        if (element) {
            return $(element).scrollTop();
        }
        else {
            return window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop || 0;
        }
    };
    ScrollPosService.prototype._clearOldUrls = function (urlshash) {
        var now = new Date().valueOf();
        for (var url in urlshash.hash) {
            var item = urlshash.hash[url];
            if (now - item.ts > this._saveTime) {
                delete urlshash.hash[url];
            }
        }
    };
    ScrollPosService.prototype._saveUrlsToLocalStorage = function (urlshash) {
        try {
            localStorage.setItem('ScrollPosHelperHash', JSON.stringify(urlshash));
        }
        catch (e) { }
    };
    ScrollPosService.ngInjectableDef = i0.defineInjectable({ factory: function ScrollPosService_Factory() { return new ScrollPosService(); }, token: ScrollPosService, providedIn: "root" });
    return ScrollPosService;
}());
export { ScrollPosService };

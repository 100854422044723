import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { from, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { AppConsts, AppSessionService, UtilHelper, } from './_imports';
import { AuthStorageService } from './auth-storage.service';
import { AuthTenantService } from './auth-tenant.service';
import { AuthTokenService } from './auth-token.service';
import { LOGIN_ROUTES as LR } from './login-routes';
import { authPasswordFlowConfig } from './oauth-config';
import { ApmAccountServiceProxy } from '@shared/index';
import * as i0 from "@angular/core";
import * as i1 from "./auth-storage.service";
import * as i2 from "angular-oauth2-oidc";
import * as i3 from "@angular/common/http";
import * as i4 from "ngx-cookie";
var AuthenticateService = /** @class */ (function () {
    function AuthenticateService(injector, ss, oauthService, httpClient, cookieService) {
        this.oauthService = oauthService;
        this.httpClient = httpClient;
        this.cookieService = cookieService;
        this._tenantService = injector.get(AuthTenantService);
        this._apmAccountServiceProxy = injector.get(ApmAccountServiceProxy);
        this._tokenService = injector.get(AuthTokenService);
        this._router = injector.get(Router);
        this._appSessionService = injector.get(AppSessionService);
        this._lsRememberClient = ss.rememberClientLs;
        var authConfig = Object.assign({}, authPasswordFlowConfig);
        authConfig.issuer = AppConsts.oidcBaseUrl;
        authConfig.clientId = AppConsts.oidcClientId;
        authConfig.dummyClientSecret = AppConsts.oidcClientSecret;
        authConfig.scope = AppConsts.oidcClientScope;
        this.oauthService.configure(authConfig);
        // this.oauthService.loadDiscoveryDocument().then(
        //     () => {
        //         console.log('OAuth Discovery Document is loaded');
        //     }).catch(err => {
        //         swal({
        //             title: 'Вход невозможен',
        //             text: 'OAuth Discovery Document не загружается.',
        //             icon: 'error',
        //         });
        // });
    }
    AuthenticateService.prototype.loadDiscoveryDocument = function () {
        var authConfig = Object.assign({}, authPasswordFlowConfig);
        authConfig.issuer = AppConsts.oidcBaseUrl;
        authConfig.clientId = AppConsts.oidcClientId;
        authConfig.dummyClientSecret = AppConsts.oidcClientSecret;
        authConfig.scope = AppConsts.oidcClientScope;
        this.oauthService.configure(authConfig);
        return from(this.oauthService.loadDiscoveryDocument());
    };
    // : Observable<AuthenticateResultModel>
    AuthenticateService.prototype.authenticateByLogin = function (viewModel, redirectUrl) {
        var _this = this;
        return this.loadDiscoveryDocument().pipe(switchMap(function () {
            var login = UtilHelper.normalizeLogin(viewModel.userNameOrEmailAddress);
            var model = {
                userNameOrEmailAddress: login,
                password: viewModel.password.trim(),
                rememberClient: !!viewModel.rememberClient
            };
            _this._tenantService.setTenantId(AppConsts.defaultTenantId);
            _this.oauthService.customQueryParams = {
                'grant_type': 'ais_login'
            };
            return from(_this.oauthService
                .fetchTokenUsingPasswordFlow(model.userNameOrEmailAddress, model.password)).pipe(switchMap(function (r) {
                if (r.isComplete) {
                    return from(_this.oauthService.loadUserProfile()).pipe(switchMap(function () {
                        // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
                        // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
                        // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
                        // и typesript 3.хх использовать коллекции angular-oauth2-oidc
                        var encrptedAuthTokenName = localStorage.getItem('encrypted_access_token');
                        var authResultModel = {
                            accessToken: _this.oauthService.getAccessToken(),
                            encryptedAccessToken: encrptedAuthTokenName,
                            // TODO поменять отдачу сервера expireAt и секунды
                            // r.expireAt
                            expireInSeconds: 60 * 60 * 24,
                            userId: undefined,
                            refreshToken: undefined,
                            tenantUid: undefined,
                            userUid: undefined,
                            lawyerUid: undefined
                        };
                        return _this._refreshSessionByOAuth(authResultModel);
                    }));
                }
                else {
                    _this._router.navigate(["/account/signup/verify/" + r.PersonContact.Id + "/" + model.userNameOrEmailAddress]);
                    return of([]);
                }
            }));
        }));
    };
    AuthenticateService.prototype.authenticateByTicket = function (model, contactId) {
        var _this = this;
        if (contactId === void 0) { contactId = null; }
        return this.loadDiscoveryDocument().pipe(switchMap(function () {
            _this.oauthService.customQueryParams = {
                'grant_type': 'contact_confirmation',
                'contactId': contactId,
                'confirmationCode': model.ticket
            };
            return from(_this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile('', '')).pipe(switchMap(function () {
                // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
                // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
                // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
                // и typesript 3.хх использовать коллекции angular-oauth2-oidc
                var encrptedAuthTokenName = localStorage.getItem('encrypted_access_token');
                var i = {
                    accessToken: _this.oauthService.getAccessToken(),
                    encryptedAccessToken: encrptedAuthTokenName,
                    // TODO поменять отдачу сервера expireAt и секунды
                    // r.expireAt
                    expireInSeconds: 60 * 60 * 24,
                    userId: undefined,
                    refreshToken: undefined,
                    tenantUid: undefined,
                    userUid: undefined,
                    lawyerUid: undefined
                };
                return _this._refreshSessionByAuth(i);
            }));
        }));
    };
    AuthenticateService.prototype.twoFaVerify = function (contactId, verificationCode) {
        var _this = this;
        this.oauthService.customQueryParams = {
            'grant_type': 'contact_confirmation',
            'contactId': contactId,
            'confirmationCode': verificationCode
        };
        return from(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile('', '')).pipe(switchMap(function () {
            // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
            // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
            // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
            // и typesript 3.хх использовать коллекции angular-oauth2-oidc
            var encrptedAuthTokenName = localStorage.getItem('encrypted_access_token');
            var i = {
                accessToken: _this.oauthService.getAccessToken(),
                encryptedAccessToken: encrptedAuthTokenName,
                // TODO поменять отдачу сервера expireAt и секунды
                // r.expireAt
                expireInSeconds: 60 * 60 * 24,
                userId: undefined,
                refreshToken: undefined,
                tenantUid: undefined,
                userUid: undefined,
                lawyerUid: undefined
            };
            return _this._refreshSessionByAuth(i);
        }));
    };
    AuthenticateService.prototype.resendEmail = function (email) {
        return this._apmAccountServiceProxy.emailValidate(email);
    };
    AuthenticateService.prototype.logout = function () {
        this.reset();
        this._router.navigate([LR.login]);
    };
    AuthenticateService.prototype.reset = function () {
        this._tokenService.remove();
        // fixme
        this._appSessionService.resetSessionUserInfo();
    };
    AuthenticateService.prototype.onAuthenticateError = function (err, smsRoute, emailRoute) {
        if (err.needConfirm) {
            this._router.navigate(smsRoute);
            return;
        }
        if (!err.headers) {
            return;
        }
        var json = err.headers['$error'];
        if (json) {
            var error = JSON.parse(json);
            if (1 === error.code) {
                // this._router.navigate(['/auth/signup/verify', model.userNameOrEmailAddress]);
                this._router.navigate(smsRoute);
            }
            else if (2 === error.code) {
                // this._router.navigate(['/auth/signup-email/verify', model.userNameOrEmailAddress]);
                this._router.navigate(emailRoute);
            }
        }
    };
    AuthenticateService.prototype.isCurrentUserByContact = function (contactValue, contactType) {
        var cr = this._appSessionService.currentLoginInformations;
        if (!cr || !cr.user || !contactValue || typeof contactType === 'number') {
            return false;
        }
        // mobile
        if (contactType === 1) {
            return cr.user.userName === contactValue;
        }
        else if (contactType === 0) {
            return cr.user.emailAddress === contactValue;
        }
        return false;
    };
    AuthenticateService.prototype._refreshSessionByAuth = function (authResultModel) {
        var _this = this;
        if (!this._lsRememberClient.get()) {
            authResultModel.expireInSeconds = undefined;
        }
        this._tokenService.save(authResultModel);
        return from(this._appSessionService.init()).pipe(map(function (success) {
            if (success) {
                return authResultModel;
            }
            // удаляем все пользовательские авторизационные данные
            _this.reset();
            throwError('Update session error');
        }));
    };
    AuthenticateService.prototype._refreshSessionByOAuth = function (authResultModel) {
        var _this = this;
        if (!this._lsRememberClient.get()) {
            authResultModel.expireInSeconds = undefined;
        }
        this._tokenService.save(authResultModel);
        return from(this._appSessionService.init()).pipe(map(function (success) {
            if (success) {
                return authResultModel;
            }
            // удаляем все пользовательские авторизационные данные
            _this.reset();
            throwError('Update session error');
        }));
    };
    AuthenticateService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticateService_Factory() { return new AuthenticateService(i0.inject(i0.INJECTOR), i0.inject(i1.AuthStorageService), i0.inject(i2.OAuthService), i0.inject(i3.HttpClient), i0.inject(i4.CookieService)); }, token: AuthenticateService, providedIn: "root" });
    return AuthenticateService;
}());
export { AuthenticateService };

import { Injectable } from '@angular/core';
import { ILocalStorageProvider, LocalStorageBaseService } from '@shared/service-global';

@Injectable({
    providedIn: 'root',
})
export class AuthStorageService extends LocalStorageBaseService {
    readonly REMEMBER_CLIENT_LS_KEY = 'F6B4CD00-DE5A-4726-B01E-CC9ACC7D93E4';

    get rememberClientLs(): ILocalStorageProvider<boolean> {
        return this.getOrCreateStorageProvider(this.REMEMBER_CLIENT_LS_KEY, Number.MAX_VALUE);
    }

    constructor() {
        super('AuthStorageService');
    }
}

import { AbstractControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { includes } from 'lodash';
import * as moment from 'moment';

import {
    AppValidatorsErrorType,
    APP_REGEXP,
    IConfigurable,
    IKeyValueStrings,
    AppValidators,
    AppDatePickerEvent,
    IDatePickerEvent,
    NullObject,
} from './_imports';

// tslint:disable-next-line:no-empty-interface
export interface IAppFormErrorMessage extends IKeyValueStrings {}
export interface IAppFormErrorWithValidator extends IAppFormErrorMessage, Validator {}

export abstract class BaseAppFormError<T extends IAppFormErrorMessage>
    implements IAppFormErrorMessage, IConfigurable<T> {
    readonly key: string;
    readonly value: string;
    constructor(validatorName: string, message: string) {
        this.key = validatorName;
        this.value = message;
    }

    public configure(configurator: (_this: T) => void): T {
        const child = this._getChild();
        configurator(child);
        return child;
    }
    protected abstract _getChild(): T;
}
export class AppFormErrorMessage extends BaseAppFormError<IAppFormErrorMessage> {
    constructor(validatorName: AppValidatorsErrorType, message: string) {
        super(validatorName, message);
    }
    protected _getChild(): IAppFormErrorMessage {
        return this;
    }
}
export class AppFormRequiredErrorMessage extends BaseAppFormError<IAppFormErrorMessage> {
    constructor(message?: string) {
        super(AppValidatorsErrorType.required, message || 'Заполнение поля обязательно');
    }
    protected _getChild(): IAppFormErrorMessage {
        return this;
    }
}
export class AppFormFullNameErrorMessage extends BaseAppFormError<IAppFormErrorMessage> {
    constructor(message?: string) {
        super(AppValidatorsErrorType.isFullUserName, message || 'Должен содержать имя и фамилию');
    }
    protected _getChild(): IAppFormErrorMessage {
        return this;
    }
}
export class AppFormMaxLenErrorMessage extends BaseAppFormError<IAppFormErrorMessage> {
    constructor(maxLen: number, message?: string, custom = false) {
        const msg = message || 'Максимально допустимое количество символов - ';
        super(AppValidatorsErrorType.maxLength, custom ? msg : msg + maxLen);
    }
    protected _getChild(): IAppFormErrorMessage {
        return this;
    }
}
export class AppFormMinLenErrorMessage extends BaseAppFormError<IAppFormErrorMessage> {
    constructor(minLen: number, message?: string, custom = false) {
        const msg = message || 'Минимально допустимое количество символов - ';
        super(AppValidatorsErrorType.minLength, custom ? msg : msg + minLen);
    }
    protected _getChild(): IAppFormErrorMessage {
        return this;
    }
}

export class AppCustomErrorMessage extends BaseAppFormError<IAppFormErrorMessage> {
    constructor(errorName: string, message: string) {
        super(errorName, message);
    }
    protected _getChild(): IAppFormErrorMessage {
        return this;
    }
}

// Максимальное допустимое количество символов - 16

export class AppFormErrorMessageValidator extends BaseAppFormError<IAppFormErrorWithValidator>
    implements IAppFormErrorWithValidator, IConfigurable<IAppFormErrorWithValidator> {
    constructor(validatorName: string, message: string, private _regExp: RegExp) {
        super(validatorName, message);
    }

    public validate(control: AbstractControl): ValidationErrors {
        const errors = Object.create(null);
        if (this._regExp.test(control.value)) {
            return null;
        }
        errors[this.key] = true;
        return errors;
    }
    public registerOnValidatorChange?(fn: () => void): void {
        throw new Error('AppFormErrorMessageValidator:registerOnValidatorChange Method not implemented.');
    }
    protected _getChild(): IAppFormErrorWithValidator {
        return this;
    }
}
export class AppFormErrorMessagePhoneValidator extends AppFormErrorMessageValidator {
    constructor(regExp?: RegExp, validatorName?: string, message?: string) {
        if (!regExp) {
            regExp = APP_REGEXP.isPhone;
        }

        if (!validatorName) {
            validatorName = 'phone';
        }
        if (!message) {
            message = 'Неверный формат номера телефона';
        }
        super(validatorName, message, regExp);
    }
}
export class AppFormErrorMessageCityPhoneValidator extends AppFormErrorMessagePhoneValidator {
    constructor(regExp?: RegExp, validatorName?: string, message?: string) {
        if (!regExp) {
            regExp = APP_REGEXP.isMoscowPhoneCode;
        }
        if (!validatorName) {
            validatorName = AppValidatorsErrorType.isCityPhone;
        }
        if (!message) {
            message = 'Введите городской номер';
        }
        super(regExp, validatorName, message);
    }
}
export class AppFormErrorMessageMoscowPhoneValidator extends AppFormErrorMessageCityPhoneValidator {
    constructor(message?: string) {
        if (!message) {
            message = 'Введите код города Москвы (499 или 495)';
        }
        super(APP_REGEXP.isMoscowPhoneCode, AppValidatorsErrorType.isCityPhone, message);
    }
}

export class AppFormErrorMessageMobilePhoneMaskValidator extends AppFormErrorMessageValidator {
    constructor(message?: string) {
        if (!message) {
            message = 'Неверный формат номера телефона';
        }
        super(AppValidatorsErrorType.isPhoneValid, message, null);
    }
    validate(ctrl: AbstractControl): ValidationErrors {
        const e = {};
        e[this.key] = true;
        const isMask = AppValidators.isDefaultMobilePhoneMask(ctrl);
        if (isMask) {
            return e;
        }
    }
}
export class AppFormErrorMessageOnlyRussianTextValidator extends AppFormErrorMessageValidator {
    constructor(message?: string, regexp?: RegExp) {
        if (!message) {
            message = 'Только русские буквы';
        }
        super(AppValidatorsErrorType.isOnlyRussianLetters, message, null);
        this.validate = AppValidators.isOnlyRussianLetters(this.key, regexp);
    }
}

export class AppFormErrorMessageDateComparerValidator extends AppFormErrorMessageValidator {
    private readonly _startTimeKey: string;
    private readonly _endTimeKey: string;
    constructor(startTimeKey: string, endTimeKey: string, message?: string) {
        if (!message) {
            message = 'Дата начала не должна превышать дату окончания.';
        }
        super(AppValidatorsErrorType.dateComparer, message, null);
        this._startTimeKey = startTimeKey;
        this._endTimeKey = endTimeKey;
    }
    validate(control: AbstractControl): ValidationErrors {
        if (!control.touched) {
            return null;
        }
        if (control && control.parent) {
            const errors = {};
            errors[this.key] = true;

            const startTimeCtrl = control.parent.get(this._startTimeKey);
            const endTimeCtrl = control.parent.get(this._endTimeKey);
            if (startTimeCtrl && endTimeCtrl && startTimeCtrl.value && endTimeCtrl.value) {
                const start = this._getValue(startTimeCtrl.value);
                const end = this._getValue(endTimeCtrl.value);

                console.log('AppFormErrorMessageDateComparerValidator', {
                    startTimeCtrl: startTimeCtrl.value,
                    endTimeCtrl: endTimeCtrl.value,
                    start,
                    end,
                    'start > end': start > end,
                });
                if (!!start && !!end && start > end) {
                    return errors;
                }
            }
        }

        return null;
    }
    private _getValue(value: string | Date | moment.Moment | IDatePickerEvent): Date | null {
        return AppDatePickerEvent.getDate(value);
    }
}

export class AppFormIsValidMomentValidator extends AppFormErrorMessageValidator {
    constructor(readonly dateFormat: string, message: string = 'Неверная дата') {
        super(AppValidatorsErrorType.isValidMoment, message, null);
    }
    validate(control: AbstractControl): ValidationErrors {
        if (!control.touched) {
            return null;
        }
        if (!control.value) {
            return null;
        }
        const error = Object.create(null);
        error[this.key] = true;
        if (control.value instanceof NullObject) {
            return error;
        }

        const stringVal = this._getValue(control.value);

        if (includes(stringVal, APP_REGEXP.defaultMaskChar)) {
            return error;
        }
        if (moment.isMoment(control.value) && control.value.isValid()) {
            return null;
        }
        if (AppDatePickerEvent.isAppDatePickerEvent(control.value)) {
            const val = control.value as IDatePickerEvent;
            if (val.moment && val.moment.isValid()) {
                return null;
            }
            return error;
        }

        console.log('AppFormIsValidMomentValidator', {
            val: stringVal,
            momentVal: moment(moment(stringVal, this.dateFormat).toDate()),
            'momentVal.isValid': moment(moment(stringVal, this.dateFormat).toDate()).isValid(),
            value: control.value,
            includes: includes(stringVal, APP_REGEXP.defaultMaskChar),
        });

        return error;
    }
    private _getValue(value: string | Date | moment.Moment | IDatePickerEvent): string | null {
        return AppDatePickerEvent.getStringVal(value);
    }
}

export class AppFormErrorMessageIsNumberValidator extends AppFormErrorMessageValidator {
    constructor(message?: string) {
        if (!message) {
            message = 'Введенное значение не является числом';
        }

        super(AppValidatorsErrorType.isNumber, message, null);
    }
    validate(ctrl: AbstractControl): ValidationErrors | null {
        if (AppValidators.isNumberByValue(ctrl.value)) {
            return null;
        }
        const key = this.key;
        return { [key]: true };
    }
}

export class AppFormErrorDomainValidator extends AppFormErrorMessageValidator {
    constructor(message?: string) {
        if (!message) {
            message = 'Неверный формат доменного имени';
        }

        super(AppValidatorsErrorType.isDomain, message, APP_REGEXP.isDomain);
    }

    validate(control: AbstractControl): ValidationErrors {
        if (!control.value) {
            return null;
        }

        return super.validate(control);
    }
}

export class AppFormErrorEmailValidator extends AppFormErrorMessageValidator {
    constructor(message?: string) {
        super(AppValidatorsErrorType.isEmailValid, message || 'Невалидный email адрес', APP_REGEXP.isEmail);
    }
}

export abstract class AppFormFnValidator extends AppFormErrorMessage implements IAppFormErrorWithValidator {
    protected readonly _fn: ValidatorFn;

    protected constructor(fn: ValidatorFn, validatorName: AppValidatorsErrorType, message: string) {
        super(validatorName, message);

        this._fn = fn;
    }

    validate(ctrl: AbstractControl): ValidationErrors | null {
        if (!this._fn(ctrl)) {
            return null;
        }
        const key = this.key;
        return { [key]: true };
    }
}

export class AppFormErrorMinValidator extends AppFormFnValidator {
    /**
     * Текстовая ошибка минимального значения числового поля
     *
     * @constructor
     * @param min Минимальное значение поля
     * @param message Выводимое сообщение. Символ %d в нем будет заменен значением параметра min
     */
    constructor(min: number, message?: string) {
        const msg = (message || 'Значение не должно быть меньше %d').replace('%d', min.toString());
        super(AppValidators.min(min), AppValidatorsErrorType.min, msg);
    }
}

export class AppFormErrorMaxValidator extends AppFormFnValidator {
    /**
     * Текстовая ошибка максимального значения числового поля
     *
     * @constructor
     * @param max Максимальное значение поля
     * @param message Выводимое сообщение. Символ %d в нем будет заменен значением параметра max
     */
    constructor(max: number, message?: string) {
        const msg = (message || 'Значение не должно быть больше %d').replace('%d', max.toString());
        super(AppValidators.max(max), AppValidatorsErrorType.max, msg);
    }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { includes } from 'lodash';
import * as moment from 'moment';
import { AppValidatorsErrorType, APP_REGEXP, AppValidators, AppDatePickerEvent, NullObject, } from './_imports';
var BaseAppFormError = /** @class */ (function () {
    function BaseAppFormError(validatorName, message) {
        this.key = validatorName;
        this.value = message;
    }
    BaseAppFormError.prototype.configure = function (configurator) {
        var child = this._getChild();
        configurator(child);
        return child;
    };
    return BaseAppFormError;
}());
export { BaseAppFormError };
var AppFormErrorMessage = /** @class */ (function (_super) {
    __extends(AppFormErrorMessage, _super);
    function AppFormErrorMessage(validatorName, message) {
        return _super.call(this, validatorName, message) || this;
    }
    AppFormErrorMessage.prototype._getChild = function () {
        return this;
    };
    return AppFormErrorMessage;
}(BaseAppFormError));
export { AppFormErrorMessage };
var AppFormRequiredErrorMessage = /** @class */ (function (_super) {
    __extends(AppFormRequiredErrorMessage, _super);
    function AppFormRequiredErrorMessage(message) {
        return _super.call(this, AppValidatorsErrorType.required, message || 'Заполнение поля обязательно') || this;
    }
    AppFormRequiredErrorMessage.prototype._getChild = function () {
        return this;
    };
    return AppFormRequiredErrorMessage;
}(BaseAppFormError));
export { AppFormRequiredErrorMessage };
var AppFormFullNameErrorMessage = /** @class */ (function (_super) {
    __extends(AppFormFullNameErrorMessage, _super);
    function AppFormFullNameErrorMessage(message) {
        return _super.call(this, AppValidatorsErrorType.isFullUserName, message || 'Должен содержать имя и фамилию') || this;
    }
    AppFormFullNameErrorMessage.prototype._getChild = function () {
        return this;
    };
    return AppFormFullNameErrorMessage;
}(BaseAppFormError));
export { AppFormFullNameErrorMessage };
var AppFormMaxLenErrorMessage = /** @class */ (function (_super) {
    __extends(AppFormMaxLenErrorMessage, _super);
    function AppFormMaxLenErrorMessage(maxLen, message, custom) {
        if (custom === void 0) { custom = false; }
        var _this_1 = this;
        var msg = message || 'Максимально допустимое количество символов - ';
        _this_1 = _super.call(this, AppValidatorsErrorType.maxLength, custom ? msg : msg + maxLen) || this;
        return _this_1;
    }
    AppFormMaxLenErrorMessage.prototype._getChild = function () {
        return this;
    };
    return AppFormMaxLenErrorMessage;
}(BaseAppFormError));
export { AppFormMaxLenErrorMessage };
var AppFormMinLenErrorMessage = /** @class */ (function (_super) {
    __extends(AppFormMinLenErrorMessage, _super);
    function AppFormMinLenErrorMessage(minLen, message, custom) {
        if (custom === void 0) { custom = false; }
        var _this_1 = this;
        var msg = message || 'Минимально допустимое количество символов - ';
        _this_1 = _super.call(this, AppValidatorsErrorType.minLength, custom ? msg : msg + minLen) || this;
        return _this_1;
    }
    AppFormMinLenErrorMessage.prototype._getChild = function () {
        return this;
    };
    return AppFormMinLenErrorMessage;
}(BaseAppFormError));
export { AppFormMinLenErrorMessage };
var AppCustomErrorMessage = /** @class */ (function (_super) {
    __extends(AppCustomErrorMessage, _super);
    function AppCustomErrorMessage(errorName, message) {
        return _super.call(this, errorName, message) || this;
    }
    AppCustomErrorMessage.prototype._getChild = function () {
        return this;
    };
    return AppCustomErrorMessage;
}(BaseAppFormError));
export { AppCustomErrorMessage };
// Максимальное допустимое количество символов - 16
var AppFormErrorMessageValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessageValidator, _super);
    function AppFormErrorMessageValidator(validatorName, message, _regExp) {
        var _this_1 = _super.call(this, validatorName, message) || this;
        _this_1._regExp = _regExp;
        return _this_1;
    }
    AppFormErrorMessageValidator.prototype.validate = function (control) {
        var errors = Object.create(null);
        if (this._regExp.test(control.value)) {
            return null;
        }
        errors[this.key] = true;
        return errors;
    };
    AppFormErrorMessageValidator.prototype.registerOnValidatorChange = function (fn) {
        throw new Error('AppFormErrorMessageValidator:registerOnValidatorChange Method not implemented.');
    };
    AppFormErrorMessageValidator.prototype._getChild = function () {
        return this;
    };
    return AppFormErrorMessageValidator;
}(BaseAppFormError));
export { AppFormErrorMessageValidator };
var AppFormErrorMessagePhoneValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessagePhoneValidator, _super);
    function AppFormErrorMessagePhoneValidator(regExp, validatorName, message) {
        var _this_1 = this;
        if (!regExp) {
            regExp = APP_REGEXP.isPhone;
        }
        if (!validatorName) {
            validatorName = 'phone';
        }
        if (!message) {
            message = 'Неверный формат номера телефона';
        }
        _this_1 = _super.call(this, validatorName, message, regExp) || this;
        return _this_1;
    }
    return AppFormErrorMessagePhoneValidator;
}(AppFormErrorMessageValidator));
export { AppFormErrorMessagePhoneValidator };
var AppFormErrorMessageCityPhoneValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessageCityPhoneValidator, _super);
    function AppFormErrorMessageCityPhoneValidator(regExp, validatorName, message) {
        var _this_1 = this;
        if (!regExp) {
            regExp = APP_REGEXP.isMoscowPhoneCode;
        }
        if (!validatorName) {
            validatorName = AppValidatorsErrorType.isCityPhone;
        }
        if (!message) {
            message = 'Введите городской номер';
        }
        _this_1 = _super.call(this, regExp, validatorName, message) || this;
        return _this_1;
    }
    return AppFormErrorMessageCityPhoneValidator;
}(AppFormErrorMessagePhoneValidator));
export { AppFormErrorMessageCityPhoneValidator };
var AppFormErrorMessageMoscowPhoneValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessageMoscowPhoneValidator, _super);
    function AppFormErrorMessageMoscowPhoneValidator(message) {
        var _this_1 = this;
        if (!message) {
            message = 'Введите код города Москвы (499 или 495)';
        }
        _this_1 = _super.call(this, APP_REGEXP.isMoscowPhoneCode, AppValidatorsErrorType.isCityPhone, message) || this;
        return _this_1;
    }
    return AppFormErrorMessageMoscowPhoneValidator;
}(AppFormErrorMessageCityPhoneValidator));
export { AppFormErrorMessageMoscowPhoneValidator };
var AppFormErrorMessageMobilePhoneMaskValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessageMobilePhoneMaskValidator, _super);
    function AppFormErrorMessageMobilePhoneMaskValidator(message) {
        var _this_1 = this;
        if (!message) {
            message = 'Неверный формат номера телефона';
        }
        _this_1 = _super.call(this, AppValidatorsErrorType.isPhoneValid, message, null) || this;
        return _this_1;
    }
    AppFormErrorMessageMobilePhoneMaskValidator.prototype.validate = function (ctrl) {
        var e = {};
        e[this.key] = true;
        var isMask = AppValidators.isDefaultMobilePhoneMask(ctrl);
        if (isMask) {
            return e;
        }
    };
    return AppFormErrorMessageMobilePhoneMaskValidator;
}(AppFormErrorMessageValidator));
export { AppFormErrorMessageMobilePhoneMaskValidator };
var AppFormErrorMessageOnlyRussianTextValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessageOnlyRussianTextValidator, _super);
    function AppFormErrorMessageOnlyRussianTextValidator(message, regexp) {
        var _this_1 = this;
        if (!message) {
            message = 'Только русские буквы';
        }
        _this_1 = _super.call(this, AppValidatorsErrorType.isOnlyRussianLetters, message, null) || this;
        _this_1.validate = AppValidators.isOnlyRussianLetters(_this_1.key, regexp);
        return _this_1;
    }
    return AppFormErrorMessageOnlyRussianTextValidator;
}(AppFormErrorMessageValidator));
export { AppFormErrorMessageOnlyRussianTextValidator };
var AppFormErrorMessageDateComparerValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessageDateComparerValidator, _super);
    function AppFormErrorMessageDateComparerValidator(startTimeKey, endTimeKey, message) {
        var _this_1 = this;
        if (!message) {
            message = 'Дата начала не должна превышать дату окончания.';
        }
        _this_1 = _super.call(this, AppValidatorsErrorType.dateComparer, message, null) || this;
        _this_1._startTimeKey = startTimeKey;
        _this_1._endTimeKey = endTimeKey;
        return _this_1;
    }
    AppFormErrorMessageDateComparerValidator.prototype.validate = function (control) {
        if (!control.touched) {
            return null;
        }
        if (control && control.parent) {
            var errors = {};
            errors[this.key] = true;
            var startTimeCtrl = control.parent.get(this._startTimeKey);
            var endTimeCtrl = control.parent.get(this._endTimeKey);
            if (startTimeCtrl && endTimeCtrl && startTimeCtrl.value && endTimeCtrl.value) {
                var start = this._getValue(startTimeCtrl.value);
                var end = this._getValue(endTimeCtrl.value);
                console.log('AppFormErrorMessageDateComparerValidator', {
                    startTimeCtrl: startTimeCtrl.value,
                    endTimeCtrl: endTimeCtrl.value,
                    start: start,
                    end: end,
                    'start > end': start > end,
                });
                if (!!start && !!end && start > end) {
                    return errors;
                }
            }
        }
        return null;
    };
    AppFormErrorMessageDateComparerValidator.prototype._getValue = function (value) {
        return AppDatePickerEvent.getDate(value);
    };
    return AppFormErrorMessageDateComparerValidator;
}(AppFormErrorMessageValidator));
export { AppFormErrorMessageDateComparerValidator };
var AppFormIsValidMomentValidator = /** @class */ (function (_super) {
    __extends(AppFormIsValidMomentValidator, _super);
    function AppFormIsValidMomentValidator(dateFormat, message) {
        if (message === void 0) { message = 'Неверная дата'; }
        var _this_1 = _super.call(this, AppValidatorsErrorType.isValidMoment, message, null) || this;
        _this_1.dateFormat = dateFormat;
        return _this_1;
    }
    AppFormIsValidMomentValidator.prototype.validate = function (control) {
        if (!control.touched) {
            return null;
        }
        if (!control.value) {
            return null;
        }
        var error = Object.create(null);
        error[this.key] = true;
        if (control.value instanceof NullObject) {
            return error;
        }
        var stringVal = this._getValue(control.value);
        if (includes(stringVal, APP_REGEXP.defaultMaskChar)) {
            return error;
        }
        if (moment.isMoment(control.value) && control.value.isValid()) {
            return null;
        }
        if (AppDatePickerEvent.isAppDatePickerEvent(control.value)) {
            var val = control.value;
            if (val.moment && val.moment.isValid()) {
                return null;
            }
            return error;
        }
        console.log('AppFormIsValidMomentValidator', {
            val: stringVal,
            momentVal: moment(moment(stringVal, this.dateFormat).toDate()),
            'momentVal.isValid': moment(moment(stringVal, this.dateFormat).toDate()).isValid(),
            value: control.value,
            includes: includes(stringVal, APP_REGEXP.defaultMaskChar),
        });
        return error;
    };
    AppFormIsValidMomentValidator.prototype._getValue = function (value) {
        return AppDatePickerEvent.getStringVal(value);
    };
    return AppFormIsValidMomentValidator;
}(AppFormErrorMessageValidator));
export { AppFormIsValidMomentValidator };
var AppFormErrorMessageIsNumberValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMessageIsNumberValidator, _super);
    function AppFormErrorMessageIsNumberValidator(message) {
        var _this_1 = this;
        if (!message) {
            message = 'Введенное значение не является числом';
        }
        _this_1 = _super.call(this, AppValidatorsErrorType.isNumber, message, null) || this;
        return _this_1;
    }
    AppFormErrorMessageIsNumberValidator.prototype.validate = function (ctrl) {
        var _a;
        if (AppValidators.isNumberByValue(ctrl.value)) {
            return null;
        }
        var key = this.key;
        return _a = {}, _a[key] = true, _a;
    };
    return AppFormErrorMessageIsNumberValidator;
}(AppFormErrorMessageValidator));
export { AppFormErrorMessageIsNumberValidator };
var AppFormErrorDomainValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorDomainValidator, _super);
    function AppFormErrorDomainValidator(message) {
        var _this_1 = this;
        if (!message) {
            message = 'Неверный формат доменного имени';
        }
        _this_1 = _super.call(this, AppValidatorsErrorType.isDomain, message, APP_REGEXP.isDomain) || this;
        return _this_1;
    }
    AppFormErrorDomainValidator.prototype.validate = function (control) {
        if (!control.value) {
            return null;
        }
        return _super.prototype.validate.call(this, control);
    };
    return AppFormErrorDomainValidator;
}(AppFormErrorMessageValidator));
export { AppFormErrorDomainValidator };
var AppFormErrorEmailValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorEmailValidator, _super);
    function AppFormErrorEmailValidator(message) {
        return _super.call(this, AppValidatorsErrorType.isEmailValid, message || 'Невалидный email адрес', APP_REGEXP.isEmail) || this;
    }
    return AppFormErrorEmailValidator;
}(AppFormErrorMessageValidator));
export { AppFormErrorEmailValidator };
var AppFormFnValidator = /** @class */ (function (_super) {
    __extends(AppFormFnValidator, _super);
    function AppFormFnValidator(fn, validatorName, message) {
        var _this_1 = _super.call(this, validatorName, message) || this;
        _this_1._fn = fn;
        return _this_1;
    }
    AppFormFnValidator.prototype.validate = function (ctrl) {
        var _a;
        if (!this._fn(ctrl)) {
            return null;
        }
        var key = this.key;
        return _a = {}, _a[key] = true, _a;
    };
    return AppFormFnValidator;
}(AppFormErrorMessage));
export { AppFormFnValidator };
var AppFormErrorMinValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMinValidator, _super);
    /**
     * Текстовая ошибка минимального значения числового поля
     *
     * @constructor
     * @param min Минимальное значение поля
     * @param message Выводимое сообщение. Символ %d в нем будет заменен значением параметра min
     */
    function AppFormErrorMinValidator(min, message) {
        var _this_1 = this;
        var msg = (message || 'Значение не должно быть меньше %d').replace('%d', min.toString());
        _this_1 = _super.call(this, AppValidators.min(min), AppValidatorsErrorType.min, msg) || this;
        return _this_1;
    }
    return AppFormErrorMinValidator;
}(AppFormFnValidator));
export { AppFormErrorMinValidator };
var AppFormErrorMaxValidator = /** @class */ (function (_super) {
    __extends(AppFormErrorMaxValidator, _super);
    /**
     * Текстовая ошибка максимального значения числового поля
     *
     * @constructor
     * @param max Максимальное значение поля
     * @param message Выводимое сообщение. Символ %d в нем будет заменен значением параметра max
     */
    function AppFormErrorMaxValidator(max, message) {
        var _this_1 = this;
        var msg = (message || 'Значение не должно быть больше %d').replace('%d', max.toString());
        _this_1 = _super.call(this, AppValidators.max(max), AppValidatorsErrorType.max, msg) || this;
        return _this_1;
    }
    return AppFormErrorMaxValidator;
}(AppFormFnValidator));
export { AppFormErrorMaxValidator };

var TextMasks = /** @class */ (function () {
    function TextMasks() {
    }
    TextMasks.passport = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    TextMasks.inn = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    // Todo: удалить после переделки
    TextMasks.mobilePhone = [
        /\d/,
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ];
    return TextMasks;
}());
export { TextMasks };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { EventEmitter } from '@angular/core';
import { TextMaskConfig } from 'angular2-text-mask';
import { each, findIndex, isEmpty, orderBy } from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { APP_REGEXP, AppFormFieldType, createDateMaskFromFormat, FileUploaderOption, DatePickerFormats, } from './_imports';
// cker/date-picker.interface';
/* #region interface */
export var AppUpdateOnFormOptions;
(function (AppUpdateOnFormOptions) {
    AppUpdateOnFormOptions["change"] = "change";
    AppUpdateOnFormOptions["blur"] = "blur";
    AppUpdateOnFormOptions["submit"] = "submit";
})(AppUpdateOnFormOptions || (AppUpdateOnFormOptions = {}));
/* #endregion */
/* #region BaseAppOrderedField */
var BaseAppOrderedField = /** @class */ (function () {
    function BaseAppOrderedField(type, _className) {
        this.type = type;
        this._className = _className;
    }
    BaseAppOrderedField.prototype.configure = function (configurator) {
        configurator(this);
        return this;
    };
    BaseAppOrderedField.prototype._log = function (data) {
        console.log(this._className + '_log', {
            data: data,
        });
    };
    BaseAppOrderedField.prototype._notImplementedException = function (data) {
        if (data === void 0) { data = null; }
        var msg = this._className + ' : Method not implemented.';
        console.error(msg, {
            data: data,
        });
        throw new Error(msg);
    };
    return BaseAppOrderedField;
}());
export { BaseAppOrderedField };
/* #endregion */
var AppOrderedFieldEmpty = /** @class */ (function () {
    function AppOrderedFieldEmpty() {
        this.type = this.key = AppFormFieldType.empty;
    }
    return AppOrderedFieldEmpty;
}());
export { AppOrderedFieldEmpty };
/* #region bool Fields */
var AppOrderedFieldCheckbox = /** @class */ (function (_super) {
    __extends(AppOrderedFieldCheckbox, _super);
    function AppOrderedFieldCheckbox(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldCheckbox';
        }
        _this_1 = _super.call(this, AppFormFieldType.checkbox, className) || this;
        return _this_1;
    }
    return AppOrderedFieldCheckbox;
}(BaseAppOrderedField));
export { AppOrderedFieldCheckbox };
var AppOrderedFieldCheckboxIsHospitalizationProcedure = /** @class */ (function (_super) {
    __extends(AppOrderedFieldCheckboxIsHospitalizationProcedure, _super);
    function AppOrderedFieldCheckboxIsHospitalizationProcedure(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldCheckboxIsHospitalizationProcedure';
        }
        _this_1 = _super.call(this, AppFormFieldType.checkboxIsHospitalizationProcedure, className) || this;
        return _this_1;
    }
    return AppOrderedFieldCheckboxIsHospitalizationProcedure;
}(BaseAppOrderedField));
export { AppOrderedFieldCheckboxIsHospitalizationProcedure };
/* #endregion */
/* #region date Fields */
var AppOrderedFieldDatepicker = /** @class */ (function (_super) {
    __extends(AppOrderedFieldDatepicker, _super);
    function AppOrderedFieldDatepicker(initialValue, dateFormat, className) {
        if (dateFormat === void 0) { dateFormat = 'DD.MM.YYYY'; }
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldDatepicker';
        }
        _this_1 = _super.call(this, AppFormFieldType.datepicker, className) || this;
        if (initialValue) {
            if (initialValue instanceof Date) {
                _this_1.initialValue = initialValue;
            }
            else if (initialValue && moment.isMoment(initialValue)) {
                _this_1.initialValue = initialValue.toDate();
            }
            else {
                _this_1._log({
                    initialValue: initialValue,
                });
            }
        }
        _this_1._validationCreated = false;
        _this_1.dateFormat = dateFormat;
        _this_1.mask = createDateMaskFromFormat(_this_1.dateFormat);
        return _this_1;
    }
    AppOrderedFieldDatepicker.prototype.createValidaion = function (dateFormat) {
        var _this_1 = this;
        if (dateFormat === void 0) { dateFormat = this.dateFormat; }
        if (this._validationCreated) {
            return;
        }
        if (!this.validators) {
            this.validators = [];
        }
        this.validators.push(function (control) {
            var val = control.value;
            if (!val && !_this_1.required) {
                return null;
            }
            var m = moment(val, dateFormat);
            if (m.isValid()) {
                var date = m.format(dateFormat);
                if (!val || isEmpty(date) || !APP_REGEXP.isDate.test(date)) {
                    return _this_1._error();
                }
                return null;
            }
            else {
                return _this_1._error();
            }
        });
        var errorMsg = {
            key: this.key,
            value: 'Не верный формат даты',
        };
        if (!this.errorMessages) {
            this.errorMessages = [];
        }
        this.errorMessages.push(errorMsg);
        this._validationCreated = true;
    };
    AppOrderedFieldDatepicker.prototype._error = function () {
        var result = {};
        result[this.key] = true;
        return result;
    };
    return AppOrderedFieldDatepicker;
}(BaseAppOrderedField));
export { AppOrderedFieldDatepicker };
var AppOrderedFieldAppDatePicker = /** @class */ (function (_super) {
    __extends(AppOrderedFieldAppDatePicker, _super);
    function AppOrderedFieldAppDatePicker(pickerType, className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldAppDatePicker';
        }
        _this_1 = _super.call(this, pickerType, className) || this;
        if (pickerType === AppFormFieldType.appDatePicker) {
            _this_1.dateFormat = DatePickerFormats.date;
        }
        else if (pickerType === AppFormFieldType.appDateTtimePpicker) {
            _this_1.dateFormat = DatePickerFormats.dateTime;
        }
        _this_1.invalidValue = null;
        return _this_1;
    }
    Object.defineProperty(AppOrderedFieldAppDatePicker.prototype, "config", {
        get: function () {
            return this._config || (this._config = this._createConfig());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppOrderedFieldAppDatePicker.prototype, "label", {
        get: function () {
            return this._label;
        },
        set: function (value) {
            this._label = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppOrderedFieldAppDatePicker.prototype, "required", {
        get: function () {
            return this._required;
        },
        set: function (value) {
            this._required = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppOrderedFieldAppDatePicker.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    AppOrderedFieldAppDatePicker.prototype._createConfig = function () {
        var _this_1 = this;
        var propNames = [
            'placeholder',
            'label',
            'required',
            'disabled',
            'mask',
            'type',
            'min',
            'max',
            'showHint',
            'hintMessageView',
            'guide',
        ];
        var props = Object.create(null);
        each(propNames, function (prop) {
            var privateProp = '_' + prop;
            Object.defineProperty(props, prop, {
                get: function () {
                    return _this_1[privateProp];
                },
                set: function (value) {
                    _this_1[privateProp] = value;
                },
            });
        });
        return props;
    };
    return AppOrderedFieldAppDatePicker;
}(BaseAppOrderedField));
export { AppOrderedFieldAppDatePicker };
/* #endregion */
/* #region File Fields */
var AppOrderedFieldFile = /** @class */ (function (_super) {
    __extends(AppOrderedFieldFile, _super);
    function AppOrderedFieldFile(instanceId, option, className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldFile';
        }
        _this_1 = _super.call(this, AppFormFieldType.file, className) || this;
        _this_1.value = option || new FileUploaderOption(instanceId);
        _this_1.appendFormGroup = true;
        return _this_1;
    }
    Object.defineProperty(AppOrderedFieldFile.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = this.value.disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    return AppOrderedFieldFile;
}(BaseAppOrderedField));
export { AppOrderedFieldFile };
/* #endregion */
/* #region Text fields */
var AppOrderedFieldText = /** @class */ (function (_super) {
    __extends(AppOrderedFieldText, _super);
    function AppOrderedFieldText(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldText';
        }
        _this_1 = _super.call(this, AppFormFieldType.text, className) || this;
        _this_1.useHint = false;
        _this_1.hintAlign = 'end';
        _this_1.hintVAlign = 'down';
        return _this_1;
    }
    return AppOrderedFieldText;
}(BaseAppOrderedField));
export { AppOrderedFieldText };
var AppOrderedFieldPassword = /** @class */ (function (_super) {
    __extends(AppOrderedFieldPassword, _super);
    function AppOrderedFieldPassword(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldPassword';
        }
        _this_1 = _super.call(this, AppFormFieldType.password, className) || this;
        _this_1.type = AppFormFieldType.password;
        return _this_1;
    }
    return AppOrderedFieldPassword;
}(BaseAppOrderedField));
export { AppOrderedFieldPassword };
var AppOrderedFieldTextArea = /** @class */ (function (_super) {
    __extends(AppOrderedFieldTextArea, _super);
    function AppOrderedFieldTextArea(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldTextArea';
        }
        _this_1 = _super.call(this, AppFormFieldType.textArea, className) || this;
        _this_1.minRows = 5;
        _this_1.maxRows = 100;
        _this_1.autosizeIsEnabled = true;
        _this_1.useHint = true;
        _this_1.maxlength = 500;
        _this_1.hintAlign = 'end';
        return _this_1;
    }
    return AppOrderedFieldTextArea;
}(BaseAppOrderedField));
export { AppOrderedFieldTextArea };
/* #endregion */
/* #region TextMaskFields */
var AppOrderedFieldTextMask = /** @class */ (function (_super) {
    __extends(AppOrderedFieldTextMask, _super);
    function AppOrderedFieldTextMask(className, mask) {
        if (mask === void 0) { mask = false; }
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldTextMask';
        }
        _this_1 = _super.call(this, AppFormFieldType.textMask, className) || this;
        _this_1.mask = new TextMaskConfig();
        _this_1.mask.mask = mask;
        return _this_1;
    }
    return AppOrderedFieldTextMask;
}(BaseAppOrderedField));
export { AppOrderedFieldTextMask };
var AppOrderedFieldDefaultPhoneTextMask = /** @class */ (function (_super) {
    __extends(AppOrderedFieldDefaultPhoneTextMask, _super);
    function AppOrderedFieldDefaultPhoneTextMask(onGroupFormInitialized, createPhoneMask, className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldDefaultPhoneTextMask';
        }
        _this_1 = _super.call(this, className, createPhoneMask || APP_REGEXP.createDefaultPhoneMask) || this;
        onGroupFormInitialized.pipe(map(function (f) {
            _this_1._formRef = f;
            return f;
        }));
        _this_1.mask.placeholderChar = APP_REGEXP.defaultPhoneMaskChar;
        _this_1.mask.pipe = function (val) { return _this_1._pipeMask(val); };
        _this_1.emmitOptions = { emitEvent: true, onlySelf: true };
        return _this_1;
    }
    AppOrderedFieldDefaultPhoneTextMask.prototype._pipeMask = function (val) {
        this.value = val;
        if (this._formRef) {
            this._formRef.get(this.key).updateValueAndValidity(this.emmitOptions);
        }
        return val;
    };
    return AppOrderedFieldDefaultPhoneTextMask;
}(AppOrderedFieldTextMask));
export { AppOrderedFieldDefaultPhoneTextMask };
var AppOrderedFieldMobilePhoneTextMask = /** @class */ (function (_super) {
    __extends(AppOrderedFieldMobilePhoneTextMask, _super);
    function AppOrderedFieldMobilePhoneTextMask(onGroupFormInitialized, createMobilePhoneMask, className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldMobilePhoneTextMask';
        }
        _this_1 = _super.call(this, onGroupFormInitialized, createMobilePhoneMask || APP_REGEXP.createMobilePhoneMask, className) || this;
        return _this_1;
    }
    return AppOrderedFieldMobilePhoneTextMask;
}(AppOrderedFieldDefaultPhoneTextMask));
export { AppOrderedFieldMobilePhoneTextMask };
/* #endregion */
/* #region Number fields */
/**
 * as text
 */
var AppOrderedFieldNumber = /** @class */ (function (_super) {
    __extends(AppOrderedFieldNumber, _super);
    function AppOrderedFieldNumber(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldNumber';
        }
        _this_1 = _super.call(this, AppFormFieldType.number, className) || this;
        return _this_1;
    }
    return AppOrderedFieldNumber;
}(BaseAppOrderedField));
export { AppOrderedFieldNumber };
var AppOrderedFieldRadio = /** @class */ (function (_super) {
    __extends(AppOrderedFieldRadio, _super);
    function AppOrderedFieldRadio(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldRadio';
        }
        _this_1 = _super.call(this, AppFormFieldType.radio, className) || this;
        _this_1._notImplementedException();
        return _this_1;
    }
    return AppOrderedFieldRadio;
}(BaseAppOrderedField));
export { AppOrderedFieldRadio };
var AppOrderedFieldChips = /** @class */ (function (_super) {
    __extends(AppOrderedFieldChips, _super);
    function AppOrderedFieldChips(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldChips';
        }
        _this_1 = _super.call(this, AppFormFieldType.chips, className) || this;
        _this_1.list = _this_1.list || [];
        _this_1.separator = _this_1.separator || ';';
        _this_1.separatorKeysCodes = _this_1.separatorKeysCodes || [COMMA, ENTER, SEMICOLON];
        _this_1.changed$ = new EventEmitter();
        return _this_1;
    }
    AppOrderedFieldChips.prototype.addChip = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            this.list.push(value.trim());
            this.emitChange();
        }
        if (input) {
            input.value = '';
        }
    };
    AppOrderedFieldChips.prototype.editChip = function (chipIndex) {
        if (chipIndex < 0) {
            return;
        }
        var chipValue = prompt("\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F " + this.list[chipIndex], this.list[chipIndex]);
        if (chipValue) {
            this.list[chipIndex] = chipValue;
            this.emitChange();
        }
    };
    AppOrderedFieldChips.prototype.removeChip = function (chipIndex) {
        if (chipIndex < 0) {
            return;
        }
        if (confirm("\u0412\u044B \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 " + this.list[chipIndex] + "?")) {
            this.list.splice(chipIndex, 1);
            this.emitChange();
        }
    };
    AppOrderedFieldChips.prototype.emitChange = function () {
        this.changed$.emit(this.list.join(this.separator));
    };
    return AppOrderedFieldChips;
}(BaseAppOrderedField));
export { AppOrderedFieldChips };
var AppOrderedFieldMultiField = /** @class */ (function (_super) {
    __extends(AppOrderedFieldMultiField, _super);
    function AppOrderedFieldMultiField(className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldMultiField';
        }
        _this_1 = _super.call(this, AppFormFieldType.multiField, className) || this;
        return _this_1;
    }
    return AppOrderedFieldMultiField;
}(BaseAppOrderedField));
export { AppOrderedFieldMultiField };
/* #endregion */
/* #region Value fields */
var BaseAppSelectableValue = /** @class */ (function () {
    function BaseAppSelectableValue(className, options, selectedIndex) {
        if (className === void 0) { className = 'BaseAppSelectableValue'; }
        this.className = className;
        this._selectedIndex = 0;
        if (selectedIndex) {
            this._selectedIndex = selectedIndex;
        }
        this.list = options;
    }
    Object.defineProperty(BaseAppSelectableValue.prototype, "selectedIndex", {
        get: function () {
            return this._selectedIndex;
        },
        set: function (value) {
            this._selectedIndex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseAppSelectableValue.prototype, "selected", {
        get: function () {
            if (this.list && this.list[this._selectedIndex]) {
                return this.list[this._selectedIndex];
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return BaseAppSelectableValue;
}());
export { BaseAppSelectableValue };
var AppSimpleSelectValue = /** @class */ (function (_super) {
    __extends(AppSimpleSelectValue, _super);
    function AppSimpleSelectValue(options, selectedIndex, className) {
        if (className === void 0) { className = 'AppSimpleSelectValue'; }
        return _super.call(this, className, options, selectedIndex) || this;
    }
    AppSimpleSelectValue.prototype.findIndex = function (value) {
        return findIndex(this.list, function (o) {
            return o === value;
        });
    };
    AppSimpleSelectValue.prototype.setByValue = function (value) {
        if (!value || !this.list) {
            return false;
        }
        var val = this.findIndex(value);
        if (val !== -1) {
            this.selectedIndex = val;
            return true;
        }
        return false;
    };
    return AppSimpleSelectValue;
}(BaseAppSelectableValue));
export { AppSimpleSelectValue };
var AppSelectValue = /** @class */ (function (_super) {
    __extends(AppSelectValue, _super);
    function AppSelectValue(options, selectedIndex, className) {
        if (className === void 0) { className = 'AppSelectValue'; }
        return _super.call(this, className, options, selectedIndex) || this;
    }
    Object.defineProperty(AppSelectValue.prototype, "viewList", {
        get: function () {
            return orderBy(this.list, ['value'], ['asc']);
        },
        enumerable: true,
        configurable: true
    });
    AppSelectValue.prototype.findIndex = function (value) {
        return findIndex(this.list, function (o) {
            return o.key === value.key;
        });
    };
    AppSelectValue.prototype.setByValue = function (value) {
        if (!value || !this.list) {
            return false;
        }
        var idx = this.findIndex(value);
        if (idx !== -1) {
            this.selectedIndex = idx;
            return true;
        }
        return false;
    };
    return AppSelectValue;
}(BaseAppSelectableValue));
export { AppSelectValue };
/* #endregion */
var AppOrderedFieldSimpleSelect = /** @class */ (function (_super) {
    __extends(AppOrderedFieldSimpleSelect, _super);
    function AppOrderedFieldSimpleSelect(value, className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldSimpleSelect';
        }
        _this_1 = _super.call(this, AppFormFieldType.simpleSelect, className) || this;
        if (value) {
            _this_1.initialValue = value.selectedIndex + '';
            _this_1.value = value;
        }
        else {
            _this_1.value = new AppSimpleSelectValue(null);
        }
        return _this_1;
    }
    return AppOrderedFieldSimpleSelect;
}(BaseAppOrderedField));
export { AppOrderedFieldSimpleSelect };
var AppOrderedFieldSelect = /** @class */ (function (_super) {
    __extends(AppOrderedFieldSelect, _super);
    function AppOrderedFieldSelect(value, className) {
        var _this_1 = this;
        if (!className) {
            className = 'AppOrderedFieldSelect';
        }
        _this_1 = _super.call(this, AppFormFieldType.select, className) || this;
        if (value) {
            _this_1.initialValue = value.selectedIndex + '';
            _this_1.value = value;
        }
        else {
            _this_1.value = new AppSelectValue([], -1);
        }
        return _this_1;
    }
    return AppOrderedFieldSelect;
}(BaseAppOrderedField));
export { AppOrderedFieldSelect };

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthTenantService {
    getTenantId(): number {
        return abp.multiTenancy.getTenantIdCookie();
    }

    setTenantId(tenantId?: number): void {
        return abp.multiTenancy.setTenantIdCookie(tenantId);
    }
}

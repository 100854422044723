import { EventEmitter } from '@angular/core';
import { IQueryOptionsDto } from '../service-proxies';
import { Observable } from 'rxjs';

/**
 * Сервис, который позволяет реализовать оповещения о событиях
 */
export class BaseFilterService {
    protected _onChange = new EventEmitter<IQueryOptionsDto>();

    /**
     * Подписка на события.
     * ```typescript
     *    baseFilterService.onChange().subscribe((queryOptions) => {
     *      // do something
     *    });
     * ```
     */
    onChange(): Observable<IQueryOptionsDto> {
        return this._onChange.asObservable();
    }

    /**
     * Отправка оповещения о событии
     * @param {IQueryOptionsDto} queryOptions Данные
     */
    emitOnChange(queryOptions: IQueryOptionsDto): void {
        this._onChange.emit(queryOptions);
    }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UtilHelper } from '../helpers/util-helper';
import { LocalStorageBaseService } from './local-storage-base.service';
import * as i0 from "@angular/core";
var TemporaryDataStorageService = /** @class */ (function (_super) {
    __extends(TemporaryDataStorageService, _super);
    function TemporaryDataStorageService() {
        var _this = _super.call(this, 'TemporaryDataStorageService') || this;
        _this._temporaryStorage = Object.create(null);
        return _this;
    }
    TemporaryDataStorageService.prototype.getTemp = function (token) {
        var ls = this._temporaryStorage[token];
        if (ls) {
            return ls.get();
        }
        return null;
    };
    /**
     *
     * @param data данные для сохранения
     * @param timeInsecond время сохранения
     * @param token устанавливает переданный или создает новый ключ
     * @returns ключ к тмп
     */
    TemporaryDataStorageService.prototype.setTmp = function (data, timeInsecond, token) {
        var t = token || UtilHelper.createQuickGuid();
        if (!this._temporaryStorage[token]) {
            this._temporaryStorage[token] = this.getOrCreateStorageProvider(t, timeInsecond);
        }
        this._temporaryStorage[token].save(data, timeInsecond);
        return t;
    };
    TemporaryDataStorageService.prototype.removeTmp = function (token) {
        this.getOrCreateStorageProvider(token).reset();
        if (this._temporaryStorage[token]) {
            delete this._temporaryStorage[token];
        }
    };
    TemporaryDataStorageService.ngInjectableDef = i0.defineInjectable({ factory: function TemporaryDataStorageService_Factory() { return new TemporaryDataStorageService(); }, token: TemporaryDataStorageService, providedIn: "root" });
    return TemporaryDataStorageService;
}(LocalStorageBaseService));
export { TemporaryDataStorageService };

var MenuItem = /** @class */ (function () {
    function MenuItem(name, permissionName, icon, route, childItems) {
        if (childItems === void 0) { childItems = null; }
        this.name = '';
        this.permissionName = '';
        this.icon = '';
        this.route = '';
        this.name = name;
        this.permissionName = permissionName;
        this.icon = icon;
        this.route = route;
        this.items = childItems;
    }
    return MenuItem;
}());
export { MenuItem };

import { Injectable } from '@angular/core';
import { LocalStorageBaseService } from './local-storage-base.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep, find } from 'lodash';

import { environment } from '../../environments/environment';
import { DataFilterDto, DataFilterServiceProxy, QueryOptionsDto } from '../service-proxies';

@Injectable({
    providedIn: 'root',
})
export class DataFilterRepositoryService extends LocalStorageBaseService {
    public readonly keys: DataKeyFilters;
    private _initialized = false;
    private _lsKey: string;
    private _list: DataFilterDto[];

    constructor(private _proxy: DataFilterServiceProxy) {
        super('DataFilterRepositoryService');
        this._lsKey = this._className;

        const prodTime = 30 * 24 * 60 * 60;
        const devTime = 60;
        this._defaultExpires = environment.production ? prodTime : devTime;
        // tslint:disable-next-line: no-use-before-declare
        this.keys = new DataKeyFilters();
    }

    init(): Observable<boolean> {
        return this._fillRepository();
    }
    /**
     *
     * @param filterId guid DataFilterDto.id
     */
    getFilterById(filterId: string): Observable<DataFilterDto> | null {
        return this._getList().pipe(
            map(list => {
                const item = find(list, (i: DataFilterDto) => {
                    return i.id === filterId;
                });
                if (item) {
                    return cloneDeep(item);
                }
                return null;
            }),
        );
    }

    private _fillRepository(): Observable<boolean> {
        if (this._initialized) {
            return of(true);
        }
        const ls = this.getStorage(this._lsKey);
        if (ls) {
            this._setListByString(ls, true);
            this._initialized = true;
            return of(true);
        }

        return this._getAllRequest().pipe(
            map(i => {
                return !!i;
            }),
        );
    }

    private _getList(): Observable<DataFilterDto[]> {
        if (this._initialized) {
            return of(this._list);
        }
        return this._fillRepository().pipe(
            map(suc => {
                if (suc) {
                    return this._list;
                }
                throw new Error('DataFilterRepositoryService.isEmpty');
            }),
        );
    }

    private _setListByString(list: string, skipSave: boolean): void {
        this._setList(JSON.parse(list), skipSave);
    }
    private _setList(list: DataFilterDto[], skipSave: boolean): void {
        this._list = list;
        if (!skipSave) {
            this.setStorage(this._lsKey, this._list, this._defaultExpires);
        }
    }

    private _getAllRequest(): Observable<DataFilterDto[]> {
        return this._proxy
            .queryPage(
                1,
                100,
                false,
                new QueryOptionsDto({
                    filterValues: [],
                    sortOptions: [],
                }),
            )
            .pipe(
                map(i => {
                    if (i && i.data && i.data.length) {
                        this._setList(i.data, false);
                    }
                    return i.data;
                }),
            );
    }
}

export class CampaignFilterKeysMap {
    readonly campaignFileDataFilterId = '7E4D98E9-A273-43AE-8CAF-EB8BEE136D6F';
}

export class MembersFilterKeysMap {
    readonly searchTextDataFilterId = 'dae55074-75aa-4823-b52a-996ed10b128d';
    readonly genderDataFilterId = 'f66c9929-7b28-4521-b0f6-294da9c43acf';
    readonly birthdayDataFilterId = '8d5cb1ad-c410-4f05-be45-0a1cac6cb7d5';
    readonly searchRegistryNumberDataFilterId = '025CA724-5771-406D-8D0F-22674FF3AB6E';
    readonly searchByFioDataFilterId = '5f788c1d-1771-4544-9f16-79fd986ec7d8';
}

export class LawyerFormationsFilterKeysMap {
    readonly searchByNameDataFilterId = '46CAA21B-15AC-4D2A-B365-D44771CEE539';
    readonly searchTextDataFilterId = 'aab83642-07f8-4058-9aa9-2536de0e60ff';
    readonly membersCountDataFilterId = '2991195f-3f9c-4ff4-959e-f493233cbc92';
    readonly participationTypeDataFilterId = '245689b6-5c0b-4c93-8936-3a63d5fe4022';
}

export class LawyerChamberFilterKeysMap {
    readonly SearchByNameDataFilterId = '0B1B8443-9DD9-414D-9175-54B41B433069';
    readonly SearchByCodeFilterId = '0CB03CC2-2CEE-4380-A390-19A24A8B7848';
}
export class AreaFilterKeysMap {
    readonly JudicialAreaDataFilterId = '671EBC80-3A06-45F9-BB19-E616D510E189';
}
export class DistrictFilterKeysMap {
    readonly DistrictByAreaDataFilterId = 'BB1DEC32-A0B0-46E0-A8F6-B8C9B89C66EA';
}
export class LawyersFilterKeysMap {
    readonly lawyerParticipationDataFilterId = '536B50D1-2237-4ADF-828D-9F7A0157D19D';
    readonly lawyerFileDataFilterId = '9CEB862F-5D08-40FC-89C0-26C49399BBED';
    readonly lawyerWorkingDistrictDataFilterId = '9770C868-050B-4EBB-8A6C-3AE089A03B14';
    readonly workingSpecializationDataFilterId = '2EC6440A-8225-4AEE-A857-98258CB03F59';
    readonly lawyerLitigationTypeDataFilterId = 'B3B7FE44-0D77-40CB-AD7A-DE9D335FEF44';
    readonly lawyerPersonIdsDataFilterId = '2BAF2BDA-0390-4B23-AC35-467D8053AB43';
    readonly lawyerQuickSearchFullNameDataFilterId = '96914EFD-2622-4727-9F54-F2DE800A2ACF';
    readonly lawyerQuickSearchRegistryNumberDataFilterId = '36CBDC62-8624-4020-AF5B-C4C4EC78036F';

    readonly lawyerQuickSearchStatisticFullNameDataFilterId = 'B9D74E34-3159-49EA-B6ED-3CFB047578D6';
    readonly lawyerQuickSearchStatisticRegistryNumberDataFilterId = 'D4C30FE8-3390-4033-BDCC-0FDD70848216';
    readonly lawyerQuickSearchStatisticWorkingDistrictDataFilterId = '987F3BD3-3A39-4418-B785-1B7AEE1BA135';
    readonly lawyerQuickSearchStatisticParticipationDataFilterId = 'A8997BE0-97C2-4B16-99F5-2CFB10F98947';
    readonly lawyerLawyerCommentDataFilterId = '469F555D-6193-4D70-B21D-CEC8771E6AAB';
    readonly lawyerQuickSearchStatisticQuickSearchDataFilterId = 'D8BC0D7D-CE06-40D7-A0CE-6FB1AD5013B3';
    readonly lawyerQuickSearchStatisticEmailDataFilterId = '10D1A82D-8F5F-4822-80E0-E3B3A4AF9356';
    readonly lawyerQuickSearchStatisticPhoneDataFilterId = '43621DBC-EEB5-449C-80D3-FCBB85582E5D';
}

export class LawyersOrganizationFilterKeysMap {
    readonly SearchByName = 'FC6738A0-48EF-41A4-8B00-961BC1CC92DD';
    readonly SearchByNameLawyerFormations = '98B92028-87D2-4931-8730-B5D44983EDA8';
    readonly SearchByFederationSubjectCode = '5D4980D5-86DF-477D-92A9-51FAA56E1922';
    readonly SearchByType = '80D26986-3707-4A46-97CA-507502DA05F5';
    readonly SearchByHeadName = 'F2E1E0C5-B8DA-4FEE-B9B4-3694A5027630';
}

export class LitigationStagesFilterKeysMap {
    readonly litigationStageDataFilterId = 'C7D705D1-A4FE-4B34-B18D-BA414B855B4C';
}

export class LawyerRequestsFilterKeysMap {
    readonly lawyerRequestStatusDataFilterId = '9BE5BE34-B864-470E-B4E6-B1E357DBA0FD';
    readonly lawyerRequestInitiatorNameFilterId = '83CB7269-E95F-4ACB-99F8-04BBF64ADA12';
    readonly lawyerRequestInitiatorNameStrictFilterId = '22359CD3-19F4-4CD5-BE1C-5FE992E22492';
    readonly lawyerRequestClientNameFilterId = '5292F9DC-95B4-4AD1-A1B1-8016A4CFBA3A';
    readonly lawyerRequestClientNameStrictFilterId = 'B7C163F5-7318-4476-AF5A-44E5CF47B73D';
    /**
     * LawyerRequest.DocNumber
     */
    readonly lawyerRequestDocNumberFilterId = 'A493C0B9-F3CD-480D-B765-BCD567D0A616';
    readonly lawyerRequestDocNumberEntryFilterId = 'D8C07BD4-51BA-470F-A350-B10A4EF08C9C';
    readonly lawyerRequestCreationTimeFilterId = '70EC240A-8428-4FAC-A022-A65FA0B71C96';
    readonly lawyerRequestIssueHearingDateFilterId = 'F097B4B2-9662-4794-A276-8CAC73497359';
    readonly lawyerRequestInitiatorPersonPrimaryPhoneFilterId = 'D63EB466-39EB-40F6-B9DA-662AC1124C58';
    readonly lawyerRequestInitiatorWorkingPositionDataFilterId = '888A4243-1F67-4AD9-8320-407C8842D60F';
    readonly lawyerRequestIssueQualificationDataFilterId = '5838BA95-6989-4AE0-9F45-5AC6544C29C0';
    readonly lawyerRequestCourtOfInquiryNameDataFilterId = 'CC62D0BB-7B20-467B-B492-D73EDACF559E';
    readonly lawyerRequestCourtOfInquiryNameStrictDataFilterId = '1E80CE56-79E2-4A77-96E7-B342A06201CB';
    readonly lawyerRequestIssueDocNumberDataFilterId = '2157BD6C-F972-4688-8DEF-918487452001';
    readonly lawyerRequestInitiatorPersonIdsDataFilterId = '725FD5E6-4245-4E8F-AEF2-C7B070D93FA7';
    readonly lawyerRequestLawyerFullNameDataFilterId = '8B4CED03-4B11-48AD-8AB6-34EED9E44C5D';
    readonly lawyerRequestIdsDataFilterId = '22AF678B-9E3F-46F1-804E-2628F716FB73';
    readonly lawyerRequestWithLawyerIdDataFilterId = '30ED996E-387A-4D41-8FD2-54CD8A7AF9E1';
    readonly lawyerRequestInitiatorIdsDataFilterId = '5AF881B8-6692-4B61-97F0-85084C6A0F5F';
    readonly lawyerRequestLawyerRegistryCodeDataFilterId = '712C9FE6-EA30-4982-90A1-9E0BD06E7572';

    readonly lawyerRequestLawyerPrimaryPhoneDataFilterId = '45C94D4E-7CEF-457D-98B5-D68534AF3A75';
    readonly lawyerRequestLawyerPrimaryEmailDataFilterId = 'D6D834BD-3930-4489-9E0B-E0987F6F5728';
    readonly lawyerRequestInitiatorPersonPrimaryEmailDataFilterId = '8D8A78A8-DD6E-4542-A594-48AB84C9B1E5';
    readonly lawyerRequestIssueHearingAddressDataFilterId = '1EB38E6B-9622-438B-9FE9-891D9C978982';
    readonly lawyerRequestLawyerSelectedTimeDataFilterId = '373F6394-954E-4A1D-8FDF-75DC2E5555EB';

    // Фильтры для альтернативного метода загрузки списка заявок

    readonly altLawyerRequestDocNumberDataFilterId = '170DF8CB-CFEC-46FA-88AE-0A82B18D7D26';
    readonly altLawyerRequestDocNumberLikeDataFilterId = '144E1DD3-0A91-4354-BFB0-B7B93FC183E0';

    readonly altLawyerRequestCreationTimeDataFilterId = 'B74F141E-F3FD-4C8D-83F3-5B1A04E6E893';

    readonly altLawyerRequestInitiatorNameLikeDataFilterId = 'EAB3AE1C-248E-4EA2-A950-81F6270B49F4';
    //

    readonly altLawyerRequestLawyerFullNameDataFilterId = 'A09CE3EA-48B0-4A71-9DCB-158D22432000';
    //

    readonly altLawyerRequestLawyerSelectedTimeDataFilterId = '5552F7D3-7D57-41DD-BDCC-7E7587BF6A30';

    readonly altLawyerRequestClientNameLikeDataFilterId = '74E2E533-31E4-49B6-ABC8-288F13C928CA';
    //

    readonly altLawyerRequestCourtOfInquiryNameLikeDataFilterId = '3598DAC9-87A7-42D8-88E1-685179DD2DCB';
    //

    readonly altLawyerRequestIssueHearingDateDataFilterId = 'E277CD94-7EC9-4BCF-8295-8115B1955D0A';

    readonly altLawyerRequestIssueHearingAddressDataFilterId = 'F9D8B045-53F9-49B0-BA54-9135B6BC49B6';
    //

    readonly altLawyerRequestStatusDataFilterId = '9B7914D1-2A7B-4150-99F3-F2C6D2AC35C7';

    readonly altLawyerRequestAppointmentTypeDataFilterId = 'C56036A5-FA71-48C2-9D3E-BA63904CBF13';

    readonly altLawyerRequestInitiatorPersonPrimaryMailDataFilterId = '788EC08E-9B1F-4F4D-9708-B6AE61A0F3AA';
    readonly altLawyerRequestInitiatorPersonPrimaryPhoneDataFilterId = '0D8299BA-B878-4B53-BAD6-61D2F529D319';

    readonly altLawyerRequestLawyerPrimaryMailDataFilterId = 'BFF67A72-C17B-4675-B02C-192BCB22C257';
    readonly altLawyerRequestLawyerPrimaryPhoneDataFilterId = '1981B05C-1980-47A4-AF16-9F104469BF97';
}

export class LawyerRequestOrdersFilterKeysMap {
    readonly lawyerRequestDataFilterId = '478A2B5C-A8D3-4FE6-9E6F-81B36B332F8C';
}

export class LawyerRequestFilesFilterKeysMap {
    readonly lawyerRequestDataFilterId = 'B0DCEE44-4A14-4658-BCE3-853911035BFE';
    readonly changeReasonDataFilterId = '070AE896-4DF3-4045-8FD0-B59EED0C3E5F';
}

export class LawyerRequestAppealsFilterKeysMap {
    readonly lawyerRequestDataFilterId = 'D86B7309-1696-4A7B-857F-7F40A6F31417';
}

export class LawyerRequestCommentsFilterKeysMap {
    readonly lawyerRequestDataFilterId = '93C4B1C9-2662-4DFE-B1A3-85C508F0E937';
    readonly commentTypeDataFilterId = '832953AF-1DFF-409D-AAFA-ADE66E1A475B';
}

export class PaymentClaimFilterKeysMap {
    readonly requestDataFilterId = '65A99C55-C44B-48E0-844C-32C7FB061336';
}

export class LawyerCertificatesFilterKeysMap {
    [key: string]: string;
    readonly lawyerIdCertificateDataFilterId = 'CCE31E13-2092-4BD5-AF5A-E49FF9E85A3F';
}

export class LawyerResponsesFilterKeysMap {
    readonly lawyerRequestDataFilterId = '2EC77205-55F3-4437-A278-175A61ABBD43';
    readonly lawyerRequestStatusDataFilterId = 'AEA65FBB-91C0-4308-8C98-F5D3DE6B813D';
    readonly lawyerDataFilterId = '92599366-B63A-4DE7-A3F3-C12160DB4B6F';
    readonly issueDocNumberDataFilterId = '82C0F8F5-B6ED-4BD8-9FDF-49864FBB75E8';
    readonly isRejectedDataFilterId = '3DE417B3-3F97-48E3-A0C8-AE4D0C64BDC1';
    readonly lawyerIdRequestDataFilterId = '67D2A21B-7DF1-427A-A689-234CDC5FEDD8';
}

export class PassportDataFilterKeysMap {
    readonly byPersonIdFilterId = 'B8487796-632F-4EA1-A84E-6687C611C7F5';
    readonly personFullNameDataFilterId = '7F05977B-EA10-413F-B2F3-423DA7E85937';
}

export class PersonContactsFilterKeysMap {
    readonly personFilterId = '9368AE82-CE81-43B7-8515-52C7E53D41E4';
    readonly personContactsByDataDataFilterId = '05CE322A-E482-45FB-94E1-99713A66328D';
    readonly personContactsByTypeDataFilterId = '62B706AD-8D71-4A1E-8A91-5969EA8FC6FD';
}

export class LawyerRewardsFilterKeysMap {
    readonly lawyerDataFilterId = 'D812909A-4889-4787-A3FE-5FC4CD2C1B1B';
}
export class LawyerStatusReasonsFilterKeysMap {
    readonly lawyerDataFilterId = '48B028EB-3E80-4035-8F27-874079B59775';
}
export class LawyerStatusHistoryFilterKeysMap {
    readonly lawyerDataFilterId = '6D5C6CAB-5C6C-4D41-B4B5-91DC4BC26C27';
}

export class ApgmLogFilterKeysMap {
    readonly browserInfoDataFilterId = '12DEE175-B80C-46CF-9221-E77DB8B55698';
    readonly clientIpAddressDataFilterId = 'F239E6BC-833A-44CB-91FE-282855060651';
    readonly clientNameDataFilterId = '90AFF1F2-08F8-44DA-BC29-CCFF75420333';
    readonly userNameOrEmailAddressDataFilterId = 'C08D31BF-78AB-42DC-A1C0-6AB92F061130';
    readonly creationTimeDataFilterId = '47BCE70A-52AE-45EF-97E3-F3E3B3DEF78C';
}

export class EducationsFilterKeysMap {
    static readonly personFilterId = '98BCE70A-B446-468B-8AC4-8C1CE1208ABB';
}

export class PersonAddressesFilterKeysMap {
    readonly personFilterId = 'D8F81ABF-C60B-409C-BB1B-6FEE74F20A19';
}

export class OathDocumentFilterKeysMap {
    static readonly lawyerId = 'C11F6833-C391-4C95-B29E-F1F02FB75A16';
}
export class AssumptionDtoFilterKeysMap {
    static readonly lawyerId = '24E34902-E646-4E2B-8A1F-65BFFC202A17';
}

export class SearchDataKeysMap {
    readonly fullNameId = '1068B4E9-C019-43D9-85E2-BE1238252F71';
    readonly startWithFullNameId = '1068B4E9-C019-43D9-85E2-BE1238252F71';
    readonly registyNumberId = '36CBDC62-8624-4020-AF5B-C4C4EC78036F';
    readonly startWithRegistyNumberId = '36CBDC62-8624-4020-AF5B-C4C4EC78036F';
    readonly phoneId = '1BE04835-EAE3-4700-8D17-6D5D3E0C303F';
    readonly statusId = 'DCDA4EEB-C992-4103-BB8F-A288533843CF';
    readonly organizationId = '4774AE1B-B805-4257-84B3-109DD8BBE963';

}
export class JobDataLogKeysMap {
    readonly lawyerRequestId = 'F2AAFE9E-CD1F-4457-9B0B-2F7946751B66';
    readonly taskId = '23F42AB4-0189-4FA1-AC88-33528C3C0770';
    readonly stateId = '595E9CC1-4F87-4592-AF22-CC45594CF8F3';
}

export class JudicialAuthorityFilterKeyMap {
    readonly nameDataFilterId = '34410294-841E-4B55-8027-2361B7E3C2E5';
    readonly actualAuthorityDataFilterId = 'BF06E6F3-16F2-4CD8-BC00-37B77D4372AA';
}

export class DataKeyFilters {
    private readonly _campaign = new CampaignFilterKeysMap();
    private readonly _members = new MembersFilterKeysMap();
    private readonly _lawyerFormations = new LawyerFormationsFilterKeysMap();
    private readonly _lawyers = new LawyersFilterKeysMap();
    private readonly _lawyersOrganisation = new LawyersOrganizationFilterKeysMap();
    private readonly _lawyerChambers = new LawyerChamberFilterKeysMap();
    private readonly _areas = new AreaFilterKeysMap();
    private readonly _districts = new DistrictFilterKeysMap();
    private readonly _litigationStages = new LitigationStagesFilterKeysMap();
    private readonly _lawyerRequests = new LawyerRequestsFilterKeysMap();
    private readonly _lawyerRequestOrders = new LawyerRequestOrdersFilterKeysMap();
    private readonly _lawyerRequestFiles = new LawyerRequestFilesFilterKeysMap();
    private readonly _lawyerRequestAppeals = new LawyerRequestAppealsFilterKeysMap();
    private readonly _lawyerRequestComments = new LawyerRequestCommentsFilterKeysMap();
    private readonly _paymentClaim = new PaymentClaimFilterKeysMap();
    private readonly _lawyerCertificates = new LawyerCertificatesFilterKeysMap();
    private readonly _lawyerResponses = new LawyerResponsesFilterKeysMap();
    private readonly _lawyerStatusReasons = new LawyerStatusReasonsFilterKeysMap();
    private readonly _lawyerStatusHistory = new LawyerStatusHistoryFilterKeysMap();

    private readonly _passportData = new PassportDataFilterKeysMap();
    private readonly _personContacts = new PersonContactsFilterKeysMap();
    private readonly _lawyerRewards = new LawyerRewardsFilterKeysMap();
    private readonly _jobDataLog = new JobDataLogKeysMap();
    private readonly _apgmLog = new ApgmLogFilterKeysMap();
    private readonly _educations = new EducationsFilterKeysMap();
    private readonly _personAddresses = new PersonAddressesFilterKeysMap();
    private readonly _search = new SearchDataKeysMap();

    private readonly _judicialAuthority = new JudicialAuthorityFilterKeyMap();

    get campaign(): CampaignFilterKeysMap {
        return cloneDeep(this._campaign);
    }

    get members(): MembersFilterKeysMap {
        return cloneDeep(this._members);
    }

    get lawyerFormations(): LawyerFormationsFilterKeysMap {
        return cloneDeep(this._lawyerFormations);
    }

    get lawyersChambers(): LawyerChamberFilterKeysMap {
        return cloneDeep(this._lawyerChambers);
    }

    get lawyers(): LawyersFilterKeysMap {
        return cloneDeep(this._lawyers);
    }

    get lawyersOrganizations(): LawyersOrganizationFilterKeysMap {
        return cloneDeep(this._lawyersOrganisation);
    }

    get areas(): AreaFilterKeysMap {
        return cloneDeep(this._areas);
    }

    get districts(): DistrictFilterKeysMap {
        return cloneDeep(this._districts);
    }

    get litigationStages(): LitigationStagesFilterKeysMap {
        return cloneDeep(this._litigationStages);
    }

    get lawyerRequests(): LawyerRequestsFilterKeysMap {
        return cloneDeep(this._lawyerRequests);
    }

    get lawyerRequestOrders(): LawyerRequestOrdersFilterKeysMap {
        return cloneDeep(this._lawyerRequestOrders);
    }

    get lawyerRequestFiles(): LawyerRequestFilesFilterKeysMap {
        return cloneDeep(this._lawyerRequestFiles);
    }

    get lawyerRequestAppeals(): LawyerRequestAppealsFilterKeysMap {
        return cloneDeep(this._lawyerRequestAppeals);
    }

    get lawyerRequestComments(): LawyerRequestCommentsFilterKeysMap {
        return cloneDeep(this._lawyerRequestComments);
    }

    get paymentClaim(): PaymentClaimFilterKeysMap {
        return cloneDeep(this._paymentClaim);
    }

    get lawyerCertificates(): LawyerCertificatesFilterKeysMap {
        return cloneDeep(this._lawyerCertificates);
    }

    get lawyerResponses(): LawyerResponsesFilterKeysMap {
        return cloneDeep(this._lawyerResponses);
    }

    get lawyerStatusReasons(): LawyerStatusReasonsFilterKeysMap {
        return cloneDeep(this._lawyerStatusReasons);
    }
    get lawyerStatusHistory(): LawyerStatusHistoryFilterKeysMap {
        return cloneDeep(this._lawyerStatusHistory);
    }

    get passportData(): PassportDataFilterKeysMap {
        return cloneDeep(this._passportData);
    }
    get personContacts(): PersonContactsFilterKeysMap {
        return cloneDeep(this._personContacts);
    }

    get lawyerRewards(): LawyerRewardsFilterKeysMap {
        return cloneDeep(this._lawyerRewards);
    }
    get apgmLog(): ApgmLogFilterKeysMap {
        return cloneDeep(this._apgmLog);
    }

    get educations(): EducationsFilterKeysMap {
        return cloneDeep(this._educations);
    }
    get personAddresses(): PersonAddressesFilterKeysMap {
        return cloneDeep(this._personAddresses);
    }
    get search(): SearchDataKeysMap {
        return cloneDeep(this._search);
    }

    get judicialAuthority(): JudicialAuthorityFilterKeyMap {
        return cloneDeep(this._judicialAuthority);
    }
    get jobDataLog(): JobDataLogKeysMap {
        return cloneDeep(this._jobDataLog);
    }
}

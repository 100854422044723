import { TextMaskConfig } from 'angular2-text-mask';

/**
 * Возвращает конфигурацию маски ввода для компонента angular2-text-mask.
 *
 * Формат - DD.MM.YYYY - DD.MM.YYYY
 */
export function createDateRangeMask(): TextMaskConfig {
    const maskConfig = new TextMaskConfig();

    maskConfig.mask = [
        /[0-3]/,
        /\d/,
        '.',
        /[0-1]/,
        /\d/,
        '.',
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        '-',
        ' ',
        /[0-3]/,
        /\d/,
        '.',
        /[0-1]/,
        /\d/,
        '.',
        /[1-9]/,
        /\d/,
        /\d/,
        /\d/,
    ];

    maskConfig.guide = true;
    maskConfig.showMask = false;
    maskConfig.keepCharPositions = true;

    // дополнительная проверка вводимых данных и отсекание лишнего
    maskConfig.pipe = (conformedValue: string, config: TextMaskConfig) => {
        const dayStart = (conformedValue[0] || '') + (conformedValue[1] || '');
        const monthStart = (conformedValue[3] || '') + (conformedValue[4] || '');

        const dayEnd = (conformedValue[13] || '') + (conformedValue[14] || '');
        const monthEnd = (conformedValue[16] || '') + (conformedValue[17] || '');

        if (!isDayValid(dayStart)) {
            conformedValue = conformedValue[0] + '_' + conformedValue.substr(2);
        }

        if (!isMonthValid(monthStart)) {
            conformedValue = conformedValue.substr(0, 4) + '_' + conformedValue.substr(5);
        }

        if (!isDayValid(dayEnd)) {
            conformedValue = conformedValue.substr(0, 14) + '_' + conformedValue.substr(15);
        }

        if (!isMonthValid(monthEnd)) {
            conformedValue = conformedValue.substr(0, 17) + '_' + conformedValue.substr(18);
        }

        return conformedValue;
    };

    return maskConfig;
}

/**
 * Проверяет, что номер дня месяца верный (между 1 и 31 числом включительно)
 *
 * @param day Число месяца
 */
function isDayValid(day: string | number): boolean {
    if (/_/g.test(day + '')) {
        return true;
    }

    if (typeof day !== 'number') {
        day = +day;
    }

    return day && day > 0 && day <= 31;
}

/**
 * Проверяет, что номер дня месяца верный (между 1 и 12 числом включительно)
 *
 * @param month Число месяца
 */
function isMonthValid(month: string | number): boolean {
    if (/_/g.test(month + '')) {
        return true;
    }

    if (typeof month !== 'number') {
        month = +month;
    }

    return month && month > 0 && month <= 12;
}

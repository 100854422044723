import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewRef,
} from '@angular/core';
import { indexOf, pullAt, each, find, unionBy, xorBy, cloneDeep, every } from 'lodash';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { IInstance } from '../_imports';

import {
    IFileUploadModel,
    FileUploadModel,
    IFileInfo,
    IFileDataView,
    IFileUploaderOption,
    FileUploaderLoadState,
    IFileUploaderOnLoadedEventData,
    IFileUploaderOnDeleteEventData,
} from '../_infrastructure';
import { FileUploaderPrivateService, FileUploaderNotifierService } from '../file-uploader.service';

/**
 * @description load files by file ids for view, deelte them and upload them if nesesary
 *
 * @example
 * <app-file-uploader [fileUploaderOption]="fileUploaderOption" ></app-file-uploader>
 * @see {@link FileUploaderOption}
 *
 * ```typescript
  this.fileUploaderOption  = new FileUploaderOption();
  this.fileUploaderOption.multimpe = false;
  this.fileUploaderOption.fileInfo =[
   { id: "entytyGuidId",
     uiId: "generated unic id for instace"
   }
  ];
```
 *
 */
@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit, OnDestroy {
    @Input()
    public fileUploaderOption: IFileUploaderOption;
    public btnUploadContent: string;
    public deleteBtnTitle: string;
    public deleteBtnIcon: string;
    public dataSource = false;
    public allowAdd = false;
    public viewFiles: IFileDataView[];
    public showMaxFileSizeError = false;

    @ViewChild('fileUploadInput')
    private _fileUploadInput: ElementRef<HTMLInputElement>;
    private _locked = false;
    private _pendingFiles: IFileInfo[];
    private _files: IFileUploadModel[] = [];
    private _destructor = new Subject();
    private _comparerKey = 'id';
    private _initFiles: IFileDataView[];
    private _hasInitFiles = false;
    private _maxFileSize = 10485760;
    private get _inPending() {
        return this._pendingFiles && this._pendingFiles.length;
    }
    private get _input(): HTMLInputElement {
        return this._fileUploadInput.nativeElement;
    }

    public get disabled() {
        return this.fileUploaderOption.disabled;
    }
    public set disabled(value) {
        this.fileUploaderOption.disabled = value;
    }
    public get loading() {
        return this._locked;
    }
    public get deleteBtn() {
        return this.fileUploaderOption.deleteButton;
    }

    constructor(
        private _privateService: FileUploaderPrivateService,
        private _notifierService: FileUploaderNotifierService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {}

    public updateConfig() {
        if (
            this.fileUploaderOption.fileData &&
            this.fileUploaderOption.fileInfo &&
            this.fileUploaderOption.fileData.length === this.fileUploaderOption.fileInfo.length
        ) {
            this._hasInitFiles = true;
            this._initByFileData(this.fileUploaderOption);
        } else if (this.fileUploaderOption.fileInfo && this.fileUploaderOption.fileInfo.length) {
            this._hasInitFiles = true;
            this._initByFileInfo(this.fileUploaderOption);
        } else {
            this.allowAdd = true;
        }
    }

    public ngOnInit() {
        this.initFileUploader();
    }

    public ngOnDestroy(): void {
        this._destructor.next();
        this._destructor.complete();
    }
    public selectFile() {
        if (!this._inPending && !this._locked && !this.disabled) {
            this._fileUploadInput.nativeElement.click();
        }
    }

    initFileUploader() {
        const cfg = this.fileUploaderOption;

        if (!cfg) {
            return;
        }
        this.disabled = cfg.disabled;
        this.btnUploadContent = cfg.selectButton.icon + cfg.selectButton.text;
        this.deleteBtnTitle = cfg.deleteButton.text;
        this.deleteBtnIcon = cfg.deleteButton.icon;

        this._initInput(cfg);
        this._subscribeOnLoaded(cfg);
        this._subscribeOnDelete(cfg);
        this._subscribeToCommands(cfg);

        if (cfg.fileData && cfg.fileInfo && cfg.fileData.length === cfg.fileInfo.length) {
            this._hasInitFiles = true;
            this._initByFileData(cfg);
        } else if (cfg.fileData && cfg.fileInfo) {
        } else if (cfg.fileInfo && cfg.fileInfo.length) {
            this._hasInitFiles = true;
            this._initByFileInfo(cfg);
        } else {
            this.allowAdd = true;
        }
    }

    public delete(file: IFileDataView) {
        if (this._locked || this._inPending) {
            return;
        } else {
            this._locked = true;
            if (this.fileUploaderOption.localDelete) {
                this._notifierService.onDelete.emit({
                    fileInfo: file,
                    instanceId: this.fileUploaderOption.instanceId,
                });
                this._detectChanges();
            } else {
                this._privateService.delete(file).subscribe(deletedFileInfo => {
                    this._notifierService.onDelete.emit({
                        fileInfo: deletedFileInfo,
                        instanceId: this.fileUploaderOption.instanceId,
                    });
                    this._detectChanges();
                });
            }
        }
    }
    public getFileName(file: IFileDataView) {
        console.log(file);
        return file.name;
    }

    private _subscribeToCommands(cfg: IFileUploaderOption) {
        this._privateService.onDisable
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe(i => {
                if (!this.isCurrentInstance({ instanceId: i })) {
                    return;
                }
                this.showMaxFileSizeError = false;
                this.disabled = true;
                this._detectChanges();
            });
        this._privateService.onEnable
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe(i => {
                if (!this.isCurrentInstance({ instanceId: i })) {
                    return;
                }
                this.showMaxFileSizeError = false;
                this.disabled = false;
                this._detectChanges();
            });
        this._privateService.onReset
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe(i => {
                if (!this.isCurrentInstance({ instanceId: i })) {
                    return;
                }
                if (!this.disabled) {
                    if (this._hasInitFiles && this._initFiles && this._initFiles.length) {
                        this.viewFiles = cloneDeep(this._initFiles);
                    } else {
                        this.viewFiles = [];
                        this.allowAdd = true;
                    }
                }
                this.showMaxFileSizeError = false;
                this._detectChanges();
            });
    }

    private _initByFileInfo(cfg: IFileUploaderOption): void {
        this._locked = true;
        this._emitLoadingState(FileUploaderLoadState.loading);
        this._pendingFiles = this._privateService.copyAsFileInfo(cfg.fileInfo);
        this._tryCatch(() => {
            this._privateService
                .loadFiles(this._pendingFiles)
                .pipe(takeUntil(this._destructor))
                .subscribe(loadedViewFiles => {
                    this._notifierService.onLoaded.emit({
                        instanceId: cfg.instanceId,
                        loadedViewFiles: loadedViewFiles,
                    });
                    this._detectChanges();
                });
        });
    }
    private _initByFileData(cfg: IFileUploaderOption) {
        this._locked = true;
        this._emitLoadingState(FileUploaderLoadState.loading);
        this._tryCatch(() => {
            this._pendingFiles = this._privateService.copyAsFileInfo(cfg.fileInfo);
            const loadedViewFiles = this._privateService.mapToView(cfg.fileInfo, cfg.fileData);
            this._notifierService.onLoaded.emit({
                instanceId: cfg.instanceId,
                loadedViewFiles: loadedViewFiles,
            });
            // this._changeDetectorRef.detectChanges();
        });
    }

    private _initInput(cfg: IFileUploaderOption) {
        const input = this._input;
        input.type = 'file';
        input.name = 'fileUpload';
        input.multiple = cfg.multiple;
        input.accept = cfg.accept;
        input.onchange = (e: Event) => {
            if (e && e.srcElement === input && input.files && !this.disabled) {
                if (this._hasMaxInList(input.files)) {
                    if (!this.showMaxFileSizeError) {
                        this.showMaxFileSizeError = true;
                        this._detectChanges();
                    }
                } else {
                    if (this.showMaxFileSizeError) {
                        this.showMaxFileSizeError = false;
                        this._detectChanges();
                    }
                    this._onInputChange(input.files);
                }
            }
        };
    }

    private _subscribeOnDelete(cfg: IFileUploaderOption) {
        this._notifierService.onDelete
            .asObservable()
            .pipe(
                takeUntil(this._destructor),
                finalize(() => {
                    this._emitLoadingState(FileUploaderLoadState.finish);
                }),
            )
            .subscribe((data: IFileUploaderOnDeleteEventData) => {
                // check is current instance
                if (!this.isCurrentInstance(data)) {
                    return;
                }

                const deletedFileInfo = data.fileInfo;
                const cfgInfo = cfg.fileInfo;
                if (cfgInfo) {
                    const fileInfo = find(cfgInfo, f => {
                        return f.uiId === deletedFileInfo.uiId;
                    });
                    if (fileInfo) {
                        pullAt(cfgInfo, [indexOf(cfgInfo, fileInfo)]);
                    }
                }
                if (this.viewFiles && this.viewFiles.length) {
                    const vfs = this.viewFiles;
                    const hasView = find(vfs, i => {
                        return i.id === data.fileInfo.id;
                    });
                    if (hasView) {
                        const newView = xorBy(vfs, [deletedFileInfo], this._comparerKey) as IFileDataView[];
                        this.viewFiles = newView;
                        if (!newView.length) {
                            if (!this.allowAdd) {
                                this.allowAdd = true;
                            }
                        } else {
                            if (cfg.multiple && !this.allowAdd) {
                                this.allowAdd = true;
                            } else if (!cfg.multiple && this.allowAdd) {
                                this.allowAdd = false;
                            }
                        }
                    }
                    if (this._locked) {
                        this._locked = false;
                    }
                }
                this._detectChanges();
            });
    }
    private _subscribeOnLoaded(cfg: IFileUploaderOption) {
        this._notifierService.onLoaded
            .asObservable()
            .pipe(takeUntil(this._destructor))
            .subscribe((data: IFileUploaderOnLoadedEventData) => {
                if (data.instanceId !== this.fileUploaderOption.instanceId) {
                    return;
                }
                const newFiles = data.loadedViewFiles;
                const pfs = this._pendingFiles;
                if (pfs && pfs.length && newFiles && pfs.length === newFiles.length) {
                    const file = newFiles[0];
                    const hasPendingFile = !!find(pfs, f => {
                        return f.id === file.id;
                    });
                    if (hasPendingFile) {
                        const viewFiles = newFiles;
                        const currentView = this.viewFiles || [];
                        const outputView = unionBy(currentView, viewFiles, this._comparerKey);
                        const newPending = xorBy(pfs as IFileInfo[], viewFiles as IFileInfo[], this._comparerKey);

                        this._pendingFiles = newPending as IFileUploadModel[];
                        if (cfg.multiple) {
                            this.viewFiles = outputView;
                            if (!this.allowAdd) {
                                this.allowAdd = true;
                            }
                        } else {
                            this.viewFiles = [outputView[0]];
                            if (this.allowAdd) {
                                this.allowAdd = false;
                            }
                        }
                        if (!this._inPending) {
                            this._locked = false;
                            if (this._hasInitFiles && !this._initFiles) {
                                this._initFiles = cloneDeep(this.viewFiles);
                            }
                            if (!this.dataSource) {
                                this.dataSource = true;
                            }
                            this._emitLoadingState(FileUploaderLoadState.finish);
                        }
                    }
                } else if (newFiles.length) {
                    this.dataSource = true;
                    this.allowAdd = cfg.multiple;
                    this._locked = false;
                    this._emitLoadingState(FileUploaderLoadState.finish);
                } else {

                    this.dataSource = true;
                    this.allowAdd = cfg.multiple;
                    this._locked = false;
                    this._emitLoadingState(FileUploaderLoadState.finish);

                    // this._emitLoadingState(FileUploaderLoadState.error);

                }
                this._pendingFiles = null;
                this._detectChanges();
            });
    }

    private _onInputChange(files: FileList) {
        if (this._locked) {
            return;
        }
        this._files = [];
        if (files && files.length) {
            const fs = [];
            each(files, (file, index) => {
                const fModel = new FileUploadModel();
                fModel.index = index;
                fModel.data = file;
                if (this.fileUploaderOption.name) {
                    fModel.name = this.fileUploaderOption.name;
                }
                fs.push(fModel);
            });
            this._files = fs;
            this._uploadFiles();
        }
    }

    private _uploadFiles() {
        this._input.value = '';
        if (this._files.length > 1) {
            this._locked = true;
            const files = this._files;
            this._files = [];
            this._pendingFiles = files;
            this._tryCatch(() => {
                this._emitLoadingState(FileUploaderLoadState.loading);
                this._privateService.uploadBulk(files).subscribe(loadedViewFiles => {
                    this._notifierService.onLoaded.emit({
                        instanceId: this.fileUploaderOption.instanceId,
                        loadedViewFiles: loadedViewFiles,
                    });
                    this._detectChanges();
                });
            });
        } else if (this._files.length === 1) {
            this._locked = true;
            const files = this._files;
            const file = files[0];
            this._files = [];
            this._pendingFiles = [file];
            this._tryCatch(() => {
                this._emitLoadingState(FileUploaderLoadState.loading);
                this._privateService.upload(file).subscribe(loadedViewFiles => {
                    this._notifierService.onLoaded.emit({
                        instanceId: this.fileUploaderOption.instanceId,
                        loadedViewFiles: loadedViewFiles,
                    });
                    this._detectChanges();
                });
            });
        }
    }

    private _emitLoadingState(state: FileUploaderLoadState) {
        this._notifierService.onLoadStateChange.emit({
            instanceId: this.fileUploaderOption.instanceId,
            loadingState: state,
        });
        this._detectChanges();
    }

    private _tryCatch(action: () => void): void {
        try {
            action();
        } catch (error) {
            this._locked = false;
            this._emitLoadingState(FileUploaderLoadState.error);
            // this._changeDetectorRef.detectChanges();
            throw error;
        } finally {
            this._detectChanges();
        }
    }

    private isCurrentInstance(info: IInstance) {
        return info && info.instanceId === this.fileUploaderOption.instanceId;
    }
    private _detectChanges(): void {
        if (!this._destructor.isStopped && !(<ViewRef>this._changeDetectorRef).destroyed) {
            this._changeDetectorRef.detectChanges();
        }
    }
    private _hasMaxInList(fileList: FileList) {
        return every(fileList, i => {
            console.log('_hasMaxInList', {
                fileList,
                i,
                isMore: this._maxFileSize <= i.size,
                _maxFileSize: this._maxFileSize,
            });
            return this._maxFileSize <= i.size;
        });
    }
}

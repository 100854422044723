import * as moment from 'moment-timezone';
import { AppConsts } from '@shared/AppConsts';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
var pathTrimRight = function (value) {
    return value.replace(/\/$/gm, '');
};
var ɵ0 = pathTrimRight;
var getAbsoluteUrl = function (url) {
    var a = document.createElement('a');
    getAbsoluteUrl = function (url) {
        a.href = url;
        return pathTrimRight(a.href);
    };
    return getAbsoluteUrl(url);
};
var ɵ1 = getAbsoluteUrl;
var AppPreBootstrap = /** @class */ (function () {
    function AppPreBootstrap() {
    }
    AppPreBootstrap.run = function (appKey, callback) {
        AppPreBootstrap.getApplicationConfig(appKey, function () {
            AppPreBootstrap.getUserConfiguration(callback);
        });
    };
    AppPreBootstrap.bootstrap = function (moduleType, compilerOptions) {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    };
    AppPreBootstrap.getApplicationConfig = function (appKey, callback) {
        return abp
            .ajax({
            url: '/assets/apps.json',
            method: 'GET',
            headers: {
                'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                '__tenant': AppConsts.defaultTenantUid,
            },
        })
            .done(function (result) {
            var appSettings = result[appKey];
            console.log('appSettings', {
                appSettings: appSettings,
                environment: environment,
            });
            AppConsts.appBaseUrl = getAbsoluteUrl(appSettings.appBaseUrl);
            var stand = '';
            if (environment.stand) {
                stand = '.' + environment.stand;
            }
            return abp
                .ajax({
                url: AppConsts.appBaseUrl + ("/assets/appconfig" + stand + ".json"),
                method: 'GET',
                headers: {
                    'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                    '__tenant': AppConsts.defaultTenantUid,
                },
            })
                .done(function (result) {
                // Здесь может приходить другой url, поэтому используем значение из apps.json выше
                // AppConsts.appBaseUrl = getAbsoluteUrl(result.appBaseUrl);
                AppConsts.remoteServiceBaseUrl = getAbsoluteUrl(result.remoteServiceBaseUrl);
                AppConsts.oidcBaseUrl = result.oidcBaseUrl;
                AppConsts.oidcClientId = result.oidcClientId;
                AppConsts.oidcClientSecret = result.oidcClientSecret;
                AppConsts.oidcClientScope = result.oidcClientScope;
                callback();
            });
        });
    };
    AppPreBootstrap.getCurrentClockProvider = function (currentProviderName) {
        if (currentProviderName === 'unspecifiedClockProvider') {
            return abp.timing.unspecifiedClockProvider;
        }
        if (currentProviderName === 'utcClockProvider') {
            return abp.timing.utcClockProvider;
        }
        return abp.timing.localClockProvider;
    };
    AppPreBootstrap.getUserConfiguration = function (callback) {
        var _this = this;
        return abp
            .ajax({
            url: AppConsts.remoteServiceBaseUrl + '/AbpUserConfiguration/GetAll',
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + abp.auth.getToken(),
                '.AspNetCore.Culture': abp.utils.getCookieValue('Abp.Localization.CultureName'),
                'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                '__tenant': AppConsts.defaultTenantUid,
            },
        })
            .done(function (result) {
            $.extend(true, abp, result);
            abp.clock.provider = _this.getCurrentClockProvider(result.clock.provider);
            moment.locale(abp.localization.currentLanguage.name);
            if (abp.clock.provider.supportsMultipleTimezone) {
                moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
            }
            callback();
        });
    };
    return AppPreBootstrap;
}());
export { AppPreBootstrap };
export { ɵ0, ɵ1 };

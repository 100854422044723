import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from './_imports';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';

@NgModule({
    imports: [CommonModule, SharedMaterialModule, RouterModule],
    exports: [DefaultLayoutComponent],
    declarations: [DefaultLayoutComponent],
})
export class DefaultLayoutModule {}

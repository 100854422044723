import {
    Input, 
    EventEmitter, 
    Output,
    OnInit,
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef } from '@angular/core';
import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';

import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

export enum LinkBarLinkType {
    submenu = 'submenu',
    rootmenu = 'rootmenu',
    router = 'router',
    direct = 'direct',
    logout = 'logout',
}

export interface ILinkBarLinkModel {
    subLinks?: ILinkBarLinkModel[] | null;
    subOpened?: boolean | undefined;
    isSubMenu?: boolean | undefined;
    routePath?: string | undefined;
    iconName?: string | undefined;
    title: string;
    type: LinkBarLinkType;
    onClick?(event: Event): void;
}


@Component({
    selector: 'link-bar',
    templateUrl: './link-bar.component.html',
    styleUrls: ['./link-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LinkBarComponent implements OnInit {

    @Output() logoutClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    _linkBarLinks: ILinkBarLinkModel[] = [];
    @Input() set linkBarLinks(newLinkBarLinks: ILinkBarLinkModel[]) {
        newLinkBarLinks.forEach((item: ILinkBarLinkModel) => {
            const i: ILinkBarLinkModel = this._linkBarLinks.find((i2) => i2.title === item.title);
            if (i && i.type === LinkBarLinkType.rootmenu) {
                item.subOpened = i.subOpened;
            }
        });
        this._linkBarLinks = newLinkBarLinks;
    }

    constructor(
        private _sanitizer: DomSanitizer,
        private _changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {

        /*
        this.linkBarLinks = [

            {
                subLinks: [
                    {
                        subLinks: null,
                        subOpened: false,
                        isSubMenu: true,
                        routePath: 'dashboard',
                        iconName: 'home',
                        title: 'Дашбоард',
                        type: LinkBarLinkType.router
                    },
                    {
                        subLinks: null,
                        subOpened: false,
                        isSubMenu: true,
                        routePath: 'http://www.advokatymoscow.ru/news/detail.php?login=yes&ID=3238&clear_cache=Y',
                        iconName: 'contact_support',
                        title: 'Вопросы по работе',
                        type: LinkBarLinkType.direct
                    },
                    {
                        subLinks: null,
                        subOpened: false,
                        isSubMenu: true,
                        routePath: '',
                        iconName: 'exit_to_app',
                        title: 'Выход',
                        type: LinkBarLinkType.logout
                    }
                ],
                subOpened: true,
                isSubMenu: false,
                routePath: '',
                iconName: 'home',
                title: 'Тест группы',
                type: LinkBarLinkType.rootmenu
            },

            {
                subLinks: null,
                subOpened: false,
                isSubMenu: false,
                routePath: 'dashboard',
                iconName: 'home',
                title: 'Дашбоард',
                type: LinkBarLinkType.router
            },
            {
                subLinks: null,
                subOpened: false,
                isSubMenu: false,
                routePath: 'http://www.advokatymoscow.ru/news/detail.php?login=yes&ID=3238&clear_cache=Y',
                iconName: 'contact_support',
                title: 'Вопросы по работе',
                type: LinkBarLinkType.direct
            },
            {
                subLinks: null,
                subOpened: false,
                isSubMenu: false,
                routePath: '',
                iconName: 'exit_to_app',
                title: 'Выход',
                type: LinkBarLinkType.logout
            }
        ];
        */
    }

    get links(): ILinkBarLinkModel[] {
        const a: ILinkBarLinkModel[] = [];

        this._linkBarLinks.forEach((item: ILinkBarLinkModel) => {
            a.push(item);
            if (item.subLinks && item.subLinks.length && item.subOpened) {
                item.subLinks.forEach(subItem => a.push(subItem));
            }
        });

        return a;
    }

    logout($event) {
        const item = this.links.find((i: ILinkBarLinkModel) => {
            return i.type === LinkBarLinkType.logout;
        });
        if (item) {
            item.onClick($event);
        }
    }

    safeUrl(link: ILinkBarLinkModel): SafeUrl {
        return this._sanitizer.bypassSecurityTrustUrl(link.routePath);
    }

    routerUrl(link: ILinkBarLinkModel): string {
        return link.routePath;
    }

    toggleMenuGroup(link: ILinkBarLinkModel): void {
        const node: ILinkBarLinkModel =
            this.links.find((item: ILinkBarLinkModel) => item.title === link.title);
        node.subOpened = !node.subOpened;
    }


}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryOptionsDto } from '../../service-proxies';
import { IBasicAppServiceProxy } from "../intrfaces/IBasicAppServiceProxy";
import { IApmReferenceAppServiceProxy } from '../intrfaces/IApmReferenceAppServiceProxy';

export class ApmReferenceToBasicServiceProxyAdapter<
    TDto extends {
        id: string;
    },
    TProxy extends IApmReferenceAppServiceProxy<TDto>
> implements IBasicAppServiceProxy<TDto> {
    constructor(private _proxy: TProxy) {
    }
    get(id: string): Observable<TDto> {
        return this._proxy.get(id);
    }
    getMany(idList: string[]): Observable<{
        [key: string]: TDto;
    }> {
        return this._proxy.many(idList);
    }
    create(dto: TDto): Observable<TDto> {
        throw new TypeError("Not supported.");
    }
    update(dto: TDto): Observable<TDto> {
        throw new TypeError("Not supported.");
    }
    save(dto: TDto): Observable<TDto> {
        throw new TypeError("Not supported.");
    }
    delete(id: string): Observable<void> {
        throw new TypeError("Not supported.");
    }
    queryPage(pageNumber: number, pageSize: number, countTotal: boolean, queryOptions: QueryOptionsDto): Observable<any> {
        return this._proxy.page(pageNumber, pageSize, countTotal, queryOptions);
    }
}

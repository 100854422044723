import { Injector } from '@angular/core';
import { AuthStorageService } from './auth-storage.service';
import * as i0 from "@angular/core";
var AuthForeignService = /** @class */ (function () {
    function AuthForeignService(_injector) {
        this._injector = _injector;
        this._key = '60B5E5C4-32A0-44A3-BD95-55694D7E75F6';
        this._defaultExpire = 86400; // sec (1 day)
        this._storageService = _injector.get(AuthStorageService);
    }
    AuthForeignService.prototype.isForeign = function () {
        return !!this._storageService.getStorage(this._key);
    };
    AuthForeignService.prototype.setForeign = function (expire) {
        expire = expire || this._defaultExpire;
        this._storageService.setStorage(this._key, true, expire);
    };
    AuthForeignService.prototype.deleteForeign = function () {
        this._storageService.removeStorage(this._key);
    };
    AuthForeignService.ngInjectableDef = i0.defineInjectable({ factory: function AuthForeignService_Factory() { return new AuthForeignService(i0.inject(i0.INJECTOR)); }, token: AuthForeignService, providedIn: "root" });
    return AuthForeignService;
}());
export { AuthForeignService };

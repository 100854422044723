/**
 * Направление сортировки, передаваемые сортировщиком Angular Material (matSort)
 */
export enum SortDirectionEnum {
    none = '',
    asc = 'asc',
    desc = 'desc',
}
export class SortParams {
    /**
     * Имя поля, по которому идет сортировка
     */
    public active: string;

    /**
     * Направление сортировки.
     * Может иметь три значения: "", "asc", "desc"
     */
    public direction: SortDirectionEnum;
}

import { APP_REGEXP } from './regexp';
var RegistryNumberFormatter = /** @class */ (function () {
    function RegistryNumberFormatter() {
    }
    RegistryNumberFormatter.prototype.transformForForm = function (ctrl, detectChanges) {
        var val = (ctrl.value + '').trim();
        var checkVal = this.transformToViewValue(val);
        if (val && (!checkVal || checkVal.length < 3)) {
            if (val !== checkVal) {
                ctrl.patchValue(checkVal);
                detectChanges();
            }
        }
        else if (val.length >= 3) {
            var checkVal2 = this.transformToViewValue(checkVal, true);
            if (val !== checkVal2) {
                ctrl.patchValue(checkVal2);
                detectChanges();
            }
        }
    };
    RegistryNumberFormatter.prototype.transformToViewValue = function (registryNumber, normalized) {
        if (normalized === void 0) { normalized = false; }
        var val = normalized ? registryNumber : this.normalize(registryNumber);
        if (val && val.length >= 3) {
            return val.replace(/^(\d{2})(\d+)/, '$1/$2');
        }
        else if (val && val.length === 2) {
            return val.replace(/^(\d{2})/, '$1/');
        }
        return val;
    };
    RegistryNumberFormatter.prototype.normalize = function (registryNumber) {
        registryNumber = (registryNumber + '').trim();
        return registryNumber.replace(APP_REGEXP.replaceAllNotNumbers, '');
    };
    return RegistryNumberFormatter;
}());
export { RegistryNumberFormatter };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LocalStorageBaseService } from './local-storage-base.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep, find } from 'lodash';
import { environment } from '../../environments/environment';
import { DataFilterServiceProxy, QueryOptionsDto } from '../service-proxies';
import * as i0 from "@angular/core";
import * as i1 from "../service-proxies/service-proxies";
var DataFilterRepositoryService = /** @class */ (function (_super) {
    __extends(DataFilterRepositoryService, _super);
    function DataFilterRepositoryService(_proxy) {
        var _this = _super.call(this, 'DataFilterRepositoryService') || this;
        _this._proxy = _proxy;
        _this._initialized = false;
        _this._lsKey = _this._className;
        var prodTime = 30 * 24 * 60 * 60;
        var devTime = 60;
        _this._defaultExpires = environment.production ? prodTime : devTime;
        // tslint:disable-next-line: no-use-before-declare
        _this.keys = new DataKeyFilters();
        return _this;
    }
    DataFilterRepositoryService.prototype.init = function () {
        return this._fillRepository();
    };
    /**
     *
     * @param filterId guid DataFilterDto.id
     */
    DataFilterRepositoryService.prototype.getFilterById = function (filterId) {
        return this._getList().pipe(map(function (list) {
            var item = find(list, function (i) {
                return i.id === filterId;
            });
            if (item) {
                return cloneDeep(item);
            }
            return null;
        }));
    };
    DataFilterRepositoryService.prototype._fillRepository = function () {
        if (this._initialized) {
            return of(true);
        }
        var ls = this.getStorage(this._lsKey);
        if (ls) {
            this._setListByString(ls, true);
            this._initialized = true;
            return of(true);
        }
        return this._getAllRequest().pipe(map(function (i) {
            return !!i;
        }));
    };
    DataFilterRepositoryService.prototype._getList = function () {
        var _this = this;
        if (this._initialized) {
            return of(this._list);
        }
        return this._fillRepository().pipe(map(function (suc) {
            if (suc) {
                return _this._list;
            }
            throw new Error('DataFilterRepositoryService.isEmpty');
        }));
    };
    DataFilterRepositoryService.prototype._setListByString = function (list, skipSave) {
        this._setList(JSON.parse(list), skipSave);
    };
    DataFilterRepositoryService.prototype._setList = function (list, skipSave) {
        this._list = list;
        if (!skipSave) {
            this.setStorage(this._lsKey, this._list, this._defaultExpires);
        }
    };
    DataFilterRepositoryService.prototype._getAllRequest = function () {
        var _this = this;
        return this._proxy
            .queryPage(1, 100, false, new QueryOptionsDto({
            filterValues: [],
            sortOptions: [],
        }))
            .pipe(map(function (i) {
            if (i && i.data && i.data.length) {
                _this._setList(i.data, false);
            }
            return i.data;
        }));
    };
    DataFilterRepositoryService.ngInjectableDef = i0.defineInjectable({ factory: function DataFilterRepositoryService_Factory() { return new DataFilterRepositoryService(i0.inject(i1.DataFilterServiceProxy)); }, token: DataFilterRepositoryService, providedIn: "root" });
    return DataFilterRepositoryService;
}(LocalStorageBaseService));
export { DataFilterRepositoryService };
var CampaignFilterKeysMap = /** @class */ (function () {
    function CampaignFilterKeysMap() {
        this.campaignFileDataFilterId = '7E4D98E9-A273-43AE-8CAF-EB8BEE136D6F';
    }
    return CampaignFilterKeysMap;
}());
export { CampaignFilterKeysMap };
var MembersFilterKeysMap = /** @class */ (function () {
    function MembersFilterKeysMap() {
        this.searchTextDataFilterId = 'dae55074-75aa-4823-b52a-996ed10b128d';
        this.genderDataFilterId = 'f66c9929-7b28-4521-b0f6-294da9c43acf';
        this.birthdayDataFilterId = '8d5cb1ad-c410-4f05-be45-0a1cac6cb7d5';
        this.searchRegistryNumberDataFilterId = '025CA724-5771-406D-8D0F-22674FF3AB6E';
        this.searchByFioDataFilterId = '5f788c1d-1771-4544-9f16-79fd986ec7d8';
    }
    return MembersFilterKeysMap;
}());
export { MembersFilterKeysMap };
var LawyerFormationsFilterKeysMap = /** @class */ (function () {
    function LawyerFormationsFilterKeysMap() {
        this.searchByNameDataFilterId = '46CAA21B-15AC-4D2A-B365-D44771CEE539';
        this.searchTextDataFilterId = 'aab83642-07f8-4058-9aa9-2536de0e60ff';
        this.membersCountDataFilterId = '2991195f-3f9c-4ff4-959e-f493233cbc92';
        this.participationTypeDataFilterId = '245689b6-5c0b-4c93-8936-3a63d5fe4022';
    }
    return LawyerFormationsFilterKeysMap;
}());
export { LawyerFormationsFilterKeysMap };
var LawyerChamberFilterKeysMap = /** @class */ (function () {
    function LawyerChamberFilterKeysMap() {
        this.SearchByNameDataFilterId = '0B1B8443-9DD9-414D-9175-54B41B433069';
        this.SearchByCodeFilterId = '0CB03CC2-2CEE-4380-A390-19A24A8B7848';
    }
    return LawyerChamberFilterKeysMap;
}());
export { LawyerChamberFilterKeysMap };
var AreaFilterKeysMap = /** @class */ (function () {
    function AreaFilterKeysMap() {
        this.JudicialAreaDataFilterId = '671EBC80-3A06-45F9-BB19-E616D510E189';
    }
    return AreaFilterKeysMap;
}());
export { AreaFilterKeysMap };
var DistrictFilterKeysMap = /** @class */ (function () {
    function DistrictFilterKeysMap() {
        this.DistrictByAreaDataFilterId = 'BB1DEC32-A0B0-46E0-A8F6-B8C9B89C66EA';
    }
    return DistrictFilterKeysMap;
}());
export { DistrictFilterKeysMap };
var LawyersFilterKeysMap = /** @class */ (function () {
    function LawyersFilterKeysMap() {
        this.lawyerParticipationDataFilterId = '536B50D1-2237-4ADF-828D-9F7A0157D19D';
        this.lawyerFileDataFilterId = '9CEB862F-5D08-40FC-89C0-26C49399BBED';
        this.lawyerWorkingDistrictDataFilterId = '9770C868-050B-4EBB-8A6C-3AE089A03B14';
        this.workingSpecializationDataFilterId = '2EC6440A-8225-4AEE-A857-98258CB03F59';
        this.lawyerLitigationTypeDataFilterId = 'B3B7FE44-0D77-40CB-AD7A-DE9D335FEF44';
        this.lawyerPersonIdsDataFilterId = '2BAF2BDA-0390-4B23-AC35-467D8053AB43';
        this.lawyerQuickSearchFullNameDataFilterId = '96914EFD-2622-4727-9F54-F2DE800A2ACF';
        this.lawyerQuickSearchRegistryNumberDataFilterId = '36CBDC62-8624-4020-AF5B-C4C4EC78036F';
        this.lawyerQuickSearchStatisticFullNameDataFilterId = 'B9D74E34-3159-49EA-B6ED-3CFB047578D6';
        this.lawyerQuickSearchStatisticRegistryNumberDataFilterId = 'D4C30FE8-3390-4033-BDCC-0FDD70848216';
        this.lawyerQuickSearchStatisticWorkingDistrictDataFilterId = '987F3BD3-3A39-4418-B785-1B7AEE1BA135';
        this.lawyerQuickSearchStatisticParticipationDataFilterId = 'A8997BE0-97C2-4B16-99F5-2CFB10F98947';
        this.lawyerLawyerCommentDataFilterId = '469F555D-6193-4D70-B21D-CEC8771E6AAB';
        this.lawyerQuickSearchStatisticQuickSearchDataFilterId = 'D8BC0D7D-CE06-40D7-A0CE-6FB1AD5013B3';
        this.lawyerQuickSearchStatisticEmailDataFilterId = '10D1A82D-8F5F-4822-80E0-E3B3A4AF9356';
        this.lawyerQuickSearchStatisticPhoneDataFilterId = '43621DBC-EEB5-449C-80D3-FCBB85582E5D';
    }
    return LawyersFilterKeysMap;
}());
export { LawyersFilterKeysMap };
var LawyersOrganizationFilterKeysMap = /** @class */ (function () {
    function LawyersOrganizationFilterKeysMap() {
        this.SearchByName = 'FC6738A0-48EF-41A4-8B00-961BC1CC92DD';
        this.SearchByNameLawyerFormations = '98B92028-87D2-4931-8730-B5D44983EDA8';
        this.SearchByFederationSubjectCode = '5D4980D5-86DF-477D-92A9-51FAA56E1922';
        this.SearchByType = '80D26986-3707-4A46-97CA-507502DA05F5';
        this.SearchByHeadName = 'F2E1E0C5-B8DA-4FEE-B9B4-3694A5027630';
    }
    return LawyersOrganizationFilterKeysMap;
}());
export { LawyersOrganizationFilterKeysMap };
var LitigationStagesFilterKeysMap = /** @class */ (function () {
    function LitigationStagesFilterKeysMap() {
        this.litigationStageDataFilterId = 'C7D705D1-A4FE-4B34-B18D-BA414B855B4C';
    }
    return LitigationStagesFilterKeysMap;
}());
export { LitigationStagesFilterKeysMap };
var LawyerRequestsFilterKeysMap = /** @class */ (function () {
    function LawyerRequestsFilterKeysMap() {
        this.lawyerRequestStatusDataFilterId = '9BE5BE34-B864-470E-B4E6-B1E357DBA0FD';
        this.lawyerRequestInitiatorNameFilterId = '83CB7269-E95F-4ACB-99F8-04BBF64ADA12';
        this.lawyerRequestInitiatorNameStrictFilterId = '22359CD3-19F4-4CD5-BE1C-5FE992E22492';
        this.lawyerRequestClientNameFilterId = '5292F9DC-95B4-4AD1-A1B1-8016A4CFBA3A';
        this.lawyerRequestClientNameStrictFilterId = 'B7C163F5-7318-4476-AF5A-44E5CF47B73D';
        /**
         * LawyerRequest.DocNumber
         */
        this.lawyerRequestDocNumberFilterId = 'A493C0B9-F3CD-480D-B765-BCD567D0A616';
        this.lawyerRequestDocNumberEntryFilterId = 'D8C07BD4-51BA-470F-A350-B10A4EF08C9C';
        this.lawyerRequestCreationTimeFilterId = '70EC240A-8428-4FAC-A022-A65FA0B71C96';
        this.lawyerRequestIssueHearingDateFilterId = 'F097B4B2-9662-4794-A276-8CAC73497359';
        this.lawyerRequestInitiatorPersonPrimaryPhoneFilterId = 'D63EB466-39EB-40F6-B9DA-662AC1124C58';
        this.lawyerRequestInitiatorWorkingPositionDataFilterId = '888A4243-1F67-4AD9-8320-407C8842D60F';
        this.lawyerRequestIssueQualificationDataFilterId = '5838BA95-6989-4AE0-9F45-5AC6544C29C0';
        this.lawyerRequestCourtOfInquiryNameDataFilterId = 'CC62D0BB-7B20-467B-B492-D73EDACF559E';
        this.lawyerRequestCourtOfInquiryNameStrictDataFilterId = '1E80CE56-79E2-4A77-96E7-B342A06201CB';
        this.lawyerRequestIssueDocNumberDataFilterId = '2157BD6C-F972-4688-8DEF-918487452001';
        this.lawyerRequestInitiatorPersonIdsDataFilterId = '725FD5E6-4245-4E8F-AEF2-C7B070D93FA7';
        this.lawyerRequestLawyerFullNameDataFilterId = '8B4CED03-4B11-48AD-8AB6-34EED9E44C5D';
        this.lawyerRequestIdsDataFilterId = '22AF678B-9E3F-46F1-804E-2628F716FB73';
        this.lawyerRequestWithLawyerIdDataFilterId = '30ED996E-387A-4D41-8FD2-54CD8A7AF9E1';
        this.lawyerRequestInitiatorIdsDataFilterId = '5AF881B8-6692-4B61-97F0-85084C6A0F5F';
        this.lawyerRequestLawyerRegistryCodeDataFilterId = '712C9FE6-EA30-4982-90A1-9E0BD06E7572';
        this.lawyerRequestLawyerPrimaryPhoneDataFilterId = '45C94D4E-7CEF-457D-98B5-D68534AF3A75';
        this.lawyerRequestLawyerPrimaryEmailDataFilterId = 'D6D834BD-3930-4489-9E0B-E0987F6F5728';
        this.lawyerRequestInitiatorPersonPrimaryEmailDataFilterId = '8D8A78A8-DD6E-4542-A594-48AB84C9B1E5';
        this.lawyerRequestIssueHearingAddressDataFilterId = '1EB38E6B-9622-438B-9FE9-891D9C978982';
        this.lawyerRequestLawyerSelectedTimeDataFilterId = '373F6394-954E-4A1D-8FDF-75DC2E5555EB';
        // Фильтры для альтернативного метода загрузки списка заявок
        this.altLawyerRequestDocNumberDataFilterId = '170DF8CB-CFEC-46FA-88AE-0A82B18D7D26';
        this.altLawyerRequestDocNumberLikeDataFilterId = '144E1DD3-0A91-4354-BFB0-B7B93FC183E0';
        this.altLawyerRequestCreationTimeDataFilterId = 'B74F141E-F3FD-4C8D-83F3-5B1A04E6E893';
        this.altLawyerRequestInitiatorNameLikeDataFilterId = 'EAB3AE1C-248E-4EA2-A950-81F6270B49F4';
        //
        this.altLawyerRequestLawyerFullNameDataFilterId = 'A09CE3EA-48B0-4A71-9DCB-158D22432000';
        //
        this.altLawyerRequestLawyerSelectedTimeDataFilterId = '5552F7D3-7D57-41DD-BDCC-7E7587BF6A30';
        this.altLawyerRequestClientNameLikeDataFilterId = '74E2E533-31E4-49B6-ABC8-288F13C928CA';
        //
        this.altLawyerRequestCourtOfInquiryNameLikeDataFilterId = '3598DAC9-87A7-42D8-88E1-685179DD2DCB';
        //
        this.altLawyerRequestIssueHearingDateDataFilterId = 'E277CD94-7EC9-4BCF-8295-8115B1955D0A';
        this.altLawyerRequestIssueHearingAddressDataFilterId = 'F9D8B045-53F9-49B0-BA54-9135B6BC49B6';
        //
        this.altLawyerRequestStatusDataFilterId = '9B7914D1-2A7B-4150-99F3-F2C6D2AC35C7';
        this.altLawyerRequestAppointmentTypeDataFilterId = 'C56036A5-FA71-48C2-9D3E-BA63904CBF13';
        this.altLawyerRequestInitiatorPersonPrimaryMailDataFilterId = '788EC08E-9B1F-4F4D-9708-B6AE61A0F3AA';
        this.altLawyerRequestInitiatorPersonPrimaryPhoneDataFilterId = '0D8299BA-B878-4B53-BAD6-61D2F529D319';
        this.altLawyerRequestLawyerPrimaryMailDataFilterId = 'BFF67A72-C17B-4675-B02C-192BCB22C257';
        this.altLawyerRequestLawyerPrimaryPhoneDataFilterId = '1981B05C-1980-47A4-AF16-9F104469BF97';
    }
    return LawyerRequestsFilterKeysMap;
}());
export { LawyerRequestsFilterKeysMap };
var LawyerRequestOrdersFilterKeysMap = /** @class */ (function () {
    function LawyerRequestOrdersFilterKeysMap() {
        this.lawyerRequestDataFilterId = '478A2B5C-A8D3-4FE6-9E6F-81B36B332F8C';
    }
    return LawyerRequestOrdersFilterKeysMap;
}());
export { LawyerRequestOrdersFilterKeysMap };
var LawyerRequestFilesFilterKeysMap = /** @class */ (function () {
    function LawyerRequestFilesFilterKeysMap() {
        this.lawyerRequestDataFilterId = 'B0DCEE44-4A14-4658-BCE3-853911035BFE';
        this.changeReasonDataFilterId = '070AE896-4DF3-4045-8FD0-B59EED0C3E5F';
    }
    return LawyerRequestFilesFilterKeysMap;
}());
export { LawyerRequestFilesFilterKeysMap };
var LawyerRequestAppealsFilterKeysMap = /** @class */ (function () {
    function LawyerRequestAppealsFilterKeysMap() {
        this.lawyerRequestDataFilterId = 'D86B7309-1696-4A7B-857F-7F40A6F31417';
    }
    return LawyerRequestAppealsFilterKeysMap;
}());
export { LawyerRequestAppealsFilterKeysMap };
var LawyerRequestCommentsFilterKeysMap = /** @class */ (function () {
    function LawyerRequestCommentsFilterKeysMap() {
        this.lawyerRequestDataFilterId = '93C4B1C9-2662-4DFE-B1A3-85C508F0E937';
        this.commentTypeDataFilterId = '832953AF-1DFF-409D-AAFA-ADE66E1A475B';
    }
    return LawyerRequestCommentsFilterKeysMap;
}());
export { LawyerRequestCommentsFilterKeysMap };
var PaymentClaimFilterKeysMap = /** @class */ (function () {
    function PaymentClaimFilterKeysMap() {
        this.requestDataFilterId = '65A99C55-C44B-48E0-844C-32C7FB061336';
    }
    return PaymentClaimFilterKeysMap;
}());
export { PaymentClaimFilterKeysMap };
var LawyerCertificatesFilterKeysMap = /** @class */ (function () {
    function LawyerCertificatesFilterKeysMap() {
        this.lawyerIdCertificateDataFilterId = 'CCE31E13-2092-4BD5-AF5A-E49FF9E85A3F';
    }
    return LawyerCertificatesFilterKeysMap;
}());
export { LawyerCertificatesFilterKeysMap };
var LawyerResponsesFilterKeysMap = /** @class */ (function () {
    function LawyerResponsesFilterKeysMap() {
        this.lawyerRequestDataFilterId = '2EC77205-55F3-4437-A278-175A61ABBD43';
        this.lawyerRequestStatusDataFilterId = 'AEA65FBB-91C0-4308-8C98-F5D3DE6B813D';
        this.lawyerDataFilterId = '92599366-B63A-4DE7-A3F3-C12160DB4B6F';
        this.issueDocNumberDataFilterId = '82C0F8F5-B6ED-4BD8-9FDF-49864FBB75E8';
        this.isRejectedDataFilterId = '3DE417B3-3F97-48E3-A0C8-AE4D0C64BDC1';
        this.lawyerIdRequestDataFilterId = '67D2A21B-7DF1-427A-A689-234CDC5FEDD8';
    }
    return LawyerResponsesFilterKeysMap;
}());
export { LawyerResponsesFilterKeysMap };
var PassportDataFilterKeysMap = /** @class */ (function () {
    function PassportDataFilterKeysMap() {
        this.byPersonIdFilterId = 'B8487796-632F-4EA1-A84E-6687C611C7F5';
        this.personFullNameDataFilterId = '7F05977B-EA10-413F-B2F3-423DA7E85937';
    }
    return PassportDataFilterKeysMap;
}());
export { PassportDataFilterKeysMap };
var PersonContactsFilterKeysMap = /** @class */ (function () {
    function PersonContactsFilterKeysMap() {
        this.personFilterId = '9368AE82-CE81-43B7-8515-52C7E53D41E4';
        this.personContactsByDataDataFilterId = '05CE322A-E482-45FB-94E1-99713A66328D';
        this.personContactsByTypeDataFilterId = '62B706AD-8D71-4A1E-8A91-5969EA8FC6FD';
    }
    return PersonContactsFilterKeysMap;
}());
export { PersonContactsFilterKeysMap };
var LawyerRewardsFilterKeysMap = /** @class */ (function () {
    function LawyerRewardsFilterKeysMap() {
        this.lawyerDataFilterId = 'D812909A-4889-4787-A3FE-5FC4CD2C1B1B';
    }
    return LawyerRewardsFilterKeysMap;
}());
export { LawyerRewardsFilterKeysMap };
var LawyerStatusReasonsFilterKeysMap = /** @class */ (function () {
    function LawyerStatusReasonsFilterKeysMap() {
        this.lawyerDataFilterId = '48B028EB-3E80-4035-8F27-874079B59775';
    }
    return LawyerStatusReasonsFilterKeysMap;
}());
export { LawyerStatusReasonsFilterKeysMap };
var LawyerStatusHistoryFilterKeysMap = /** @class */ (function () {
    function LawyerStatusHistoryFilterKeysMap() {
        this.lawyerDataFilterId = '6D5C6CAB-5C6C-4D41-B4B5-91DC4BC26C27';
    }
    return LawyerStatusHistoryFilterKeysMap;
}());
export { LawyerStatusHistoryFilterKeysMap };
var ApgmLogFilterKeysMap = /** @class */ (function () {
    function ApgmLogFilterKeysMap() {
        this.browserInfoDataFilterId = '12DEE175-B80C-46CF-9221-E77DB8B55698';
        this.clientIpAddressDataFilterId = 'F239E6BC-833A-44CB-91FE-282855060651';
        this.clientNameDataFilterId = '90AFF1F2-08F8-44DA-BC29-CCFF75420333';
        this.userNameOrEmailAddressDataFilterId = 'C08D31BF-78AB-42DC-A1C0-6AB92F061130';
        this.creationTimeDataFilterId = '47BCE70A-52AE-45EF-97E3-F3E3B3DEF78C';
    }
    return ApgmLogFilterKeysMap;
}());
export { ApgmLogFilterKeysMap };
var EducationsFilterKeysMap = /** @class */ (function () {
    function EducationsFilterKeysMap() {
    }
    EducationsFilterKeysMap.personFilterId = '98BCE70A-B446-468B-8AC4-8C1CE1208ABB';
    return EducationsFilterKeysMap;
}());
export { EducationsFilterKeysMap };
var PersonAddressesFilterKeysMap = /** @class */ (function () {
    function PersonAddressesFilterKeysMap() {
        this.personFilterId = 'D8F81ABF-C60B-409C-BB1B-6FEE74F20A19';
    }
    return PersonAddressesFilterKeysMap;
}());
export { PersonAddressesFilterKeysMap };
var OathDocumentFilterKeysMap = /** @class */ (function () {
    function OathDocumentFilterKeysMap() {
    }
    OathDocumentFilterKeysMap.lawyerId = 'C11F6833-C391-4C95-B29E-F1F02FB75A16';
    return OathDocumentFilterKeysMap;
}());
export { OathDocumentFilterKeysMap };
var AssumptionDtoFilterKeysMap = /** @class */ (function () {
    function AssumptionDtoFilterKeysMap() {
    }
    AssumptionDtoFilterKeysMap.lawyerId = '24E34902-E646-4E2B-8A1F-65BFFC202A17';
    return AssumptionDtoFilterKeysMap;
}());
export { AssumptionDtoFilterKeysMap };
var SearchDataKeysMap = /** @class */ (function () {
    function SearchDataKeysMap() {
        this.fullNameId = '1068B4E9-C019-43D9-85E2-BE1238252F71';
        this.startWithFullNameId = '1068B4E9-C019-43D9-85E2-BE1238252F71';
        this.registyNumberId = '36CBDC62-8624-4020-AF5B-C4C4EC78036F';
        this.startWithRegistyNumberId = '36CBDC62-8624-4020-AF5B-C4C4EC78036F';
        this.phoneId = '1BE04835-EAE3-4700-8D17-6D5D3E0C303F';
        this.statusId = 'DCDA4EEB-C992-4103-BB8F-A288533843CF';
        this.organizationId = '4774AE1B-B805-4257-84B3-109DD8BBE963';
    }
    return SearchDataKeysMap;
}());
export { SearchDataKeysMap };
var JobDataLogKeysMap = /** @class */ (function () {
    function JobDataLogKeysMap() {
        this.lawyerRequestId = 'F2AAFE9E-CD1F-4457-9B0B-2F7946751B66';
        this.taskId = '23F42AB4-0189-4FA1-AC88-33528C3C0770';
        this.stateId = '595E9CC1-4F87-4592-AF22-CC45594CF8F3';
    }
    return JobDataLogKeysMap;
}());
export { JobDataLogKeysMap };
var JudicialAuthorityFilterKeyMap = /** @class */ (function () {
    function JudicialAuthorityFilterKeyMap() {
        this.nameDataFilterId = '34410294-841E-4B55-8027-2361B7E3C2E5';
        this.actualAuthorityDataFilterId = 'BF06E6F3-16F2-4CD8-BC00-37B77D4372AA';
    }
    return JudicialAuthorityFilterKeyMap;
}());
export { JudicialAuthorityFilterKeyMap };
var DataKeyFilters = /** @class */ (function () {
    function DataKeyFilters() {
        this._campaign = new CampaignFilterKeysMap();
        this._members = new MembersFilterKeysMap();
        this._lawyerFormations = new LawyerFormationsFilterKeysMap();
        this._lawyers = new LawyersFilterKeysMap();
        this._lawyersOrganisation = new LawyersOrganizationFilterKeysMap();
        this._lawyerChambers = new LawyerChamberFilterKeysMap();
        this._areas = new AreaFilterKeysMap();
        this._districts = new DistrictFilterKeysMap();
        this._litigationStages = new LitigationStagesFilterKeysMap();
        this._lawyerRequests = new LawyerRequestsFilterKeysMap();
        this._lawyerRequestOrders = new LawyerRequestOrdersFilterKeysMap();
        this._lawyerRequestFiles = new LawyerRequestFilesFilterKeysMap();
        this._lawyerRequestAppeals = new LawyerRequestAppealsFilterKeysMap();
        this._lawyerRequestComments = new LawyerRequestCommentsFilterKeysMap();
        this._paymentClaim = new PaymentClaimFilterKeysMap();
        this._lawyerCertificates = new LawyerCertificatesFilterKeysMap();
        this._lawyerResponses = new LawyerResponsesFilterKeysMap();
        this._lawyerStatusReasons = new LawyerStatusReasonsFilterKeysMap();
        this._lawyerStatusHistory = new LawyerStatusHistoryFilterKeysMap();
        this._passportData = new PassportDataFilterKeysMap();
        this._personContacts = new PersonContactsFilterKeysMap();
        this._lawyerRewards = new LawyerRewardsFilterKeysMap();
        this._jobDataLog = new JobDataLogKeysMap();
        this._apgmLog = new ApgmLogFilterKeysMap();
        this._educations = new EducationsFilterKeysMap();
        this._personAddresses = new PersonAddressesFilterKeysMap();
        this._search = new SearchDataKeysMap();
        this._judicialAuthority = new JudicialAuthorityFilterKeyMap();
    }
    Object.defineProperty(DataKeyFilters.prototype, "campaign", {
        get: function () {
            return cloneDeep(this._campaign);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "members", {
        get: function () {
            return cloneDeep(this._members);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerFormations", {
        get: function () {
            return cloneDeep(this._lawyerFormations);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyersChambers", {
        get: function () {
            return cloneDeep(this._lawyerChambers);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyers", {
        get: function () {
            return cloneDeep(this._lawyers);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyersOrganizations", {
        get: function () {
            return cloneDeep(this._lawyersOrganisation);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "areas", {
        get: function () {
            return cloneDeep(this._areas);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "districts", {
        get: function () {
            return cloneDeep(this._districts);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "litigationStages", {
        get: function () {
            return cloneDeep(this._litigationStages);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerRequests", {
        get: function () {
            return cloneDeep(this._lawyerRequests);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerRequestOrders", {
        get: function () {
            return cloneDeep(this._lawyerRequestOrders);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerRequestFiles", {
        get: function () {
            return cloneDeep(this._lawyerRequestFiles);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerRequestAppeals", {
        get: function () {
            return cloneDeep(this._lawyerRequestAppeals);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerRequestComments", {
        get: function () {
            return cloneDeep(this._lawyerRequestComments);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "paymentClaim", {
        get: function () {
            return cloneDeep(this._paymentClaim);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerCertificates", {
        get: function () {
            return cloneDeep(this._lawyerCertificates);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerResponses", {
        get: function () {
            return cloneDeep(this._lawyerResponses);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerStatusReasons", {
        get: function () {
            return cloneDeep(this._lawyerStatusReasons);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerStatusHistory", {
        get: function () {
            return cloneDeep(this._lawyerStatusHistory);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "passportData", {
        get: function () {
            return cloneDeep(this._passportData);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "personContacts", {
        get: function () {
            return cloneDeep(this._personContacts);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "lawyerRewards", {
        get: function () {
            return cloneDeep(this._lawyerRewards);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "apgmLog", {
        get: function () {
            return cloneDeep(this._apgmLog);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "educations", {
        get: function () {
            return cloneDeep(this._educations);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "personAddresses", {
        get: function () {
            return cloneDeep(this._personAddresses);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "search", {
        get: function () {
            return cloneDeep(this._search);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "judicialAuthority", {
        get: function () {
            return cloneDeep(this._judicialAuthority);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataKeyFilters.prototype, "jobDataLog", {
        get: function () {
            return cloneDeep(this._jobDataLog);
        },
        enumerable: true,
        configurable: true
    });
    return DataKeyFilters;
}());
export { DataKeyFilters };

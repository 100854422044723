import { AppValidators } from '@shared/validators';
import { filter, map as _map, upperFirst, takeRight, join } from 'lodash';
import { APP_REGEXP } from '../infrastructure/regexp';
import { Guid } from '../infrastructure/guid';
var UtilHelper = /** @class */ (function () {
    function UtilHelper() {
    }
    UtilHelper.Base64ToBytes = function (base64) {
        var s = window.atob(base64);
        var bytes = new Uint8Array(s.length);
        for (var i = 0; i < s.length; i++) {
            bytes[i] = s.charCodeAt(i);
        }
        return bytes;
    };
    ;
    UtilHelper.createQuickGuid = function () {
        return Guid.createQuickGuid().toString();
    };
    UtilHelper.createGuid = function () {
        return Guid.createGuid().toString();
    };
    UtilHelper.createSpanContent = function (textContent, css) {
        return UtilHelper.createTagContent('span', textContent, css);
    };
    UtilHelper.createTagContent = function (tagName, textContent, css) {
        if (css) {
            return "<" + tagName + " class=\"" + css + "\">" + textContent + "</" + tagName + ">";
        }
        return "<" + tagName + ">" + textContent + "</" + tagName + ">";
    };
    UtilHelper.unsubscribe = function (subscription) {
        if (subscription && !subscription.closed) {
            subscription.unsubscribe();
        }
    };
    UtilHelper.cleanPhoneMask = function (phone) {
        if (!phone || !phone.length) {
            return '';
        }
        return phone.replace(APP_REGEXP.replaceAllNotNumbers, '');
    };
    UtilHelper.eachFirstToUpper = function (str) {
        str = str || str.trim().toLowerCase();
        if (!str) {
            return '';
        }
        return _map(filter(str.split(' '), function (i) { return i !== ' '; }), function (i) { return upperFirst(i); }).join(' ');
    };
    UtilHelper.normalizeString = function (val, regexp, isInvariant) {
        if (regexp === void 0) { regexp = null; }
        if (isInvariant === void 0) { isInvariant = false; }
        val = val || '';
        var value = val.trim();
        if (isInvariant) {
            value = value.toLowerCase();
        }
        if (regexp) {
            value = value.replace(regexp, '').trim();
        }
        return value;
    };
    UtilHelper.normalizePhone = function (phone, specialRegExp) {
        if (specialRegExp === void 0) { specialRegExp = APP_REGEXP.replaceAllNotNumbers; }
        if (!phone) {
            return '';
        }
        return UtilHelper.normalizeString(phone, specialRegExp, false);
    };
    UtilHelper.splitFullName = function (fullName, normalize, normalizeRegexp, isInvariant) {
        if (normalize === void 0) { normalize = true; }
        if (normalizeRegexp === void 0) { normalizeRegexp = null; }
        if (isInvariant === void 0) { isInvariant = false; }
        var checkedName = normalize
            ? UtilHelper.normalizeString(fullName, normalizeRegexp || APP_REGEXP.replaceAllNotRussian, isInvariant)
            : fullName || '';
        checkedName = checkedName.replace(APP_REGEXP.replaceAllDoubleSpace, ' ').trim();
        var nameArray = checkedName.split(' ');
        var fNameName = [];
        var lastName = nameArray[0] || '';
        var firstName = nameArray[1] || '';
        var middleName = '';
        if (nameArray[2]) {
            middleName = join(takeRight(nameArray, nameArray.length - 2), ' ').trim();
        }
        if (lastName) {
            fNameName.push(lastName);
        }
        if (firstName) {
            fNameName.push(firstName);
        }
        if (middleName) {
            fNameName.push(middleName);
        }
        return {
            fullName: fNameName.join(' ').trim(),
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
        };
    };
    UtilHelper.removeSpacesFromClientName = function (name) {
        if (name) {
            var regexp = APP_REGEXP.replaceAllDoubleSpace;
            var s = name.trim()
                .replace(regexp, ' ')
                .trim()
                .replace(/\s{1,}-/g, '-')
                .replace(/-\s{1,}/g, '-');
            return s;
        }
        else {
            return '';
        }
    };
    UtilHelper.getShortFullName = function (fullName) {
        var tFullName = this.splitFullName(fullName), tFirstName = tFullName.firstName.length ? (tFullName.firstName[0] + '.') : '', tMiddleName = tFullName.middleName.length ? (tFullName.middleName[0] + '.') : '', result = tFullName.lastName + ' ' + tFirstName + ' ' + tMiddleName;
        return result;
    };
    UtilHelper.toPhoneView = function (phone) {
        if (!phone) {
            return '';
        }
        phone = phone || '';
        phone = UtilHelper.normalizePhone(phone);
        return UtilHelper.viewPhoneNumberFormatter(phone);
    };
    UtilHelper.viewPhoneNumberFormatter = function (phone, pattern) {
        if (!phone) {
            return '';
        }
        if (phone[0] === '8' && phone.length === 11) {
            phone = 7 + phone.substr(1);
        }
        phone = phone.replace('+', '');
        phone += '';
        var val = '';
        var mask = pattern || APP_REGEXP.phoneMaskTemplate;
        var numI = 0;
        for (var idx = 0; idx < mask.length; idx++) {
            if (!phone[numI]) {
                break;
            }
            var maskChar = mask[idx];
            var numChar = phone[numI];
            if (maskChar === numChar || maskChar === 'x') {
                val += numChar;
                numI++;
            }
            else {
                val += maskChar;
            }
        }
        return val;
    };
    UtilHelper.normalizeLogin = function (login) {
        var result = '';
        if (APP_REGEXP.isPhone.test(login)) {
            result = UtilHelper.normalizePhone(login);
        }
        else if (APP_REGEXP.isEmail.test(login)) {
            result = UtilHelper.normalizeString(login);
        }
        else if (AppValidators.isRegistryNumberByValue(login)) {
            result = UtilHelper.normalizeString(login, APP_REGEXP.replaceAllNotNumbers);
        }
        else if (APP_REGEXP.isOnlyLetters.test(login.trim())) {
            result = UtilHelper.normalizeString(login);
        }
        else {
            throw new Error('Логин не тот, что ожидается');
        }
        return result;
    };
    UtilHelper.toPassportView = function (passport) {
        if (!passport || typeof passport !== 'string' || passport.length < 10) {
            return '';
        }
        passport = passport.replace(/ /g, '');
        var arr = passport.split('');
        return arr.slice(0, 2).join('') + " " + arr.slice(2, 4).join('') + " " + arr.slice(4).join('');
    };
    /**
     * Меняет местами элементы коллекции с указанными индексами
     *
     * @param collection Коллекция
     * @param firstIndex Индекс первого элемента
     * @param secondIndex Индекс второго элемента
     */
    UtilHelper.exchangeItems = function (collection, firstIndex, secondIndex) {
        if (!collection || !collection.length) {
            return;
        }
        if (firstIndex < 0 || secondIndex < 0) {
            return;
        }
        var lastIndex = collection.length - 1;
        if (lastIndex < firstIndex || lastIndex < secondIndex) {
            return;
        }
        var tmp = collection[firstIndex];
        collection[firstIndex] = collection[secondIndex];
        collection[secondIndex] = tmp;
    };
    UtilHelper.stripHtml = function (string) {
        if (typeof (string) !== 'string') {
            return string;
        }
        return string.replace(/(<([^>]+)>)/gi, '');
    };
    UtilHelper.markFormGroupTouched = function (formGroup) {
        var _this = this;
        Object.values(formGroup.controls).forEach(function (control) {
            control.markAsTouched();
            if (control.controls) {
                _this.markFormGroupTouched(control);
            }
        });
    };
    UtilHelper.updateValueAndValidity = function (formGroup) {
        var _this = this;
        Object.values(formGroup.controls).forEach(function (control) {
            control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            if (control.controls) {
                _this.updateValueAndValidity(control);
            }
        });
    };
    return UtilHelper;
}());
export { UtilHelper };

import { AuthConfig } from 'angular-oauth2-oidc';

export const authPasswordFlowConfig: AuthConfig = {
    issuer: 'https://apm-is-demo.isap.team',
    clientId: 'Ais_App',
    dummyClientSecret: '1q2w3e*',
    scope: 'openid profile email Ais Apgm',
    showDebugInformation: true,
    oidc: false,
    //skipIssuerCheck: true,
  };
import { UtilsService } from '@abp/utils/utils.service';
import { Injector } from '@angular/core';
import * as i0 from "@angular/core";
var AuthCookieService = /** @class */ (function () {
    function AuthCookieService(_injector) {
        this._injector = _injector;
        this._abpUtilsService = _injector.get(UtilsService);
    }
    AuthCookieService.prototype.getCookie = function (name) {
        return this._abpUtilsService.getCookieValue(name);
    };
    AuthCookieService.prototype.setCookie = function (name, value, expireDate, path) {
        this._abpUtilsService.setCookieValue(name, value, expireDate, path);
    };
    AuthCookieService.prototype.deleteCookie = function (name, path) {
        this._abpUtilsService.deleteCookie(name, path);
    };
    AuthCookieService.ngInjectableDef = i0.defineInjectable({ factory: function AuthCookieService_Factory() { return new AuthCookieService(i0.inject(i0.INJECTOR)); }, token: AuthCookieService, providedIn: "root" });
    return AuthCookieService;
}());
export { AuthCookieService };

import { OnInit } from '@angular/core';
var AppPreloaderComponent = /** @class */ (function () {
    function AppPreloaderComponent() {
        this.message = 'Подождите, идет загрузка ...';
        this.isShow = false;
    }
    AppPreloaderComponent.prototype.ngOnInit = function () { };
    return AppPreloaderComponent;
}());
export { AppPreloaderComponent };

var UrlHelper = /** @class */ (function () {
    function UrlHelper() {
    }
    UrlHelper.getQueryParameters = function () {
        return document.location.search
            .replace(/(^\?)/, '')
            .split('&')
            .map(function (n) {
            return (n = n.split('=')), (this[n[0]] = n[1]), this;
        }.bind({}))[0];
    };
    /**
     * The URL requested, before initial routing.
     */
    UrlHelper.initialUrl = location.href;
    return UrlHelper;
}());
export { UrlHelper };

import { EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
export var LinkBarLinkType;
(function (LinkBarLinkType) {
    LinkBarLinkType["submenu"] = "submenu";
    LinkBarLinkType["rootmenu"] = "rootmenu";
    LinkBarLinkType["router"] = "router";
    LinkBarLinkType["direct"] = "direct";
    LinkBarLinkType["logout"] = "logout";
})(LinkBarLinkType || (LinkBarLinkType = {}));
var LinkBarComponent = /** @class */ (function () {
    function LinkBarComponent(_sanitizer, _changeDetector) {
        this._sanitizer = _sanitizer;
        this._changeDetector = _changeDetector;
        this.logoutClick = new EventEmitter();
        this._linkBarLinks = [];
    }
    Object.defineProperty(LinkBarComponent.prototype, "linkBarLinks", {
        set: function (newLinkBarLinks) {
            var _this = this;
            newLinkBarLinks.forEach(function (item) {
                var i = _this._linkBarLinks.find(function (i2) { return i2.title === item.title; });
                if (i && i.type === LinkBarLinkType.rootmenu) {
                    item.subOpened = i.subOpened;
                }
            });
            this._linkBarLinks = newLinkBarLinks;
        },
        enumerable: true,
        configurable: true
    });
    LinkBarComponent.prototype.ngOnInit = function () {
        /*
        this.linkBarLinks = [

            {
                subLinks: [
                    {
                        subLinks: null,
                        subOpened: false,
                        isSubMenu: true,
                        routePath: 'dashboard',
                        iconName: 'home',
                        title: 'Дашбоард',
                        type: LinkBarLinkType.router
                    },
                    {
                        subLinks: null,
                        subOpened: false,
                        isSubMenu: true,
                        routePath: 'http://www.advokatymoscow.ru/news/detail.php?login=yes&ID=3238&clear_cache=Y',
                        iconName: 'contact_support',
                        title: 'Вопросы по работе',
                        type: LinkBarLinkType.direct
                    },
                    {
                        subLinks: null,
                        subOpened: false,
                        isSubMenu: true,
                        routePath: '',
                        iconName: 'exit_to_app',
                        title: 'Выход',
                        type: LinkBarLinkType.logout
                    }
                ],
                subOpened: true,
                isSubMenu: false,
                routePath: '',
                iconName: 'home',
                title: 'Тест группы',
                type: LinkBarLinkType.rootmenu
            },

            {
                subLinks: null,
                subOpened: false,
                isSubMenu: false,
                routePath: 'dashboard',
                iconName: 'home',
                title: 'Дашбоард',
                type: LinkBarLinkType.router
            },
            {
                subLinks: null,
                subOpened: false,
                isSubMenu: false,
                routePath: 'http://www.advokatymoscow.ru/news/detail.php?login=yes&ID=3238&clear_cache=Y',
                iconName: 'contact_support',
                title: 'Вопросы по работе',
                type: LinkBarLinkType.direct
            },
            {
                subLinks: null,
                subOpened: false,
                isSubMenu: false,
                routePath: '',
                iconName: 'exit_to_app',
                title: 'Выход',
                type: LinkBarLinkType.logout
            }
        ];
        */
    };
    Object.defineProperty(LinkBarComponent.prototype, "links", {
        get: function () {
            var a = [];
            this._linkBarLinks.forEach(function (item) {
                a.push(item);
                if (item.subLinks && item.subLinks.length && item.subOpened) {
                    item.subLinks.forEach(function (subItem) { return a.push(subItem); });
                }
            });
            return a;
        },
        enumerable: true,
        configurable: true
    });
    LinkBarComponent.prototype.logout = function ($event) {
        var item = this.links.find(function (i) {
            return i.type === LinkBarLinkType.logout;
        });
        if (item) {
            item.onClick($event);
        }
    };
    LinkBarComponent.prototype.safeUrl = function (link) {
        return this._sanitizer.bypassSecurityTrustUrl(link.routePath);
    };
    LinkBarComponent.prototype.routerUrl = function (link) {
        return link.routePath;
    };
    LinkBarComponent.prototype.toggleMenuGroup = function (link) {
        var node = this.links.find(function (item) { return item.title === link.title; });
        node.subOpened = !node.subOpened;
    };
    return LinkBarComponent;
}());
export { LinkBarComponent };

/**
 * Базовый класс создания моделей фильтров для использования запросах данных
 */
var BaseDataFilterValueBuilder = /** @class */ (function () {
    function BaseDataFilterValueBuilder() {
    }
    /**
     * Создание модели фильтра
     */
    BaseDataFilterValueBuilder.prototype.build = function () {
        if (!this._isValid()) {
            throw new Error('Не все данные были переданы в построитель фильтра. Например, filterId');
        }
        return this._build();
    };
    BaseDataFilterValueBuilder.prototype.setFilterId = function (value) {
        this._filterId = value;
        return this;
    };
    BaseDataFilterValueBuilder.prototype.setValue = function (value) {
        this._value = value;
        return this;
    };
    BaseDataFilterValueBuilder.prototype._isValid = function () {
        return !!this._filterId;
    };
    return BaseDataFilterValueBuilder;
}());
export { BaseDataFilterValueBuilder };

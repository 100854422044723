import { FormBuilder } from '@angular/forms';
import { each } from 'lodash';
import { QueryOptionsDto } from '@shared/service-proxies';
/**
 * Базовый компонент для создания формы фильтра.
 *
 * ### Пример нового компонента:
 * ```typescript
 *    @Component({
 *        selector: 'filter',
 *        templateUrl: './filter.component.html',
 *    })
 *    export class FilterComponent extends BaseFilterComponent {
 *        constructor(private _filterService: BaseFilterService) {
 *            super();
 *        }
 *
 *        createFields(): DefaultFilterFieldModel[] {
 *            return [
 *                {
 *                    name: 'docNumber',
 *                    label: 'Номер заявки',
 *                    type: AppFormFieldType.text,
 *                    filterId: 'A493C0B9-F3CD-480D-B765-BCD567D0A616',
 *                    dataBuilder: DefaultDataFilterValueBuilder,
 *                },
 *                {
 *                    name: 'creationTime',
 *                    label: 'Дата создания заявки',
 *                    type: AppFormFieldType.datepicker,
 *                    filterId: '70EC240A-8428-4FAC-A022-A65FA0B71C96',
 *                    dataBuilder: DateDataFilterValueBuilder,
 *                }
 *            ];
 *        }
 *
 *        public search(): void {
 *            this.change();
 *        }
 *
 *        protected _afterChangeAction(queryOptions: IQueryOptionsDto): void {
 *            this._filterService.emitOnSubmit(queryOptions);
 *        }
 *    }
 * ```
 *
 * ### Пример шаблона:
 * ```html
 *    <form [formGroup]="formGroup">
 *        <div *ngFor="let field of fields">
 *            <ng-template [ngIf]="'text' === field.type">
 *                <mat-form-field>
 *                    <mat-label>{{ field.label }}</mat-label>
 *                    <input matInput [formControl]="formGroup.get(field.name)" />
 *                </mat-form-field>
 *            </ng-template>
 *
 *            <ng-template [ngIf]="'datepicker' === field.type">
 *                <mat-form-field>
 *                    <mat-label>{{ field.label }}</mat-label>
 *                    <input matInput [matDatepicker]="datePicker" [formControl]="formGroup.get(field.name)"/>
 *                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
 *                    <mat-datepicker #datePicker></mat-datepicker>
 *                </mat-form-field>
 *            </ng-template>
 *        </div>
 *        <button mat-button (click)="search()">Найти</button>
 *    </form>
 * ```
 */
var BaseFilterComponent = /** @class */ (function () {
    function BaseFilterComponent() {
        this._fb = new FormBuilder();
    }
    BaseFilterComponent.prototype.ngOnInit = function () {
        this.fields = this.createFields();
        this._createControls();
    };
    /**
     * Запуск обработки данных
     */
    BaseFilterComponent.prototype.change = function () {
        var queryOptions = this._buildQueryOptions();
        this._afterChangeAction(queryOptions);
    };
    /**
     * Создает объект из фильтров, который будет использоваться для запросов к api
     * @protected
     */
    BaseFilterComponent.prototype._buildQueryOptions = function () {
        var formValues = this.formGroup.value;
        var dataFilter = new QueryOptionsDto({
            filterValues: [],
            sortOptions: [],
        });
        each(this.fields, function (field) {
            var fieldName = field.name;
            if (!formValues.hasOwnProperty(fieldName) || !formValues[fieldName]) {
                return; // continue
            }
            var dataFilterBuilder = new field.dataBuilder();
            var fieldValue = formValues[fieldName];
            var dataFilterValue = dataFilterBuilder
                .setFilterId(field.filterId)
                .setValue(fieldValue)
                .build();
            dataFilter.filterValues.push(dataFilterValue);
        });
        return dataFilter;
    };
    /**
     * На основе данных из fields создает controls для использования в шаблоне компонента
     * @protected
     */
    BaseFilterComponent.prototype._createControls = function () {
        var _this = this;
        this.formGroup = this._fb.group({});
        each(this.fields, function (field) {
            _this.formGroup.addControl(field.name, _this._fb.control(''));
        });
    };
    /**
     * Запускает дополнительные действия после обработки данных.
     * Переопределяется в классе-наследнике если нужно
     * @param {IQueryOptionsDto} queryOptions Объект из фильтров
     * @protected
     */
    BaseFilterComponent.prototype._afterChangeAction = function (queryOptions) { };
    return BaseFilterComponent;
}());
export { BaseFilterComponent };

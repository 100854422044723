import { Injectable, Injector, Type, InjectionToken } from '@angular/core';
import {
    JudicialDistrictDto,
    LitigationStageDto,
    LitigationTypeDto,
    LitigationStageServiceProxy,
    JudicialDistrictServiceProxy,
    LawyerRequestDto,
    LawyerRequestServiceProxy,
    LawyerRequestFileServiceProxy,
    LawyerRequestFileDto,
    LawyerResponseDto,
    LawyerResponseServiceProxy,
    InitiatorServiceProxy,
    InitiatorDto,
    StatusHistoryServiceProxy,
    StatusHistoryDto,
    LawyerStatusReasonServiceProxy,
    LawyerStatusReasonDto,
    LawChamberServiceProxy,
    LawChamberDto,
    LawyerDto,
    LawyerServiceProxy,
    ArticleParticipationTypeServiceProxy,
    ArticleParticipationTypeDto,
    SpecializationServiceProxy,
    SpecializationDto,
    ContactServiceProxy,
    PersonContactDto,
    JudicialAuthorityDto,
    JudicialAuthorityServiceProxy,
    LawyerRequestCommentDto,
    LawyerRequestCommentServiceProxy,
    OfficialDocumentTypeServiceProxy,
    OfficialDocumentTypeDto,
    OrganizationServiceProxy,
    OrganizationDto,
} from '../service-proxies';
import { StorageInMemory } from './storage-in-memory';
import { IBasicAppServiceProxy } from './intrfaces/IBasicAppServiceProxy';
import { LitigationTypeServiceProxyAdapter } from './adapters/concrete/LitigationTypeServiceProxyAdapter';

@Injectable({
    providedIn: 'root',
})
export class DataBaseService {
    constructor(private _injector: Injector) {}

    // справочники
    get judicialDistricts(): StorageInMemory<JudicialDistrictDto, JudicialDistrictServiceProxy> {
        return this._getOrCreateEntityStorage('judicialDistricts', JudicialDistrictServiceProxy);
    }

    /**
     * Справочник судов, органов дознания и следствия
     */
    get judicialAuthorities(): StorageInMemory<JudicialAuthorityDto, JudicialAuthorityServiceProxy> {
        return this._getOrCreateEntityStorage('judicialAuthority', JudicialAuthorityServiceProxy);
    }

    get litigationStages(): StorageInMemory<LitigationStageDto, LitigationStageServiceProxy> {
        return this._getOrCreateEntityStorage('litigationStages', LitigationStageServiceProxy);
    }

    get litigationTypes(): StorageInMemory<LitigationTypeDto, LitigationTypeServiceProxyAdapter> {
        return this._getOrCreateEntityStorage<LitigationTypeDto, LitigationTypeServiceProxyAdapter>(
            'litigationTypes',
            LitigationTypeServiceProxyAdapter
        );
    }

    get specialization(): StorageInMemory<SpecializationDto, SpecializationServiceProxy> {
        return this._getOrCreateEntityStorage('specialization', SpecializationServiceProxy);
    }

    get articleParticipationType(): StorageInMemory<ArticleParticipationTypeDto, ArticleParticipationTypeServiceProxy> {
        return this._getOrCreateEntityStorage<ArticleParticipationTypeDto, ArticleParticipationTypeServiceProxy>(
            'articleParticipationType',
            ArticleParticipationTypeServiceProxy,
        );
    }

    get lawChamber(): StorageInMemory<LawChamberDto, LawChamberServiceProxy> {
        return this._getOrCreateEntityStorage<LawChamberDto, LawChamberServiceProxy>(
            'lawChamber',
            LawChamberServiceProxy,
        );
    }

    get lawyerStatusReason(): StorageInMemory<LawyerStatusReasonDto, LawyerStatusReasonServiceProxy> {
        return this._getOrCreateEntityStorage<LawyerStatusReasonDto, LawyerStatusReasonServiceProxy>(
            'lawyerStatusReason',
            LawyerStatusReasonServiceProxy,
        );
    }
    get organization(): StorageInMemory<OrganizationDto, OrganizationServiceProxy> {
        return this._getOrCreateEntityStorage<OrganizationDto, OrganizationServiceProxy>(
            'organization',
            OrganizationServiceProxy,
        );
    }

    // остальное
    get lawyerRequest(): StorageInMemory<LawyerRequestDto, LawyerRequestServiceProxy> {
        return this._getOrCreateEntityStorage<LawyerRequestDto, LawyerRequestServiceProxy>(
            'lawyerRequest',
            LawyerRequestServiceProxy,
        );
    }

    get lawyerRequestFile(): StorageInMemory<LawyerRequestFileDto, LawyerRequestFileServiceProxy> {
        return this._getOrCreateEntityStorage<LawyerRequestFileDto, LawyerRequestFileServiceProxy>(
            'lawyerRequestFile',
            LawyerRequestFileServiceProxy,
        );
    }

    get lawyerRequestComment(): StorageInMemory<LawyerRequestCommentDto, LawyerRequestCommentServiceProxy> {
        return this._getOrCreateEntityStorage<LawyerRequestCommentDto, LawyerRequestCommentServiceProxy>(
            'lawyerRequestComment',
            LawyerRequestCommentServiceProxy,
        );
    }

    get lawyerResponse(): StorageInMemory<LawyerResponseDto, LawyerResponseServiceProxy> {
        return this._getOrCreateEntityStorage<LawyerResponseDto, LawyerResponseServiceProxy>(
            'lawyerResponse',
            LawyerResponseServiceProxy,
        );
    }

    get initiator(): StorageInMemory<InitiatorDto, InitiatorServiceProxy> {
        return this._getOrCreateEntityStorage<InitiatorDto, InitiatorServiceProxy>('initiator', InitiatorServiceProxy);
    }

    get statusHistory(): StorageInMemory<StatusHistoryDto, StatusHistoryServiceProxy> {
        return this._getOrCreateEntityStorage<StatusHistoryDto, StatusHistoryServiceProxy>(
            'statusHistory',
            StatusHistoryServiceProxy,
        );
    }

    get lawyer(): StorageInMemory<LawyerDto, LawyerServiceProxy> {
        return this._getOrCreateEntityStorage<LawyerDto, LawyerServiceProxy>('lawyer', LawyerServiceProxy);
    }

    get contact(): StorageInMemory<PersonContactDto, ContactServiceProxy> {
        return this._getOrCreateEntityStorage<PersonContactDto, ContactServiceProxy>('contact', ContactServiceProxy);
    }

    get officialDocumentType(): StorageInMemory<OfficialDocumentTypeDto, OfficialDocumentTypeServiceProxy> {
        return this._getOrCreateEntityStorage<OfficialDocumentTypeDto, OfficialDocumentTypeServiceProxy>(
            'officialDocumentType',
            OfficialDocumentTypeServiceProxy,
        );
    }

    private _getOrCreateEntityStorage<
        TDto extends { id: string | number },
        TProxy extends IBasicAppServiceProxy<TDto>
    >(entityName: string, token: Type<TProxy> | InjectionToken<TProxy>) {
        const privatePropName = '__' + entityName;
        if (!this[privatePropName]) {
            this[privatePropName] = new StorageInMemory<TDto, TProxy>(this._injector.get(token));
        }
        return this[privatePropName];
    }
}

import { Injectable } from '@angular/core';

/**
 * сохранение и восстановления позиции скроллинга для окна или элемента
 */
@Injectable({
    providedIn: 'root',
})
export class ScrollPosService {

    private _inited = false;
    private _saveTime = 30 * 60 *  1000; // время хранения не более 30 минут

    public save (url: string, element: string): void {
        const urlshash = this._getUrlsHash();
        this._clearOldUrls(urlshash);
        (<any>urlshash).hash[url] = {
            ts: new Date().valueOf(),
            pos: this._getScrollPos(element),
            element: element
        }
        this._saveUrlsToLocalStorage(urlshash);
    }

    public restore (url: string): void {
        const urlshash = this._getUrlsHash();
        const item = (<any>urlshash).hash[url];
        if (item) {
            this._restoreScrollPos(item);
        }
    }

    public restoreRequest (url: string): void {
        const urlshash = this._getUrlsHash();
        const item = (<any>urlshash).hash[url];
        if (item) {
            item.restoreRequest = 1;
            this._saveUrlsToLocalStorage(urlshash);
        }
    }

    public checkRestoreRequest(url: string): void {
        const urlshash = this._getUrlsHash();
        const item = (<any>urlshash).hash[url];
        if (item && item.restoreRequest) {
            const now = new Date().valueOf();
            if (now - item.ts < 10 * 60 * 1000) {
                this._restoreScrollPos(item);
            }
            delete (<any>urlshash).hash[url];
            this._saveUrlsToLocalStorage(urlshash);
        }
    }

    private _restoreScrollPos (item): void {
        if (item.element) {
            setTimeout(() => {
                $(item.element).scrollTop(item.pos);
            }, 100);
        } else {
            this._windowScrollTo(item.pos);
        }
    }

    private _getUrlsHash (): object {
        let urlshash;
        try {
            urlshash = JSON.parse(localStorage.getItem('ScrollPosHelperHash')) || this._getEmptyHash();
        } catch (e) {
            urlshash = this._getEmptyHash();
        }
        return urlshash;
    }

    private _getEmptyHash (): object {
        return {
            hash: {}
        };
    }

    private _windowScrollTo (pos): void {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        // Internet Explorer
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            setTimeout(() => {
                window.scrollTo(0, pos);
            }, 100);
        } else {
            setTimeout(() => {
                window.scrollTo({
                    top: pos,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }

    private _getScrollPos (element: string): number {
        if (element) {
            return $(element).scrollTop();
        } else {
            return window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop || 0;
        }
    }

    private _clearOldUrls (urlshash): void {
        const now = new Date().valueOf();
        for (var url in urlshash.hash) {
            let item = urlshash.hash[url];
            if (now - item.ts > this._saveTime) {
                delete urlshash.hash[url]
            }
        }
    }

    private _saveUrlsToLocalStorage (urlshash): void {
        try {
            localStorage.setItem('ScrollPosHelperHash', JSON.stringify(urlshash));
        } catch (e) { }
    }

}

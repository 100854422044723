import { Injectable } from '@angular/core';
import { from, Subject, Observable } from 'rxjs';
import { each, remove } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    listeners: { [key: string]: ((...args) => any)[] };
    eventsSubject: Subject<any>;
    events: Observable<any>;

    constructor() {
        this.listeners = {};
        this.eventsSubject = new Subject<any>();

        this.events = from(this.eventsSubject);

        this.events.subscribe(({ name, args }) => {
            if (this.listeners[name]) {
                const col = this.listeners[name];
                each(col, listener => {
                    listener(...args);
                });
            }
        });
    }

    on<T>(name: string, listener: (...args) => T): void {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        this.listeners[name].push(listener);
    }
    remove<T>(name: string, listener: (...args) => T): void {
        if (this.listeners[name]) {
            remove(this.listeners[name], i => {
                return i === listener;
            });
        }
    }

    broadcast(name: string, ...args): void {
        this.eventsSubject.next({
            name,
            args,
        });
    }
}

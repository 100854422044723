var MaterialIconHelper = /** @class */ (function () {
    function MaterialIconHelper() {
    }
    MaterialIconHelper.createMaterialIcon = function (iconName) {
        return "<i class=\"app-icon material-icons\">" + iconName + "</i>";
    };
    Object.defineProperty(MaterialIconHelper, "phone", {
        get: function () {
            return 'phone';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "smartPhone", {
        get: function () {
            return 'smartphone';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "localPhone", {
        get: function () {
            return 'local_phone';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "check", {
        get: function () {
            return 'check';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "dateRange", {
        get: function () {
            return 'date_range';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "person", {
        get: function () {
            return 'person';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "user", {
        get: function () {
            return MaterialIconHelper.person;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "alternateEmail", {
        get: function () {
            return 'alternate_email';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "link", {
        get: function () {
            return 'link';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "locationCity", {
        get: function () {
            return 'location_city';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "file", {
        get: function () {
            return 'insert_drive_file';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "fileUpload", {
        get: function () {
            return 'file_upload';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "clear", {
        get: function () {
            return 'clear';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "close", {
        get: function () {
            return MaterialIconHelper.clear;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "delete", {
        get: function () {
            return MaterialIconHelper.clear;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "listAlt", {
        get: function () {
            return 'list_alt';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "helpOutline", {
        get: function () {
            return 'help_outline';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MaterialIconHelper, "visibility", {
        get: function () {
            return 'visibility';
        },
        enumerable: true,
        configurable: true
    });
    return MaterialIconHelper;
}());
export { MaterialIconHelper };
/**
 * this class use fa 4+ version but starts from 5+ version fa use fas prefix, we need update this section
 */
var FontAwesomeIconHelper = /** @class */ (function () {
    function FontAwesomeIconHelper() {
    }
    Object.defineProperty(FontAwesomeIconHelper, "certificate", {
        get: function () {
            // <i class="fas fa-file-certificate"></i>
            return 'fa fa-certificate';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "skype", {
        get: function () {
            return 'fa fa-skype';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "telegramPlane", {
        get: function () {
            return 'fab fa-telegram-plane';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "telegram", {
        get: function () {
            return 'fa fa-telegram';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "vk", {
        get: function () {
            return 'fa fa-vk';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "facebook", {
        get: function () {
            return 'fa fa-facebook';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "twitter", {
        get: function () {
            return 'fa fa-twitter';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "pencil", {
        get: function () {
            return 'fa fa-pencil';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "medal", {
        get: function () {
            return 'fas fa-medal';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "fileAlt", {
        get: function () {
            return 'fas fa-file-alt';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "liveJournal", {
        get: function () {
            // no icon in fa for alternative use oencil
            return FontAwesomeIconHelper.pencil;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "globe", {
        get: function () {
            return 'fa fa-globe';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "webPage", {
        get: function () {
            return FontAwesomeIconHelper.globe;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "link", {
        get: function () {
            return 'fa fa-link';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "question", {
        get: function () {
            return 'fa fa-question';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "university", {
        get: function () {
            return 'fa fa-university';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "graduationCap", {
        get: function () {
            return 'fa fa-graduation-cap';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FontAwesomeIconHelper, "fileText", {
        get: function () {
            return 'fa  fa-file-text';
        },
        enumerable: true,
        configurable: true
    });
    FontAwesomeIconHelper.createFaIcon = function (iconCss) {
        return "<i class=\"app-icon " + iconCss + "\"></i>";
    };
    return FontAwesomeIconHelper;
}());
export { FontAwesomeIconHelper };

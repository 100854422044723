import { cloneDeep, find, each } from 'lodash';
import { from } from 'rxjs';
import { SessionServiceProxy, UserLoginInfoDto, } from '@shared/service-proxies/service-proxies';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import * as i0 from "@angular/core";
import * as i1 from "../service-proxies/service-proxies";
import * as i2 from "abp-ng2-module/dist/src/multi-tenancy/abp-multi-tenancy.service";
var AppSessionService = /** @class */ (function () {
    function AppSessionService(_sessionService, _abpMultiTenancyService) {
        this._sessionService = _sessionService;
        this._abpMultiTenancyService = _abpMultiTenancyService;
    }
    Object.defineProperty(AppSessionService.prototype, "application", {
        get: function () {
            return cloneDeep(this._currentLoginInformations.application);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSessionService.prototype, "user", {
        get: function () {
            return cloneDeep(this._currentLoginInformations.user);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSessionService.prototype, "userId", {
        get: function () {
            var user = this.user;
            return user ? user.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSessionService.prototype, "tenant", {
        get: function () {
            return cloneDeep(this._currentLoginInformations.tenant);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSessionService.prototype, "tenantId", {
        get: function () {
            var tenant = this.tenant;
            return tenant ? tenant.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSessionService.prototype, "currentLoginInformations", {
        get: function () {
            return cloneDeep(this._currentLoginInformations);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSessionService.prototype, "isLawyer51Role", {
        get: function () {
            if (this._currentLoginInformations &&
                this._currentLoginInformations.userRoles) {
                return this._currentLoginInformations.userRoles.indexOf('Lawyer51') >= 0;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    AppSessionService.prototype.getShownLoginName = function () {
        var userName = this.user.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }
        var tenant = this.tenant;
        return (tenant ? tenant.tenancyName : '.') + '\\' + userName;
    };
    AppSessionService.prototype.init = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._sessionService
                .getCurrentLoginInformations()
                .toPromise()
                .then(function (result) {
                _this._currentLoginInformations = result;
                // Вытсавляем серверное время 1 раз при загрузке приложения
                AppSessionService.serverTime = result.serverTime;
                // Делаем увеличение на 1 сек по интервалу, для пподержания актуальности времени
                if (AppSessionService.serverTimeInterval) {
                    clearInterval(AppSessionService.serverTimeInterval);
                }
                AppSessionService.serverTimeInterval = setInterval(function () {
                    AppSessionService.serverTime = AppSessionService.serverTime.add(1, 'second');
                }, 1000);
                resolve(true);
            }, function (err) {
                reject(err);
            });
        });
    };
    AppSessionService.prototype.changeTenantIfNeeded = function (tenantId) {
        if (this._isCurrentTenant(tenantId)) {
            return false;
        }
        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    };
    AppSessionService.prototype.containAnyRoleFromList = function (roles) {
        var _this = this;
        var info = this._currentLoginInformations;
        if (!info || !info.userRoles || !info.userRoles.length) {
            return false;
        }
        var hasRole = false;
        each(roles, function (role) {
            hasRole = _this._containRole(role, info.userRoles);
            if (hasRole) {
                return false;
            }
        });
        return hasRole;
    };
    AppSessionService.prototype.containRole = function (role) {
        var info = this._currentLoginInformations;
        if (!info) {
            return false;
        }
        return this._containRole(role, info.userRoles);
    };
    AppSessionService.prototype.refresh = function () {
        return from(this.init());
    };
    AppSessionService.prototype.resetSessionUserInfo = function () {
        if (this._currentLoginInformations) {
            this._currentLoginInformations.user = new UserLoginInfoDto();
            this._currentLoginInformations.userRoles = [];
            this._currentLoginInformations.lawyerId = null;
            this._currentLoginInformations.initiatorId = null;
        }
    };
    AppSessionService.prototype._containRole = function (role, activeUserRoles) {
        if (!role || !activeUserRoles || !activeUserRoles.length) {
            return false;
        }
        var searchRole = role.toLowerCase();
        return !!find(activeUserRoles, function (r) {
            return r.toLowerCase() === searchRole;
        });
    };
    AppSessionService.prototype._isCurrentTenant = function (tenantId) {
        var tenant = this.tenant;
        if (!tenantId || !tenant) {
            return false;
        }
        return tenant.id === tenantId;
    };
    AppSessionService.ngInjectableDef = i0.defineInjectable({ factory: function AppSessionService_Factory() { return new AppSessionService(i0.inject(i1.SessionServiceProxy), i0.inject(i2.AbpMultiTenancyService)); }, token: AppSessionService, providedIn: "root" });
    return AppSessionService;
}());
export { AppSessionService };

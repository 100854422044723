import { Pipe, PipeTransform } from '@angular/core';
import { DatePeriodFormatter } from './date-period-formatter';

@Pipe({
    name: 'datePeriod',
})
/**
 * Форматирует данные временного периода.
 * ---
 *
 * ## Примеры использования:
 *
 * ```html
 * <input value="10:15:00 | datePeriod"> <!-- 0.10:15:00 -->
 * <input value="10:15:00 | datePeriod:'HH:mm'"> <!-- 10:15 -->
 * <input value="1.10:15:00 | datePeriod:'HH ч mm мин'"> <!-- 34 ч 15 мин -->
 * ```
 *
 * @see DatePeriodFormatter
 */
export class DatePeriodPipe implements PipeTransform {
    transform(value: string, ...args: any[]): string {
        return DatePeriodFormatter.transform(value, ...args);
    }
}

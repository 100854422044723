import { Injector, Type, InjectionToken } from '@angular/core';
import { LitigationStageServiceProxy, JudicialDistrictServiceProxy, LawyerRequestServiceProxy, LawyerRequestFileServiceProxy, LawyerResponseServiceProxy, InitiatorServiceProxy, StatusHistoryServiceProxy, LawyerStatusReasonServiceProxy, LawChamberServiceProxy, LawyerServiceProxy, ArticleParticipationTypeServiceProxy, SpecializationServiceProxy, ContactServiceProxy, JudicialAuthorityServiceProxy, LawyerRequestCommentServiceProxy, OfficialDocumentTypeServiceProxy, OrganizationServiceProxy, } from '../service-proxies';
import { StorageInMemory } from './storage-in-memory';
import { LitigationTypeServiceProxyAdapter } from './adapters/concrete/LitigationTypeServiceProxyAdapter';
import * as i0 from "@angular/core";
var DataBaseService = /** @class */ (function () {
    function DataBaseService(_injector) {
        this._injector = _injector;
    }
    Object.defineProperty(DataBaseService.prototype, "judicialDistricts", {
        // справочники
        get: function () {
            return this._getOrCreateEntityStorage('judicialDistricts', JudicialDistrictServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "judicialAuthorities", {
        /**
         * Справочник судов, органов дознания и следствия
         */
        get: function () {
            return this._getOrCreateEntityStorage('judicialAuthority', JudicialAuthorityServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "litigationStages", {
        get: function () {
            return this._getOrCreateEntityStorage('litigationStages', LitigationStageServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "litigationTypes", {
        get: function () {
            return this._getOrCreateEntityStorage('litigationTypes', LitigationTypeServiceProxyAdapter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "specialization", {
        get: function () {
            return this._getOrCreateEntityStorage('specialization', SpecializationServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "articleParticipationType", {
        get: function () {
            return this._getOrCreateEntityStorage('articleParticipationType', ArticleParticipationTypeServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "lawChamber", {
        get: function () {
            return this._getOrCreateEntityStorage('lawChamber', LawChamberServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "lawyerStatusReason", {
        get: function () {
            return this._getOrCreateEntityStorage('lawyerStatusReason', LawyerStatusReasonServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "organization", {
        get: function () {
            return this._getOrCreateEntityStorage('organization', OrganizationServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "lawyerRequest", {
        // остальное
        get: function () {
            return this._getOrCreateEntityStorage('lawyerRequest', LawyerRequestServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "lawyerRequestFile", {
        get: function () {
            return this._getOrCreateEntityStorage('lawyerRequestFile', LawyerRequestFileServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "lawyerRequestComment", {
        get: function () {
            return this._getOrCreateEntityStorage('lawyerRequestComment', LawyerRequestCommentServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "lawyerResponse", {
        get: function () {
            return this._getOrCreateEntityStorage('lawyerResponse', LawyerResponseServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "initiator", {
        get: function () {
            return this._getOrCreateEntityStorage('initiator', InitiatorServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "statusHistory", {
        get: function () {
            return this._getOrCreateEntityStorage('statusHistory', StatusHistoryServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "lawyer", {
        get: function () {
            return this._getOrCreateEntityStorage('lawyer', LawyerServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "contact", {
        get: function () {
            return this._getOrCreateEntityStorage('contact', ContactServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataBaseService.prototype, "officialDocumentType", {
        get: function () {
            return this._getOrCreateEntityStorage('officialDocumentType', OfficialDocumentTypeServiceProxy);
        },
        enumerable: true,
        configurable: true
    });
    DataBaseService.prototype._getOrCreateEntityStorage = function (entityName, token) {
        var privatePropName = '__' + entityName;
        if (!this[privatePropName]) {
            this[privatePropName] = new StorageInMemory(this._injector.get(token));
        }
        return this[privatePropName];
    };
    DataBaseService.ngInjectableDef = i0.defineInjectable({ factory: function DataBaseService_Factory() { return new DataBaseService(i0.inject(i0.INJECTOR)); }, token: DataBaseService, providedIn: "root" });
    return DataBaseService;
}());
export { DataBaseService };

import { PipeTransform } from '@angular/core';
import { DatePeriodFormatter } from './date-period-formatter';
var DatePeriodPipe = /** @class */ (function () {
    function DatePeriodPipe() {
    }
    DatePeriodPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return DatePeriodFormatter.transform.apply(DatePeriodFormatter, [value].concat(args));
    };
    return DatePeriodPipe;
}());
export { DatePeriodPipe };

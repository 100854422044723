import { AbstractControl } from '@angular/forms';
import { APP_REGEXP } from './regexp';

export class RegistryNumberFormatter {
    transformForForm(ctrl: AbstractControl, detectChanges: () => void): void {
        const val = (ctrl.value + '').trim();
        const checkVal = this.transformToViewValue(val);

        if (val && (!checkVal || checkVal.length < 3)) {
            if (val !== checkVal) {
                ctrl.patchValue(checkVal);
                detectChanges();
            }
        } else if (val.length >= 3) {
            const checkVal2 = this.transformToViewValue(checkVal, true);

            if (val !== checkVal2) {
                ctrl.patchValue(checkVal2);
                detectChanges();
            }
        }
    }
    transformToViewValue(registryNumber: string, normalized = false): string {
        const val = normalized ? registryNumber : this.normalize(registryNumber);
        if (val && val.length >= 3) {
            return val.replace(/^(\d{2})(\d+)/, '$1/$2');
        } else if (val && val.length === 2) {
            return val.replace(/^(\d{2})/, '$1/');
        }
        return val;
    }
    normalize(registryNumber: string | number): string {
        registryNumber = (registryNumber + '').trim();
        return registryNumber.replace(APP_REGEXP.replaceAllNotNumbers, '');
    }
}

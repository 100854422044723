// tslint:disable-next-line:class-name
export class APP_REGEXP {
    static get phoneMaskTemplateChar() {
        return 'x';
    }
    static get phoneMaskTemplate() {
        return '+7 (xxx) xxx-xx-xxxxx';
    }
    static get zeroUnicodeChar(): string {
        return '\u24EA'; // empty circled zero
        // return '\u24ff'; // circled zero with  black fill (negative zero)
    }
    static get defaultMaskChar(): string {
        return '_';
    }
    static get defaultPhoneMaskChar(): string {
        return APP_REGEXP.defaultMaskChar;
    }

    static get isUrl(): RegExp {
        return new RegExp(
            // tslint:disable-next-line:max-line-length
            /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        );
    }
    static get isPhone(): RegExp {
        return new RegExp(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/);
    }
    static get isMoscowPhoneCode(): RegExp {
        // +7 (495) 123 45 45
        return new RegExp(/\((49[59])\)/);
    }

    static get isDate(): RegExp {
        return new RegExp(/^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$/);
    }
    static get isEmail(): RegExp {
        return new RegExp(/^[a-zA-Z\d_\.-]+@[a-zA-Z\d_\.-]+\.[a-zA-Z]+$/);
    }
    static get isOnlyNumber(): RegExp {
        return new RegExp(/^\d+$/);
    }
    static get isRegistryNumber(): RegExp {
        return new RegExp(/^[0-9]+(?:\/[0-9]+)?$/);
    }
    static get isLogin(): RegExp {
        return new RegExp(/^[a-zA-Z0-9!@#$%^&*()_+[\]{}|;:'",.<>?/\\=-]+$/);
    }
    static get createMobilePhoneMask() {
        return APP_REGEXP.createDefaultPhoneMask;
    }
    static get createDefaultPhoneMask() {
        const d = APP_REGEXP.numberRegExp;
        return ['+', '7', ' ', '(', d, d, d, ')', ' ', d, d, d, '-', d, d, '-', d, d];
    }
    static get numberRegExp(): RegExp {
        return new RegExp(/\d/);
    }

    static get replaceAllDoubleSpace(): RegExp {
        return new RegExp(/\s{2,}/);
    }
    static get defaultPhoneMask(): string {
        const d = APP_REGEXP.defaultPhoneMaskChar;
        const templateChar = this.defaultPhoneMaskChar;
        const template = this.phoneMaskTemplate;
        return template.replace(templateChar, d);
    }

    static get zeroPhoneMask(): string {
        const zeroChar = APP_REGEXP.zeroUnicodeChar;
        const template = this.phoneMaskTemplate;
        const templateChar = this.phoneMaskTemplateChar;
        return template.replace(templateChar, zeroChar);
    }

    static get replaceAllNotNumbers(): RegExp {
        return new RegExp(/\D/g);
    }
    static get isOnlyLetters() {
        return new RegExp(/[A-Za-z]/g);
    }
    static get isOnlyRussianLetters() {
        return new RegExp(/^[ёа-я- ]+$/iu);
    }
    static get replaceAllNotRussian() {
        return new RegExp(/[^ёа-я- ]/iu);
    }
    static get isRussianInfinityFullName() {
        return new RegExp(/^[ёа-я-]{2,36}\s+[ёа-я-]{2,36}(\s+[ёа-я-]{2,36}){0,}$/iu);
    }
    static get isRussianFullName() {
        return new RegExp(/^[ёа-я-]{1,36}\s+[ёа-я-]{1,36}(\s+[ёа-я-]{1,36}){0,}$/iu);
    }
    static get isPassword() {
        return new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&-_=+)(]{6,32}$/);
    }

    static get isTime() {
        return new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
    }

    /**
     * Регулярное выражение проверки доменного имени
     *
     * Совпадает с:
     * * example.com
     * * www.example.com
     * * www.example-org.com
     * * www.city.example-org.com
     *
     * @see https://en.wikipedia.org/wiki/Domain_name
     */
    static get isDomain() {
        return new RegExp(/^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,6}$/i);
    }
}

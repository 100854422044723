import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { AbpModule } from '@abp/abp.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatPaginatorIntl } from '@angular/material';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AbpHttpConfiguration, AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { AuthHttpConfiguration, AuthHttpInterceptor } from './auth/interseptors';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { ModalModule } from 'ngx-bootstrap';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';


import {
    AppConsts,
    SharedModule,
    AppSessionService,
    API_BASE_URL,
    NotFoundComponent,
    MatPaginatorIntlDefault,
} from '@shared/index';

import { RootComponent } from './root.component';
import { AppPreBootstrap } from './app-pre-bootstrap';
import { RootRoutingModule } from './root-routing.module';

import { LkDefatulComponent } from 'default-pages/lk-default/lk-default.component';
import { SitemapModule } from './sitemap/sitemap.module';
import { LogService } from '@abp/log/log.service';
import { CookieModule } from 'ngx-cookie';

export function appInitializerFactory(injector: Injector) {
    return () => {
        abp.ui.setBusy();
        return new Promise<boolean>((resolve, reject) => {
            const onError = err => {
                abp.ui.clearBusy();
                reject(err);
            };
            AppPreBootstrap.run('ui', () => {
                const appSessionService: AppSessionService = injector.get(AppSessionService);
                appSessionService.init().then(result => {
                    resolve(result);
                    abp.ui.clearBusy();
                }, onError);
            });
        });
    };
}

export function getRemoteServiceBaseUrl(): string {
    return AppConsts.remoteServiceBaseUrl;
}

export function getCurrentLanguage(): string {
    const localeName = abp.localization.currentLanguage.name;
    switch (localeName) {
        case 'ru':
            registerLocaleData(localeRu);
            break;
        default:
            registerLocaleData(localeRu);
            break;
    }
    return localeName;
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
        ModalModule.forRoot(),

        AbpModule,

        RootRoutingModule,
        HttpClientModule,
        SitemapModule,

        OAuthModule.forRoot({
            resourceServer: {
              allowedUrls: ['http://www.angular.at/api'],
              sendAccessToken: true
            }
          }),

        NgxsModule.forRoot([]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        // NgxsLoggerPluginModule.forRoot(),

        CookieModule.forRoot()
    ],
    declarations: [
        RootComponent,
        LkDefatulComponent,
        NotFoundComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AbpHttpInterceptor,
            multi: true,
        },
        {
            provide: AbpHttpConfiguration,
            useClass: AuthHttpConfiguration,
        },
        { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [Injector],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: getCurrentLanguage,
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlDefault,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'ru-Ru',
        },
        LogService,
    ],
    bootstrap: [RootComponent],
})
export class RootModule {}

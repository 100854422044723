import { Type } from '@angular/core';
import { AppFormFieldType } from '@shared/enums';
import { BaseDataFilterValueBuilder } from './base-data-filter-value-builder';

/**
 * Интерфейс модели поля фильтра в форме
 */
export interface IBaseFilterFieldModel {
    /**
     * Id фильтра, с которым будет работать поле
     */
    filterId: string;

    /**
     * Тип поля.
     * Определяет шаблон вывода в форме
     */
    type: AppFormFieldType;

    /**
     * Имя поля.
     * Используется для заполнения атрибута name в поле формы и для генерации control с помощью FormBuilder
     */
    name: string;

    /**
     * Название поля.
     * Определяет содержимое тега label
     */
    label: string;

    /**
     * Тип, который используется для создания объекта-строителя модели IDataFilterValueDto
     */
    dataBuilder: Type<BaseDataFilterValueBuilder>;

    /**
     * Дополнительные параметры, необходимые для создания поля в форме
     */
    params?: { [key: string]: any };
}

/**
 * Модель поля фильтра в форме
 */
export class DefaultFilterFieldModel implements IBaseFilterFieldModel {
    filterId: string;
    type: AppFormFieldType;
    name: string;
    label: string;
    dataBuilder: Type<BaseDataFilterValueBuilder>;
    params?: { [key: string]: any };
}

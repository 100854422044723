﻿import * as moment from 'moment-timezone';
import { AppConsts } from '@shared/AppConsts';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CompilerOptions, NgModuleRef, Type } from '@angular/core';
import { environment } from './environments/environment';

let pathTrimRight = function(value) {
    return value.replace(/\/$/gm, '');
};

let getAbsoluteUrl = function(url) {
    let a = document.createElement('a');
    getAbsoluteUrl = function(url) {
        a.href = url;
        return pathTrimRight(a.href);
    };
    return getAbsoluteUrl(url);
};

export class AppPreBootstrap {

    static run(appKey: string, callback: () => void): void {
        AppPreBootstrap.getApplicationConfig(appKey, () => {
            AppPreBootstrap.getUserConfiguration(callback);
        });
    }

    static bootstrap<TM>(
        moduleType: Type<TM>,
        compilerOptions?: CompilerOptions | CompilerOptions[],
    ): Promise<NgModuleRef<TM>> {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    }

    private static getApplicationConfig(appKey: string, callback: () => void) {
        return abp
            .ajax({
                url: '/assets/apps.json',
                method: 'GET',
                headers: {
                    'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                    '__tenant': AppConsts.defaultTenantUid,
                },
            })
            .done(result => {
                let appSettings: { appBaseUrl: string } = result[appKey];

                console.log('appSettings', {
                    appSettings: appSettings,
                    environment: environment,
                });

                AppConsts.appBaseUrl = getAbsoluteUrl(appSettings.appBaseUrl);
                let stand = '';
                if (environment.stand) {
                    stand = '.' + environment.stand;
                }
                return abp
                    .ajax({
                        url: AppConsts.appBaseUrl + `/assets/appconfig${stand}.json`,
                        method: 'GET',
                        headers: {
                            'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                            '__tenant': AppConsts.defaultTenantUid,
                        },
                    })
                    .done(result => {

                        // Здесь может приходить другой url, поэтому используем значение из apps.json выше
                        // AppConsts.appBaseUrl = getAbsoluteUrl(result.appBaseUrl);
                        AppConsts.remoteServiceBaseUrl = getAbsoluteUrl(result.remoteServiceBaseUrl);

                        AppConsts.oidcBaseUrl = result.oidcBaseUrl;
                        AppConsts.oidcClientId = result.oidcClientId;
                        AppConsts.oidcClientSecret = result.oidcClientSecret;
                        AppConsts.oidcClientScope = result.oidcClientScope;

                        callback();
                    });
            });
    }

    private static getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
        if (currentProviderName === 'unspecifiedClockProvider') {
            return abp.timing.unspecifiedClockProvider;
        }

        if (currentProviderName === 'utcClockProvider') {
            return abp.timing.utcClockProvider;
        }

        return abp.timing.localClockProvider;
    }

    private static getUserConfiguration(callback: () => void): JQueryPromise<any> {
        return abp
            .ajax({
                url: AppConsts.remoteServiceBaseUrl + '/AbpUserConfiguration/GetAll',
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + abp.auth.getToken(),
                    '.AspNetCore.Culture': abp.utils.getCookieValue('Abp.Localization.CultureName'),
                    'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                    '__tenant': AppConsts.defaultTenantUid,
                },
            })
            .done(result => {
                $.extend(true, abp, result);

                abp.clock.provider = this.getCurrentClockProvider(result.clock.provider);

                moment.locale(abp.localization.currentLanguage.name);

                if (abp.clock.provider.supportsMultipleTimezone) {
                    moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
                }

                callback();
            });
    }
}

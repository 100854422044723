var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Validators } from '@angular/forms';
import { isEmpty, toString, eq, has } from 'lodash';
import * as moment from 'moment';
import { APP_REGEXP } from '../infrastructure/regexp';
var AppValidators = /** @class */ (function (_super) {
    __extends(AppValidators, _super);
    function AppValidators() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppValidators.isEmptyString = function (ctrl) {
        if (!ctrl.touched) {
            return null;
        }
        var val = toString(ctrl.value || '').trim();
        if (isEmpty(val)) {
            return { isEmptyString: true };
        }
        return null;
    };
    AppValidators.isFullUserName = function (isFullUserNameRegexp) {
        var error = { isFullUserName: true };
        return function (ctrl) {
            if (!ctrl.value) {
                return error;
            }
            var val = toString(ctrl.value)
                .replace(APP_REGEXP.replaceAllDoubleSpace, '')
                .trim();
            if (!isFullUserNameRegexp.test(val)) {
                return error;
            }
            return null;
        };
    };
    AppValidators.inPhoneOrEmail = function (ctrl) {
        var isPhone = APP_REGEXP.isPhone.test(ctrl.value);
        if (isPhone) {
            return null;
        }
        var isEmail = APP_REGEXP.isEmail.test(ctrl.value);
        if (isEmail) {
            return null;
        }
        return { inPhoneOrEmail: true };
    };
    AppValidators.isEmailValid = function (ctrl) {
        if (!ctrl.touched && !ctrl.dirty) {
            return null;
        }
        if (!ctrl.value) {
            return null;
        }
        if (!AppValidators.isEmailValidByValue(ctrl.value)) {
            return { isEmailValid: true };
        }
        return null;
    };
    AppValidators.isEmailValidByValue = function (val) {
        val = toString(val).trim();
        var mailRegExp = APP_REGEXP.isEmail;
        return val.search(mailRegExp) >= 0;
    };
    AppValidators.isDefaultMobilePhoneMask = function (ctrl) {
        var error = { isDefaultMobilePhoneMask: true };
        var isCityPhone = AppValidators.isCityPhoneByValue(ctrl.value);
        if (isCityPhone) {
            return error;
        }
        var isDefault = AppValidators.isDefaultMobilePhoneMaskByValue(ctrl.value);
        if (isDefault) {
            return error;
        }
        return null;
    };
    AppValidators.isDefaultMobilePhoneMaskByValue = function (value) {
        var val = value + '';
        var defaultMask = APP_REGEXP.defaultPhoneMask;
        var zeroMask = APP_REGEXP.zeroPhoneMask;
        var template = APP_REGEXP.phoneMaskTemplate;
        var defaultChar = APP_REGEXP.defaultPhoneMaskChar;
        var templateChar = APP_REGEXP.phoneMaskTemplateChar;
        if (eq(val, defaultMask) ||
            eq(val, template) ||
            eq(val, zeroMask) ||
            val.search(defaultChar) !== -1 ||
            val.search(templateChar) !== -1) {
            return true;
        }
        return false;
    };
    AppValidators.dateRange = function (minDate, maxDate, format, undefinedIsError) {
        if (format === void 0) { format = 'DD.MM.YYYY'; }
        if (undefinedIsError === void 0) { undefinedIsError = false; }
        var min = moment(moment(minDate, format).format(format), format);
        var max = moment(moment(maxDate, format).format(format), format);
        var error = { dateRange: true };
        return function (control) {
            if (!control.touched) {
                return null;
            }
            var isEmptyVal = control.value === null || control.value === undefined || control.value === '';
            if (undefinedIsError && isEmptyVal) {
                return error;
            }
            if (isEmptyVal) {
                return null;
            }
            var date = AppValidators._getMomentDate(control.value);
            var inputDate = AppValidators._getInputDate(control.value);
            if (AppValidators._hasDateMask(inputDate)) {
                return error;
            }
            if (!date.isValid() || date.isAfter(max) || date.isBefore(min)) {
                return error;
            }
            return null;
        };
    };
    /**
     *
     * Создает валидатор для входящего значения отсносительно таргетингового времени (с учетом часового мояса)
     * и минимально максимальных отклонений от него
     * @param minRangeDuration абсолютно минимальное смещение времени относительно сейчас
     * @param maxRangeDuration абсолютно максимальное смещение времени относительно сейчас
     * @param utcOffsetDuration смещение времени для  value относительно utc
     * @param undefinedIsError
     */
    AppValidators.dateRangeOffsetNow = function (minRangeDuration, maxRangeDuration, utcOffsetDuration, undefinedIsError) {
        if (undefinedIsError === void 0) { undefinedIsError = false; }
        var error = { dateRange: true };
        return function (control) {
            if (!control.touched) {
                return null;
            }
            var isEmptyVal = control.value === null || control.value === undefined || control.value === '';
            if (undefinedIsError && isEmptyVal) {
                return error;
            }
            if (isEmptyVal) {
                return null;
            }
            var inputDate = AppValidators._getInputDate(control.value);
            if (AppValidators._hasDateMask(inputDate)) {
                return error;
            }
            var now = moment()
                .utc(true)
                .add(utcOffsetDuration);
            var min = now.clone().add(minRangeDuration);
            var max = now.clone().add(maxRangeDuration);
            var date = AppValidators._getMomentDate(control.value);
            var val = moment(date)
                .clone()
                .utc(true);
            if (val > max || val < min) {
                return error;
            }
            return null;
        };
    };
    AppValidators.isOnlyRussianLetters = function (errorKey, regexp) {
        if (errorKey === void 0) { errorKey = 'isOnlyRussianLetters'; }
        if (regexp === void 0) { regexp = APP_REGEXP.isOnlyRussianLetters; }
        var _a;
        var e = (_a = {},
            _a[errorKey] = true,
            _a);
        return function (control) {
            var isOnlyRussianLetters = regexp.test(control.value);
            if (!isOnlyRussianLetters) {
                return e;
            }
            return null;
        };
    };
    AppValidators.isDomain = function (ctrl) {
        var value = ctrl.value;
        if (APP_REGEXP.isDomain.test(value)) {
            return null;
        }
        return {
            isDomain: true,
        };
    };
    AppValidators.isCityPhoneByValue = function (value, regexp) {
        if (regexp === void 0) { regexp = APP_REGEXP.isMoscowPhoneCode; }
        if (!value) {
            return false;
        }
        value = (value + '').trim();
        if (value.length < 5) {
            return false;
        }
        return regexp.test(value);
    };
    AppValidators.isCityPhone = function (regexp) {
        if (regexp === void 0) { regexp = APP_REGEXP.isMoscowPhoneCode; }
        return function (control) {
            if (AppValidators.isCityPhoneByValue(control.value, regexp)) {
                return null;
            }
            return {
                isCityPhone: true,
            };
        };
    };
    AppValidators.isPhone = function (control) {
        if (AppValidators.isPhoneByValue(control.value)) {
            return null;
        }
        return { isPhone: true };
    };
    AppValidators.isPhoneByValue = function (value) {
        var val = value + '';
        return APP_REGEXP.isPhone.test(val);
    };
    AppValidators.isNumber = function (control) {
        if (AppValidators.isNumberByValue(control.value)) {
            return null;
        }
        return { isNumber: true };
    };
    AppValidators.isNumberByValue = function (value) {
        return APP_REGEXP.isOnlyNumber.test(value);
    };
    AppValidators.isRegistryNumberByValue = function (value) {
        var val = (value + '').replace(APP_REGEXP.replaceAllNotNumbers, '').replace(' ', '');
        if (!val) {
            console.log('!value', val);
            return false;
        }
        if (val.length < 3 || val.length > 8) {
            console.log('value.length < 3 || value.length > 8', val);
            return false;
        }
        if (value.startsWith('77/')) {
            return true;
        }
        return true;
    };
    AppValidators.isRegistryNumber = function (ctrl) {
        var isRegistry = AppValidators.isRegistryNumberByValue(ctrl.value);
        if (!isRegistry) {
            return { isRegistryNumber: true };
        }
        return null;
    };
    AppValidators.isMatched = function (ctrl1, whenTouched) {
        if (whenTouched === void 0) { whenTouched = true; }
        return function (ctrl2) {
            if (whenTouched && (!ctrl1.touched || !ctrl2.touched)) {
                return null;
            }
            if (ctrl1.value !== ctrl2.value) {
                return { isMatched: true };
            }
            return null;
        };
    };
    AppValidators._getMomentDate = function (value) {
        var date;
        if (typeof value === 'object' && has(value, 'moment')) {
            date = value.moment;
        }
        else {
            date = moment(value);
        }
        return date;
    };
    AppValidators._getInputDate = function (value) {
        if (typeof value === 'object' && has(value, 'input')) {
            return value.input;
        }
        else if (typeof value === 'string') {
            return value;
        }
        return '';
    };
    AppValidators._hasDateMask = function (value, defaultMaskChar) {
        if (defaultMaskChar === void 0) { defaultMaskChar = APP_REGEXP.defaultMaskChar; }
        if (!value) {
            return false;
        }
        return value.search(defaultMaskChar) >= 0;
    };
    return AppValidators;
}(Validators));
export { AppValidators };

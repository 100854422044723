﻿import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AppAuthService {

    constructor(
        private _router: Router,
    ) {
    }

    logout(reload?: boolean): void {
        abp.auth.clearToken();
        if (reload !== false) {
            location.href = AppConsts.appBaseUrl;
        }
    }

    reEnter(returnUrl: string | null = null, reload: boolean = false) {
        abp.auth.clearToken();

        if (!returnUrl) {
            returnUrl = encodeURIComponent(location.href);
        }

        console.log(returnUrl, location.href.includes('/account/login'), encodeURIComponent(location.href));

        if (reload === false) {
            this._router.navigate(['/account/login', returnUrl ? { returnUrl: returnUrl } : {}]);
        } else {
            location.href = AppConsts.appBaseUrl + '/account/login' + (returnUrl ? ('?returnUrl=' + returnUrl) : '');
        }
    }
}

import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { TextMaskConfig } from 'angular2-text-mask';
import { each, findIndex, isEmpty, orderBy } from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    APP_REGEXP,
    AppFormFieldType,
    createDateMaskFromFormat,
    FileUploaderOption,
    IAppFormErrorMessage,
    IConfigurable,
    IDatePickerProps,
    IDatePickerType,
    IFileUploaderOption,
    IKeyValue,
    IDatePickerEvent,
    DatePickerFormats,
    ISelectable,
    IKeyValueStrings,
    IInstance,
    NullObject,
} from './_imports';

// cker/date-picker.interface';

/* #region interface */

export enum AppUpdateOnFormOptions {
    change = 'change',
    blur = 'blur',
    submit = 'submit',
}
export interface IAppOrderedField<ValueType> extends IKeyValue<string, ValueType> {
    order: number;
    key: string; // fieldName:
    value: ValueType; // fieldValue:
}

export interface IAppFormOrderedFieldEventOption<T> {
    control: AbstractControl;
    newValue: T | string;
    formGroup?: FormGroup;
}

export interface IAppFormOrderedField<ValueType> extends IAppOrderedField<ValueType> {
    initialValue?: ValueType | string | undefined;
    required?: boolean;
    type: string;
    label: string;
    disabled?: boolean;
    readonly?: boolean;
    validators?: ValidatorFn[];
    errorMessages?: IAppFormErrorMessage[];
    valueChanges?: EventEmitter<IAppFormOrderedFieldEventOption<ValueType | string>>;
    formGroupRef?: FormGroup;
    appendFormGroup?: boolean;
    advancedMessages?: string[];
    updateOn?: AppUpdateOnFormOptions;
    maxlength?: number;
    hidden?: boolean;
}
export interface IAppResizableField {
    autosizeIsEnabled?: boolean;
    minRows?: number;
    maxRows?: number;
}
export interface IAppFormData {
    fields: IAppFormOrderedField<any>[];
    isOrderedFields?: boolean;
    onGroupFormInitialized?: Subject<FormGroup>;
    _formGroup?: FormGroup;
}
/* #endregion */

/* #region BaseAppOrderedField */
export abstract class BaseAppOrderedField<ValueType>
    implements IAppFormOrderedField<ValueType>, IConfigurable<IAppFormOrderedField<ValueType>> {
    initialValue?: ValueType | string;
    required?: boolean;
    label: string;
    disabled?: boolean;
    validators?: ValidatorFn[];
    errorMessages?: IAppFormErrorMessage[];
    valueChanges: EventEmitter<IAppFormOrderedFieldEventOption<ValueType | string>>;
    appendFormGroup?: boolean;
    readonly?: boolean;
    formGroupRef?: FormGroup;
    advancedMessages?: string[];

    order: number;
    key: string;
    value: ValueType;
    updateOn?: AppUpdateOnFormOptions;
    notUpdateOnInput?: boolean;
    hidden?: boolean;

    constructor(public type: AppFormFieldType, protected readonly _className: string) {}

    public configure(configurator: (_this: IAppFormOrderedField<ValueType>) => void): IAppFormOrderedField<ValueType> {
        configurator(this);
        return this;
    }
    protected _log(data) {
        console.log(this._className + '_log', {
            data: data,
        });
    }
    protected _notImplementedException(data = null) {
        const msg = this._className + ' : Method not implemented.';
        console.error(msg, {
            data: data,
        });
        throw new Error(msg);
    }
}
/* #endregion */
export class AppOrderedFieldEmpty implements IAppFormOrderedField<null> {
    initialValue?: string;
    required?: boolean;
    type: string;
    label: string;
    disabled?: boolean;
    validators?: ValidatorFn[];
    errorMessages?: IAppFormErrorMessage[];
    valueChanges?: EventEmitter<IAppFormOrderedFieldEventOption<string>>;
    formGroupRef?: FormGroup;
    appendFormGroup?: boolean;
    advancedMessages?: string[];
    order: number;
    key: string;
    value: null;
    constructor() {
        this.type = this.key = AppFormFieldType.empty;
    }
}

/* #region bool Fields */
export class AppOrderedFieldCheckbox extends BaseAppOrderedField<boolean> {
    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldCheckbox';
        }
        super(AppFormFieldType.checkbox, className);
    }
}

export class AppOrderedFieldCheckboxIsHospitalizationProcedure extends BaseAppOrderedField<boolean> {

    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldCheckboxIsHospitalizationProcedure';
        }
        super(AppFormFieldType.checkboxIsHospitalizationProcedure, className);
    }
}

/* #endregion */

/* #region date Fields */
export class AppOrderedFieldDatepicker extends BaseAppOrderedField<Date> {
    public readonly dateFormat: string;
    public mask: TextMaskConfig;
    private _validationCreated: boolean;
    constructor(initialValue?: Date | Object | moment.Moment | string, dateFormat = 'DD.MM.YYYY', className?: string) {
        if (!className) {
            className = 'AppOrderedFieldDatepicker';
        }
        super(AppFormFieldType.datepicker, className);
        if (initialValue) {
            if (initialValue instanceof Date) {
                this.initialValue = initialValue;
            } else if (initialValue && moment.isMoment(initialValue)) {
                this.initialValue = (<moment.Moment>initialValue).toDate();
            } else {
                this._log({
                    initialValue: initialValue,
                });
            }
        }
        this._validationCreated = false;
        this.dateFormat = dateFormat;
        this.mask = createDateMaskFromFormat(this.dateFormat);
    }

    createValidaion(dateFormat: string = this.dateFormat): void {
        if (this._validationCreated) {
            return;
        }
        if (!this.validators) {
            this.validators = [];
        }
        this.validators.push((control: AbstractControl) => {
            const val = control.value;
            if (!val && !this.required) {
                return null;
            }
            const m = moment(val, dateFormat);
            if (m.isValid()) {
                const date = m.format(dateFormat);
                if (!val || isEmpty(date) || !APP_REGEXP.isDate.test(date)) {
                    return this._error();
                }
                return null;
            } else {
                return this._error();
            }
        });
        const errorMsg = {
            key: this.key,
            value: 'Не верный формат даты',
        };
        if (!this.errorMessages) {
            this.errorMessages = [];
        }
        this.errorMessages.push(errorMsg);
        this._validationCreated = true;
    }

    private _error() {
        const result = {};
        result[this.key] = true;
        return result;
    }
}
export interface IAppOrderedFieldAppDatePicker extends IAppFormOrderedField<IDatePickerEvent | string>, IInstance {
    dateFormat: string;
    instanceId: string;
    initValue?: moment.Moment | Date | string;
    config: IDatePickerProps;
    label: string;
    required: boolean;
    disabled: boolean;
    invalidValue: moment.Moment | Date | string | undefined | null | NullObject;
}
export class AppOrderedFieldAppDatePicker extends BaseAppOrderedField<IDatePickerEvent | string>
    implements IAppOrderedFieldAppDatePicker {
    readonly dateFormat: string;
    instanceId: string;
    initValue?: moment.Moment | Date | string;
    invalidValue: moment.Moment | Date | string | undefined | null | NullObject;

    private _config: IDatePickerProps;
    private _required: boolean;
    private _label: string;
    private _placeholder: string;
    private _disabled: boolean;
    private _mask: TextMaskConfig;
    private _type: IDatePickerType;
    private _min: moment.Moment;
    private _max: moment.Moment;
    private _showHint?: boolean;
    private _hintMessageView?: string;
    private _guide?: boolean;

    constructor(pickerType: AppFormFieldType, className?: string) {
        if (!className) {
            className = 'AppOrderedFieldAppDatePicker';
        }
        super(pickerType, className);
        if (pickerType === AppFormFieldType.appDatePicker) {
            this.dateFormat = DatePickerFormats.date;
        } else if (pickerType === AppFormFieldType.appDateTtimePpicker) {
            this.dateFormat = DatePickerFormats.dateTime;
        }
        this.invalidValue = null;
    }
    get config(): IDatePickerProps {
        return this._config || (this._config = this._createConfig());
    }

    get label(): string {
        return this._label;
    }
    set label(value: string) {
        this._label = value;
    }
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value;
    }

    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = value;
    }

    private _createConfig(): IDatePickerProps {
        const propNames = [
            'placeholder',
            'label',
            'required',
            'disabled',
            'mask',
            'type',
            'min',
            'max',
            'showHint',
            'hintMessageView',
            'guide',
        ];
        const props = Object.create(null);
        each(propNames, prop => {
            const privateProp = '_' + prop;
            Object.defineProperty(props, prop, {
                get: () => {
                    return this[privateProp];
                },
                set: value => {
                    this[privateProp] = value;
                },
            });
        });
        return props;
    }
}

/* #endregion */

/* #region File Fields */
export class AppOrderedFieldFile extends BaseAppOrderedField<IFileUploaderOption> {
    formGroupRef: FormGroup;
    appendFormGroup: boolean;
    _disabled: boolean;
    constructor(instanceId: string, option?: IFileUploaderOption, className?: string) {
        if (!className) {
            className = 'AppOrderedFieldFile';
        }
        super(AppFormFieldType.file, className);
        this.value = option || new FileUploaderOption(instanceId);
        this.appendFormGroup = true;
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = this.value.disabled = value;
    }
}
/* #endregion */

/* #region Text fields */
export class AppOrderedFieldText extends BaseAppOrderedField<string> {
    useHint: boolean;
    hintVAlign: 'up' | 'down';
    maxlength: number;
    hintAlign: 'start' | 'end';
    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldText';
        }
        super(AppFormFieldType.text, className);
        this.useHint = false;
        this.hintAlign = 'end';
        this.hintVAlign = 'down';
    }
}

export class AppOrderedFieldPassword extends BaseAppOrderedField<string> {
    maxlength?: number;
    type: AppFormFieldType;
    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldPassword';
        }

        super(AppFormFieldType.password, className);


        this.type = AppFormFieldType.password;
    }
}

export class AppOrderedFieldTextArea extends BaseAppOrderedField<string> implements IAppResizableField {
    minRows: number;
    maxRows: number;
    autosizeIsEnabled: boolean;

    readonly: boolean;
    // hint opts
    useHint: boolean;
    maxlength: number;
    hintAlign: 'start' | 'end';

    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldTextArea';
        }
        super(AppFormFieldType.textArea, className);

        this.minRows = 5;
        this.maxRows = 100;
        this.autosizeIsEnabled = true;
        this.useHint = true;
        this.maxlength = 500;
        this.hintAlign = 'end';
    }
}

/* #endregion */

/* #region TextMaskFields */
export class AppOrderedFieldTextMask extends BaseAppOrderedField<string> {
    public mask: TextMaskConfig;
    constructor(
        className?: string,
        mask: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>) | false = false,
    ) {
        if (!className) {
            className = 'AppOrderedFieldTextMask';
        }
        super(AppFormFieldType.textMask, className);
        this.mask = new TextMaskConfig();
        this.mask.mask = mask;
    }
}
export class AppOrderedFieldDefaultPhoneTextMask extends AppOrderedFieldTextMask {
    emmitOptions: { emitEvent?: boolean; onlySelf?: boolean };
    protected _formRef: FormGroup;

    constructor(
        onGroupFormInitialized: Subject<FormGroup>,
        createPhoneMask?: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>),
        className?: string,
    ) {
        if (!className) {
            className = 'AppOrderedFieldDefaultPhoneTextMask';
        }
        super(className, createPhoneMask || APP_REGEXP.createDefaultPhoneMask);
        onGroupFormInitialized.pipe(
            map(f => {
                this._formRef = f;
                return f;
            }),
        );
        this.mask.placeholderChar = APP_REGEXP.defaultPhoneMaskChar;
        this.mask.pipe = val => this._pipeMask(val);
        this.emmitOptions = { emitEvent: true, onlySelf: true };
    }

    protected _pipeMask(val: string): string | false | Object {
        this.value = val;
        if (this._formRef) {
            this._formRef.get(this.key).updateValueAndValidity(this.emmitOptions);
        }
        return val;
    }
}
export class AppOrderedFieldMobilePhoneTextMask extends AppOrderedFieldDefaultPhoneTextMask {
    constructor(
        onGroupFormInitialized: Subject<FormGroup>,
        createMobilePhoneMask?: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>),
        className?: string,
    ) {
        if (!className) {
            className = 'AppOrderedFieldMobilePhoneTextMask';
        }
        super(onGroupFormInitialized, createMobilePhoneMask || APP_REGEXP.createMobilePhoneMask, className);
    }
}
/* #endregion */

/* #region Number fields */
/**
 * as text
 */
export class AppOrderedFieldNumber extends BaseAppOrderedField<number> {
    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldNumber';
        }
        super(AppFormFieldType.number, className);
    }
}
export class AppOrderedFieldRadio extends BaseAppOrderedField<any> {
    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldRadio';
        }
        super(AppFormFieldType.radio, className);
        this._notImplementedException();
    }
}

export class AppOrderedFieldChips extends BaseAppOrderedField<string> {
    list: string[];
    addOnBlur: boolean;
    removable: boolean;
    selectable: boolean;
    separator: string;
    separatorKeysCodes: number[];
    changed$: EventEmitter<string>;

    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldChips';
        }
        super(AppFormFieldType.chips, className);

        this.list = this.list || [];
        this.separator = this.separator || ';';
        this.separatorKeysCodes = this.separatorKeysCodes || [COMMA, ENTER, SEMICOLON];
        this.changed$ = new EventEmitter<string>();
    }

    addChip(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.list.push(value.trim());
            this.emitChange();
        }

        if (input) {
            input.value = '';
        }
    }

    editChip(chipIndex: number): void {
        if (chipIndex < 0) {
            return;
        }

        const chipValue = prompt(`Редактирование значения ${this.list[chipIndex]}`, this.list[chipIndex]);

        if (chipValue) {
            this.list[chipIndex] = chipValue;
            this.emitChange();
        }
    }

    removeChip(chipIndex: number): void {
        if (chipIndex < 0) {
            return;
        }

        if (confirm(`Вы действительно хотите удалить значение ${this.list[chipIndex]}?`)) {
            this.list.splice(chipIndex, 1);
            this.emitChange();
        }
    }

    private emitChange(): void {
        this.changed$.emit(this.list.join(this.separator));
    }
}

export class AppOrderedFieldMultiField extends BaseAppOrderedField<string> {
    constructor(className?: string) {
        if (!className) {
            className = 'AppOrderedFieldMultiField';
        }
        super(AppFormFieldType.multiField, className);
    }
}
/* #endregion */

/* #region Value fields */

export abstract class BaseAppSelectableValue<T> implements ISelectable<T> {
    list: T[];
    protected _selectedIndex = 0;

    protected constructor(readonly className: string = 'BaseAppSelectableValue', options: T[], selectedIndex?: number) {
        if (selectedIndex) {
            this._selectedIndex = selectedIndex;
        }
        this.list = options;
    }

    get selectedIndex() {
        return this._selectedIndex;
    }
    set selectedIndex(value: number) {
        this._selectedIndex = value;
    }
    get selected() {
        if (this.list && this.list[this._selectedIndex]) {
            return this.list[this._selectedIndex];
        }
        return null;
    }
    abstract findIndex(value: T): number;
}

export class AppSimpleSelectValue extends BaseAppSelectableValue<string> {
    constructor(options: string[], selectedIndex?: number, className: string = 'AppSimpleSelectValue') {
        super(className, options, selectedIndex);
    }
    findIndex(value: string): number {
        return findIndex(this.list, o => {
            return o === value;
        });
    }
    setByValue(value: string): boolean {
        if (!value || !this.list) {
            return false;
        }
        const val = this.findIndex(value);
        if (val !== -1) {
            this.selectedIndex = val;
            return true;
        }
        return false;
    }
}

export class AppSelectValue extends BaseAppSelectableValue<IKeyValueStrings> {
    get viewList(): IKeyValueStrings[] {
        return orderBy(this.list, ['value'], ['asc']);
    }

    constructor(options: IKeyValueStrings[], selectedIndex?: number, className: string = 'AppSelectValue') {
        super(className, options, selectedIndex);
    }

    findIndex(value: IKeyValueStrings): number {
        return findIndex(this.list, o => {
            return o.key === value.key;
        });
    }

    setByValue(value: IKeyValueStrings): boolean {
        if (!value || !this.list) {
            return false;
        }

        const idx = this.findIndex(value);

        if (idx !== -1) {
            this.selectedIndex = idx;
            return true;
        }

        return false;
    }
}

/* #endregion */

export class AppOrderedFieldSimpleSelect extends BaseAppOrderedField<AppSimpleSelectValue> {
    constructor(value?: AppSimpleSelectValue, className?: string) {
        if (!className) {
            className = 'AppOrderedFieldSimpleSelect';
        }
        super(AppFormFieldType.simpleSelect, className);
        if (value) {
            this.initialValue = value.selectedIndex + '';
            this.value = value;
        } else {
            this.value = new AppSimpleSelectValue(null);
        }
    }
}

export class AppOrderedFieldSelect extends BaseAppOrderedField<AppSelectValue> {
    public hidden?: boolean;

    constructor(value?: AppSelectValue, className?: string) {
        if (!className) {
            className = 'AppOrderedFieldSelect';
        }

        super(AppFormFieldType.select, className);

        if (value) {
            this.initialValue = value.selectedIndex + '';
            this.value = value;
        } else {
            this.value = new AppSelectValue([], -1);
        }
    }
}

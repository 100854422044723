/**
 * Направление сортировки, передаваемые сортировщиком Angular Material (matSort)
 */
export var SortDirectionEnum;
(function (SortDirectionEnum) {
    SortDirectionEnum["none"] = "";
    SortDirectionEnum["asc"] = "asc";
    SortDirectionEnum["desc"] = "desc";
})(SortDirectionEnum || (SortDirectionEnum = {}));
var SortParams = /** @class */ (function () {
    function SortParams() {
    }
    return SortParams;
}());
export { SortParams };

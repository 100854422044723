import { TextMaskConfig } from 'angular2-text-mask';
import { each } from 'lodash';

export function createDateMaskFromFormat(format: string, guide = false): TextMaskConfig {
    const mask = [];
    const num = /\d/;

    each(format, (char: string) => {
        let v;

        if (!!+char) {
            v = num;
        }

        const invChar = char.toLowerCase();

        switch (invChar) {
            case 'd':
            case 'm':
            case 'y':
            case 'h':
            case 's':
                v = num;
                break;
            default:
                v = char;
                break;
        }

        mask.push(v);
    });

    const config = new TextMaskConfig();
    config.mask = mask;
    config.guide = guide;
    config.showMask = false;
    config.keepCharPositions = false;

    return config;
}

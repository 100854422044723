import { AppConsts } from '@shared/AppConsts';
import { UtilsService } from '@abp/utils/utils.service';
var SignalRHelper = /** @class */ (function () {
    function SignalRHelper() {
    }
    SignalRHelper.initSignalR = function () {
        jQuery.getScript(AppConsts.remoteServiceBaseUrl + '/signalr/hubs', function () {
            jQuery.connection.hub.url = AppConsts.remoteServiceBaseUrl + '/signalr';
            var encryptedAuthToken = new UtilsService().getCookieValue(AppConsts.authorization.encrptedAuthTokenName);
            jQuery.connection.hub.qs =
                AppConsts.authorization.encrptedAuthTokenName + '=' + encodeURIComponent(encryptedAuthToken);
            jQuery.getScript(AppConsts.appBaseUrl + '/assets/abp/abp.signalr.js');
        });
    };
    return SignalRHelper;
}());
export { SignalRHelper };

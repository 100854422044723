import { has } from 'lodash';
import * as moment from 'moment';
import { NullObject } from '../../infrastructure/null-object';
var AppDatePickerEvent = /** @class */ (function () {
    function AppDatePickerEvent(event) {
        if (event === void 0) { event = null; }
        this.input = '';
        this.date = null;
        this.moment = null;
        if (AppDatePickerEvent.isValid(event)) {
            this.input = event.input;
            this.date = event.date;
            this.moment = event.moment;
        }
    }
    AppDatePickerEvent.isAppDatePickerEvent = function (value) {
        if (typeof value === 'object' && has(value, 'moment') && has(value, 'input') && has(value, 'date')) {
            return true;
        }
        return false;
    };
    AppDatePickerEvent.isValid = function (value) {
        if (AppDatePickerEvent.isAppDatePickerEvent(value)) {
            var val = value;
            return (moment.isMoment(val.moment) &&
                val.date instanceof Date &&
                typeof val.input === 'string' &&
                val.input.length > 0);
        }
        return false;
    };
    AppDatePickerEvent.createByMoment = function (value, format) {
        if (moment.isMoment(value) && value.isValid()) {
            var val = new AppDatePickerEvent();
            val.date = value.toDate();
            val.moment = value;
            val.input = value.format(format);
            return val;
        }
        else {
            var message = 'createByMoment: incrorrect value type';
            console.error(message, {
                value: value,
                format: format,
            });
            throw new Error(message);
        }
    };
    AppDatePickerEvent.getDate = function (value) {
        if (!value) {
            return null;
        }
        if (moment.isMoment(value)) {
            return value.toDate();
        }
        if (value instanceof Date) {
            return value;
        }
        if (typeof value === 'string') {
            return moment(value).toDate();
        }
        if (AppDatePickerEvent.isValid(value)) {
            var val = value;
            return val.date;
        }
        else {
            return null;
        }
    };
    AppDatePickerEvent.getStringVal = function (value, format) {
        if (typeof value === 'string') {
            return value;
        }
        if (value instanceof NullObject) {
            return '';
        }
        if (AppDatePickerEvent.isAppDatePickerEvent(value)) {
            return value.input;
        }
        else if (moment.isMoment(value)) {
            // да я в курсе что это котыль, кто придумает лучше способ определить из Date/moment
            // без input <string>  не являются ли значения маской или значением  по умолчанию
            // получит очивку и плюшку от меня лично (Arun)
            var val = value['_i'];
            if (typeof val === 'string') {
                return val;
            }
            else if (AppDatePickerEvent.isAppDatePickerEvent(val)) {
                return val.input;
            }
            else {
                return moment(value, format).format(format);
            }
        }
        else {
            return moment(value, format).format(format);
        }
    };
    AppDatePickerEvent.getMomentVal = function (value, format) {
        var val = AppDatePickerEvent.getStringVal(value, format);
        return moment(moment(val, format).toJSON());
    };
    return AppDatePickerEvent;
}());
export { AppDatePickerEvent };

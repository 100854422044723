var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material';
import { each, find } from 'lodash';
import { QueryOptionsDto } from '../service-proxies/service-proxies';
var BasePaginatorService = /** @class */ (function () {
    function BasePaginatorService(__proxy) {
        this.__proxy = __proxy;
        this.onDataChange = new EventEmitter();
        this.pageSizeOptions = [15, 25, 50];
        this.startPage = 1;
    }
    Object.defineProperty(BasePaginatorService.prototype, "pageSize", {
        get: function () {
            if (this._pageSize) {
                return this._pageSize;
            }
            this._pageSize = this.pageSizeOptions[0];
            return this._pageSize;
        },
        set: function (value) {
            if (this._pageSize !== value) {
                this._pageSize = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    BasePaginatorService.prototype.loadPage = function (page, pageSize, opts) {
        var _this = this;
        if (page === void 0) { page = this.startPage; }
        if (pageSize === void 0) { pageSize = this.pageSize; }
        var queryOptions = this.getQueryOptionsDto(opts);
        this.__proxy.queryPage(page, pageSize, true, queryOptions).subscribe(function (response) {
            _this.onDataChange.emit(response);
        });
    };
    BasePaginatorService.prototype.getQueryOptionsDto = function (opts) {
        return (opts ||
            new QueryOptionsDto({
                filterValues: [],
                sortOptions: [],
            }));
    };
    BasePaginatorService.prototype._hasSortOption = function (opts, sortFieldName) {
        if (!opts || !opts.length) {
            return false;
        }
        var sort = find(opts, function (i) {
            return i.fieldName === sortFieldName;
        });
        return !!sort;
    };
    BasePaginatorService.prototype._hasFilterOption = function (opts, filterId) {
        return !!this._getFilter(opts, filterId);
    };
    BasePaginatorService.prototype._getFilter = function (opts, filterId) {
        if (!opts || !opts.length) {
            return null;
        }
        var filter = find(opts, function (i) {
            return i.dataFilterId === filterId;
        });
        return filter;
    };
    return BasePaginatorService;
}());
export { BasePaginatorService };
var BasePaginatorComponent = /** @class */ (function () {
    function BasePaginatorComponent(_service) {
        this._service = _service;
        this._subs = [];
    }
    BasePaginatorComponent.prototype.ngOnInit = function () {
        this._initPaginator();
    };
    BasePaginatorComponent.prototype.ngOnDestroy = function () {
        if (this._subs && this._subs.length) {
            each(this._subs, function (i) {
                if (i && !i.closed) {
                    i.unsubscribe();
                }
            });
        }
    };
    BasePaginatorComponent.prototype._initPaginator = function () {
        var _this = this;
        this.paginator.pageSizeOptions = this._service.pageSizeOptions;
        this._subs.push(this._service.onDataChange.subscribe(function (response) {
            _this._service.pageSize = response.pageSize;
            _this.paginator.pageIndex = response.pageNumber - 1;
            _this.paginator.length = response.totalCount;
            _this.paginator.pageSize = _this._service.pageSize;
        }));
        this._subs.push(this.paginator.page.subscribe(function (page) {
            // page:
            // length: 70
            // pageIndex: 1
            // pageSize: 15
            // previousPageIndex: 0
            _this._service.pageSize = page.pageSize;
            _this._service.loadPage(page.pageIndex + 1);
        }));
    };
    return BasePaginatorComponent;
}());
export { BasePaginatorComponent };
var MatPaginatorIntlDefault = /** @class */ (function (_super) {
    __extends(MatPaginatorIntlDefault, _super);
    function MatPaginatorIntlDefault() {
        var _this = _super.call(this) || this;
        _this.itemsPerPageLabel = 'Отображены записи';
        _this.nextPageLabel = 'Перейдите на следующую страницу';
        _this.previousPageLabel = 'Перейти на предыдущую страницу';
        _this.firstPageLabel = 'Вернуться на первую страницу';
        _this.lastPageLabel = 'К последней странице';
        _this.getRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return '0 из ' + length;
            }
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            var endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return startIndex + 1 + ' - ' + endIndex + ' из ' + length;
        };
        return _this;
    }
    return MatPaginatorIntlDefault;
}(MatPaginatorIntl));
export { MatPaginatorIntlDefault };

import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var AppFieldNotifierService = /** @class */ (function () {
    function AppFieldNotifierService() {
        this.update = new Subject();
    }
    AppFieldNotifierService.prototype.emit = function (instanceId, value) {
        this.update.next({
            instanceId: instanceId,
            value: value,
        });
    };
    AppFieldNotifierService.ngInjectableDef = i0.defineInjectable({ factory: function AppFieldNotifierService_Factory() { return new AppFieldNotifierService(); }, token: AppFieldNotifierService, providedIn: "root" });
    return AppFieldNotifierService;
}());
export { AppFieldNotifierService };

import { each } from 'lodash';
var LocalStorageProvider = /** @class */ (function () {
    function LocalStorageProvider(_service, key, _expires) {
        this._service = _service;
        this.key = key;
        this._expires = _expires;
    }
    LocalStorageProvider.prototype.get = function () {
        var store = this._service.getStorage(this.key);
        if (store) {
            return JSON.parse(store);
        }
        return null;
    };
    LocalStorageProvider.prototype.save = function (value, expires) {
        if (expires) {
            this._expires = expires;
        }
        return this._service.setStorage(this.key, value, this._expires);
    };
    LocalStorageProvider.prototype.reset = function () {
        return this._service.removeStorage(this.key);
    };
    return LocalStorageProvider;
}());
export { LocalStorageProvider };
var LocalStorageBaseService = /** @class */ (function () {
    function LocalStorageBaseService(_className) {
        this._className = _className;
        this._postfix_expiresIn = '_expiresIn';
        this._defaultExpires = 24 * 60 * 60;
        this._providers = Object.create(null);
    }
    LocalStorageBaseService.prototype.removeStorage = function (itemKey) {
        try {
            localStorage.removeItem(itemKey);
            localStorage.removeItem(this.getExpiresInKey(itemKey));
        }
        catch (e) {
            this._log('removeStorage', "Error removing key ['" + itemKey + "'] from localStorage", e);
            return false;
        }
        return true;
    };
    LocalStorageBaseService.prototype.getExpiresInKey = function (itemKey) {
        return itemKey + this._postfix_expiresIn;
    };
    LocalStorageBaseService.prototype.getExpiresInTime = function (itemKey) {
        var time = localStorage.getItem(this.getExpiresInKey(itemKey));
        if (!time) {
            return 0;
        }
        return +time;
    };
    LocalStorageBaseService.prototype.getStorage = function (itemKey) {
        var now = Date.now();
        var expiresIn = this.getExpiresInTime(itemKey);
        if (expiresIn < now) {
            // Expired
            this.removeStorage(itemKey);
            return null;
        }
        else {
            try {
                return localStorage.getItem(itemKey);
            }
            catch (e) {
                this._log('getStorage', "Error reading key [" + itemKey + "] from localStorage", e);
                return null;
            }
        }
    };
    LocalStorageBaseService.prototype.setStorage = function (itemKey, value, expires) {
        if (!itemKey) {
            return false;
        }
        if (!expires) {
            expires = this._defaultExpires;
        }
        else {
            expires = Math.abs(expires);
        }
        var valueToSave = '';
        if (typeof value !== 'string') {
            valueToSave = JSON.stringify(value);
        }
        else {
            valueToSave = value;
        }
        var now = Date.now();
        var schedule = now + expires * 1000;
        try {
            localStorage.setItem(itemKey, valueToSave);
            localStorage.setItem(this.getExpiresInKey(itemKey), schedule + '');
        }
        catch (e) {
            this._log('setStorage', 'Error setting key [${itemKey}] in localStorage', e);
            return false;
        }
        return true;
    };
    LocalStorageBaseService.prototype.getOrCreateStorageProvider = function (key, expires) {
        if (expires === void 0) { expires = null; }
        if (!this._providers[key]) {
            expires = expires || this._defaultExpires;
            this._providers[key] = new LocalStorageProvider(this, key, expires);
        }
        return this._providers[key];
    };
    LocalStorageBaseService.prototype._resetMany = function (keys) {
        var _this = this;
        each(keys, function (k) {
            _this.getOrCreateStorageProvider(k).reset();
        });
    };
    LocalStorageBaseService.prototype._log = function (methodName, message, data) {
        if (data === void 0) { data = null; }
        console.log(this._className + ("." + methodName + ": " + message), {
            data: data,
        });
    };
    return LocalStorageBaseService;
}());
export { LocalStorageBaseService };

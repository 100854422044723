var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthTokenService } from '../services';
import { AbpHttpConfiguration, LogService, MessageService } from './_imports';
import { AppConsts } from 'shared';
import * as i0 from "@angular/core";
var AuthHttpConfigurationHandler = /** @class */ (function () {
    function AuthHttpConfigurationHandler() {
        this._methods = Object.create(null);
        this._methodsEnable = Object.create(null);
    }
    AuthHttpConfigurationHandler.prototype.showError = function (error) {
        return this._methods['showError'] && this._methods['showError'](error);
    };
    /**
     * Назначение замещающего метода обработки
     *
     * @param name Имя метода (должно совпадать с именем из HttpConfigurationInterface)
     * @param method Определение метода
     */
    AuthHttpConfigurationHandler.prototype.setMethod = function (name, method) {
        this._methods[name] = method;
        this._methodsEnable[name] = true;
    };
    /**
     * Удаление замещающего метода обработки
     *
     * @param name Имя метода
     */
    AuthHttpConfigurationHandler.prototype.deleteMethod = function (name) {
        if (this._methods[name]) {
            delete this._methods[name];
            delete this._methodsEnable[name];
        }
    };
    /**
     * Проверка активности замещающего метода обработки
     *
     * @param name Имя метода
     */
    AuthHttpConfigurationHandler.prototype.isEnableMethod = function (name) {
        return !!(this._methods[name] && this._methodsEnable[name]);
    };
    /**
     * Устанавливает флаг активности замещающего метода обработки
     *
     * @param name Имя метода
     * @param isEnable Флаг активности
     */
    AuthHttpConfigurationHandler.prototype.setEnableMethod = function (name, isEnable) {
        if (this._methods[name]) {
            this._methodsEnable[name] = isEnable;
            return;
        }
        delete this._methodsEnable[name];
    };
    AuthHttpConfigurationHandler.ngInjectableDef = i0.defineInjectable({ factory: function AuthHttpConfigurationHandler_Factory() { return new AuthHttpConfigurationHandler(); }, token: AuthHttpConfigurationHandler, providedIn: "root" });
    return AuthHttpConfigurationHandler;
}());
export { AuthHttpConfigurationHandler };
var AuthHttpConfiguration = /** @class */ (function (_super) {
    __extends(AuthHttpConfiguration, _super);
    function AuthHttpConfiguration(_handler, messageService, logService) {
        var _this = _super.call(this, messageService, logService) || this;
        _this._handler = _handler;
        return _this;
    }
    /**
     * @override
     */
    AuthHttpConfiguration.prototype.showError = function (error) {
        if (this._handler.isEnableMethod('showError')) {
            return this._handler.showError(error);
        }
        return _super.prototype.showError.call(this, error);
    };
    return AuthHttpConfiguration;
}(AbpHttpConfiguration));
export { AuthHttpConfiguration };
var AuthHttpInterceptor = /** @class */ (function () {
    function AuthHttpInterceptor(_tokenService) {
        this._tokenService = _tokenService;
        this._isRefreshTokenProcess = false;
    }
    AuthHttpInterceptor.prototype.intercept = function (req, next) {
        var request = req.clone();
        var tokens = this._tokenService.get();
        var modifiedHeaders = request.headers.set('__tenant', AppConsts.defaultTenantUid);
        // меняем заголовки запроса если это запрос обновления токена
        if (/RefreshToken/i.test(req.url)) {
            modifiedHeaders = modifiedHeaders.set('Authorization', 'Bearer ' + tokens.refreshToken);
            return next.handle(request.clone({
                headers: modifiedHeaders,
            }));
        }
        // полная очистка если токены авторизации не найдены
        if (!tokens.accessToken && !tokens.refreshToken) {
            tokens = {};
            this._tokenService.remove();
        }
        // обновление токенов
        // if (!this._isRefreshTokenProcess && !tokens.accessToken && tokens.refreshToken) {
        //     this._isRefreshTokenProcess = true;
        //     return this._tokenService.refreshToken().pipe(
        //         take(1),
        //         map((result: IAuthenticateResultModel) => {
        //             this._tokenService.save(result);
        //             this._isRefreshTokenProcess = false;
        //             return result;
        //         }),
        //         switchMap((result: IAuthenticateResultModel) => {
        //             modifiedHeaders = modifiedHeaders.append('Authorization', 'Bearer ' + result.accessToken);
        //             const modifiedRequest = request.clone({
        //                 headers: modifiedHeaders,
        //             });
        //             return next.handle(modifiedRequest);
        //         }),
        //     );
        // }
        var modifiedRequestClone = request.clone({
            headers: modifiedHeaders,
        });
        //return next.handle(modifiedRequestClone);
        return next.handle(modifiedRequestClone).pipe(tap(function (event) {
            // TODO этот финт с перехватом encrypted_access_token в http interceprtor 
            // пришлось реализовать по причине несовместимости новой библиотеки 10й версии
            // angular-oauth2-oidc с typescript 2.xx, после перехода на ангулар 10
            // и typesript 3.хх использовать коллекции angular-oauth2-oidc
            if (event.body && event.body.encrypted_access_token) {
                localStorage.setItem('encrypted_access_token', event.body.encrypted_access_token);
            }
        }, function (err) {
        }));
    };
    return AuthHttpInterceptor;
}());
export { AuthHttpInterceptor };

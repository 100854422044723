import { ElementRef, AfterViewInit } from '@angular/core';
var MaterialInput = /** @class */ (function () {
    function MaterialInput(_element) {
        this._element = _element;
    }
    MaterialInput.prototype.ngAfterViewInit = function () {
        $.AdminBSB.input.activate($(this._element.nativeElement).parent());
    };
    return MaterialInput;
}());
export { MaterialInput };

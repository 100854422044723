import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ChangeDetectionStrategy, Input, HostListener, OnDestroy, OnInit } from '@angular/core';
import { trim } from 'lodash';
import * as moment from 'moment';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DaterangepickerComponent } from './daterangepicker.component';

@Component({
    selector: 'date-range-picker-toggle',
    template: '<ng-content></ng-content>',
    styles: [':host {cursor: pointer}'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Компонент, позволяющий связать компонент выбора даты с полем ввода формы
 */
export class DaterangepickerToggleComponent implements OnInit, OnDestroy {
    /**
     * Элемент ввода формы
     */
    @Input() control: HTMLInputElement;

    private readonly _dateFormat = 'DD.MM.YYYY';

    private _overlayRef: OverlayRef;

    private _destructor$ = new Subject();
    private _disposed$ = new Subject();

    constructor(private _overlay: Overlay) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._destructor$.next();
        this._destructor$.complete();
    }

    @HostListener('click')
    onClick(): void {
        this._overlayRef ? this._close() : this._open();
    }

    private get _config(): OverlayConfig {
        return {
            hasBackdrop: true,
            backdropClass: '',
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this.control)
                .withPositions([
                    {
                        overlayX: 'start',
                        overlayY: 'top',
                        originX: 'start',
                        originY: 'bottom',
                    },
                ]),
        };
    }

    private _open(): void {
        const portal = new ComponentPortal(DaterangepickerComponent);

        this._overlayRef = this._overlay.create(this._config);
        const component = this._overlayRef.attach(portal);

        const datepickerComponent = component.instance;
        datepickerComponent.autoApply = true;
        datepickerComponent.drops = 'up';
        datepickerComponent.opens = 'right';
        datepickerComponent.locale.separator = ' - ';
        datepickerComponent.locale.format = this._dateFormat;

        // параметр говорит о том, что date range picker создает данный компонент
        // влияет на отображение стрелок выбора дат
        datepickerComponent.options.isToggleComponent = true;

        const dateRange = this._createDateRangeFromString(this.control.value);

        if (dateRange.startDate && dateRange.startDate.isValid()) {
            datepickerComponent.setStartDate(dateRange.startDate);
        }

        if (dateRange.endDate && dateRange.endDate.isValid()) {
            datepickerComponent.setEndDate(dateRange.endDate);
        }

        datepickerComponent.choosedDate
            .asObservable()
            .pipe(takeUntil(merge(this._destructor$, this._disposed$)))
            .subscribe(value => {
                const startDate: moment.Moment = value['startDate'];
                const endDate: moment.Moment = value['endDate'];

                this.control.value = (startDate && startDate.format(this._dateFormat)) || '';
                this.control.value += (endDate && ' - ' + endDate.format(this._dateFormat)) || '';

                this.control.dispatchEvent(new Event('input'));
            });

        this._overlayRef
            .backdropClick()
            .pipe(takeUntil(merge(this._destructor$, this._disposed$)))
            .subscribe(() => this._close());
    }

    private _close(): void {
        if (this._overlayRef) {
            this._overlayRef.dispose();
            this._overlayRef = null;

            this._disposed$.complete();
            this._disposed$.next();
        }
    }

    private _createDateRangeFromString(value: string): IDateRange {
        const dateValues = value.split('-');

        return {
            startDate: dateValues[0] && moment(trim(dateValues[0]), this._dateFormat),
            endDate: dateValues[1] && moment(trim(dateValues[1]), this._dateFormat),
        };
    }
}

/**
 * Диапазон дат
 */
interface IDateRange {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    ViewChild,
    TemplateRef,
    ViewChildren,
    AfterViewInit,
    QueryList,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import {
    MatInput,
    MatDatepickerInputEvent,
    MatAutocompleteTrigger,
    MatAutocompleteSelectedEvent,
} from '@angular/material';
import { sortBy, find, findIndex } from 'lodash';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

import { AppFormFieldType, IKeyValueStrings, IDatePickerEvent } from './_imports';
import {
    IAppFormData,
    IAppFormOrderedField,
    AppOrderedFieldDatepicker,
    IAppOrderedFieldAppDatePicker,
} from './_form-fields';
import { IAppOrderedFieldAutocomplete } from './i-app-ordered-field-autocomplete';
import { AppOrderedFieldAutocomplete } from './app-ordered-field-autocomplete';
import { getTreeControlFunctionsMissingError } from '@angular/cdk/tree';

@Component({
    selector: 'app-form',
    templateUrl: './app-form.component.html',
    styleUrls: ['./app-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() formData: IAppFormData;
    formGroup: FormGroup;
    isHospitalizationTipVisible = false;

    @ViewChild(AppFormFieldType.autocomplete)
    private autocomplete: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.checkbox)
    private checkbox: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.checkboxIsHospitalizationProcedure)
    private checkboxIsHospitalizationProcedure: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.chips)
    private chips: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.multiField)
    private multiField: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.datepicker)
    private datepicker: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.file)
    private file: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.number)
    private number: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.radio)
    private radio: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.simpleSelect)
    private simpleSelect: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.select)
    private select: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.text)
    private text: TemplateRef<IAppFormOrderedField<any>>;
    @ViewChild(AppFormFieldType.password)
    private password: TemplateRef<IAppFormOrderedField<any>>;
    @ViewChild(AppFormFieldType.textArea)
    private textArea: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.textMask)
    private textMask: TemplateRef<IAppFormOrderedField<any>>;
    @ViewChild(AppFormFieldType.empty)
    private empty: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChild(AppFormFieldType.appDatePicker)
    private appDatePicker: TemplateRef<IAppOrderedFieldAppDatePicker>;
    @ViewChild(AppFormFieldType.appDateTtimePpicker)
    private appDateTtimePpicker: TemplateRef<IAppOrderedFieldAppDatePicker>;

    @ViewChild('default')
    private default: TemplateRef<IAppFormOrderedField<any>>;

    @ViewChildren(MatInput) private _inputs: QueryList<MatInput>;
    private _inputCtrls: MatInput[];
    private _destructor = new Subject();
    private _onOrderChange = new Subject();

    constructor(private _fb: FormBuilder, private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.formGroup = this._fb.group({});
        if (this.formData.fields && this.formData.fields.length) {
            if (!this.formData.isOrderedFields) {
                const f = sortBy(this.formData.fields, ['order']);
                this.formData.fields = f;
                this.formData.isOrderedFields = true;
            }
            this.formData.fields.forEach(e => {

                if (e.required) {
                    if (!e.validators) {
                        e.validators = [Validators.required];
                    } else {
                        const hasRequired = !!find(e.validators, f => {
                            return f === Validators.required;
                        });
                        if (!hasRequired) {
                            e.validators.push(Validators.required);
                        }
                    }
                }

                const control = new FormControl(e.initialValue, {
                    updateOn: e.updateOn,
                    validators: e.validators,
                });

                // control['_setValue'] = control.setValue;
                // control.setValue = (value: any, options?: {
                //     onlySelf?: boolean;
                //     emitEvent?: boolean;
                //     emitModelToViewChange?: boolean;
                //     emitViewToModelChange?: boolean;
                // }) => {
                //     control['_setValue'](value, options);
                //     if (e.key === 'caseNumber') {
                //         // ;
                //     }
                // };

                if (e.disabled) {
                    control.disable();
                }

                if (e.type === AppFormFieldType.autocomplete && e instanceof AppOrderedFieldAutocomplete) {
                    (e as IAppOrderedFieldAutocomplete).configureFiltration(control);
                }
                if (e.valueChanges instanceof EventEmitter) {
                    control.valueChanges.pipe(takeUntil(this._destructor)).subscribe(v => {
                        this._emmitFieldEvent(v, e, control);
                    });
                }

                this.formGroup.addControl(e.key, control);
                if (e.appendFormGroup) {
                    this._appendForm(e, 'formGroupRef');
                }
            });
        }
        this._appendForm(this.formData, '_formGroup');

        if (this.formData.onGroupFormInitialized instanceof Subject) {
            this.formData.onGroupFormInitialized.next(this.formGroup);
        }
        this.formGroup.markAsPristine();
    }

    ngAfterViewInit(): void {
        this._inputCtrls = this._inputs ? this._inputs.toArray() : [];
    }
    ngOnDestroy(): void {
        this._destructor.next();
        this._destructor.complete();
    }
    trackByFnIndex(index: number, item): number {
        return index;
    }

    getTemplate(field: IAppFormOrderedField<any>): TemplateRef<IAppFormOrderedField<any>> {
        if (this[field.type]) {
            return this[field.type];
        }
        return this.default;
    }

    showError(formName: string, errorName: string): boolean {
        if (this.formGroup.disabled) {
            return false;
        }
        const ctrl = this.formGroup.get(formName);
        if (!ctrl.touched) {
            return false;
        }
        return ctrl.hasError(errorName);
    }

    onKeyEnter(e: Event): void {
        if (e.defaultPrevented) {
            return;
        }
        const input = this._getNextInput((e.target as HTMLInputElement).id);
        if (input && !input.focused) {
            e.stopImmediatePropagation();
            input.focus();
        }
    }

    dateChange(e: MatDatepickerInputEvent<any>, field: AppOrderedFieldDatepicker): void {
        if (field && field.valueChanges) {
            const ctrl = this.formGroup.get(field.key);
            if (ctrl) {
                let val = null;
                const inputValue = (e.targetElement as HTMLInputElement).value;
                const date = moment(inputValue, field.dateFormat);
                if (date.isValid()) {
                    val = date.toDate();
                }
                if (ctrl.value !== val) {
                    ctrl.markAsDirty();
                    ctrl.patchValue(val || '', {
                        emitEvent: true,
                        onlySelf: true,
                    });
                    ctrl.updateValueAndValidity();
                }
            }
        }
    }
    onAppDatePickerChange(event: IDatePickerEvent, field: IAppOrderedFieldAppDatePicker): void {
        this.onAppDateTimePickerChange(event, field);
    }
    onAppDateTimePickerChange(event: IDatePickerEvent, field: IAppOrderedFieldAppDatePicker): void {
        if (field && field.valueChanges) {
            const ctrl = this.formGroup.get(field.key);
            if (ctrl) {
                let value;

                if (event.input === '') {
                    ctrl.setValue(null);
                    return;
                } else if (event.moment) {
                    value = event.moment;
                } else if (!!event.input) {
                    value = moment(moment(event.input, field.dateFormat).toJSON());
                }
                let val = null;
                if (moment.isMoment(value) && value.isValid()) {
                    val = value;
                } else {
                    val = field.invalidValue;
                }
                if (ctrl.value !== val) {
                    ctrl.setValue(val);
                }
                // console.log('onAppDateTimePickerChange', {
                //     event,
                //     val,
                // });

                // this._emmitFieldEvent(event, field, ctrl);
            }
        }
    }

    hasError(field: IAppFormOrderedField<any>): boolean {
        // f.errorMessages && f.errorMessages.length && (lawCity.dirty || lawCity.touched
        if (field.hidden || !field.errorMessages || !field.errorMessages.length) {
            return false;
        }

        const ctrl = this.formGroup.get(field.key);
        if (!ctrl) {
            console.warn('AppFormComponent field control not exists ');
            return false;
        }
        return field.errorMessages && field.errorMessages.length && (ctrl.dirty || ctrl.touched);
    }

    resetAutocomplete(e: Event, field: IAppOrderedFieldAutocomplete, autoTrigger: MatAutocompleteTrigger) {
        field.onClose(e, autoTrigger, this.formGroup.get(field.key));
    }
    displayFnAutocomplete(field: IAppOrderedFieldAutocomplete): (value?: IKeyValueStrings) => string | undefined {
        return (value?: IKeyValueStrings) => {
            return field.displayFn(value);
        };
    }
    autocompleteSelected($event: MatAutocompleteSelectedEvent, field: IAppOrderedFieldAutocomplete): void {
        field.onSelected($event, this.formGroup.get(field.key));
    }
    onClickAutocomplete(
        $event: Event,
        field: IAppOrderedFieldAutocomplete,
        autoTrigger: MatAutocompleteTrigger,
        isArrow?: boolean,
    ): void {
        field.onClick($event, autoTrigger, this.formGroup.get(field.key), isArrow);
    }
    private _appendForm(object: object, key: string) {
        Object.defineProperty(object, key, {
            get: () => {
                return this.formGroup;
            },
        });
    }

    private _getNextInput(sourceInputId: string): MatInput {
        const currentIndex = findIndex(this._inputCtrls, e => {
            return e.id === sourceInputId;
        });
        if (currentIndex || currentIndex === 0) {
            const nextIndex = currentIndex + 1;
            if (this._inputCtrls[nextIndex]) {
                return this._inputCtrls[nextIndex];
            } else {
                return this._inputCtrls[0];
            }
        }
        return null;
    }

    private _emmitFieldEvent(newValue: any, field: IAppFormOrderedField<any>, ctrl?: AbstractControl) {
        const control = ctrl || this.formGroup.get(field.key);
        if (typeof(newValue) === 'boolean') {
            this.isHospitalizationTipVisible = newValue;
        }
        field.valueChanges.emit({
            control: control,
            newValue: newValue,
            formGroup: this.formGroup,
        });
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MaterialIconHelper as MIcon, UtilHelper } from './_imports';
export var FileUploaderLoadState;
(function (FileUploaderLoadState) {
    /**File in pending  state, lock form for that state */
    FileUploaderLoadState["loading"] = "loading";
    /** loading finished, all data exists, we can unlock our form */
    FileUploaderLoadState["finish"] = "finish";
    /** some error in process */
    FileUploaderLoadState["error"] = "error";
})(FileUploaderLoadState || (FileUploaderLoadState = {}));
var FileUploaderOption = /** @class */ (function () {
    function FileUploaderOption(instanceId, selectButton, deleteButton, accept, disabled, name) {
        if (disabled === void 0) { disabled = true; }
        if (name === void 0) { name = 'file'; }
        this.instanceId = instanceId;
        this.name = name;
        this.selectButton = __assign({ icon: MIcon.createMaterialIcon(MIcon.fileUpload), text: 'Прикрепить файл' }, selectButton);
        this.deleteButton = __assign({ icon: MIcon.createMaterialIcon(MIcon.delete), text: 'Удалить файл' }, deleteButton);
        // this.accept = accept || "image/*";
        this.accept = accept;
        this.multiple = false;
        this.disabled = disabled;
    }
    return FileUploaderOption;
}());
export { FileUploaderOption };
var FileUploadModel = /** @class */ (function () {
    function FileUploadModel(name) {
        if (name === void 0) { name = 'file'; }
        this.name = name;
        this.uiId = UtilHelper.createQuickGuid();
    }
    return FileUploadModel;
}());
export { FileUploadModel };

import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService, PushNotifyType, SignalRAspNetCoreHelper } from '../_imports';
var AppPushInfoComponent = /** @class */ (function () {
    function AppPushInfoComponent(_router, _eventsService) {
        this._router = _router;
        this._eventsService = _eventsService;
    }
    AppPushInfoComponent.prototype.ngOnInit = function () {
        /**
         * FIXME  на нормальный сервис  и собятия подписки
         */
        var _this = this;
        var isLawyerPage = this._router.url.includes('/lk-lawyer/article-51');
        var isInitiatorPage = this._router.url.includes('initiator/request/show');
        if (isLawyerPage || isInitiatorPage) {
            SignalRAspNetCoreHelper.initSignalR().subscribe(function (next) {
                if (next) {
                    abp.event.on('abp.notifications.received', function (userNotification) {
                        if (PushNotifyType.locked === userNotification.notification.data.notifyType) {
                            _this._eventsService.broadcast(PushNotifyType.locked);
                            return;
                        }
                        _this._eventsService.broadcast('update', userNotification.notification.data.message);
                        if (isLawyerPage && userNotification.notification.data.notifyType !== PushNotifyType.hidden) {
                            abp.notify.info(userNotification.notification.data.message);
                            // see ref https://pushjs.org/docs/permissions
                            if (Push.Permission.has()) {
                                _this.playAudio();
                                Push.create('Уведомление', {
                                    body: userNotification.notification.data.message,
                                    icon: abp.appPath + 'assets/app-logo-small.png',
                                    timeout: 6000,
                                    onClick: function () {
                                        window.focus();
                                        this.close();
                                    },
                                });
                            }
                        }
                    });
                }
            });
        }
    };
    AppPushInfoComponent.prototype.playAudio = function () {
        var audio = new Audio();
        audio.src = 'assets/app/media/audio/to-the-point.mp3';
        audio.load();
        audio.play();
    };
    return AppPushInfoComponent;
}());
export { AppPushInfoComponent };

import { Injector } from '@angular/core';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { AppSessionService } from '@shared/service-global/app-session.service';
import * as i0 from "@angular/core";
var AuthRedirectFactoryService = /** @class */ (function () {
    function AuthRedirectFactoryService(_injector) {
        this._injector = _injector;
        this._sessionService = _injector.get(AppSessionService);
    }
    /**
     * Возвращает url для перехода в нужный модуль в записимости от роли пользователя
     *
     * todo уточнить все роли и url
     */
    AuthRedirectFactoryService.prototype.createUrl = function () {
        // fixme на данный момент "admin" выполняет роль модератора. заменить в будущем
        if (this._sessionService.containRole(UserRoles.admin)) {
            return '/moderator/request-list';
        }
        if (this._sessionService.containRole(UserRoles.chamberAccountant)) {
            return '/accountant-apm';
        }
        if (this._sessionService.containRole(UserRoles.humanResourcesOfficer)) {
            return '/registry/lawyers';
        }
        // todo роль "moderator" на данный момент не настроена на сервере
        if (this._sessionService.containRole(UserRoles.moderator)) {
            return '/moderator/request-list';
        }
        if (this._sessionService.containRole(UserRoles.lawyer)) {
            return '/lk-lawyer';
        }
        if (this._sessionService.containRole(UserRoles.formationAccount)) {
            return '/accountant-ao';
        }
        if (this._sessionService.containRole(UserRoles.assistantManager)) {
            return '/moderator/request-list';
        }
        // todo на данный момент у инициатора своя страница логина. переделать в будущем
        if (this._sessionService.containRole(UserRoles.initiator)) {
            return '/initiator/request/new';
        }
    };
    AuthRedirectFactoryService.ngInjectableDef = i0.defineInjectable({ factory: function AuthRedirectFactoryService_Factory() { return new AuthRedirectFactoryService(i0.inject(i0.INJECTOR)); }, token: AuthRedirectFactoryService, providedIn: "root" });
    return AuthRedirectFactoryService;
}());
export { AuthRedirectFactoryService };

import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from './app-session.service';
import * as i0 from "@angular/core";
import * as i1 from "./app-session.service";
var AppUrlService = /** @class */ (function () {
    function AppUrlService(_appSessionService) {
        this._appSessionService = _appSessionService;
        this._urlStorage = Object.create(null);
    }
    Object.defineProperty(AppUrlService.prototype, "appRootUrl", {
        get: function () {
            if (this._appSessionService.tenant) {
                return this.getAppRootUrlOfTenant(this._appSessionService.tenant.tenancyName);
            }
            else {
                return this.getAppRootUrlOfTenant(null);
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Returning url ends with '/'.
     */
    AppUrlService.prototype.getAppRootUrlOfTenant = function (tenancyName) {
        var baseUrl = this.ensureEndsWith(AppConsts.appBaseUrl, '/');
        if (baseUrl.indexOf(AppUrlService.tenancyNamePlaceHolder) < 0) {
            return baseUrl;
        }
        if (baseUrl.indexOf(AppUrlService.tenancyNamePlaceHolder + '.') >= 0) {
            baseUrl = baseUrl.replace(AppUrlService.tenancyNamePlaceHolder + '.', AppUrlService.tenancyNamePlaceHolder);
            if (tenancyName) {
                tenancyName = tenancyName + '.';
            }
        }
        if (!tenancyName) {
            return baseUrl.replace(AppUrlService.tenancyNamePlaceHolder, '');
        }
        return baseUrl.replace(AppUrlService.tenancyNamePlaceHolder, tenancyName);
    };
    AppUrlService.prototype.getUrl = function (urlKey) {
        if (this._urlStorage[urlKey]) {
            return this._urlStorage[urlKey];
        }
        return null;
    };
    AppUrlService.prototype.setUrl = function (urlKey, urlValue) {
        if (!urlKey || !urlValue) {
            throw new Error('argument error');
        }
        this._urlStorage[urlKey] = urlValue;
    };
    AppUrlService.prototype.removeUrl = function (urlKey) {
        if (this._urlStorage[urlKey]) {
            delete this._urlStorage[urlKey];
        }
    };
    AppUrlService.prototype.ensureEndsWith = function (str, c) {
        if (str.charAt(str.length - 1) !== c) {
            str = str + c;
        }
        return str;
    };
    AppUrlService.prototype.removeFromEnd = function (str, c) {
        if (str.charAt(str.length - 1) === c) {
            str = str.substr(0, str.length - 1);
        }
        return str;
    };
    AppUrlService.tenancyNamePlaceHolder = '{TENANCY_NAME}';
    AppUrlService.ngInjectableDef = i0.defineInjectable({ factory: function AppUrlService_Factory() { return new AppUrlService(i0.inject(i1.AppSessionService)); }, token: AppUrlService, providedIn: "root" });
    return AppUrlService;
}());
export { AppUrlService };

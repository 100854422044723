import { TokenService as AbpTokenService } from '@abp/auth/token.service';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';

import {
    ApmAccountServiceProxy,
    IRestorePasswordInput,
    RestorePasswordInput,
} from '@shared/service-proxies';

import { AuthCookieService } from './auth-cookie.service';

@Injectable({
    providedIn: 'root',
})
export class AuthTokenService {
    private _abpTokenService: AbpTokenService;
    private _apmAccountServiceProxy: ApmAccountServiceProxy;
    private _cookieService: AuthCookieService;

    constructor(injector: Injector) {
        this._apmAccountServiceProxy = injector.get(ApmAccountServiceProxy);
        this._abpTokenService = injector.get(AbpTokenService);
        this._cookieService = injector.get(AuthCookieService);
    }

    authenticate(model: any): Observable<any> {
        // return this._tokenAuthServiceProxy.authenticate(new AuthenticateModel(model));
        return of([]);
    }

    authenticateByContact(model: any): Observable<any> {
        // return this._tokenAuthServiceProxy.authenticateByContact(new AuthenticateByContactModel(model));
        return of([]);
    }
    authenticateByTicket(model: any): Observable<any> {
        // return this._tokenAuthServiceProxy.authenticateByTicket(new AuthenticateByTicketModel(model));
        return of([]);
    }
    emailValidate(email: string): Observable<boolean> {
        return this._apmAccountServiceProxy.emailValidate((email + '').trim());
    }
    restorePassword(model: IRestorePasswordInput): any {
        return this._apmAccountServiceProxy.restorePassword(new RestorePasswordInput(model));
    }
    refreshToken(): Observable<any> {
        // return this._tokenAuthServiceProxy.refreshToken();
        return of([]);
    }

    save(model: any): void {
        let accessTokenExpireDate: Date;
        let refreshTokenExpireDate: Date;

        if (Number.isInteger(model.expireInSeconds)) {
            const expirePeriodTime = new Date().getTime() + model.expireInSeconds * 1000; // milliseconds
            accessTokenExpireDate = new Date(expirePeriodTime);

            const weekPeriodTime = 60 * 60 * 24 * 7 * 1000; // milliseconds
            const refreshPeriodTime = new Date().getTime() + weekPeriodTime;
            refreshTokenExpireDate = new Date(refreshPeriodTime);
        }

        this._abpTokenService.setToken(model.accessToken, accessTokenExpireDate);

        this._cookieService.setCookie(
            AppConsts.authorization.encrptedAuthTokenName,
            model.encryptedAccessToken,
            accessTokenExpireDate,
            abp.appPath,
        );

        if (accessTokenExpireDate) {
            this._cookieService.setCookie(
                AppConsts.authorization.refreshTokenName,
                model.refreshToken,
                refreshTokenExpireDate,
                abp.appPath,
            );

            this._cookieService.setCookie(
                AppConsts.authorization.expiredTokenName,
                accessTokenExpireDate.getTime() + '',
                accessTokenExpireDate,
                abp.appPath,
            );
        }
    }

    get(): any {
        return {
            accessToken: this._abpTokenService.getToken(),
            encryptedAccessToken: this._cookieService.getCookie(AppConsts.authorization.encrptedAuthTokenName),
            refreshToken: this._cookieService.getCookie(AppConsts.authorization.refreshTokenName),
        };
    }

    remove(): void {
        this._abpTokenService.clearToken();
        this._cookieService.deleteCookie(AppConsts.authorization.encrptedAuthTokenName, abp.appPath);
        this._cookieService.deleteCookie(AppConsts.authorization.refreshTokenName, abp.appPath);
        this._cookieService.deleteCookie(AppConsts.authorization.expiredTokenName, abp.appPath);
    }
}

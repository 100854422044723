import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedMaterialModule } from '@shared/shared.material.module';

import { DatePickerComponent } from './date-picker.component';
import { DateTimePickerComponent } from './date-time-picker.component';

@NgModule({
    imports: [
        CommonModule,
        TextMaskModule,
        FormsModule,
        SharedMaterialModule,
        MatMomentDateModule,
        MatMomentDatetimeModule,
        MatDatetimepickerModule,
    ],
    declarations: [DatePickerComponent, DateTimePickerComponent],
    exports: [DatePickerComponent, DateTimePickerComponent],
    providers: [],
})
export class DatePickerModule {}

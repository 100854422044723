var ApmReferenceToBasicServiceProxyAdapter = /** @class */ (function () {
    function ApmReferenceToBasicServiceProxyAdapter(_proxy) {
        this._proxy = _proxy;
    }
    ApmReferenceToBasicServiceProxyAdapter.prototype.get = function (id) {
        return this._proxy.get(id);
    };
    ApmReferenceToBasicServiceProxyAdapter.prototype.getMany = function (idList) {
        return this._proxy.many(idList);
    };
    ApmReferenceToBasicServiceProxyAdapter.prototype.create = function (dto) {
        throw new TypeError("Not supported.");
    };
    ApmReferenceToBasicServiceProxyAdapter.prototype.update = function (dto) {
        throw new TypeError("Not supported.");
    };
    ApmReferenceToBasicServiceProxyAdapter.prototype.save = function (dto) {
        throw new TypeError("Not supported.");
    };
    ApmReferenceToBasicServiceProxyAdapter.prototype.delete = function (id) {
        throw new TypeError("Not supported.");
    };
    ApmReferenceToBasicServiceProxyAdapter.prototype.queryPage = function (pageNumber, pageSize, countTotal, queryOptions) {
        return this._proxy.page(pageNumber, pageSize, countTotal, queryOptions);
    };
    return ApmReferenceToBasicServiceProxyAdapter;
}());
export { ApmReferenceToBasicServiceProxyAdapter };

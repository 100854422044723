import { AbstractControl } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material';
import { filter, orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppFormFieldType, IKeyValueStrings } from './_imports';
import { BaseAppOrderedField } from './_form-fields';
import { IAppOrderedFieldAutocomplete } from './i-app-ordered-field-autocomplete';
import { KeyValueStrings } from 'shared/interfaces';
export class AppOrderedFieldAutocomplete extends BaseAppOrderedField<any> implements IAppOrderedFieldAutocomplete {
    /**
     * append in a form after view init
     */
    filtredList: Observable<IKeyValueStrings[]>;
    sourceRefList: IKeyValueStrings[];
    orderKey: string;
    orderDirection: 'asc' | 'desc';
    hidden?: boolean;
    showArrow?: boolean;
    constructor(sourceList: IKeyValueStrings[], className?: string) {
        if (!className) {
            className = 'AppOrderedFieldAutocomplete';
        }
        super(AppFormFieldType.autocomplete, className);
        this.sourceRefList = sourceList;
        this.orderKey = 'value';
        this.orderDirection = 'asc';
    }
    configureFiltration(ctrl: AbstractControl): void {
        this.filtredList = ctrl.valueChanges.pipe(
            startWith<string | IKeyValueStrings>(''),
            map(i => (i ? i : '')),
            map(i => (typeof i === 'string' ? i : i.value)),
            map(i => {
                return this.map(i);
            }),
        );
    }
    map(val: string): IKeyValueStrings[] {
        const value = <string>val;
        const col = val ? this.filter(this.sourceRefList, value) : this.sourceRefList ? this.sourceRefList.slice() : [];
        return this.sortByVal(col);
    }
    filter(coll: IKeyValueStrings[], value: string): IKeyValueStrings[] {
        return filter(coll, s => {
            return this.filterPredicate(s, value);
        });
    }
    filterPredicate(item: IKeyValueStrings, value: string): boolean {
        const v = (item.value + '').trim().toLowerCase();
        const sv = (value + '').trim().toLowerCase();
        return v.includes(sv);
    }
    sortByVal(coll: IKeyValueStrings[]): IKeyValueStrings[] {
        return orderBy(coll, [this.orderKey], [this.orderDirection]);
    }
    onClick($event: Event, autoTrigger: MatAutocompleteTrigger, ctrl?: AbstractControl, isArrow?: boolean): void {
        if (ctrl && !isArrow) {
            ctrl.reset();
        }
        autoTrigger.openPanel();
    }
    onClose($event: Event, autoTrigger: MatAutocompleteTrigger, ctrl?: AbstractControl): void {
        if ($event.defaultPrevented) {
            return;
        }
        $event.stopImmediatePropagation();
        autoTrigger.closePanel();
        if (ctrl) {
            ctrl.reset('');
        }
    }
    displayFn(value?: IKeyValueStrings): string | undefined {
        return value ? value.value : undefined;
    }
    onSelected($event: MatAutocompleteSelectedEvent, ctrl?: AbstractControl) {
        // implements in children if need
    }
    isSelected(ctrl: AbstractControl): boolean {
        if (!ctrl || !ctrl.value) {
            return false;
        }
        if (this.sourceRefList && !this.sourceRefList.length) {
            return true;
        }
        if (ctrl.value instanceof KeyValueStrings) {
            return !!ctrl.value.key;
        }
        return false;
    }
}

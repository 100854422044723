import { UtilsService } from '@abp/utils/utils.service';
import { AppConsts } from '@shared/AppConsts';
import { Observable, of, from } from 'rxjs';
import { map } from 'rxjs/operators';

export class SignalRAspNetCoreHelper {
    private static _initialized = false;
    static initSignalR(): Observable<boolean> {
        if (SignalRAspNetCoreHelper._initialized) {
            return of(false);
        }
        const token = AppConsts.authorization.encrptedAuthTokenName;
        if (!token) {
            console.error('SignalRAspNetCoreHelper:!token', AppConsts);
            return of(false);
        }
        const encryptedAuthToken = new UtilsService().getCookieValue(token);
        if (!encryptedAuthToken) {
            console.error('SignalRAspNetCoreHelper:!encryptedAuthToken', AppConsts);
            return of(false);
        }
        abp.signalr = {
            autoConnect: true,
            connect: undefined,
            hubs: undefined,
            qs: AppConsts.authorization.encrptedAuthTokenName + '=' + encodeURIComponent(encryptedAuthToken),
            remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
            startConnection: undefined,
            url: '/signalr',
        };
        return from(jQuery.getScript(AppConsts.appBaseUrl + '/assets/abp/abp.signalr-client.js')).pipe(
            map(() => {
                SignalRAspNetCoreHelper._initialized = true;
                return true;
            }),
        );
    }
}

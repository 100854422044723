import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppFormBtnType } from '@shared/enums';
import { IAppFormBtn } from '@shared/ui-components';
import { values as _values, each, has } from 'lodash';

@Component({
    selector: 'multi-field',
    templateUrl: './multi-field.component.html',
    styleUrls: ['./multi-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Компонент для формы с произвольным количеством текстовых полей.
 *
 * Позволяет динамически создавать дополнительные текстовые поля,
 * для независимого редактирования нескольких значений одной сущности
 */
export class MultiFieldComponent implements OnInit, OnChanges {
    /**
     * Объект текстового поля (внешний)
     */
    @Input() control: FormControl;

    @Input() disabled = false;

    /**
     * Заголовок компонента
     */
    @Input() placeholder?: string;

    /**
     * Символ-разделитель отдельных значений
     */
    @Input() separator?: string;

    /**
     * Название кнопки добавления нового поля
     */
    @Input() addButtonName?: string;

    /**
     * Название кнопки удаления выбранного текстового поля
     */
    @Input() removeButtonName?: string;

    /**
     * Кнопка добавления нового поля
     */
    addButton: IAppFormBtn;

    /**
     * Значения полей
     */
    fieldNames: string[] = ['main'];

    formGroup: FormGroup;

    constructor(private _changeDetection: ChangeDetectorRef) {}

    ngOnInit() {
        this.separator = this.separator || ';';
        this.addButtonName = this.addButtonName || 'Добавить';
        this.removeButtonName = this.removeButtonName || 'Удалить';

        this._createForm();

        const initialValues = (this.control.value || '').split(this.separator).filter(v => v);
        each(initialValues, (value, index) => {
            this.addField(`control-${index}`, value);
        });

        if (this.disabled) {
            this._setDisable();
        }

        this.control.statusChanges.subscribe(status => {
            if ('DISABLED' === status && this.formGroup) {
                this._setDisable();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (has(changes, 'disabled') && this.formGroup) {
            if (changes['disabled'].currentValue) {
                this._setDisable();
            } else {
                this._setEnable();
            }
        }
    }

    addField(fieldName: string, value?: string): void {
        this.formGroup.addControl(fieldName, new FormControl(value));
        this.fieldNames.splice(this.fieldNames.length - 1, 0, fieldName);
        this.formGroup.get('main').setValue('');
    }

    removeField(fieldName: string): void {
        this.fieldNames.splice(this.fieldNames.indexOf(fieldName), 1);
        this.formGroup.removeControl(fieldName);
    }

    private _createForm(): void {
        this.formGroup = new FormGroup({
            main: new FormControl(''),
        });

        this.formGroup.valueChanges.subscribe(values => {
            this.addButton.disabled = !values['main'];

            this.control.setValue(
                _values(values)
                    .filter(v => v)
                    .join(this.separator),
            );
        });

        this.addButton = {
            type: AppFormBtnType.simple,
            disabled: true,
            onClick: () => {
                const fieldName = `control-${this.fieldNames.length}`;
                const value = this.formGroup.get('main').value;

                if (value) {
                    this.addField(fieldName, value);
                }
            },
            css: 'claim-form__btn',
            value: this.addButtonName,
        };
    }

    private _setDisable(): void {
        this.formGroup.disable({ onlySelf: true, emitEvent: false });
        this.addButton.disabled = true;
        this.disabled = true;

        this._changeDetection.detectChanges();
    }

    private _setEnable(): void {
        this.formGroup.enable({ onlySelf: true, emitEvent: false });
        this.addButton.disabled = false;
        this.disabled = false;

        this._changeDetection.detectChanges();
    }
}

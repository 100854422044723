var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var KeyValue = /** @class */ (function () {
    function KeyValue(key, value) {
        this.key = key;
        this.value = value;
    }
    return KeyValue;
}());
export { KeyValue };
var KeyValueStrings = /** @class */ (function (_super) {
    __extends(KeyValueStrings, _super);
    function KeyValueStrings(key, value) {
        return _super.call(this, key, value) || this;
    }
    return KeyValueStrings;
}(KeyValue));
export { KeyValueStrings };
/* #endregion */

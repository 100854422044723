import { LawyerRequestDtoState } from '../service-proxies/service-proxies';
export var LawyerRequestStatusViewType;
(function (LawyerRequestStatusViewType) {
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["draft"] = 0] = "draft";
    /// <summary>
    ///     Заявка открыта.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["open"] = 1] = "open";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyers"] = 10] = "proposeLawyers";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из первого и второго круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep1"] = 11] = "proposeLawyersStep1";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из третьего круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep2"] = 12] = "proposeLawyersStep2";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из четвертого круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep3"] = 13] = "proposeLawyersStep3";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из пятого круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep4"] = 14] = "proposeLawyersStep4";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из шестого круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep5"] = 15] = "proposeLawyersStep5";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из седьмого круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep6"] = 16] = "proposeLawyersStep6";
    /// <summary>
    ///     Отправка предложений об исполниении заявки адвокатам из восьмого круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep7"] = 17] = "proposeLawyersStep7";
    /// <summary>
    ///     Назначение исполнителем адвоката из девятого круга.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersStep8"] = 18] = "proposeLawyersStep8";
    /// <summary>
    ///     Приостановить поиск адвоката.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["SuspendSearch"] = 20] = "SuspendSearch";
    /// <summary>
    ///     Перезапустить поиск адвоката.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["Restart"] = 30] = "Restart";
    /// <summary>
    ///     Не удалось выбрать адвоката в качестве исполнителя.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["proposeLawyersError"] = 40] = "proposeLawyersError";
    /// <summary>
    ///     По заявке только что откликнулись адвокаты. Состояние говорит о том что можно переходить к процедуре выбора
    ///     адвоката из откликнувшихся.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["accepted"] = 50] = "accepted";
    /// <summary>
    ///     Адвокат выбран/назначен.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["lawyerSelected"] = 51] = "lawyerSelected";
    /// <summary>
    ///     Запущена процедура смены адвоката.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["changeLawyer"] = 52] = "changeLawyer";
    /// <summary>
    ///     Запущена процедура смены адвоката по причине его неявки.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["startChangeLawyerByAbscence"] = 53] = "startChangeLawyerByAbscence";
    /// <summary>
    ///     Запущена процедура смены адвоката по причине процессуального решения.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["startChangeLawyerByProceduralDecision"] = 54] = "startChangeLawyerByProceduralDecision";
    /// <summary>
    ///     Исполнена.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["executed"] = 100] = "executed";
    /// <summary>
    ///     Оплачена.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["payed"] = 150] = "payed";
    /// <summary>
    ///     Отменена.
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["cancelled"] = 200] = "cancelled";
    /// <summary>
    /// 	Адвокат по соглашению
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["appointedByAgreement"] = 210] = "appointedByAgreement";
    /// <summary>
    /// 	Адвокат сменен по соглашению
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["cancelledByAgreement"] = 250] = "cancelledByAgreement";
    /// <summary>
    ///     Замена адвоката по причине неявки (конечный статус, создается новая заявка)
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["lawyerChangedByAbsence"] = 251] = "lawyerChangedByAbsence";
    /// <summary>
    ///     Замена адвоката на основании процессуального решения (конечный статус, создается новая заявка)
    /// </summary>
    LawyerRequestStatusViewType[LawyerRequestStatusViewType["lawyerChangedByProceduralDecision"] = 252] = "lawyerChangedByProceduralDecision";
})(LawyerRequestStatusViewType || (LawyerRequestStatusViewType = {}));
var LawyerRequestStatusViewModel = /** @class */ (function () {
    function LawyerRequestStatusViewModel(status) {
        this._init(status);
    }
    LawyerRequestStatusViewModel.prototype._setFlags = function () {
        this.changeLawyerDisabled = true;
        this.changeLawyerByProcessualDisabled = true;
        this.changeLawyerByAbsenceDisabled = true;
        this.cancelRequestDisabled = true;
        this.cancelRequestByAgreementDisabled = true;
        this.hideLawyerInfo = true;
        switch (this.status) {
            case LawyerRequestDtoState._0:
                break;
            case LawyerRequestDtoState._40:
                /**
                 * адвокат не найден, но есть каеи то опции - уточнить
                 **/
                this.cancelRequestDisabled = false;
                this.cancelRequestByAgreementDisabled = false;
                break;
            case LawyerRequestDtoState._1:
            case LawyerRequestDtoState._10:
            case LawyerRequestDtoState._11:
            case LawyerRequestDtoState._12:
            case LawyerRequestDtoState._13:
            case LawyerRequestDtoState._14:
            case LawyerRequestDtoState._15:
            case LawyerRequestDtoState._16:
            case LawyerRequestDtoState._17:
            case LawyerRequestDtoState._18:
            case LawyerRequestDtoState._20:
            case LawyerRequestDtoState._30:
            case LawyerRequestDtoState._50:
                /** работают умолчания, меняем только отличия */
                this.cancelRequestDisabled = false;
                this.cancelRequestByAgreementDisabled = false;
                break;
            case LawyerRequestDtoState._51:
                this.changeLawyerDisabled = false;
                this.changeLawyerByProcessualDisabled = false;
                this.changeLawyerByAbsenceDisabled = false;
                this.cancelRequestDisabled = false;
                this.cancelRequestByAgreementDisabled = false;
                this.hideLawyerInfo = false;
                break;
            case LawyerRequestDtoState._52:
                /** работают умолчания, меняем только отличия */
                this.cancelRequestDisabled = false;
                // this.cancelRequestByAgreementDisabled = false;
                this.hideLawyerInfo = false;
                break;
            case LawyerRequestDtoState._53:
            case LawyerRequestDtoState._54:
                /** работают умолчания, меняем только отличия */
                break;
            case LawyerRequestDtoState._100:
            case LawyerRequestDtoState._150:
            case LawyerRequestDtoState._200:
            case LawyerRequestDtoState._210:
            case LawyerRequestDtoState._250:
            case LawyerRequestDtoState._251:
            case LawyerRequestDtoState._252:
                /** работают умолчания, меняем только отличия */
                break;
            default:
                /** работают умолчания,ничего не меняем */
                break;
        }
    };
    LawyerRequestStatusViewModel.prototype._init = function (status) {
        this.status = status;
        var roundPlus = 'Отправка предложений об исполниении заявки адвокатам ';
        switch (status) {
            case LawyerRequestDtoState._0:
                this.name = 'Черновик';
                this.description = 'Черновик';
                break;
            case LawyerRequestDtoState._1:
                this.name = 'Старт поиска адвоката';
                this.description = 'Старт поиска адвоката';
                break;
            case LawyerRequestDtoState._10:
                this.name = 'Инициирована отправка предложений';
                this.description = 'Отправка предложений адвокатам';
                break;
            case LawyerRequestDtoState._11:
                this.name = this._round(1);
                this.description = roundPlus + 'из первого круга';
                break;
            case LawyerRequestDtoState._12:
                this.name = this._round(2);
                this.description = roundPlus + 'из второго круга';
                break;
            case LawyerRequestDtoState._13:
                this.name = this._round(3);
                this.description = roundPlus + 'из третьего круга';
                break;
            case LawyerRequestDtoState._14:
                this.name = this._round(4);
                this.description = roundPlus + 'из четвертого круга';
                break;
            case LawyerRequestDtoState._15:
                this.name = this._round(5);
                this.description = roundPlus + 'из пятого круга';
                break;
            case LawyerRequestDtoState._16:
                this.name = this._round(6);
                this.description = roundPlus + 'из шестого круга';
                break;
            case LawyerRequestDtoState._17:
                this.name = this._round(7);
                this.description = roundPlus + 'из седьмого круга';
                break;
            case LawyerRequestDtoState._18:
                this.name = this._round(8);
                this.description = roundPlus + 'из восьмого круга';
                break;
            case LawyerRequestDtoState._20:
                this.name = 'Приостановка поиска адвоката';
                this.description = 'Приостановить поиск адвоката';
                break;
            case LawyerRequestDtoState._30:
                this.name = 'Перезапуск поиска адвоката';
                this.description = 'Перезапустить поиск адвоката';
                break;
            case LawyerRequestDtoState._40:
                this.name = 'Адвокат не найден';
                this.description = 'Не удалось выбрать адвоката в качестве исполнителя';
                break;
            case LawyerRequestDtoState._50:
                this.name = 'Выбор адвоката (Есть отклики)';
                this.description =
                    'По заявке только что откликнулись адвокаты.' +
                        ' Состояние говорит о том что можно переходить к процедуре выбора адвоката из откликнувшихся';
                break;
            case LawyerRequestDtoState._51:
                this.name = 'Адвокат назначен';
                this.description = 'Адвокат назначен директивно';
                break;
            case LawyerRequestDtoState._52:
                this.name = 'Замена адвоката';
                this.description = 'Запущена процедура замены адвоката';
                break;
            case LawyerRequestDtoState._53:
                this.name = 'Замена адвоката по причине неявки';
                this.description = 'Запущена процедура замены адвоката по причине неявки адвоката';
                break;
            case LawyerRequestDtoState._54:
                this.name = 'Замена адвоката на основании процессуального решения';
                this.description = 'Запущена процедура замены адвоката на основании процессуального решения';
                break;
            case LawyerRequestDtoState._100:
                this.name = 'Завершена';
                this.description = 'Исполнена';
                break;
            case LawyerRequestDtoState._150:
                this.name = 'Оплачена';
                this.description = 'Оплачена';
                break;
            case LawyerRequestDtoState._200:
                this.name = 'Отменена';
                this.name = 'Заявка отменена';
                break;
            case LawyerRequestDtoState._210:
                this.name = 'Назначен по соглашению';
                this.description = 'Адвокат отменен по соглашению';
                break;
            case LawyerRequestDtoState._250:
                this.name = 'Отменена по причине вступления адвоката по соглашению';
                this.description = 'Инициатор отменил заявку по соглашению, и назначил другого адвоката';
                break;
            case LawyerRequestDtoState._251:
                this.name = 'Заменен по причине неявки';
                this.description = 'Замена адвоката по причине неявки';
                break;
            case LawyerRequestDtoState._252:
                this.name = 'Заменен на основании процессуального решения';
                this.description = 'Заменен на основании процессуального решения';
                break;
            default:
                throw new Error('Не поддерживаемй статус: ' + status);
        }
        this._setFlags();
    };
    LawyerRequestStatusViewModel.prototype._round = function (num) {
        return "\u0412\u044B\u0431\u043E\u0440 \u0430\u0434\u0432\u043E\u043A\u0430\u0442\u0430 " + num + " \u043A\u0440\u0443\u0433";
    };
    return LawyerRequestStatusViewModel;
}());
export { LawyerRequestStatusViewModel };

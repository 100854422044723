import { UtilsService } from '@abp/utils/utils.service';
import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthCookieService {
    private _abpUtilsService: UtilsService;

    constructor(private _injector: Injector) {
        this._abpUtilsService = _injector.get(UtilsService);
    }

    getCookie(name: string): string {
        return this._abpUtilsService.getCookieValue(name);
    }

    setCookie(name: string, value: string, expireDate?: Date, path?: string): void {
        this._abpUtilsService.setCookieValue(name, value, expireDate, path);
    }

    deleteCookie(name: string, path?: string): void {
        this._abpUtilsService.deleteCookie(name, path);
    }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { DataFilterValueDto } from '@shared/service-proxies';
import { BaseDataFilterValueBuilder } from '../base-data-filter-value-builder';
/**
 * Создатель моделей фильтров поиска по времени за 1 день
 */
var DateDataFilterValueBuilder = /** @class */ (function (_super) {
    __extends(DateDataFilterValueBuilder, _super);
    function DateDataFilterValueBuilder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
        return _this;
    }
    /**
     * @override
     */
    DateDataFilterValueBuilder.prototype._build = function () {
        var dateValue = moment(this._value).utcOffset(this._getUtcOffset(), true);
        var dateFrom = dateValue.clone();
        dateFrom.startOf('day');
        var dateTo = dateValue.clone();
        dateTo.endOf('day');
        return new DataFilterValueDto({
            dataFilterId: this._filterId,
            values: {
                fromValue: dateFrom.format(this.DATE_FORMAT),
                toValue: dateTo.format(this.DATE_FORMAT),
            },
        });
    };
    /**
     * Возвращает разницу (в минутах) временной зоны пользователя относительно UTC
     * @private
     */
    DateDataFilterValueBuilder.prototype._getUtcOffset = function () {
        var date = new Date();
        return date.getTimezoneOffset();
    };
    return DateDataFilterValueBuilder;
}(BaseDataFilterValueBuilder));
export { DateDataFilterValueBuilder };

import { Injectable } from '@angular/core';

import { UtilHelper } from '../helpers/util-helper';
import { LocalStorageBaseService, ILocalStorageProvider } from './local-storage-base.service';

@Injectable({
    providedIn: 'root',
})
export class TemporaryDataStorageService extends LocalStorageBaseService {
    private readonly _temporaryStorage: { [key: string]: ILocalStorageProvider<any> } = Object.create(null);
    constructor() {
        super('TemporaryDataStorageService');
    }

    getTemp<T>(token: string): T {
        const ls = this._temporaryStorage[token];
        if (ls) {
            return ls.get();
        }
        return null;
    }
    /**
     *
     * @param data данные для сохранения
     * @param timeInsecond время сохранения
     * @param token устанавливает переданный или создает новый ключ
     * @returns ключ к тмп
     */
    setTmp<T>(data: T, timeInsecond: number, token?: string): string {
        const t = token || UtilHelper.createQuickGuid();
        if (!this._temporaryStorage[token]) {
            this._temporaryStorage[token] = this.getOrCreateStorageProvider<T>(t, timeInsecond);
        }
        this._temporaryStorage[token].save(data, timeInsecond);
        return t;
    }
    removeTmp(token: string): void {
        this.getOrCreateStorageProvider(token).reset();
        if (this._temporaryStorage[token]) {
            delete this._temporaryStorage[token];
        }
    }
}

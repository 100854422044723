<div class="header">
    <img src="assets/app/media/img/logo.png" alt="" class="header__icon" />
    <span class="header__title">Адвокатская палата города Москвы</span>
</div>

<div class="content">
    <h1 class="content__title">Карта сайта</h1>

    <h3 class="content__title">Раздел "51-я статья"</h3>

    <ul class="list">
        <li class="list-item list-item__root">
            <a href="{{ clientBaseUrl }}/lk-lawyer">Личный кабинет адвоката</a>

            <ul class="list">
                <li class="list-item">
                    <a href="{{ clientBaseUrl }}/lk-lawyer/article-51/requests">Список заявок</a>

                    <ul class="list">
                        <li class="list-item"><a href="{{ clientBaseUrl }}/lk-lawyer/article-51/requests/new">Новые</a></li>
                        <li class="list-item"><a href="{{ clientBaseUrl }}/lk-lawyer/article-51/requests/in-work">Назначен</a></li>
                        <li class="list-item">
                            <a
                                href="{{
                                    clientBaseUrl
                                }}/lk-lawyer/article-51/requests/completed"
                                >Завершены</a
                            >
                        </li>
                        <li class="list-item">
                            <a
                                href="{{
                                    clientBaseUrl
                                }}/lk-lawyer/article-51/requests/all"
                                >Все</a
                            >
                        </li>
                    </ul>
                </li>
                <li class="list-item"><a href="{{ clientBaseUrl }}/lk-lawyer/profile">Профиль</a></li>
                <li class="list-item"><a href="{{ clientBaseUrl }}/lk-lawyer/settings">Настройки</a></li>
            </ul>
        </li>

        <li class="list-item list-item__root">
            <a href="{{ clientBaseUrl }}/article-51/claim">Личный кабинет инициатора</a>

            <ul class="list">
                <li class="list-item"><a href="{{ clientBaseUrl }}/initiator/request/new">Создание заявки</a></li>
                <li class="list-item"><a href="{{ clientBaseUrl }}/initiator/login">Просмотр заявки</a></li>
                <li class="list-item" *ngIf="!isProduction()"><a href="{{ serverBaseUrl }}/SentMessages">Просмотр sms сообщений</a></li>
                <li class="list-item" *ngIf="!isProduction()">
                    <a href="{{ serverBaseUrl }}/SentEmailMessages">Просмотр email сообщений</a>
                </li>
            </ul>
        </li>
    </ul>

    <h3 class="content__title">Раздел модератора</h3>

    <ul class="list">
        <li class="list-item list-item__root">
            <a href="{{ clientBaseUrl }}/moderator">Панель модератора</a>

            <ul class="list">
                <li class="list-item"><a href="{{ clientBaseUrl }}/moderator/settings">Настройки модуля 51 статьи</a></li>
                <li class="list-item"><a href="{{ clientBaseUrl }}/moderator/logs">Логи пользователей</a></li>
            </ul>
        </li>
    </ul>

    <h3 class="content__title">Другое</h3>

    <ul class="list">
        <li class="list-item list-item__root">
            <a href="/">Работа по назначению</a>

            <span class="list-item__description"
                >(* Этот раздел находится в реестре адвокатов в карточке адвоката, вкладка <em>"Работа по назначению"</em>)</span
            >
        </li>
    </ul>
</div>

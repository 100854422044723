export class MaterialIconHelper {
    static createMaterialIcon(iconName: string): string {
        return `<i class="app-icon material-icons">${iconName}</i>`;
    }
    static get phone() {
        return 'phone';
    }
    static get smartPhone() {
        return 'smartphone';
    }
    static get localPhone() {
        return 'local_phone';
    }
    static get check() {
        return 'check';
    }
    static get dateRange() {
        return 'date_range';
    }
    static get person() {
        return 'person';
    }
    static get user() {
        return MaterialIconHelper.person;
    }
    static get alternateEmail() {
        return 'alternate_email';
    }
    static get link() {
        return 'link';
    }
    static get locationCity() {
        return 'location_city';
    }
    static get file() {
        return 'insert_drive_file';
    }
    static get fileUpload() {
        return 'file_upload';
    }
    static get clear() {
        return 'clear';
    }
    static get close() {
        return MaterialIconHelper.clear;
    }
    static get delete() {
        return MaterialIconHelper.clear;
    }
    static get listAlt() {
        return 'list_alt';
    }
    static get helpOutline() {
        return 'help_outline';
    }
    static get visibility() {
        return 'visibility';
    }
}
/**
 * this class use fa 4+ version but starts from 5+ version fa use fas prefix, we need update this section
 */
export class FontAwesomeIconHelper {
    static get certificate() {
        // <i class="fas fa-file-certificate"></i>
        return 'fa fa-certificate';
    }
    static get skype(): string {
        return 'fa fa-skype';
    }
    static get telegramPlane(): string {
        return 'fab fa-telegram-plane';
    }
    static get telegram(): string {
        return 'fa fa-telegram';
    }
    static get vk(): string {
        return 'fa fa-vk';
    }
    static get facebook(): string {
        return 'fa fa-facebook';
    }
    static get twitter(): string {
        return 'fa fa-twitter';
    }

    static get pencil(): string {
        return 'fa fa-pencil';
    }
    static get medal(): string {
        return 'fas fa-medal';
    }
    static get fileAlt(): string {
        return 'fas fa-file-alt';
    }

    static get liveJournal(): string {
        // no icon in fa for alternative use oencil
        return FontAwesomeIconHelper.pencil;
    }
    static get globe(): string {
        return 'fa fa-globe';
    }
    static get webPage(): string {
        return FontAwesomeIconHelper.globe;
    }
    static get link(): string {
        return 'fa fa-link';
    }
    static get question(): string {
        return 'fa fa-question';
    }
    static get university(): string {
        return 'fa fa-university';
    }
    static get graduationCap(): string {
        return 'fa fa-graduation-cap';
    }
    static get fileText(): string {
        return 'fa  fa-file-text';
    }

    static createFaIcon(iconCss: string): string {
        return `<i class="app-icon ${iconCss}"></i>`;
    }
}

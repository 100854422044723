import { DataFilterValueDto } from '@shared/service-proxies';

/**
 * Базовый класс создания моделей фильтров для использования запросах данных
 */
export abstract class BaseDataFilterValueBuilder {
    /**
     * Id фильтра
     */
    protected _filterId: string;

    /**
     * Значение фильтра
     */
    protected _value: any;

    /**
     * Реализует алгоритм создания модели фильтра.
     * @protected
     */
    protected abstract _build(): DataFilterValueDto;

    /**
     * Создание модели фильтра
     */
    build(): DataFilterValueDto {
        if (!this._isValid()) {
            throw new Error('Не все данные были переданы в построитель фильтра. Например, filterId');
        }

        return this._build();
    }

    setFilterId(value: string): this {
        this._filterId = value;

        return this;
    }

    setValue(value: any): this {
        this._value = value;

        return this;
    }

    protected _isValid(): boolean {
        return !!this._filterId;
    }
}

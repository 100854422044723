import { find, cloneDeep, each, map as _map, filter } from 'lodash';
import { Observable, of, forkJoin } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { QueryOptionsDto, DataFilterValueDto, LawyerStatusReasonServiceProxy } from '../service-proxies';
import { IBasicAppServiceProxy } from './intrfaces/IBasicAppServiceProxy';

export class StorageInMemory<
    TDto extends { id: string | number },
    TProxy extends IBasicAppServiceProxy<TDto>
> {
    // protected _maxItemsInPart = 10000;
    protected _maxItemsInPart = 1000;
    private _storage: { [key: string]: TDto };
    private _proxy: TProxy;
    private _inSync = false;
    private _isFull = false;
    private _sync: Observable<void>;
    constructor(proxy: TProxy) {
        this._proxy = proxy;
        this._storage = Object.create(null);
    }
    get proxy() {
        return this._proxy;
    }

    /* #region Hybrid actions local + request */
    /**
     * Делает попытку получить Элемент из памяти и вернуть, иначе делает запрос.
     * Делает копию ответа, сохраняет в память и возвращает ответ.
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param id
     */
    getOrAdd(id: string, refresh = false): Observable<TDto> {
        return this._request(() => {
            const item = this.getItem(id);
            if (item && !refresh) {
                return of(item);
            }
            return this._proxy.get(id).pipe(
                take(1),
                map(response => {
                    this._storage[id] = cloneDeep(response);
                    return response;
                }),
            );
        });
    }
    /**
     * Пытается  найти локально все данные из списка по ключам,
     * Исключает из списка те результаты которые есть локально,
     * На остальные  Id делает запрос,
     * Результат конкатит с локальными данными и вовзращает копии всей коллекции.
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param idList
     */
    getOrAddMany(idList: string[]): Observable<TDto[]> {
        return this._request(() => {
            const items: TDto[] = [];
            const idsForGet: string[] = [];
            each(idList, i => {
                const item = this._storage[i];
                if (item) {
                    items.push(item);
                } else {
                    idsForGet.push(i);
                }
            });
            if (!idsForGet.length) {
                return of(items);
            }
            return this._proxy.getMany(idsForGet).pipe(
                take(1),
                map(response => {
                    each(response, (elem, key) => {
                        items.push(this._addItem(elem));
                    });
                    return items;
                }),
            );
        });
    }

    /**
     * Делает запрос на обновление сущностни, копию полученного ответа сохраняет в память
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param dto
     */
    update(dto: TDto): Observable<TDto> {
        return this._request(() => {
            return this._proxy.update(dto).pipe(
                map(response => {
                    this._storage[response.id] = cloneDeep(response);
                    return response;
                }),
            );
        });
    }
    /**
     * Делает запрос на создание одного элемента.
     * Делает копию результата и сохраняет в память.
     * возвращает созданный объект.
     * Если данные синхонизируются ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param dto
     */
    create(dto: TDto): Observable<TDto> {
        return this._request(() => {
            return this._proxy.create(dto).pipe(
                map(response => {
                    this._storage[response.id] = cloneDeep(response);
                    return response;
                }),
            );
        });
    }
    /**
     * этот метод есть не у всех проксей
     * Делает запрос на создание коллекции сущностей
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param dtos
     */
    createMany(dtos: TDto[]): Observable<TDto[]> {
        return this._request(() => {
            return (this._proxy as any).createMany(dtos).pipe(
                map((response: TDto[]) => {
                    each(response, r => {
                        this._storage[r.id] = cloneDeep(r);
                    });
                    return response;
                }),
            );
        });
    }

    /**
     * Удаляет локальную копию и делает запрос на получение
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param id
     */
    refreshItem(id: string): Observable<TDto> {
        this._deleteItem(id);
        return this.getOrAdd(id);
    }
    /**
     * Делает запрос на удаление по Id  затем удаляет элемент из памяти
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param id
     */
    delete(id: string): Observable<void> {
        return this._request(() => {
            return this._proxy.delete(id).pipe(
                map(() => {
                    this._deleteItem(id);
                }),
            );
        });
    }
    /**
     * Выбирает все ключи из памяти, делит их на порции запросов  исходя из
     * настроек размера коллекции
     * {@link StorageInMemory._maxItemsInPart }
     * Возвращает ответ когда вся коллекция буде загружена
     */
    synchronize(): Observable<void> {
        if (this._inSync) {
            return this._sync;
        }
        this._inSync = true;
        const ids = _map(this._storage, i => i.id);
        if (!ids || !ids.length) {
            return of(null);
        }

        const getMany = colIds =>
            this._proxy.getMany(colIds).pipe(
                take(1),
                map(response => {
                    each(response, (elem, key) => {
                        this._addItem(elem);
                    });
                }),
            );
        if (ids.length > this._maxItemsInPart) {
            const part = this._maxItemsInPart;
            const partIds = [];
            let bag = 0;
            let idx = 0;
            each(ids, id => {
                if (!partIds[bag]) {
                    partIds[bag] = [id];
                } else if (idx <= part) {
                    partIds[bag].push(id);
                    idx++;
                } else {
                    bag++;
                    partIds[bag] = [id];
                    idx = 1;
                }
            });

            const actions = _map(partIds, colIds => {
                return getMany(colIds);
            });
            this._sync = forkJoin(actions).pipe(
                map(() => {
                    this._inSync = false;
                }),
            );
        } else {
            this._sync = getMany(ids).pipe(
                map(() => {
                    this._inSync = false;
                }),
            );
            //  this._proxy.getMany(ids).pipe(
            //     take(1),
            //     map(response => {
            //         each(response, (elem, key) => {
            //             this._addItem(elem);
            //         });
            //         this._inSync = false;
            //     }),
            // );
        }

        return this._sync;
    }

    /**
     * Цепочка запросов.
     * 1. Получает информацию о размере коллекции
     * 2. Исходя из общего количества данных в ответе делит запрос на сстраницы
     * 3. В параллельном режиме загружает все страницы
     * 4. Результат сохраняет в память
     * 5. Устанавливает переменную _isFull  в тру что говорит другим методам
     *  о том что локальные данные загружены все из возможных
     * 6. Возвращает ответ тольпо при полной загрузке всех страниц
     * @param ignoreCache если выставлен в тру, несмотря на состояние кеша сделает запрос
     */
    fillAll<
        T extends {
            data: TDto[];
            isLastPage: boolean;
            pageCount: number;
            totalCount: number;
        }
    >(ignoreCache = false, dataFilter = null): Observable<void> {
        // 1e6
        if (!ignoreCache && this._isFull) {
            return of(null);
        }
        return this._request(() => {

            const onePart = this._maxItemsInPart;

            let opts;
            if (dataFilter) {
                opts = new QueryOptionsDto({
                    filterValues: [
                        dataFilter
                    ],
                    sortOptions: [] });
            } else {
                opts = new QueryOptionsDto({ filterValues: [], sortOptions: [] });
            }

            return this._proxy.queryPage(1, onePart, true, opts).pipe(
                switchMap(r => {
                    if (!r.isLastPage) {
                        each(r.data, i => this._addItem(i, true));

                        const total = r.totalCount;
                        const pages = Math.ceil(total / onePart);
                        const requests = [];

                        for (let page = 2; page <= pages; page++) {
                            requests.push(
                                this._proxy.queryPage(page, onePart, false, opts).pipe(
                                    map((partResponse: T) => {
                                        each(partResponse.data, i => this._addItem(i, true));
                                    }),
                                ),
                            );
                        }
                        return forkJoin(requests).pipe(
                            map(() => {
                                this._isFull = true;
                            }),
                        );
                    } else {
                        each(r.data, i => this._addItem(i, true));
                        this._isFull = true;
                    }
                    return of(null);
                }),
            );
        });
    }

    /**
     * Надстройка над fillAll
     * @param ignoreCache если выставлен в тру, несмотря на состояние кеша сделает запрос
     */
    getAll(ignoreCache = false): Observable<TDto[]> {
        return this.fillAll(ignoreCache).pipe(
            map(() => {
                return this.toArray();
            }),
        );
    }

    /* #endregion */

    /* #region Public Local actions */
    getItem(id: string, dontClone = false): TDto {
        const item = this._storage[id];
        return item ? (dontClone ? item : cloneDeep(item)) : null;
    }
    toArray(): TDto[] {
        if (!this._storage) {
            return null;
        }
        return _map(this._storage, item => {
            return cloneDeep(item);
        });
    }
    findById(container: TDto[], id: string) {
        return find(container, i => {
            return i.id === id;
        });
    }
    findBy(container: TDto[], predicate: (item: TDto) => boolean): TDto {
        return find(container, i => {
            return predicate(i);
        });
    }
    findByLocal(predicate: (item: TDto) => boolean): TDto {
        const item = find(this._storage, (value, key) => {
            return predicate(value);
        });
        if (item) {
            return cloneDeep(item);
        }
        return null;
    }
    filterByLocal(predicate: (item: TDto) => boolean): TDto[] {
        const list = filter(this._storage, (value, key) => {
            return predicate(value);
        });
        if (list && list.length) {
            return cloneDeep(list);
        }
        return null;
    }
    updateLocal(dto: TDto): TDto {
        return this._addItem(dto);
    }
    deleteLocal(id: string): void {
        this._deleteItem(id);
    }
    /* #endregion */

    protected _request<T>(asyncAction: () => Observable<T>): Observable<T> {
        if (!this._inSync) {
            return asyncAction();
        }
        return this._sync.pipe(
            switchMap(() => {
                return asyncAction();
            }),
        );
    }

    protected _addItem(item: TDto, dontClone = false): TDto {
        this._storage[item.id] = item;
        if (dontClone) {
            return item;
        }
        return cloneDeep(item);
    }
    private _deleteItem(id: string) {
        if (this._storage[id]) {
            delete this._storage[id];
        }
    }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';

/**
 * Сервис сравнения времени
 */
@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    /**
     * Возвращает разницу в секундах между проверяемым временем и текущим
     *
     * @param dateTime Проверяемое время
     */
    diffCurrent(dateTime: moment.Moment): number {
        const currentTime = this._getCurrentDateTime();

        console.log(currentTime, dateTime);

        return dateTime.unix() - currentTime.unix();
    }

    /**
     * Проверяет, истекло ли проверяемое время по оношению к текущему
     *
     * @param dateTime Проверяемое время
     */
    isTimeOver(dateTime: moment.Moment): boolean {
        const diffCurrent = this.diffCurrent(dateTime);

        return diffCurrent <= 0;
    }

    private _getCurrentDateTime(): moment.Moment {
        return moment();
    }
}

/**
 * Длительность в секундах временных промежутков
 */
export enum DateTimeDuration {
    minute = 60,
    hour = 3600,
    day = 86400,
}

﻿import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';

import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '../service-global';
import { AppAuthService } from '@shared/auth/app-auth.service';

// tslint:disable-next-line:no-empty-interface
export interface IRoutePermitionData extends Object {
    notAllowRedirectUrl: string;
    allowForRoles: string[];
}

@Injectable({
    providedIn: 'root',
})
export class AppRouteGuard implements CanActivate, CanActivateChild {

    protected readonly _permissionChecker: PermissionCheckerService;
    protected readonly _router: Router;
    protected readonly _appAuthService: AppAuthService;
    protected readonly _sessionService: AppSessionService;

    constructor(injector: Injector) {
        this._permissionChecker = injector.get(PermissionCheckerService);
        this._router = injector.get(Router);
        this._appAuthService = injector.get(AppAuthService);
        this._sessionService = injector.get(AppSessionService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._sessionService.user) {
            this._appAuthService.reEnter();
            return false;
        }
        if (!route.data) {
            return true;
        }
        if (route.data['allowForRoles']) {
            const roles = route.data['allowForRoles'] as string[];
            const containRole = this._sessionService.containAnyRoleFromList(roles);
            if (!containRole) {
                this._appAuthService.reEnter(this._selectBestNotAllowRoute(route.data as IRoutePermitionData));
            }
            return containRole;
        }
        if (!route.data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }

        this._appAuthService.reEnter(this._selectBestNotAllowRoute(route.data as IRoutePermitionData));
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    protected _selectBestNotAllowRoute(routeData?: IRoutePermitionData): string {
        if (routeData && routeData.notAllowRedirectUrl) {
            return routeData.notAllowRedirectUrl;
        }
        if (!this._sessionService.user) {
            console.log('selectBestNotAllowRoute');
            return '/account/login';
        }
        if (this._sessionService.containRole('admin')) {
            return '/app/admin/users';
        } else if (this._sessionService.containRole('moderator')) {
            // todo role moderator not exists in app, need server role name
            return '/moderator/request-list';
        } else if (this._sessionService.containRole('lawyer')) {
            return '/article-51/dashboard';
        } else if (this._sessionService.containRole('FormationAccount') || this._sessionService.containRole('OrganizationHead')) {
            return '/accountant-ao';
        } else {
            return '/article-51/show-info';
        }
    }
}

import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { timer, Subject, of } from 'rxjs';
import { takeUntil, take, delay } from 'rxjs/operators';
import * as moment from 'moment';
export enum AppProgressLoaderMode {
    overflow = 'overflow',
    content = 'content',
}

@Component({
    selector: 'app-progress-loader',
    templateUrl: './app-progress-loader.component.html',
    styleUrls: ['./app-progress-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppProgressLoaderComponent implements OnInit, OnDestroy {
    @Input() title = 'Загрузка...';
    @Input() mode = AppProgressLoaderMode.overflow;
    @Input() minTime = 1;
    ellipsis = Array(6);
    isOverflow: boolean;

    @Input() set showWhen(value: boolean) {
        this._setCondition(value);
    }
    private _condition: boolean;
    private _destructor: Subject<any> = new Subject();
    private _startTime: moment.Moment;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}
    get showWhen() {
        return this._condition;
    }
    ngOnInit() {
        this._changeDetectorRef.reattach();
        switch (this.mode) {
            case AppProgressLoaderMode.overflow:
                this.isOverflow = true;
                break;
            case AppProgressLoaderMode.content:
                this.isOverflow = false;
                break;
            default:
                this.isOverflow = true;
                break;
        }
        this._detectChanges();
    }
    ngOnDestroy(): void {
        this._destructor.next();
        this._destructor.complete();
        this._changeDetectorRef.detach();
    }

    private _setCondition(value: boolean) {
        if (value === this._condition) {
            return;
        } else if (value) {
            this._condition = value;
            this._startTime = moment();
        } else {
            const currentTime = moment();
            const diff = currentTime.diff(this._startTime);
            const minDelay = 1000 * this.minTime;
            const delta = minDelay - diff;
            if (diff < minDelay) {
                of(delta)
                    .pipe(
                        takeUntil(this._destructor),
                        take(1),
                        delay(delta),
                    )
                    .subscribe(() => {
                        this._condition = false;
                        this._detectChanges();
                    });
                // do something
            } else {
                this._condition = false;
            }
        }
        this._detectChanges();
    }
    private _detectChanges() {
        if (!this._destructor.isStopped) {
            this._changeDetectorRef.detectChanges();
        }
    }
}

import { OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppFormBtnType } from '@shared/enums';
import { values as _values, each, has } from 'lodash';
var MultiFieldComponent = /** @class */ (function () {
    function MultiFieldComponent(_changeDetection) {
        this._changeDetection = _changeDetection;
        this.disabled = false;
        /**
         * Значения полей
         */
        this.fieldNames = ['main'];
    }
    MultiFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.separator = this.separator || ';';
        this.addButtonName = this.addButtonName || 'Добавить';
        this.removeButtonName = this.removeButtonName || 'Удалить';
        this._createForm();
        var initialValues = (this.control.value || '').split(this.separator).filter(function (v) { return v; });
        each(initialValues, function (value, index) {
            _this.addField("control-" + index, value);
        });
        if (this.disabled) {
            this._setDisable();
        }
        this.control.statusChanges.subscribe(function (status) {
            if ('DISABLED' === status && _this.formGroup) {
                _this._setDisable();
            }
        });
    };
    MultiFieldComponent.prototype.ngOnChanges = function (changes) {
        if (has(changes, 'disabled') && this.formGroup) {
            if (changes['disabled'].currentValue) {
                this._setDisable();
            }
            else {
                this._setEnable();
            }
        }
    };
    MultiFieldComponent.prototype.addField = function (fieldName, value) {
        this.formGroup.addControl(fieldName, new FormControl(value));
        this.fieldNames.splice(this.fieldNames.length - 1, 0, fieldName);
        this.formGroup.get('main').setValue('');
    };
    MultiFieldComponent.prototype.removeField = function (fieldName) {
        this.fieldNames.splice(this.fieldNames.indexOf(fieldName), 1);
        this.formGroup.removeControl(fieldName);
    };
    MultiFieldComponent.prototype._createForm = function () {
        var _this = this;
        this.formGroup = new FormGroup({
            main: new FormControl(''),
        });
        this.formGroup.valueChanges.subscribe(function (values) {
            _this.addButton.disabled = !values['main'];
            _this.control.setValue(_values(values)
                .filter(function (v) { return v; })
                .join(_this.separator));
        });
        this.addButton = {
            type: AppFormBtnType.simple,
            disabled: true,
            onClick: function () {
                var fieldName = "control-" + _this.fieldNames.length;
                var value = _this.formGroup.get('main').value;
                if (value) {
                    _this.addField(fieldName, value);
                }
            },
            css: 'claim-form__btn',
            value: this.addButtonName,
        };
    };
    MultiFieldComponent.prototype._setDisable = function () {
        this.formGroup.disable({ onlySelf: true, emitEvent: false });
        this.addButton.disabled = true;
        this.disabled = true;
        this._changeDetection.detectChanges();
    };
    MultiFieldComponent.prototype._setEnable = function () {
        this.formGroup.enable({ onlySelf: true, emitEvent: false });
        this.addButton.disabled = false;
        this.disabled = false;
        this._changeDetection.detectChanges();
    };
    return MultiFieldComponent;
}());
export { MultiFieldComponent };

import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppFieldNotifierService {
    update = new Subject();
    emit<T>(instanceId: string, value: T) {
        this.update.next({
            instanceId,
            value,
        });
    }
}

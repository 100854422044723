﻿import { Injectable } from '@angular/core';
import { cloneDeep, find, each } from 'lodash';
import { from, Observable } from 'rxjs';
import {
    SessionServiceProxy,
    UserLoginInfoDto,
    TenantLoginInfoDto,
    ApplicationInfoDto,
    GetCurrentLoginInformationsOutput,
} from '@shared/service-proxies/service-proxies';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import * as moment from 'moment';
import Timer = NodeJS.Timer;

@Injectable({
    providedIn: 'root',
})
export class AppSessionService {

    get application(): ApplicationInfoDto {
        return cloneDeep(this._currentLoginInformations.application);
    }

    get user(): UserLoginInfoDto {
        return cloneDeep(this._currentLoginInformations.user);
    }

    get userId(): number {
        const user = this.user;

        return user ? user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return cloneDeep(this._currentLoginInformations.tenant);
    }

    get tenantId(): number {
        const tenant = this.tenant;

        return tenant ? tenant.id : null;
    }

    get currentLoginInformations(): GetCurrentLoginInformationsOutput {
        return cloneDeep(this._currentLoginInformations);
    }

    get isLawyer51Role(): boolean {
        if (this._currentLoginInformations &&
            this._currentLoginInformations.userRoles) {
                return this._currentLoginInformations.userRoles.indexOf('Lawyer51') >= 0;
        } else {
            return false;
        }
    }

    public static serverTime: moment.Moment | undefined;
    public static serverTimeInterval: Timer;

    private _currentLoginInformations: GetCurrentLoginInformationsOutput;

    constructor(
        private _sessionService: SessionServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService,
    ) {}

    getShownLoginName(): string {
        const userName = this.user.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        const tenant = this.tenant;

        return (tenant ? tenant.tenancyName : '.') + '\\' + userName;
    }

    init(): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {
            this._sessionService
                .getCurrentLoginInformations()
                .toPromise()
                .then(
                    (result: GetCurrentLoginInformationsOutput) => {
                        this._currentLoginInformations = result;

                        // Вытсавляем серверное время 1 раз при загрузке приложения
                        AppSessionService.serverTime = result.serverTime;
                        // Делаем увеличение на 1 сек по интервалу, для пподержания актуальности времени
                        if (AppSessionService.serverTimeInterval) {
                            clearInterval(AppSessionService.serverTimeInterval);
                        }
                        AppSessionService.serverTimeInterval = setInterval(() => {
                            AppSessionService.serverTime = AppSessionService.serverTime.add(1, 'second');
                        }, 1000);

                        resolve(true);
                    },
                    err => {
                        reject(err);
                    },
                );
        });
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this._isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    containAnyRoleFromList(roles: string[]): boolean {
        const info = this._currentLoginInformations;
        if (!info || !info.userRoles || !info.userRoles.length) {
            return false;
        }
        let hasRole = false;
        each(roles, role => {
            hasRole = this._containRole(role, info.userRoles);
            if (hasRole) {
                return false;
            }
        });
        return hasRole;
    }
    containRole(role: string) {
        const info = this._currentLoginInformations;
        if (!info) {
            return false;
        }
        return this._containRole(role, info.userRoles);
    }
    refresh(): Observable<boolean> {
        return from(this.init());
    }
    resetSessionUserInfo() {
        if (this._currentLoginInformations) {
            this._currentLoginInformations.user = new UserLoginInfoDto();
            this._currentLoginInformations.userRoles = [];
            this._currentLoginInformations.lawyerId = null;
            this._currentLoginInformations.initiatorId = null;
        }
    }

    private _containRole(role: string, activeUserRoles: string[]): boolean {
        if (!role || !activeUserRoles || !activeUserRoles.length) {
            return false;
        }
        const searchRole = role.toLowerCase();
        return !!find(activeUserRoles, (r: string) => {
            return r.toLowerCase() === searchRole;
        });
    }
    private _isCurrentTenant(tenantId?: number) {
        const tenant = this.tenant;

        if (!tenantId || !tenant) {
            return false;
        }

        return tenant.id === tenantId;
    }
}

import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '../service-global';
import { AppAuthService } from '@shared/auth/app-auth.service';
import * as i0 from "@angular/core";
var AppRouteGuard = /** @class */ (function () {
    function AppRouteGuard(injector) {
        this._permissionChecker = injector.get(PermissionCheckerService);
        this._router = injector.get(Router);
        this._appAuthService = injector.get(AppAuthService);
        this._sessionService = injector.get(AppSessionService);
    }
    AppRouteGuard.prototype.canActivate = function (route, state) {
        if (!this._sessionService.user) {
            this._appAuthService.reEnter();
            return false;
        }
        if (!route.data) {
            return true;
        }
        if (route.data['allowForRoles']) {
            var roles = route.data['allowForRoles'];
            var containRole = this._sessionService.containAnyRoleFromList(roles);
            if (!containRole) {
                this._appAuthService.reEnter(this._selectBestNotAllowRoute(route.data));
            }
            return containRole;
        }
        if (!route.data['permission']) {
            return true;
        }
        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }
        this._appAuthService.reEnter(this._selectBestNotAllowRoute(route.data));
        return false;
    };
    AppRouteGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    AppRouteGuard.prototype._selectBestNotAllowRoute = function (routeData) {
        if (routeData && routeData.notAllowRedirectUrl) {
            return routeData.notAllowRedirectUrl;
        }
        if (!this._sessionService.user) {
            console.log('selectBestNotAllowRoute');
            return '/account/login';
        }
        if (this._sessionService.containRole('admin')) {
            return '/app/admin/users';
        }
        else if (this._sessionService.containRole('moderator')) {
            // todo role moderator not exists in app, need server role name
            return '/moderator/request-list';
        }
        else if (this._sessionService.containRole('lawyer')) {
            return '/article-51/dashboard';
        }
        else if (this._sessionService.containRole('FormationAccount') || this._sessionService.containRole('OrganizationHead')) {
            return '/accountant-ao';
        }
        else {
            return '/article-51/show-info';
        }
    };
    AppRouteGuard.ngInjectableDef = i0.defineInjectable({ factory: function AppRouteGuard_Factory() { return new AppRouteGuard(i0.inject(i0.INJECTOR)); }, token: AppRouteGuard, providedIn: "root" });
    return AppRouteGuard;
}());
export { AppRouteGuard };

import { Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmpty, toString, eq, has } from 'lodash';
import * as moment from 'moment';
import { APP_REGEXP } from '../infrastructure/regexp';

export class AppValidators extends Validators {
    public static isEmptyString(ctrl: AbstractControl): ValidationErrors | null {
        if (!ctrl.touched) {
            return null;
        }
        const val = toString(ctrl.value || '').trim();
        if (isEmpty(val)) {
            return { isEmptyString: true };
        }
        return null;
    }

    public static isFullUserName(isFullUserNameRegexp: RegExp): ValidatorFn {
        const error = { isFullUserName: true };
        return (ctrl: AbstractControl) => {
            if (!ctrl.value) {
                return error;
            }

            const val = toString(ctrl.value)
                .replace(APP_REGEXP.replaceAllDoubleSpace, '')
                .trim();

            if (!isFullUserNameRegexp.test(val)) {
                return error;
            }
            return null;
        };
    }
    public static inPhoneOrEmail(ctrl: AbstractControl): ValidationErrors | null {
        const isPhone = APP_REGEXP.isPhone.test(ctrl.value);
        if (isPhone) {
            return null;
        }
        const isEmail = APP_REGEXP.isEmail.test(ctrl.value);
        if (isEmail) {
            return null;
        }
        return { inPhoneOrEmail: true };
    }

    public static isEmailValid(ctrl: AbstractControl): ValidationErrors | null {
        if (!ctrl.touched && !ctrl.dirty) {
            return null;
        }
        if (!ctrl.value) {
            return null;
        }
        if (!AppValidators.isEmailValidByValue(ctrl.value)) {
            return { isEmailValid: true };
        }
        return null;
    }
    public static isEmailValidByValue(val: string | number): boolean {
        val = toString(val).trim();
        const mailRegExp = APP_REGEXP.isEmail;
        return val.search(mailRegExp) >= 0;
    }

    public static isDefaultMobilePhoneMask(ctrl: AbstractControl): ValidationErrors | null {
        const error = { isDefaultMobilePhoneMask: true };
        const isCityPhone = AppValidators.isCityPhoneByValue(ctrl.value);

        if (isCityPhone) {
            return error;
        }
        const isDefault = AppValidators.isDefaultMobilePhoneMaskByValue(ctrl.value);
        if (isDefault) {
            return error;
        }
        return null;
    }
    public static isDefaultMobilePhoneMaskByValue(value: string): boolean {
        const val = value + '';
        const defaultMask = APP_REGEXP.defaultPhoneMask;
        const zeroMask = APP_REGEXP.zeroPhoneMask;
        const template = APP_REGEXP.phoneMaskTemplate;
        const defaultChar = APP_REGEXP.defaultPhoneMaskChar;
        const templateChar = APP_REGEXP.phoneMaskTemplateChar;
        if (
            eq(val, defaultMask) ||
            eq(val, template) ||
            eq(val, zeroMask) ||
            val.search(defaultChar) !== -1 ||
            val.search(templateChar) !== -1
        ) {
            return true;
        }
        return false;
    }

    public static dateRange(
        minDate: string | moment.Moment | Date,
        maxDate: string | moment.Moment | Date,
        format = 'DD.MM.YYYY',
        undefinedIsError = false,
    ): ValidatorFn {
        const min = moment(moment(minDate, format).format(format), format);
        const max = moment(moment(maxDate, format).format(format), format);
        const error = { dateRange: true };
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.touched) {
                return null;
            }
            const isEmptyVal = control.value === null || control.value === undefined || control.value === '';
            if (undefinedIsError && isEmptyVal) {
                return error;
            }

            if (isEmptyVal) {
                return null;
            }

            const date = AppValidators._getMomentDate(control.value);
            const inputDate = AppValidators._getInputDate(control.value);
            if (AppValidators._hasDateMask(inputDate)) {
                return error;
            }

            if (!date.isValid() || date.isAfter(max) || date.isBefore(min)) {
                return error;
            }

            return null;
        };
    }

    /**
     *
     * Создает валидатор для входящего значения отсносительно таргетингового времени (с учетом часового мояса)
     * и минимально максимальных отклонений от него
     * @param minRangeDuration абсолютно минимальное смещение времени относительно сейчас
     * @param maxRangeDuration абсолютно максимальное смещение времени относительно сейчас
     * @param utcOffsetDuration смещение времени для  value относительно utc
     * @param undefinedIsError
     */
    public static dateRangeOffsetNow(
        minRangeDuration: moment.Duration,
        maxRangeDuration: moment.Duration,
        utcOffsetDuration: moment.Duration,
        undefinedIsError = false,
    ): ValidatorFn {
        const error = { dateRange: true };
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.touched) {
                return null;
            }

            const isEmptyVal = control.value === null || control.value === undefined || control.value === '';
            if (undefinedIsError && isEmptyVal) {
                return error;
            }
            if (isEmptyVal) {
                return null;
            }
            const inputDate = AppValidators._getInputDate(control.value);
            if (AppValidators._hasDateMask(inputDate)) {
                return error;
            }

            const now = moment()
                .utc(true)
                .add(utcOffsetDuration);
            const min = now.clone().add(minRangeDuration);
            const max = now.clone().add(maxRangeDuration);
            const date = AppValidators._getMomentDate(control.value);

            const val = moment(date)
                .clone()
                .utc(true);
            if (val > max || val < min) {
                return error;
            }

            return null;
        };
    }

    public static isOnlyRussianLetters(
        errorKey: string = 'isOnlyRussianLetters',
        regexp: RegExp = APP_REGEXP.isOnlyRussianLetters,
    ): ValidatorFn {
        const e = {
            [errorKey]: true,
        };
        return (control: AbstractControl): ValidationErrors | null => {
            const isOnlyRussianLetters = regexp.test(control.value);

            if (!isOnlyRussianLetters) {
                return e;
            }
            return null;
        };
    }

    static isDomain(ctrl: AbstractControl): ValidationErrors | null {
        const value = ctrl.value;

        if (APP_REGEXP.isDomain.test(value)) {
            return null;
        }

        return {
            isDomain: true,
        };
    }

    public static isCityPhoneByValue(value: string, regexp = APP_REGEXP.isMoscowPhoneCode): boolean {
        if (!value) {
            return false;
        }
        value = (value + '').trim();
        if (value.length < 5) {
            return false;
        }
        return regexp.test(value);
    }
    public static isCityPhone(regexp = APP_REGEXP.isMoscowPhoneCode): ValidatorFn {
        return (control: AbstractControl) => {
            if (AppValidators.isCityPhoneByValue(control.value, regexp)) {
                return null;
            }
            return {
                isCityPhone: true,
            };
        };
    }
    public static isPhone(control: AbstractControl) {
        if (AppValidators.isPhoneByValue(control.value)) {
            return null;
        }
        return { isPhone: true };
    }
    public static isPhoneByValue(value: string): boolean {
        const val = value + '';
        return APP_REGEXP.isPhone.test(val);
    }
    public static isNumber(control: AbstractControl): ValidationErrors | null {
        if (AppValidators.isNumberByValue(control.value)) {
            return null;
        }
        return { isNumber: true };
    }
    public static isNumberByValue(value: string): boolean {
        return APP_REGEXP.isOnlyNumber.test(value);
    }
    public static isRegistryNumberByValue(value: string): boolean {
        const val = (value + '').replace(APP_REGEXP.replaceAllNotNumbers, '').replace(' ', '');

        if (!val) {
            console.log('!value', val);
            return false;
        }

        if (val.length < 3 || val.length > 8) {
            console.log('value.length < 3 || value.length > 8', val);
            return false;
        }

        if (value.startsWith('77/')) {
            return true;
        }

        return true;
    }

    public static isRegistryNumber(ctrl: AbstractControl): ValidationErrors | null {
        const isRegistry = AppValidators.isRegistryNumberByValue(ctrl.value);
        if (!isRegistry) {
            return { isRegistryNumber: true };
        }
        return null;
    }

    public static isMatched(ctrl1: AbstractControl, whenTouched = true): ValidatorFn {
        return (ctrl2: AbstractControl) => {
            if (whenTouched && (!ctrl1.touched || !ctrl2.touched)) {
                return null;
            }

            if (ctrl1.value !== ctrl2.value) {
                return { isMatched: true };
            }

            return null;
        };
    }

    private static _getMomentDate(value: string | moment.Moment | any): moment.Moment {
        let date: moment.Moment;
        if (typeof value === 'object' && has(value, 'moment')) {
            date = value.moment;
        } else {
            date = moment(value);
        }
        return date;
    }
    private static _getInputDate(value: string | any): string {
        if (typeof value === 'object' && has(value, 'input')) {
            return value.input;
        } else if (typeof value === 'string') {
            return value;
        }
        return '';
    }
    private static _hasDateMask(value: string, defaultMaskChar = APP_REGEXP.defaultMaskChar): boolean {
        if (!value) {
            return false;
        }
        return value.search(defaultMaskChar) >= 0;
    }
}

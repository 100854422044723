import { find, cloneDeep, each, map as _map, filter } from 'lodash';
import { of, forkJoin } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { QueryOptionsDto } from '../service-proxies';
var StorageInMemory = /** @class */ (function () {
    function StorageInMemory(proxy) {
        // protected _maxItemsInPart = 10000;
        this._maxItemsInPart = 1000;
        this._inSync = false;
        this._isFull = false;
        this._proxy = proxy;
        this._storage = Object.create(null);
    }
    Object.defineProperty(StorageInMemory.prototype, "proxy", {
        get: function () {
            return this._proxy;
        },
        enumerable: true,
        configurable: true
    });
    /* #region Hybrid actions local + request */
    /**
     * Делает попытку получить Элемент из памяти и вернуть, иначе делает запрос.
     * Делает копию ответа, сохраняет в память и возвращает ответ.
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param id
     */
    StorageInMemory.prototype.getOrAdd = function (id, refresh) {
        var _this = this;
        if (refresh === void 0) { refresh = false; }
        return this._request(function () {
            var item = _this.getItem(id);
            if (item && !refresh) {
                return of(item);
            }
            return _this._proxy.get(id).pipe(take(1), map(function (response) {
                _this._storage[id] = cloneDeep(response);
                return response;
            }));
        });
    };
    /**
     * Пытается  найти локально все данные из списка по ключам,
     * Исключает из списка те результаты которые есть локально,
     * На остальные  Id делает запрос,
     * Результат конкатит с локальными данными и вовзращает копии всей коллекции.
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param idList
     */
    StorageInMemory.prototype.getOrAddMany = function (idList) {
        var _this = this;
        return this._request(function () {
            var items = [];
            var idsForGet = [];
            each(idList, function (i) {
                var item = _this._storage[i];
                if (item) {
                    items.push(item);
                }
                else {
                    idsForGet.push(i);
                }
            });
            if (!idsForGet.length) {
                return of(items);
            }
            return _this._proxy.getMany(idsForGet).pipe(take(1), map(function (response) {
                each(response, function (elem, key) {
                    items.push(_this._addItem(elem));
                });
                return items;
            }));
        });
    };
    /**
     * Делает запрос на обновление сущностни, копию полученного ответа сохраняет в память
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param dto
     */
    StorageInMemory.prototype.update = function (dto) {
        var _this = this;
        return this._request(function () {
            return _this._proxy.update(dto).pipe(map(function (response) {
                _this._storage[response.id] = cloneDeep(response);
                return response;
            }));
        });
    };
    /**
     * Делает запрос на создание одного элемента.
     * Делает копию результата и сохраняет в память.
     * возвращает созданный объект.
     * Если данные синхонизируются ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param dto
     */
    StorageInMemory.prototype.create = function (dto) {
        var _this = this;
        return this._request(function () {
            return _this._proxy.create(dto).pipe(map(function (response) {
                _this._storage[response.id] = cloneDeep(response);
                return response;
            }));
        });
    };
    /**
     * этот метод есть не у всех проксей
     * Делает запрос на создание коллекции сущностей
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param dtos
     */
    StorageInMemory.prototype.createMany = function (dtos) {
        var _this = this;
        return this._request(function () {
            return _this._proxy.createMany(dtos).pipe(map(function (response) {
                each(response, function (r) {
                    _this._storage[r.id] = cloneDeep(r);
                });
                return response;
            }));
        });
    };
    /**
     * Удаляет локальную копию и делает запрос на получение
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param id
     */
    StorageInMemory.prototype.refreshItem = function (id) {
        this._deleteItem(id);
        return this.getOrAdd(id);
    };
    /**
     * Делает запрос на удаление по Id  затем удаляет элемент из памяти
     * Если данные синхонизируются, ожидает выполнения синхронизации
     * и только затем выполяет запрос
     * @param id
     */
    StorageInMemory.prototype.delete = function (id) {
        var _this = this;
        return this._request(function () {
            return _this._proxy.delete(id).pipe(map(function () {
                _this._deleteItem(id);
            }));
        });
    };
    /**
     * Выбирает все ключи из памяти, делит их на порции запросов  исходя из
     * настроек размера коллекции
     * {@link StorageInMemory._maxItemsInPart }
     * Возвращает ответ когда вся коллекция буде загружена
     */
    StorageInMemory.prototype.synchronize = function () {
        var _this = this;
        if (this._inSync) {
            return this._sync;
        }
        this._inSync = true;
        var ids = _map(this._storage, function (i) { return i.id; });
        if (!ids || !ids.length) {
            return of(null);
        }
        var getMany = function (colIds) {
            return _this._proxy.getMany(colIds).pipe(take(1), map(function (response) {
                each(response, function (elem, key) {
                    _this._addItem(elem);
                });
            }));
        };
        if (ids.length > this._maxItemsInPart) {
            var part_1 = this._maxItemsInPart;
            var partIds_1 = [];
            var bag_1 = 0;
            var idx_1 = 0;
            each(ids, function (id) {
                if (!partIds_1[bag_1]) {
                    partIds_1[bag_1] = [id];
                }
                else if (idx_1 <= part_1) {
                    partIds_1[bag_1].push(id);
                    idx_1++;
                }
                else {
                    bag_1++;
                    partIds_1[bag_1] = [id];
                    idx_1 = 1;
                }
            });
            var actions = _map(partIds_1, function (colIds) {
                return getMany(colIds);
            });
            this._sync = forkJoin(actions).pipe(map(function () {
                _this._inSync = false;
            }));
        }
        else {
            this._sync = getMany(ids).pipe(map(function () {
                _this._inSync = false;
            }));
            //  this._proxy.getMany(ids).pipe(
            //     take(1),
            //     map(response => {
            //         each(response, (elem, key) => {
            //             this._addItem(elem);
            //         });
            //         this._inSync = false;
            //     }),
            // );
        }
        return this._sync;
    };
    /**
     * Цепочка запросов.
     * 1. Получает информацию о размере коллекции
     * 2. Исходя из общего количества данных в ответе делит запрос на сстраницы
     * 3. В параллельном режиме загружает все страницы
     * 4. Результат сохраняет в память
     * 5. Устанавливает переменную _isFull  в тру что говорит другим методам
     *  о том что локальные данные загружены все из возможных
     * 6. Возвращает ответ тольпо при полной загрузке всех страниц
     * @param ignoreCache если выставлен в тру, несмотря на состояние кеша сделает запрос
     */
    StorageInMemory.prototype.fillAll = function (ignoreCache, dataFilter) {
        var _this = this;
        if (ignoreCache === void 0) { ignoreCache = false; }
        if (dataFilter === void 0) { dataFilter = null; }
        // 1e6
        if (!ignoreCache && this._isFull) {
            return of(null);
        }
        return this._request(function () {
            var onePart = _this._maxItemsInPart;
            var opts;
            if (dataFilter) {
                opts = new QueryOptionsDto({
                    filterValues: [
                        dataFilter
                    ],
                    sortOptions: []
                });
            }
            else {
                opts = new QueryOptionsDto({ filterValues: [], sortOptions: [] });
            }
            return _this._proxy.queryPage(1, onePart, true, opts).pipe(switchMap(function (r) {
                if (!r.isLastPage) {
                    each(r.data, function (i) { return _this._addItem(i, true); });
                    var total = r.totalCount;
                    var pages = Math.ceil(total / onePart);
                    var requests = [];
                    for (var page = 2; page <= pages; page++) {
                        requests.push(_this._proxy.queryPage(page, onePart, false, opts).pipe(map(function (partResponse) {
                            each(partResponse.data, function (i) { return _this._addItem(i, true); });
                        })));
                    }
                    return forkJoin(requests).pipe(map(function () {
                        _this._isFull = true;
                    }));
                }
                else {
                    each(r.data, function (i) { return _this._addItem(i, true); });
                    _this._isFull = true;
                }
                return of(null);
            }));
        });
    };
    /**
     * Надстройка над fillAll
     * @param ignoreCache если выставлен в тру, несмотря на состояние кеша сделает запрос
     */
    StorageInMemory.prototype.getAll = function (ignoreCache) {
        var _this = this;
        if (ignoreCache === void 0) { ignoreCache = false; }
        return this.fillAll(ignoreCache).pipe(map(function () {
            return _this.toArray();
        }));
    };
    /* #endregion */
    /* #region Public Local actions */
    StorageInMemory.prototype.getItem = function (id, dontClone) {
        if (dontClone === void 0) { dontClone = false; }
        var item = this._storage[id];
        return item ? (dontClone ? item : cloneDeep(item)) : null;
    };
    StorageInMemory.prototype.toArray = function () {
        if (!this._storage) {
            return null;
        }
        return _map(this._storage, function (item) {
            return cloneDeep(item);
        });
    };
    StorageInMemory.prototype.findById = function (container, id) {
        return find(container, function (i) {
            return i.id === id;
        });
    };
    StorageInMemory.prototype.findBy = function (container, predicate) {
        return find(container, function (i) {
            return predicate(i);
        });
    };
    StorageInMemory.prototype.findByLocal = function (predicate) {
        var item = find(this._storage, function (value, key) {
            return predicate(value);
        });
        if (item) {
            return cloneDeep(item);
        }
        return null;
    };
    StorageInMemory.prototype.filterByLocal = function (predicate) {
        var list = filter(this._storage, function (value, key) {
            return predicate(value);
        });
        if (list && list.length) {
            return cloneDeep(list);
        }
        return null;
    };
    StorageInMemory.prototype.updateLocal = function (dto) {
        return this._addItem(dto);
    };
    StorageInMemory.prototype.deleteLocal = function (id) {
        this._deleteItem(id);
    };
    /* #endregion */
    StorageInMemory.prototype._request = function (asyncAction) {
        if (!this._inSync) {
            return asyncAction();
        }
        return this._sync.pipe(switchMap(function () {
            return asyncAction();
        }));
    };
    StorageInMemory.prototype._addItem = function (item, dontClone) {
        if (dontClone === void 0) { dontClone = false; }
        this._storage[item.id] = item;
        if (dontClone) {
            return item;
        }
        return cloneDeep(item);
    };
    StorageInMemory.prototype._deleteItem = function (id) {
        if (this._storage[id]) {
            delete this._storage[id];
        }
    };
    return StorageInMemory;
}());
export { StorageInMemory };

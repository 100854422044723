import { ModuleWithProviders } from '@angular/core';
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
        };
    };
    return SharedModule;
}());
export { SharedModule };

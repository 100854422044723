import { UtilsService } from '@abp/utils/utils.service';
import { AppConsts } from '@shared/AppConsts';
import { of, from } from 'rxjs';
import { map } from 'rxjs/operators';
var SignalRAspNetCoreHelper = /** @class */ (function () {
    function SignalRAspNetCoreHelper() {
    }
    SignalRAspNetCoreHelper.initSignalR = function () {
        if (SignalRAspNetCoreHelper._initialized) {
            return of(false);
        }
        var token = AppConsts.authorization.encrptedAuthTokenName;
        if (!token) {
            console.error('SignalRAspNetCoreHelper:!token', AppConsts);
            return of(false);
        }
        var encryptedAuthToken = new UtilsService().getCookieValue(token);
        if (!encryptedAuthToken) {
            console.error('SignalRAspNetCoreHelper:!encryptedAuthToken', AppConsts);
            return of(false);
        }
        abp.signalr = {
            autoConnect: true,
            connect: undefined,
            hubs: undefined,
            qs: AppConsts.authorization.encrptedAuthTokenName + '=' + encodeURIComponent(encryptedAuthToken),
            remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
            startConnection: undefined,
            url: '/signalr',
        };
        return from(jQuery.getScript(AppConsts.appBaseUrl + '/assets/abp/abp.signalr-client.js')).pipe(map(function () {
            SignalRAspNetCoreHelper._initialized = true;
            return true;
        }));
    };
    SignalRAspNetCoreHelper._initialized = false;
    return SignalRAspNetCoreHelper;
}());
export { SignalRAspNetCoreHelper };

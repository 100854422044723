import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-preloader',
    templateUrl: './app-preloader.component.html',
    styleUrls: ['./app-preloader.component.scss'],
})
export class AppPreloaderComponent implements OnInit {
    @Input() message = 'Подождите, идет загрузка ...';
    @Input() isShow = false;

    constructor() {}

    ngOnInit() {}
}

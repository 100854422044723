import * as moment from 'moment';
import { indexOf } from 'lodash';

/**
 * Форматер временного периода.
 * ---
 *
 * Временной период может быть представлен строкой вида 0.00:00:00.0000000 (дни.часы:минуты:секунды.доли_секунды).
 * Формат выводимого значения задается шаблоном. Шаблон представляет строку,
 * в котором будут заменены специальные символы или группа символов.
 * Символы, отличные от специальных, заменены не будут.
 *
 * ## Специальные символы для замены в шаблоне:
 *
 * * d - число дней временного периода (0, 1);
 * * H - число часов временного периода (0, 1);
 * * HH - число часов временного периода с ведущим нулем (00, 01);
 * * m - число минут временного периода (0, 1);
 * * mm - число минут временного периода с ведущим нулем (00, 01);
 * * s - число секунд временного периода (0, 1);
 * * ss - число секунд временного периода с ведущим нулем (00, 01).
 *
 * ## Шаблон по умолчанию
 *
 * Если шаблон форматирования не передан, то будет использован шаблон по умолчанию - d.HH:mm:ss
 *
 * ## Пересчет значений
 *
 * Если в шаблоне форматирования не указаны, например, дни, а в передаваемом значении они есть, то значение дней будет
 * переведено в часы и добавлено к значению часов при выводе. Иначе говоря, если в шаблоне не указаны старшие параметры
 * (для часов - дни, для минут - часы, для секунд - минуты), то эти параметры будут преобразованы в значения младших и
 * добавлены к ним.
 */
export class DatePeriodFormatter {
    private static _instance: DatePeriodFormatter;

    readonly dayPattern = 'd';

    readonly hourLargePattern = 'HH';
    readonly hourShortPattern = 'H';

    readonly minuteLargePattern = 'mm';
    readonly minuteShortPattern = 'm';

    readonly secondLargePattern = 'ss';
    readonly secondShortPattern = 's';

    private _duration: moment.Duration;
    private _pattern: string;

    private constructor() {}

    private static get _formatter(): DatePeriodFormatter {
        if (!DatePeriodFormatter._instance) {
            DatePeriodFormatter._instance = new DatePeriodFormatter();
        }

        return DatePeriodFormatter._instance;
    }

    static transform(value: string, ...args: any[]): string {
        if (!value) {
            return '';
        }

        const formatter = DatePeriodFormatter._formatter;

        formatter._duration = moment.duration(value);
        formatter._pattern = args[0] || formatter._getDefaultPattern();

        return formatter._format(formatter._pattern);
    }

    private _format(pattern: string): string {
        const result = pattern
            .replace(this.dayPattern, `${this._getDays()}`)
            .replace(this.hourLargePattern, this._padZero(this._getHours()))
            .replace(this.hourShortPattern, `${this._getHours()}`)
            .replace(this.minuteLargePattern, this._padZero(this._getMinutes()))
            .replace(this.minuteShortPattern, `${this._getMinutes()}`)
            .replace(this.secondLargePattern, this._padZero(this._getSeconds()))
            .replace(this.secondShortPattern, `${this._getSeconds()}`);

        return this._sanitize(result);
    }

    private _getDefaultPattern(): string {
        return `${this.dayPattern}.${this.hourLargePattern}:${this.minuteLargePattern}:${this.secondLargePattern}`;
    }

    private _getDays(): number {
        return this._duration.days();
    }

    private _getHours(): number {
        let hours = this._duration.hours();

        if (!this._hasDayPattern()) {
            hours += this._getDays() * 24;
        }

        return hours;
    }

    private _getMinutes(): number {
        let minutes = this._duration.minutes();

        if (!this._hasHourPattern()) {
            minutes += this._getHours() * 60;
        }

        return minutes;
    }

    private _getSeconds(): number {
        let seconds = this._duration.seconds();

        if (!this._hasMinutePattern()) {
            seconds += this._getMinutes() * 60;
        }

        return seconds;
    }

    private _hasDayPattern(): boolean {
        return this._isPatternInclude(this.dayPattern);
    }

    private _hasHourPattern(): boolean {
        return this._isPatternInclude(this.hourShortPattern);
    }

    private _hasMinutePattern(): boolean {
        return this._isPatternInclude(this.minuteShortPattern);
    }

    private _isPatternInclude(value: string): boolean {
        if (!value) {
            return false;
        }

        return indexOf(this._pattern, value) !== -1;
    }

    private _padZero(value: number): string {
        if (value < 10) {
            return `0${value}`;
        }

        return `${value}`;
    }

    private _sanitize(value: string): string {
        return value.replace(/[dHms]+[.:]?/g, '').replace(/[.:]$/, '');
    }
}

import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OnDestroy, OnInit } from '@angular/core';
import { trim } from 'lodash';
import * as moment from 'moment';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DaterangepickerComponent } from './daterangepicker.component';
var DaterangepickerToggleComponent = /** @class */ (function () {
    function DaterangepickerToggleComponent(_overlay) {
        this._overlay = _overlay;
        this._dateFormat = 'DD.MM.YYYY';
        this._destructor$ = new Subject();
        this._disposed$ = new Subject();
    }
    DaterangepickerToggleComponent.prototype.ngOnInit = function () { };
    DaterangepickerToggleComponent.prototype.ngOnDestroy = function () {
        this._destructor$.next();
        this._destructor$.complete();
    };
    DaterangepickerToggleComponent.prototype.onClick = function () {
        this._overlayRef ? this._close() : this._open();
    };
    Object.defineProperty(DaterangepickerToggleComponent.prototype, "_config", {
        get: function () {
            return {
                hasBackdrop: true,
                backdropClass: '',
                positionStrategy: this._overlay
                    .position()
                    .flexibleConnectedTo(this.control)
                    .withPositions([
                    {
                        overlayX: 'start',
                        overlayY: 'top',
                        originX: 'start',
                        originY: 'bottom',
                    },
                ]),
            };
        },
        enumerable: true,
        configurable: true
    });
    DaterangepickerToggleComponent.prototype._open = function () {
        var _this = this;
        var portal = new ComponentPortal(DaterangepickerComponent);
        this._overlayRef = this._overlay.create(this._config);
        var component = this._overlayRef.attach(portal);
        var datepickerComponent = component.instance;
        datepickerComponent.autoApply = true;
        datepickerComponent.drops = 'up';
        datepickerComponent.opens = 'right';
        datepickerComponent.locale.separator = ' - ';
        datepickerComponent.locale.format = this._dateFormat;
        // параметр говорит о том, что date range picker создает данный компонент
        // влияет на отображение стрелок выбора дат
        datepickerComponent.options.isToggleComponent = true;
        var dateRange = this._createDateRangeFromString(this.control.value);
        if (dateRange.startDate && dateRange.startDate.isValid()) {
            datepickerComponent.setStartDate(dateRange.startDate);
        }
        if (dateRange.endDate && dateRange.endDate.isValid()) {
            datepickerComponent.setEndDate(dateRange.endDate);
        }
        datepickerComponent.choosedDate
            .asObservable()
            .pipe(takeUntil(merge(this._destructor$, this._disposed$)))
            .subscribe(function (value) {
            var startDate = value['startDate'];
            var endDate = value['endDate'];
            _this.control.value = (startDate && startDate.format(_this._dateFormat)) || '';
            _this.control.value += (endDate && ' - ' + endDate.format(_this._dateFormat)) || '';
            _this.control.dispatchEvent(new Event('input'));
        });
        this._overlayRef
            .backdropClick()
            .pipe(takeUntil(merge(this._destructor$, this._disposed$)))
            .subscribe(function () { return _this._close(); });
    };
    DaterangepickerToggleComponent.prototype._close = function () {
        if (this._overlayRef) {
            this._overlayRef.dispose();
            this._overlayRef = null;
            this._disposed$.complete();
            this._disposed$.next();
        }
    };
    DaterangepickerToggleComponent.prototype._createDateRangeFromString = function (value) {
        var dateValues = value.split('-');
        return {
            startDate: dateValues[0] && moment(trim(dateValues[0]), this._dateFormat),
            endDate: dateValues[1] && moment(trim(dateValues[1]), this._dateFormat),
        };
    };
    return DaterangepickerToggleComponent;
}());
export { DaterangepickerToggleComponent };

import { Injectable } from '@angular/core';
import { LitigationTypeDto, LitigationTypeServiceProxy } from '../../../service-proxies';
import { ApmReferenceToBasicServiceProxyAdapter } from '../ApmReferenceToBasicServiceProxyAdapter';

@Injectable({
    providedIn: 'root',
})
export class LitigationTypeServiceProxyAdapter extends ApmReferenceToBasicServiceProxyAdapter<LitigationTypeDto, LitigationTypeServiceProxy> {
    constructor(proxy: LitigationTypeServiceProxy) {
        super(proxy);
    }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LocalStorageBaseService } from '@shared/service-global';
import * as i0 from "@angular/core";
var AuthStorageService = /** @class */ (function (_super) {
    __extends(AuthStorageService, _super);
    function AuthStorageService() {
        var _this = _super.call(this, 'AuthStorageService') || this;
        _this.REMEMBER_CLIENT_LS_KEY = 'F6B4CD00-DE5A-4726-B01E-CC9ACC7D93E4';
        return _this;
    }
    Object.defineProperty(AuthStorageService.prototype, "rememberClientLs", {
        get: function () {
            return this.getOrCreateStorageProvider(this.REMEMBER_CLIENT_LS_KEY, Number.MAX_VALUE);
        },
        enumerable: true,
        configurable: true
    });
    AuthStorageService.ngInjectableDef = i0.defineInjectable({ factory: function AuthStorageService_Factory() { return new AuthStorageService(); }, token: AuthStorageService, providedIn: "root" });
    return AuthStorageService;
}(LocalStorageBaseService));
export { AuthStorageService };

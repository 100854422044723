import { EventEmitter } from '@angular/core';
/**
 * Сервис, который позволяет реализовать оповещения о событиях
 */
var BaseFilterService = /** @class */ (function () {
    function BaseFilterService() {
        this._onChange = new EventEmitter();
    }
    /**
     * Подписка на события.
     * ```typescript
     *    baseFilterService.onChange().subscribe((queryOptions) => {
     *      // do something
     *    });
     * ```
     */
    BaseFilterService.prototype.onChange = function () {
        return this._onChange.asObservable();
    };
    /**
     * Отправка оповещения о событии
     * @param {IQueryOptionsDto} queryOptions Данные
     */
    BaseFilterService.prototype.emitOnChange = function (queryOptions) {
        this._onChange.emit(queryOptions);
    };
    return BaseFilterService;
}());
export { BaseFilterService };

﻿import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    styles: [`:host {
        height: inherit;
    }`],
    template: `
        <router-outlet></router-outlet>
    `,
})
export class RootComponent {}

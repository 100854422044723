import { Pipe, PipeTransform } from '@angular/core';
import { UtilHelper } from '../helpers/util-helper';

@Pipe({
    name: 'phoneNumberFormatter',
})
export class PhonePipe implements PipeTransform {
    /**
     *
     * @param v
     * @param pattern default "x(xxx) xxx-xx-xx"
     */
    transform(v: string, pattern?: string) {
        return UtilHelper.viewPhoneNumberFormatter(v, pattern);
    }
}

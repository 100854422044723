import { OnInit, TemplateRef, DoCheck, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppFormBtnType } from './_imports';
var AppFormBtnComponent = /** @class */ (function () {
    function AppFormBtnComponent(_changeDetectorRef) {
        this._changeDetectorRef = _changeDetectorRef;
    }
    AppFormBtnComponent.prototype.ngOnInit = function () {
        switch (this.btn.type) {
            case AppFormBtnType.simple:
                this.btnTemplate = this.simple;
                break;
            case AppFormBtnType.icon:
                this.btnTemplate = this.icon;
                break;
            case AppFormBtnType.flat:
                this.btnTemplate = this.flat;
                break;
            case AppFormBtnType.raised:
                this.btnTemplate = this.raised;
                break;
            case AppFormBtnType.stroked:
                this.btnTemplate = this.stroked;
                break;
            case AppFormBtnType.fab:
                this.btnTemplate = this.fab;
                break;
            case AppFormBtnType.miniFab:
                this.btnTemplate = this.miniFab;
                break;
            // case AppFormBtnType.templateBtn:
            //     this.btnTemplate = this.templateBtn;
            //     break;
            default:
                this.btnTemplate = this.default;
                break;
        }
        this._old = {
            css: this.btn.css,
            disabled: this.btn.disabled,
            type: this.btn.type,
            value: this.btn.value,
            onClick: this.btn.onClick,
        };
    };
    AppFormBtnComponent.prototype.ngDoCheck = function () {
        var detectChanges;
        if (this._old.css !== this.btn.css) {
            detectChanges = true;
            this._old.css = this.btn.css;
        }
        if (this._old.disabled !== this.btn.disabled) {
            detectChanges = true;
            this._old.disabled = this.btn.disabled;
        }
        if (this._old.type !== this.btn.type) {
            detectChanges = true;
            this._old.type = this.btn.type;
        }
        if (this._old.value !== this.btn.value) {
            detectChanges = true;
            this._old.value = this.btn.value;
        }
        if (this._old.onClick !== this.btn.onClick) {
            detectChanges = true;
            this._old.onClick = this.btn.onClick;
        }
        if (detectChanges) {
            this._changeDetectorRef.detectChanges();
        }
    };
    AppFormBtnComponent.prototype.onClick = function (e) {
        if (e.defaultPrevented || this.btn.disabled) {
            return;
        }
        this.btn.onClick(e, this);
        this._changeDetectorRef.detectChanges();
    };
    AppFormBtnComponent.prototype.ngOnDestroy = function () {
        this.btn = null;
        this._old = null;
    };
    return AppFormBtnComponent;
}());
export { AppFormBtnComponent };

import * as i0 from "@angular/core";
var AuthTenantService = /** @class */ (function () {
    function AuthTenantService() {
    }
    AuthTenantService.prototype.getTenantId = function () {
        return abp.multiTenancy.getTenantIdCookie();
    };
    AuthTenantService.prototype.setTenantId = function (tenantId) {
        return abp.multiTenancy.setTenantIdCookie(tenantId);
    };
    AuthTenantService.ngInjectableDef = i0.defineInjectable({ factory: function AuthTenantService_Factory() { return new AuthTenantService(); }, token: AuthTenantService, providedIn: "root" });
    return AuthTenantService;
}());
export { AuthTenantService };

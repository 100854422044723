export var MatDrawerMode;
(function (MatDrawerMode) {
    MatDrawerMode["over"] = "over";
    MatDrawerMode["push"] = "push";
    MatDrawerMode["side"] = "side";
})(MatDrawerMode || (MatDrawerMode = {}));
var DefaultLayoutModel = /** @class */ (function () {
    function DefaultLayoutModel(config) {
        if (!config) {
            this.showBanner = true;
            this.routeLinks = [];
            this.footer = null;
            this.navMode = MatDrawerMode.side;
            this.navIsOpen = true;
            this.renderSidebar = true;
        }
        else {
            this.showBanner = config.showBanner;
            this.routeLinks = config.routeLinks;
            this.footer = config.footer;
            this.navMode = config.navMode;
            this.navIsOpen = config.navIsOpen;
            this.renderSidebar = !!config.renderSidebar;
        }
    }
    return DefaultLayoutModel;
}());
export { DefaultLayoutModel };

import * as moment from 'moment';
import { DataFilterValueDto } from '@shared/service-proxies';
import { BaseDataFilterValueBuilder } from '../base-data-filter-value-builder';

/**
 * Создатель моделей фильтров поиска по времени за 1 день
 */
export class DateDataFilterValueBuilder extends BaseDataFilterValueBuilder {
    private readonly DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

    /**
     * @override
     */
    protected _build(): DataFilterValueDto {
        const dateValue = moment(this._value).utcOffset(this._getUtcOffset(), true);

        let dateFrom = dateValue.clone();
        dateFrom.startOf('day');

        let dateTo = dateValue.clone();
        dateTo.endOf('day');

        return new DataFilterValueDto({
            dataFilterId: this._filterId,
            values: {
                fromValue: dateFrom.format(this.DATE_FORMAT),
                toValue: dateTo.format(this.DATE_FORMAT),
            },
        });
    }

    /**
     * Возвращает разницу (в минутах) временной зоны пользователя относительно UTC
     * @private
     */
    private _getUtcOffset(): number {
        const date = new Date();

        return date.getTimezoneOffset();
    }
}

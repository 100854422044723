var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { filter, orderBy } from 'lodash';
import { map, startWith } from 'rxjs/operators';
import { AppFormFieldType } from './_imports';
import { BaseAppOrderedField } from './_form-fields';
import { KeyValueStrings } from 'shared/interfaces';
var AppOrderedFieldAutocomplete = /** @class */ (function (_super) {
    __extends(AppOrderedFieldAutocomplete, _super);
    function AppOrderedFieldAutocomplete(sourceList, className) {
        var _this = this;
        if (!className) {
            className = 'AppOrderedFieldAutocomplete';
        }
        _this = _super.call(this, AppFormFieldType.autocomplete, className) || this;
        _this.sourceRefList = sourceList;
        _this.orderKey = 'value';
        _this.orderDirection = 'asc';
        return _this;
    }
    AppOrderedFieldAutocomplete.prototype.configureFiltration = function (ctrl) {
        var _this = this;
        this.filtredList = ctrl.valueChanges.pipe(startWith(''), map(function (i) { return (i ? i : ''); }), map(function (i) { return (typeof i === 'string' ? i : i.value); }), map(function (i) {
            return _this.map(i);
        }));
    };
    AppOrderedFieldAutocomplete.prototype.map = function (val) {
        var value = val;
        var col = val ? this.filter(this.sourceRefList, value) : this.sourceRefList ? this.sourceRefList.slice() : [];
        return this.sortByVal(col);
    };
    AppOrderedFieldAutocomplete.prototype.filter = function (coll, value) {
        var _this = this;
        return filter(coll, function (s) {
            return _this.filterPredicate(s, value);
        });
    };
    AppOrderedFieldAutocomplete.prototype.filterPredicate = function (item, value) {
        var v = (item.value + '').trim().toLowerCase();
        var sv = (value + '').trim().toLowerCase();
        return v.includes(sv);
    };
    AppOrderedFieldAutocomplete.prototype.sortByVal = function (coll) {
        return orderBy(coll, [this.orderKey], [this.orderDirection]);
    };
    AppOrderedFieldAutocomplete.prototype.onClick = function ($event, autoTrigger, ctrl, isArrow) {
        if (ctrl && !isArrow) {
            ctrl.reset();
        }
        autoTrigger.openPanel();
    };
    AppOrderedFieldAutocomplete.prototype.onClose = function ($event, autoTrigger, ctrl) {
        if ($event.defaultPrevented) {
            return;
        }
        $event.stopImmediatePropagation();
        autoTrigger.closePanel();
        if (ctrl) {
            ctrl.reset('');
        }
    };
    AppOrderedFieldAutocomplete.prototype.displayFn = function (value) {
        return value ? value.value : undefined;
    };
    AppOrderedFieldAutocomplete.prototype.onSelected = function ($event, ctrl) {
        // implements in children if need
    };
    AppOrderedFieldAutocomplete.prototype.isSelected = function (ctrl) {
        if (!ctrl || !ctrl.value) {
            return false;
        }
        if (this.sourceRefList && !this.sourceRefList.length) {
            return true;
        }
        if (ctrl.value instanceof KeyValueStrings) {
            return !!ctrl.value.key;
        }
        return false;
    };
    return AppOrderedFieldAutocomplete;
}(BaseAppOrderedField));
export { AppOrderedFieldAutocomplete };
